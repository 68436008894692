import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { ApiService } from '../../../services/api.service';
import { ToasterService } from '../../../services/toastr.service';
import { ValidationService } from '../../../services/validation.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ViewChild } from '@angular/core'

interface Stat {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-viewprofile',
  templateUrl: './viewprofile.component.html',
  styleUrls: ['./viewprofile.component.css']
})
export class ViewProfileComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  firstName:any='';
  lastname:any='';
  emailid:any='';
  country_id:any;
  stateid:any;
  districtid:any;
  cityid:any;
  Countries=[];
  States=[];
  Districts=[];
  Cities=[];
  coursedetails=[];
  incoursedetails=[];
  CertificationStatus=[];
  orderStatus=[];
  uservalue:any;
  uservalue1:any;
  hideTable = true;
  norecords = true;
  hideMain: boolean;
  detailedTab = true;
  coursetab=true;
  data;
  enable:boolean=false;
  disable:boolean=false;
  displayedColumns: string[] = ['username', 'Email','contact number','certification user','action'];

  objparti = {
    firstname: '',
    lastname:'',
    email: '',
    mobile: '',
    predoorno: '',
    prerelationship: '',
    preflat: '',
    prelandmark: '',
    prestreet: '',
    prearea: '',
    precity: '',
    predistrict: '',
    prestate: '',
    precountry: '',
    isenabled: 'true',
    uservalue:'',
  }
  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService,
    public toast: ToasterService,
    private spinner: NgxSpinnerService) { }

 ngOnInit(): void {
  this.getCountries();
  }
 getCountries(){
    var regionid = 5;
    this.service
    .get(this.service.getCountries)
    .subscribe((res) => {
      if (res != null) {
        // this.Countries = res;
        // for (let i = 0; i < res.length; i++) {
          // if (res[i].country_id == '35') {

            // this.Countries.push({
            //   country_id: res[i].country_id,
            //   country_name: res[i].country_name
            // })
            this.Countries = res.sort(function(a,b)
            {
              var st1 = a.country_name.toUpperCase();
              var st2 = b.country_name.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });

          // }
        // }
      }
    })
  }
  // console.log(this.country_id);

  getStates(id) {
    console.log(this.country_id);
    var countryid = id;
    if(countryid != "null"){
      this.service.get(this.service.getStates + countryid).subscribe((resp) => {
        if (resp != null) {
          this.States = resp.sort(function(a,b)
          {
            var st1 = a.statename.toUpperCase();
            var st2 = b.statename.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
          
        }
      })
    }
  }
  getDistricts(id) {
    var state_id = id;
    if(state_id != "null"){
      this.service.get(this.service.getDistricts + state_id).subscribe((resp) => {
        if (resp != null) {
          this.Districts = resp.sort(function(a,b)
          {
            var st1 = a.districtname.toUpperCase();
            var st2 = b.districtname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }
  getCities(id) {
    var district_id = id;
    if(district_id != "null"){
    this.service
      .get(this.service.getCities + district_id)
      .subscribe((resp) => {
        if (resp != null) {
          this.Cities = resp.sort(function(a,b)
          {
            var st1 = a.cityname.toUpperCase();
            var st2 = b.cityname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        
        }
      })
    }
  }

  
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  funValidate() {
    if (this.ValidationService.funValidateEmailFormat(this.emailid,
      this.ValidationService.msg_Invalid_Email_Format, '#mailformat')) {
      return true;
    }
  }
  // clearFields() {
  //   this.firstName = "",
  //     this.emailid = "",
  //     this.lastname = "",
  //     this.hideTable = true;
  //     var data = []
  //     this.data = new MatTableDataSource(data)
  //     this.data.paginator = this.paginator;
  // }
   // FOR PAGINATOR
   funSyncPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }

  searchResult(){
    // this.spinner.show()
    if (this.firstName == "" 
       && this.emailid == "" 
       && this.lastname == "" 
       && this.country_id == ""
       && this.stateid == ""
       && this.districtid ==""
       && this.cityid ==""
       ) {
      this.toast.showwarning(this.ValidationService.msg_Validate_empty)
      this.hideTable = true;
      this.norecords = true;
      this.data = []
      this.spinner.hide()
    } else {
      if (this.emailid != undefined && this.emailid != "") {
        if (this.funValidate()) {
          this.spinner.hide()
          return;
        }
      }
      if(this.cityid == undefined){
        this.cityid=0;
      }
      if(this.districtid == undefined){
        this.districtid=0;
      }
      if(this.stateid == undefined){
        this.stateid=0;
      }
      if(this.country_id==undefined)
      {
        this.country_id=0;
      }
      var particiobj = {
        first_name: this.firstName,
        last_name: this.lastname,
        user_email: this.emailid,
        pre_cityid: this.cityid,
        pre_districtid: this.districtid,
        pre_stateid: this.stateid,
        pre_countryid: this.country_id
       
      }
      this.service.post(this.service.getAdminParticipantdata, particiobj).subscribe(resp => {
        // this.spinner.hide()
        
        // console.log(resp)
        if (resp != [] && resp.length != 0) {
          this.hideTable = false;
          this.norecords = true;
          this.data = new MatTableDataSource(resp)
          this.data.paginator = this.paginator;
        } else {
          this.hideTable = false;
          this.norecords = false;
          resp = []
          this.data = new MatTableDataSource(resp)
          this.data.paginator = this.paginator;
        }
      })
    }
  }

  userCourses(){
     var userid=localStorage.getItem('uid');
     this.incoursedetails=[]
    this.service.get(this.service.getOrderSummaryByUser + userid).subscribe((resp) => {
      if (resp != null) {
        this.coursetab=false;
        for (let i = 0; i < resp.length; i++){
          this.incoursedetails.push({
            certificationid:resp[i].certificationid,
            certification_name:resp[i].certification_name,
            certification_code:resp[i].certification_code,
            certification_status_name:resp[i].certification_status_name
          })
          // var orderid=resp[i].order_id
          // this.service.get(this.service.getdetailsbyOrderID + orderid).subscribe(res => {
          //   if(res!=null){
          //     for(let k=0;k<res.length;k++){
          //       this.incoursedetails.push({
          //         certification_name:res[i].certification_name,
          //         firstname:res[i].firstname,
          //         lastname:res[i].lastname,
          //         email:res[i].email,
          //         mobilenumber:res[i].mobilenumber,
          //         enrolled_date:res[i].enrolled_date,
          //         submitted_date:res[i].submitted_date,
          //         completed_date:res[i].completed_date,
          //         course_examdate:res[i].course_examdate
          //       })
          //     }
          //   } 
          // })
          // this.coursedetails.push({
          //   order_id:resp[i].order_id,
          // })
        }
        // this.userCourseDetails();
      }
      else if(resp.length==0){
        this.coursetab = true;

      }else{
        this.coursetab = true;
      }
    })
  }
 
viewParticipantDetails(id){
  this.detailedTab=false;
  this.hideTable=true;
  this.hideMain=true;
  var userid=id;
  localStorage.setItem('uid',userid);
  this.service.get(this.service.getUsers + userid).subscribe((resp) => {
    if (resp != null) {
        this.objparti.firstname = resp[0].firstname;
        this.objparti.lastname  = resp[0].lastname;
        this.objparti.email = resp[0].email;
        this.objparti.mobile = resp[0].mobilenumber;
        this.objparti.predoorno = resp[0].predoorno;
        this.objparti.preflat = resp[0].preflat;
        this.objparti.prelandmark = resp[0].prelandmark;
        this.objparti.prestreet = resp[0].prestreet;
        this.objparti.prearea = resp[0].prearea;
        this.objparti.precity = resp[0].precity;
        this.objparti.predistrict = resp[0].predistrict;
        this.objparti.prestate = resp[0].prestate;
        this.objparti.precountry = resp[0].precountry;
        this.objparti.isenabled = resp[0].isenabled;
        localStorage.setItem('edbtn',this.objparti.isenabled);
        var ed=localStorage.getItem('edbtn')
        if(ed == 'true'){
          this.enable=true;
          this.objparti.uservalue='Enabled';
        }else{
          this.disable=true;
          this.objparti.uservalue='Disabled';
        }
        this.userCourses();
      }
    })
  }
  // userCourseDetails(){
  //   var orderid=0;
  //   var len=this.coursedetails.length;
  //   this.incoursedetails=[];
  //   for(let j=0;j<len ;j++)
  //   { 
  //     var i=j;
  //     orderid= this.coursedetails[i].order_id;
  //     this.service.get(this.service.getdetailsbyOrderID + orderid).subscribe(res => {
  //       if(res!=null){
  //         for(let k=0;k<res.length;k++){
  //           this.incoursedetails.push({
  //             certification_name:res[i].certification_name,
  //             firstname:res[i].firstname,
  //             lastname:res[i].lastname,
  //             email:res[i].email,
  //             mobilenumber:res[i].mobilenumber,
  //             enrolled_date:res[i].enrolled_date,
  //             submitted_date:res[i].submitted_date,
  //             completed_date:res[i].completed_date,
  //             course_examdate:res[i].course_examdate
  //           })
  //         }
  //       } 
  //     })
  //   }
  // }
  // getCertificationStatus() {
  //   this.service.get(this.service.getCertificationStatus).subscribe(resp => {
  //     this.CertificationStatus = resp
  //   })
  // }


edbtn:any;
userDisable(){
 var userid=Number(localStorage.getItem('uid'));
 var obj={
    user_id: userid ,
    is_enabled: false,
    is_active:false,
  }
  this.service.post(this.service.userEnableDisable, obj ).subscribe((resp)=>{
    if(resp==1){
      this.objparti.isenabled='Disabled';
      this.disable=false;
      this.enable=true;
      this.toast.showsuccess(this.ValidationService.msg_Validate_userDisabledSuccess);
    }else{
      this.objparti.isenabled='Enabled';
      this.disable=true;
      this.enable=false;
      this.toast.showwarning(this.ValidationService.msg_Validate_userDisabledFailure);
    }
  })
}
userEnable(){
  var userid=Number(localStorage.getItem('uid'));
  
  var obj2={
        user_id: userid ,
        is_enabled: true,
        is_active:true,
  }
    this.service.post(this.service.userEnableDisable, obj2).subscribe((resp)=>{
      if(resp==1){
        this.objparti.isenabled='Enabled';
        this.enable=false;
        this.disable=true;
        this.toast.showsuccess(this.ValidationService.msg_Validate_userenabledSuccess);
      }else{
          this.objparti.isenabled='Disabled';
          this.enable=true;
          this.disable=false;
          this.toast.showwarning(this.ValidationService.msg_Validate_userEnableFailure);
        }
    }) 
}

stepBack() {
  this.detailedTab = true;
  this.hideMain = false;
  // this.hideTable = false;
}

}