<div [hidden]="hideMain">
<h4 class="heading">Use this page to access and view participants in your region</h4>
<mat-card class="py-3 activeCourse">
  <mat-card-header>
    <mat-card-title class="m-0 p-0 participants">View Participants</mat-card-title>
</mat-card-header>
    <section class="page-section pb-4 mb-3" >
      <div class="container text-center mb-4">
        <!-- <h3 class="main-heading text-blue"></h3> -->
        <!-- <p class="display-5">There are <b>{{count}}</b> engineering professionals who successfully completed AEP
          Certification globally. <br> Search for certification holders by entering below details.</p> -->
          <p class="display-5">Search for enrolled and certified professionals in your region.</p>
        <div class="col-sm-12">
          <form>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group aep-form">
                  <mat-form-field class="example-full-width customForm">
                    <img src="../../assets/img/userIcon.jpg" alt="">
                    <mat-label>First Name<span></span></mat-label>
                    <input matInput class="custom-form-control" name="firstName" [(ngModel)]="firstName"
                      placeholder="Enter Your First Name">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group aep-form">
                  <mat-form-field class="example-full-width customForm">
                    <img src="../../assets/img/userIcon.jpg" alt="">
                    <mat-label>Last Name<span></span></mat-label>
                    <input matInput class="custom-form-control" name="lastname" [(ngModel)]="lastname"
                      placeholder="Enter Your Last Name">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group aep-form">
                  <mat-form-field class="example-full-width customForm">
                    <img src="../../assets/img/emailIcon.jpg" alt="">
                    <mat-label>Email<span></span></mat-label>
                    <input matInput class="custom-form-control" id="mailformat" name="emailid" [(ngModel)]="emailid"
                      placeholder="Enter Your Last Name">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group clsdrop">
                  <div class="">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Status</mat-label>
                      <mat-select name="lastname" [(ngModel)]="Status">
                        <mat-option *ngFor="let Status of CertificationStatus" [value]="Status.certification_status_name">
                          {{Status.certification_status_name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group clsdrop">
                  <mat-form-field class="example-full-width px-3">
                    <mat-label>From Date</mat-label>
                    <input matInput [matDatepicker]="picker" name="fromDate" [(ngModel)]="fromDate"
                      data-date-format='YYYY-MM-DD' value="fromDate" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group clsdrop">
                  <mat-form-field class="example-full-width px-3">
                    <mat-label>To After</mat-label>
                    <input matInput [matDatepicker]="picker1" name="toDate" [(ngModel)]="toDate" value="toDate" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
  
            <p class="mt-3">* At least one of First Name, Last Name, Email, Status, From Date or To Date fields must be
              entered.
            </p>
            <div class="form-group mt-3 px-1">
              <div class="text-center mt-4">
                <button type="submit" (click)="searchResult()" class="btn custom-btn py-3 width-mob">SEARCH</button>
              </div>
            </div>
          </form>
        </div>
      </div><br>
      <div class="col-md-12 d-block m-auto">
        <section class="">
            <div [hidden]="hideTable">
              <mat-paginator
          #paginator
          [pageSizeOptions]="[10, 20, 30]"
          showFirstLastButtons
        ></mat-paginator>
            <div class="table-widtht arppt"> 
              <table mat-table [dataSource]="data" class="mat-elevation-z8 filter-header remove-header-color tblwidth">
                <ng-container matColumnDef="certificationid">
                  <th class="header1" mat-header-cell *matHeaderCellDef> Certification Id </th>
                  <td mat-cell *matCellDef="let element"> {{element.certification_exam_id_code}} </td>
                </ng-container>
                <ng-container matColumnDef="username">
                  <th class="header1" mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let element"> {{element.username}} </td>
                </ng-container>
                <ng-container matColumnDef="certification_name">
                  <th class="tab-width" mat-header-cell *matHeaderCellDef>Certification Name</th>
                  <td mat-cell *matCellDef="let element"> {{element.certification_name}} </td>
                </ng-container>
                <ng-container matColumnDef="certification_status_name">
                  <th class="header1" mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <span class="status">
                      <div class="progress">
                          <div class="progress-bar progress-bar-striped progress-bar-animated"
                          [ngClass]="element.certification_status_name === 'Completed' ? 'bg-primary' : 'bg-success'"
                              role="progressbar" style="width:100%">
                              <span>{{element.certification_status_name}}</span>
                          </div>
                      </div>
                  </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th class="header123"  mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    <button mat-icon-button id="schemeToggle" [style.overflow]="'visible'" matTooltip="View" class=""
                      (click)="funview(element.order_id)">
                      <mat-icon>pageview</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <div [hidden]="norecords" class="center-align">
                <h3>No Records to Display</h3>
              </div>
              
            </div>
            <mat-paginator #paginator (page)="funSyncPaginator($event)" [pageSize]="paginator.pageSize"
            [pageIndex]="paginator.pageIndex" [length]="paginator.length"
            [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
          </div>
        </section>
      </div>
      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
      </ngx-spinner>
    </section>
  </mat-card>
</div>
  <div [hidden] = "detailedTab">
    <mat-card class="px-4 py-3 activeCourse large">
        <div class="row">
          <div class="col-sm-10">
            <mat-card-header>
              <mat-card-title class="m-0 p-0 participants second">
                Participant Certification Details
              </mat-card-title>
              <!-- <span class="material-icons float-right" >keyboard_backspace</span> -->
            </mat-card-header>
          </div>
          <div class="col-sm-2 text-right pr-5 pt-3 arp-backbtn">
            <button mat-raised-button (click)="stepBack()">Back</button>
          </div>
        </div>
        <mat-card-content>
            <div class="col-md-12" *ngFor="let pdetails  of participantDetails">
                <div class="bordered">
                    <h5 class="activeCourse-heading">{{pdetails.certification_name}}</h5>
                    <div class="row px-3">
                        <div class="col-md-12 col-sm-12">
                            <p><label>FirstName</label>{{pdetails.firstname}}</p>
                            <p><label>LastName</label>{{pdetails.lastname}}</p>
                            <p class="break-word"><label>Email</label>{{pdetails.email}}</p>
                            <p><label>MobileNumber</label>{{pdetails.mobilenumber}}</p>
                            <!-- <p><label>Certification Name</label>{{pdetails.certification_name}}</p> -->
                            <div *ngIf="pdetails.enrolled_date!=null">
                              <p><label>Enrolled Date</label>{{pdetails.enrolled_date}}</p>
                             </div>
                             <div *ngIf="pdetails.enrolled_date == null">
                              <p><label>Enrolled Date</label>NA</p>
                             </div>
                             <div *ngIf="pdetails.course_examdate != null">
                              <p><label>Planned Exam Date</label>{{pdetails.course_examdate}}</p>
                             </div>
                             <div *ngIf="pdetails.course_examdate == null">
                              <p><label>Planned Exam Date</label>NA</p>
                             </div>
                             <div *ngIf="pdetails.submitted_date != null">
                              <p><label>SBT/PBP Submission Date</label>{{pdetails.submitted_date}}</p>
                             </div>
                             <div *ngIf="pdetails.submitted_date == null">
                              <p><label>SBT/PBP Submission Date</label>NA</p>
                             </div>
                             <div *ngIf="pdetails.completed_date!= null">
                              <p><label>Certification Completion Date</label>{{pdetails.completed_date}}</p>
                            </div>
                            <div *ngIf="pdetails.completed_date == null">
                              <p><label>Certification Completion Date</label>NA</p>
                            </div>
                            <div>
                              <p><label>Certification Status</label>
                                <span class="form-group d-inline-block">
                                  <div class="">
                                    <mat-form-field>
                                      <mat-label>Status</mat-label>
                                      <mat-select name="orderStatus" [(ngModel)]="orderStatus"  [disabled]="isdisabledstatus">
                                        <mat-option *ngFor="let orderStatus of CertificationStatus" [value]="orderStatus.certification_status_id">
                                          {{orderStatus.certification_status_name}}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                </span>
                              </p>
                            </div>
                            <!-- <p><label>Upload Doc Link</label>{{pdetails.file_location}}</p> -->
                          </div>
                          <!-- <div class="col-md-10">
                            <p><label>Certification Status</label></p>
                          <div class="col-sm-4">
                            <div class="form-group dropdowncls">
                              <div class="">
                                <mat-form-field class="example-full-width px-3">
                                  <mat-label>Status</mat-label>
                                  <mat-select name="orderStatus" [(ngModel)]="orderStatus"  [disabled]="isdisabledstatus">
                                    <mat-option *ngFor="let orderStatus of CertificationStatus" [value]="orderStatus.certification_status_id">
                                      {{orderStatus.certification_status_name}}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          </div> -->
                          <div class="ActiveCourseDetails">
                            <h6>ARP Details</h6>
                            <div class="row px-3">
                                <div class="col-md-12">
                                    <p><label>ARP Name</label>{{pdetails.arp_name}}</p>
                                </div>
                                <div class="col-md-12">
                                    <p><label>Contact Number</label>{{pdetails.arp_number}}</p>
                                    <p><label>Email</label>{{pdetails.arp_email}}</p>
                                </div>
                            </div>
                            <h6>TIP Centre Details</h6>
                            <div class="row px-3 mb-3">
                                <div class="col-md-12">
                                    <p><label>TIP Centre Name</label>{{pdetails.tipname}}</p>
                                </div>
                                <div class="col-md-12">
                                    <p><label>Contact Person Name</label>{{pdetails.tipcontactname}}</p>
                                    <p><label>Contact Number</label>{{pdetails.tip_mobile}}</p>
                                    <p><label>Email</label>{{pdetails.tip_email}}</p>
                                    <p><label>Website</label>{{pdetails.orgwebsite}}</p>
                                    <p><label>Area</label>{{pdetails.orgaddress}}</p>
                                    <p><label>City</label>{{pdetails.orgcity}}</p>
                                    <p><label>District</label>{{pdetails.orgdistrict}}</p>
                                </div>
                            </div>
                            <!-- <h6>view upload pdf</h6>
                            <div class="viewPDF">
                              <a href="#">
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <caption>samplepdffile.pdf</caption>
                              </a>
                              <a href="#">
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <caption>samplepdffile55555555555555555555555555.pdf</caption>
                            </a>
                              <a href="#">
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <caption>samplepdffile.pdf</caption>
                              </a>
                              <a href="#">
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <caption>samplepdffile.pdf</caption>
                              </a>
                              <a href="#">
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <caption>samplepdffile.pdf</caption>
                              </a>
                            </div> -->
                          </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
  </div>