<mat-card class="py-4 px-4" *ngIf="viewarp">
    <div class="row">
        <div class="col-7">
            <h5>ARP-TIP Centre Mapping</h5>
        </div>
    </div>
    <p class="mt-3"><b class="note">*</b> Please Select Country and State to View the ARP </p>
    <div class="row">
        <div class="col-md-3">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Country:</mat-label>
                <mat-select #countcountryid [(ngModel)]="country" name="country"
                  (selectionChange)="getStates($event.value)">
                  <mat-option *ngFor="let Country of CCountries" [value]="Country.country_id">
                    {{Country.country_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="example-full-width px-3">
              <mat-label>State</mat-label>
              <mat-select #countstateid name="state" [(ngModel)]="state"
              (selectionChange)="statefilter($event.value)">
                <mat-option *ngFor="let State of States" [value]="State">
                  {{State.statename}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          </div>
        <table class="table table-border">
            <thead class="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">ARP Id</th>
                <th scope="col">Company Name</th>
                <!-- <th scope="col">Contact Person Name</th> -->
                <th scope="col">Email</th>
                <th scope="col">Contact Number</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of arpdetails, let i = index">
                    <td>{{i+1}}</td>
                    <!-- <td>{{data.aepid}}</td> -->
                    <td>{{data.arp_id}}</td>
                    <td>{{data.company_name}}</td>
                    <!-- <td>{{data.contact_person_name}}</td> -->
                    <td>{{data.arp_email}}</td>
                    <td>{{data.mobile_number}}</td>
                    <td>
                        <button mat-stroked-button color="primary" (click)="viewdetails(data)">View</button>
                    </td>
                </tr>
            </tbody>
          </table>
          <div [hidden]="norecords" class="center-align">
            <h3>No Records to Display</h3>
          </div>
</mat-card>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<mat-card *ngIf="showdetails" class="details">
    <button mat-stroked-button color="primary" (click)="showArp()">Back</button>
    <div class="row mt-3">
        <div class="col-6" >
            <mat-card *ngFor="let data2 of indarpdetails, let i = index" class="card-height">
                <h6>ARP Details:</h6>
                <p><label>Company Name:</label>{{data2.company_name}}</p>
                <p><label>Contact Person Name:</label>{{data2.contact_person_name}}</p>
                <p><label>Mobile:</label>{{data2.mobile_number}}</p>
                <p><label>Email:</label>{{data2.arp_email}}</p>
                <p><label>Area:</label>{{data2.office_area}}</p>
                <p><label>Street:</label>{{data2.office_street}}</p>
                <p><label>City:</label>{{data2.office_city}}</p>
                <p><label>District:</label>{{data2.office_district}}</p>
                <p><label>State:</label>{{data2.office_state}}</p>
                <p><label>Country:</label>{{data2.offfice_country}}</p> 
            </mat-card>
        </div>
        <div class="col-6">
            <mat-card *ngFor="let data of indtipdetails, let i = index" class="card-height">
                <button mat-raised-button color="primary" class="btn" (click)="assign(data)" >Assign ARP</button>  
                <h6>TIP Centre {{i+1}} Details:</h6>
                <p><label>TIP Centre Name:</label>{{data.orgname}}</p>
                <p><label>Contact Person Name:</label>{{data.firstname}} {{data.lastname}}</p>
                <p><label>Contact Number:</label>{{data.mobilenumber}}</p>
                <p><label>Email Id:</label>{{data.email}}</p>
                <p><label>Address:</label>{{data.orgaddress}}</p>
                <p><label>City:</label>{{data.orgcity}}</p>
                <p><label>District:</label>{{data.orgdistrict}}</p>
                <p><label>State:</label>{{data.orgstate}}</p>
                <p><label>Country:</label>{{data.orgcountry}}</p>
                <!-- <p><label>Assigned from:</label>{{data2.arp_tip_mapped_date}}</p> -->
            </mat-card>
            <div [hidden]="notiprecords" class="center-align">
              <h3>No TIP Centres available in this Region.</h3>
            </div>
        </div>
    </div>
</mat-card>
