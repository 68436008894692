<mat-card class="py-4 px-4" *ngIf="viewtip">
            <h5>TIP Centre Authorization</h5>
            <p class="mt-3"><b class="note">* </b> Please Select Country, State and Organisation name to View the Organisations. </p>
              <div class="row">
              <div class="col-md-3">
                <mat-form-field class="example-full-width px-3">
                  <mat-label>Country:</mat-label>
                  <mat-select #countcountryid [(ngModel)]="country" name="country"
                    (selectionChange)="getStates($event.value)">
                    <mat-option *ngFor="let Country of CCountries" [value]="Country.country_id">
                      {{Country.country_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="example-full-width px-3">
                  <mat-label>State</mat-label>
                  <mat-select #countstateid name="state" [(ngModel)]="state"
                    (selectionChange)="statefilter($event.value)">
                    <mat-option *ngFor="let State of States" [value]="State">
                      {{State.statename}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-3" >
                <mat-form-field class="example-full-width px-3" [hidden]="orgdis" >
                  <mat-label>Organisation name</mat-label>
                  <mat-select #organisationid name="org" [(ngModel)]="org"
                    (selectionChange)="orgfilter($event.value)">
                    <mat-option *ngFor="let data of tipdetails" [value]="data.orgname">
                      {{data.orgname}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            
            <table class="table table-border">
            <thead class="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Organisation Name</th>
                <th scope="col">Type</th>
                <!-- <th scope="col">Website</th> -->
                <th scope="col">Email</th>
                <th scope="col">Contact Number</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let data of orgdetails, let i = index">
                    <td>{{i +1}}</td>
                    <td>{{data.orgname}}</td>
                    <td>{{data.industrytype}}</td>
                    <!-- <td>{{data.orgwebsite}}</td> -->
                    <td>{{data.email}}</td>
                    <td>{{data.mobilenumber}}</td>
                    <td>
                        <button mat-stroked-button color="primary" (click)="viewdetails(data)">View</button>
                    </td>
                </tr>
            </tbody>
          </table>
          <div [hidden]="norecords" class="center-align">
            <h3>No Records to Display</h3>
          </div>
</mat-card>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<mat-card *ngIf="showdetails" class="details">
  <button mat-stroked-button color="primary" (click)="showTip()">Back</button>
  <div class="row mt-3">
      <div class="col-6" >
          <mat-card *ngFor="let data of indtipdetails, let i = index">
            <button mat-raised-button color="primary" class="btn" (click)="convert(data)" >Convert to TIP</button>  
            <h6>Organisation Details:</h6>
              <p><label>Organisation Name:</label>{{data.orgname}}</p>
              <p><label>Contact Person Name:</label>{{data.firstname}} {{data.lastname}}</p>
              <p><label>Contact Number:</label>{{data.mobilenumber}}</p>
              <p><label>Email Id:</label>{{data.email}}</p>
              <p><label>Address:</label>{{data.orgaddress}}</p>
              <p><label>City:</label>{{data.orgcity}}</p>
              <p><label>District:</label>{{data.orgdistrict}}</p>
              <p><label>State:</label>{{data.orgstate}}</p>
              <p><label>Country:</label>{{data.orgcountry}}</p>
          </mat-card>
      </div>