<h4 class="heading">Please update your complete profile,It is mandatory to update location details in the profile in
  order to select ARP and TIP Centre, and enroll for certifications.</h4>
<mat-card>
  <mat-card-header>
    <mat-card-title class="trainer">{{isProfile}} Your Profile</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="example-form">
      <div class="row">
        <div class="col-sm-3">
          <mat-form-field class="example-full-width px-3">
            <mat-label>First Name<span class="required-mark">*</span></mat-label>
            <input matInput placeholder="Enter your First Name" name="frstname" [(ngModel)]="obj.student_firstname">
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-form-field class="example-full-width px-3">
            <mat-label>Last Name<span class="required-mark">*</span></mat-label>
            <input matInput placeholder="Enter your Last Name" name="lastname" [(ngModel)]="obj.student_lastname">
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-form-field class="example-full-width px-3">
            <mat-label>Email Id<span class="required-mark">*</span></mat-label>
            <input matInput placeholder="Enter your Email Id" [disabled]="st_email" name="email"
              [(ngModel)]="obj.student_email">
          </mat-form-field>
        </div>
        <div class="col-sm-3">

          <mat-form-field class="example-full-width px-3">
            <mat-label>Mobile<span class="required-mark">*</span></mat-label>
            <input matInput name="mobile" [(ngModel)]="obj.student_mobile" placeholder="Enter your Mobile Number">
          </mat-form-field>
        </div>
      </div>
      <span class="required-note2">Note:Firstname and Lastname will be as it is displayed on the Certificate when issued
        by AEP Certification.</span>
      <div class="col-sm-12">
        <div class="profileSection">
          <mat-tab-group>
            <mat-tab label="Present Address">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Door Number</mat-label>
                      <input matInput name="predoor" [(ngModel)]="obj.student_predoorno"
                        placeholder="Enter your Door Number">
                    </mat-form-field>
                  </div>

                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Flat/Floor/Building</mat-label>
                      <input matInput name="preflat" [(ngModel)]="obj.student_preflat"
                        placeholder="Enter your Flat/Floor">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Street/Colony</mat-label>
                      <input matInput name="predtreet" [(ngModel)]="obj.student_prestreet"
                        placeholder="Enter your Area">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Area<span class="required-mark">*</span></mat-label>
                      <input matInput name="prestreet" [(ngModel)]="obj.student_prearea" placeholder="Enter Area">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Landmark</mat-label>
                      <input matInput name="preland" [(ngModel)]="obj.student_prelandmark" placeholder="Enter Landmark">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">

                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Country<span class="required-mark">*</span></mat-label>
                      <mat-select #countid name="precount" [(ngModel)]="obj.country_id"
                        (selectionChange)="getStatesm($event.value)">
                        <mat-option *ngFor="let Country of Countries" [value]="Country.country_id">
                          {{Country.country_name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>State<span class="required-mark">*</span></mat-label>
                      <mat-select #countstateid name="prestat" [(ngModel)]="obj.stateid"
                        (selectionChange)="getDistrictsm($event.value)">
                        <mat-option *ngFor="let State of States" [value]="State.stateid">
                          {{State.statename}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">

                    <mat-form-field class="example-full-width px-3">
                      <mat-label>District<span class="required-mark">*</span></mat-label>
                      <mat-select #countdistid name="predist" [(ngModel)]="obj.districtid"
                        (selectionChange)="getCitiesm($event.value)">
                        <mat-option *ngFor="let District of Districts" [value]="District.districtid">
                          {{District.districtname}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">

                    <mat-form-field class="example-full-width px-3">
                      <mat-label>City/Town/Area<span class="required-mark">*</span></mat-label>
                      <mat-select #countcityid name="precity" [(ngModel)]="obj.cityid">
                        <mat-option *ngFor="let City of Cities" [value]="City.cityid">
                          {{City.cityname}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Pincode<span class="required-mark">*</span></mat-label>
                      <input matInput name="prepin" [(ngModel)]="obj.student_prepincode" placeholder="Enter Pincode">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0">
                    <div class="form-check mt-4">
                      <mat-checkbox class="form-check-label example-margin displaywat" name="issame"
                        (change)="issame($event)" [(ngModel)]="issameaddress">Update Permanent Address same as Present
                        Address</mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Permanent Address" [disabled]="ispermanent">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Door Number</mat-label>
                      <input matInput name="perdoor" [(ngModel)]="obj.student_perdoorno"
                        placeholder="Enter your Door Number">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Flat/Floor/Building</mat-label>
                      <input matInput name="perflat" [(ngModel)]="obj.student_perflat"
                        placeholder="Enter your Flat/Floor">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Street</mat-label>
                      <input matInput name="perstreet" [(ngModel)]="obj.student_perstreet"
                        placeholder="Enter your Area">
                    </mat-form-field>
                  </div>

                </div>
                <div class="row">

                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Area<span class="required-mark">*</span></mat-label>
                      <input matInput name="perarea" [(ngModel)]="obj.student_perarea" placeholder="Enter Area">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Landmark</mat-label>
                      <input matInput name="perland" [(ngModel)]="obj.student_perlandmark" placeholder="Enter Landmark">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">

                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Country<span class="required-mark">*</span></mat-label>
                      <mat-select #percountid name="percount" [(ngModel)]="obj.country_id2"
                        (selectionChange)="getStates2($event.value)">
                        <mat-option *ngFor="let Countrys of CCountries" [value]="Countrys.country_id">
                          {{Countrys.country_name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">

                  <div class="col-sm-4 pr-0 mr-l">

                    <mat-form-field class="example-full-width px-3">
                      <mat-label>State<span class="required-mark">*</span></mat-label>
                      <mat-select #percountstateid name="perstate" [(ngModel)]="obj.stateid2"
                        (selectionChange)="getDistricts2($event.value)">
                        <mat-option *ngFor="let Stat of SStates" [value]="Stat.stateid">
                          {{Stat.statename}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-4 pr-0 mr-l">

                    <mat-form-field class="example-full-width px-3">
                      <mat-label>District<span class="required-mark">*</span></mat-label>
                      <mat-select #percountdistid name="perdist" [(ngModel)]="obj.districtid2"
                        (selectionChange)="getCities2($event.value)">
                        <mat-option *ngFor="let Dist of DDistricts" [value]="Dist.districtid">
                          {{Dist.districtname}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                  <div class="col-sm-4 pr-0 mr-l">

                    <mat-form-field class="example-full-width px-3">
                      <mat-label>City/Town/Area<span class="required-mark">*</span></mat-label>
                      <mat-select #percountcityid name="percity" [(ngModel)]="obj.cityid2">
                        <mat-option *ngFor="let Cityy of CCities" [value]="Cityy.cityid">
                          {{Cityy.cityname}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4 pr-0 mr-l">
                    <mat-form-field class="example-full-width px-3">
                      <mat-label>Pincode<span class="required-mark">*</span></mat-label>
                      <input matInput name="perpin" [(ngModel)]="obj.student_perpincode" placeholder="Enter Pincode">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="row my-2 d-block text-center">
        <button mat-flat-button color="primary" (click)="updProfile()">Save</button>
      </div>
    </form>
  </mat-card-content>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
</mat-card>