<footer class="footer">
    <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h5>Corporate Office</h5>
                    <!-- <p class="mb-4">Level 7, Maximus Towers, Servcorp Building, 
                        2A Mindspace Complex, <br>Hi-Tech City, Hyderabad - 500 081, India.</p> -->
                        <p class="mb-4">Quality Business Hub LLP ,    Plot No: 8-2-293/82/A/732, <br>  Plot 732-A, 
                            SASI ICON Building, <br> Beside Madhapur Metro Station, <br> Road No. 36 Jubilee Hills,       

                            Hyderabad, <br>500033 India.</p>

                            
                    <!-- <h5>India - Regional Office</h5>
                    <p class="mb-4">2nd Floor, George Court, HRBR Layout, 2nd Block,
                        kalyan Nagar, Bengaluru, Karnataka - 560 043.</p> -->
                    <h5>Singapore - Regional Office</h5>
                    <p class="mb-4">158, Mei Ling Street, Singapore - 140 518. </p>
                    <h5>Connnect with Us</h5>
                    <a class="btn btn-outline-light btn-social mx-1" href="https://www.facebook.com/AEP-Certification-575273579833819" target="_blank"><i class="fab fa-fw fa-facebook-f"></i></a>
                    <a class="btn btn-outline-light btn-social mx-1" href="https://twitter.com/AEPCertificati1" target="_blank"><i class="fab fa-fw fa-twitter"></i></a>
                    <a class="btn btn-outline-light btn-social mx-1" href="https://www.linkedin.com/company/aep-certification/" target="_blank"><i class="fab fa-fw fa-linkedin-in"></i></a>
                </div>
                <div class="col-md-3">
                    <ul>
                        <li routerLink="/aep/home">Home</li>
                        <li routerLink="/aep/about/whoweare">About</li>
                        <li routerLink="/aep/certificationofferings/certificate">Certifications</li>
                        <li routerLink="/aep/partners/representative">Partners</li>
                        <li routerLink="/aep/contact">Contact</li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <ul>
                        <li routerLink="/aep/about/whoweare">Who We Are?</li>
                        <li routerLink="/aep/about/programoverview">AEP Certification Program Overview</li>
                        <li routerLink="/aep/about/extendedservice">Extended Services</li>
                        <li routerLink="/aep/about/careers">Careers</li>
                        <li routerLink="/aep/certificationofferings/certificate">Certification</li>
                        <li routerLink="/aep/certificationofferings/searchaepcertification">Search AEP Certification Holders</li>
                        <li routerLink="/aep/partners/representative">Associate Representative Partner</li>
                        <li routerLink="/aep/partners/traininginstitute">Training Institute Partners</li>
                        <li routerLink="/aep/partners/authorizedindustry">Authorized Industry Trainers</li>
                        <li routerLink="/aep/partners/course">Course Initiators</li>
                    </ul>
                </div>
            </div>
    </div>
</footer>
<!-- Copyright Section-->
<div class="copyright py-4 text-white">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <a href="../../assets/pdf/AEP Certification Privacy Policy.pdf" target="_blank"><small class="text-white"> Privacy Policy</small></a>
                <a href="../../assets/pdf/AEP Certification Terms of Service.pdf" target="_blank">
                <small class="ml-5 text-white">Terms of Service</small></a>
                <a>
                <small class="ml-5 text-white" (click)="showAuthorization()" >Authorization</small></a>
            </div>
            <div class="col-md-8 text-center">
                <small>© Copyright AEP CERTIFICATION. All rights reserved.</small>
            </div>
        </div>
        </div>
</div>
<!-- <button (click)="gotoTop()" id="myBtn" title="Go to top">Top</button> -->