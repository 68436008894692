import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Session } from 'protractor';
import { SessionStorage } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { ToasterService } from '../services/toastr.service';
import { ValidationService } from '../services/validation.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  public isForgot: boolean = false;
  public isSignin: boolean = true;
  public lgArpAdmin : boolean= false;
  public ar: boolean = false;
  public ad: boolean = false;

  inputUserId: any;
  inputUserPassword: any;
  users = '1';
  userparticipant: any;
  useradmin: any;
  userarp: any;
  participant: any;
  arp: any;
  admin: any;
  inputAddress4: any;

  // userBind:any;
  inputAddress: '';
  inputAddress2: '';
  // gridcheck:false,
  // users:any=[
  //   'Participant',
  //   'ARP',
  //   'ADMIN'
  // ]



  constructor(private ValidationService: ValidationService, private spinner: NgxSpinnerService, private service: ApiService, public toast: ToasterService, private router: Router) { }

  ngOnInit(): void {
    localStorage.setItem('key1', '1');
  }  

  onSubmitForgotPassword() {
    if (this.function2()) {
      return;
    }
    this.spinner.show()
    var x = Number(localStorage.getItem('key1'));
    var obj2 = {
      roleid: x,
      emailid: String(this.inputAddress4),

    }

    // if(x == 2 || x == 3){
      
    //   this.toast.showwarning(this.ValidationService.msg_Validate_siteUnderdevelopment);
    // }

    // forgot password email

    this.service.post(this.service.forgotPasswordEmailNotification, obj2).subscribe((resp) => {
      this.spinner.hide()
      // console.log(resp);
      if (resp == true) {
        this.toast.showsuccess(this.ValidationService.msg_Validate_ForgotPasswordMailSuccess);
        this.router.navigate(['/aep/signin']);
      }
     else {
      this.toast.showwarning(this.ValidationService.msg_Validate_ForgotPasswordMailFail);
        //  this.router.navigate(['/aep/participant/courseenrollment']);
      }
    })
  }


  onSubmitSignIn() {
    if (this.function1()) {
      return;
    }
    this.spinner.show()
    var obj = {
      email_id: String(this.inputAddress),
      passwords: String(this.inputAddress2),
      role_name: "",
    }
    var obj1 = {
      email_id: String(this.inputAddress),
      passwords: String(this.inputAddress2),
      role_name: "admin",
      is_active: "true",
    }
    // participant
    var x = Number(localStorage.getItem('key1'));
    if (x === 1) {
      this.service.postLogin(this.service.getStudentLogin, obj).subscribe((resp) => {
        this.spinner.hide();
        // console.log(resp)
        if(resp == null)
        {
          this.toast.showwarning(this.ValidationService.msg_Validate_emailNotVerified);
          
        }
        else{
        var res = resp.userid
        var res1 = resp.isfirsttimelogin
        var token = resp.access_token
        localStorage.setItem('userid', res);
        localStorage.setItem('token',token);
        
        if (resp != null) {
          if (res != -1) {

            if (res1 == true) {
              this.toast.showsuccess(this.ValidationService.msg_Validate_LoginSuccess);
              this.router.navigate(['/aep/participant/Addprofile']);

            } else {
              this.toast.showsuccess(this.ValidationService.msg_Validate_LoginSuccess);
              // this.router.navigate(['/aep/participant/activecourses']);
              this.router.navigate(['/aep/participant/courseenrollment']);

            }
          } else {
            this.toast.showwarning(this.ValidationService.msg_Validate_LoginFailure);
            localStorage.removeItem('userid');
            localStorage.removeItem('token');
          }
        }
      }
        
      })
    }

    // if( x == 3){
    //   this.toast.showwarning(this.ValidationService.msg_Validate_siteUnderdevelopment);
    // }

    // arp
    if (x === 2) {
      this.service.postLogin(this.service.getArpLogin, obj).subscribe((resp) => {
        this.spinner.hide();
        // console.log(resp);
        var res = resp.arp_id;
        var token = resp.access_token

        localStorage.setItem('arpid',res);
        localStorage.setItem('token',token);
        
        var res1 = resp.isfirsttimelogin
        this.spinner.hide()

        if (resp != null) {
          if (res != -1) {

            if (res1 == false) {
              this.toast.showsuccess(this.ValidationService.msg_Validate_LoginSuccess);
              this.router.navigate(['/aep/arp/arptips']);
            } else {
              this.router.navigate(['/aep/updatearppassword']);
            }

          } else {
            this.toast.showwarning(this.ValidationService.msg_Validate_LoginFailure);
            localStorage.removeItem('arpid');
            localStorage.removeItem('token');
            this.spinner.hide();
          }
        }
      })
    }
    // ADMIN
    if (x === 3) {
      this.service.postLogin(this.service.getAdminLogin, obj1).subscribe((resp) => {
        // console.log(resp)
        this.spinner.hide();
        var res = resp.admin_id
        var token = resp.access_token
        localStorage.setItem("adminid", res);
        localStorage.setItem('token',token);

        if (resp != null) {
          if (res != -1) {
            this.toast.showsuccess(this.ValidationService.msg_Validate_LoginSuccess);
            // this.router.navigate(['/aep/signup']);
            this.router.navigate(['/aep/admin/arpenrollment']);
          } else {
            this.toast.showwarning(this.ValidationService.msg_Validate_LoginFailure);
            localStorage.removeItem("adminid");
            localStorage.removeItem('token');
            this.spinner.hide();
          }
        }
      })
    }
  }



  function1() {


    if (this.ValidationService.funValidateEmptyText(this.inputAddress, this.ValidationService.msg_Validate_Email, '#inputAddress')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.inputAddress, this.ValidationService.msg_Invalid_Email_Format, '#inputAddress')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.inputAddress2, this.ValidationService.msg_Validate_Password, '#inputAddress2')) {
      return true;
    }
    

  }

  function2() {
    if (this.ValidationService.funValidateEmptyText(this.inputAddress4, this.ValidationService.msg_Validate_Email, '#inputAddress4')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.inputAddress4, this.ValidationService.msg_Invalid_Email_Format, '#inputAddress4')) {
      return true;
    }
  }



  selectcheck1(id) {
    // console.log(id);
    localStorage.setItem('key1', id);

  }
  selectcheck2(id) {
    // console.log(id);
    localStorage.setItem('key1', id);
  }
  selectcheck3(id) {
    // console.log(id);
    localStorage.setItem('key1', id);
  }

  public gotoSignin() {
    this.router.navigateByUrl('/aep/signup');
  }

  public showForgotpage() {
    this.isForgot = true;
    this.isSignin = false;
  }

  public showSigninpage() {
    this.isForgot = false;
    this.isSignin = true;
  }

}
