import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
import { ValidationService } from 'src/app/services/validation.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-tipmapping',
  templateUrl: './tipmapping.component.html',
  styleUrls: ['./tipmapping.component.css']
})
export class TipMappingComponent implements OnInit {

  public viewarp: boolean = true;
  public showdetails: boolean =false;
  public country:any;
  public state:any;
  public CCountries = [];
  States =[];
  public arpdetails=[];
  public indarpdetails=[];
  public indtipdetails=[];
  norecords = true;
  notiprecords = true;

  constructor(private service: ApiService, private ValidationService :ValidationService, private toast : ToasterService, private router: Router,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.getcountries();
  }

  getcountries(){
    // COUNTRY
    var regionid = 5;
    this.service
      .get(this.service.getCountries)
      .subscribe((resp) => {
        if (resp != null) {
          // for (let i = 0; i < resp.length; i++) {
          //   // if (resp[i].country_id == '35') {
          //     this.CCountries.push({
          //       country_id: resp[i].country_id,
          //       country_name: resp[i].country_name
          //     })
          //   // }
          // }

          this.CCountries = resp.sort(function(a,b)
            {
              var st1 = a.country_name.toUpperCase();
              var st2 = b.country_name.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
        }
      })
    }


    getStates(id) {
      this.spinner.show()
      this.service.get(this.service.getStates + id).subscribe((resp) => {
        this.spinner.hide()
      this.States =resp.sort(function(a,b)
      {
        var st1 = a.statename.toUpperCase();
        var st2 = b.statename.toUpperCase();
        return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
      });
    })
      // console.log(this.statedata);  
  }

  statefilter(id) {
    this.spinner.show()
   let statename = id.statename;

   this.arpdetails=[];

   this.service.get(this.service.getArpList).subscribe((res) =>{
    this.spinner.hide()
     if(res!=null){
       for(let i =0; i < res.length; i++ ){
         if(statename == res[i].office_state){
           this.arpdetails.push({
            arp_id : res[i].arp_id,
            arp_email : res[i].arp_email,
            rolename : res[i].rolename,
            arp_aepid : res[i].arp_aepid,
            company_name : res[i].company_name,
            contact_person_name : res[i].contact_person_name,
            gst_code : res[i].gst_code,
            pan_or_uid_number: res[i].pan_or_uid_number,
            office_doorno : res[i].office_doorno,
            office_landmark : res[i].office_landmark,
            office_area : res[i].office_area,
            office_city : res[i].office_city,
            office_district : res[i].office_district,
            office_state : res[i].office_state,
            offfice_country : res[i].offfice_country,
            office_pincode:res[i].office_pincode,
            bank_acc_number: res[i].bank_acc_number,
            bank_ifsc : res[i].bank_ifsc,
            canncelled_cheque_path: res[i].canncelled_cheque_path ,
            mobile_number: res[i].mobile_number,
            arp_tip_mapped_date: res[i].arp_tip_mapped_date ,
               })
             }
            }
            if(this.arpdetails.length!= 0) {
              this.norecords=true;
           }
           else{
             this.norecords=false;
           }
          }
        })

      }


      public viewdetails(data){
        this.spinner.show()
        this.viewarp = false;
        this.showdetails = true;
        var id1 = data.arp_id;
        localStorage.setItem('arpmapid',id1);
    
        this.indarpdetails = [];
        this.service.get(this.service.getarpbyarpid + id1 ).subscribe((res) => {
          // console.log(res);
          if(res != null){
          for (let i = 0; i < res.length; i++) {
              this.indarpdetails.push({
                arp_id : res[i].arp_id,
                arp_email : res[i].arp_email,
                rolename : res[i].rolename,
                arp_aepid : res[i].arp_aepid,
                company_name : res[i].company_name,
                contact_person_name : res[i].contact_person_name,
                gst_code : res[i].gst_code,
                pan_or_uid_number: res[i].pan_or_uid_number,
                office_doorno : res[i].office_doorno,
                office_landmark : res[i].office_landmark,
                office_area : res[i].office_area,
                office_city : res[i].office_city,
                office_district : res[i].office_district,
                office_state : res[i].office_state,
                offfice_country : res[i].offfice_country,
                office_pincode:res[i].office_pincode,
                bank_acc_number: res[i].bank_acc_number,
                bank_ifsc : res[i].bank_ifsc,
                canncelled_cheque_path: res[i].canncelled_cheque_path ,
                mobile_number: res[i].mobile_number,
                arp_tip_mapped_date: res[i].arp_tip_mapped_date ,
              })
          }
         }
        })

        this.indtipdetails = [];
        this.service.get(this.service.getorgtips).subscribe((res) => {
          this.spinner.hide()
          // console.log(res);
          if(res != null){
          for (let i = 0; i < res.length; i++) {
            if (data.office_state == res[i].orgstate && res[i].isarpmapped == false ) {
              this.indtipdetails.push({
                orgid: res[i].orgid,
                orgname: res[i].orgname,
                orgaddress: res[i].orgaddress,
                orgstate: res[i].orgstate,
                orgcountry: res[i].orgcountry,
                orggstnumber:res[i].orggstnumber,
                orgaepid: res[i].orgaepid,
                industrytype: res[i].industrytype,
                orgwebsite: res[i].orgwebsite,
                email: res[i].email,
                mobilenumber: res[i].mobilenumber,
                designation: res[i].designation,
                firstname: res[i].firstname,
                lastname: res[i].lastname,
                arp_tip_mapping_id: res[i].arp_tip_mapping_id,
                arp_tip_mapped_date: res[i].arp_tip_mapped_date,
                orgdistrict:res[i].orgdistrict,
                orgcity: res[i].orgcity, 
              })
            }
          }
          if(this.indtipdetails.length!= 0) {
            this.notiprecords=true;
         }
         else{
           this.notiprecords=false;
         }
         }
        })


      }

      assign(data){
        this.spinner.show()
        var id = data.orgid;

        var obj = {
          arpid   : Number(localStorage.getItem('arpmapid')),  
          org_id  : id,
          arpname : this.indarpdetails[0].company_name,
          arpemail: this.indarpdetails[0].arp_email,
          tipname:  this.indtipdetails[0].orgname,
          contactname: this.indtipdetails[0].lastname,
          contactnumber: this.indtipdetails[0].mobilenumber,
          tipemail : this.indtipdetails[0].email,
          address : this.indtipdetails[0].orgaddress,
          city : this.indtipdetails[0].orgcity,
          state : this.indtipdetails[0].orgstate,
          country : this.indtipdetails[0].orgcountry,
                  
        }

        this.service.post(this.service.postarptipmap, obj ).subscribe((res) => {
          this.spinner.hide()
          // console.log(res);
          if(res!=null){
            if(res==1){
              this.toast.showsuccess(this.ValidationService.msg_Validate_ArpTipMappingSuccess);
               this.showArp();
              }
              else{
                this.toast.showwarning(this.ValidationService.msg_Validate_ArpTipMappingFailure);
               this.showArp();
              }
            
            }
      })

      }


      public showArp(){
        this.viewarp = true;
        this.showdetails = false;
      }
}
