<header class="masthead">
    <div id="offerings" class="carousel slide" data-ride="carousel">
      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li data-target="#offerings" data-slide-to="0" class="active"></li>
        <li data-target="#offerings" data-slide-to="1"></li>
      </ul>
      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="./assets/img/slider5.jpg">
        </div>
        <div class="carousel-item">
          <img src="./assets/img/slider4.jpg">
        </div>
      </div>
      <!-- Left and right controls -->
      <a class="carousel-control-prev" href="#offerings" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a class="carousel-control-next" href="#offerings" data-slide="next">
        <span class="carousel-control-next-icon"></span>
      </a>
    </div>
  </header>
  
  <section class="page-section pb-4">
    <div class="container">
      <h3 class="main-heading text-blue">Validate Skills and Experience to align with industry trends <br>and grow your career!</h3>
    <p class="display-5">AEP Certifications are designed and developed by engineering professionals for engineering professionals. Our certifications are role specific, which are created based on rigorous standards and practices demanded by industries for all levels.</p>
    <b class="display-5 text-bold text-blue">Independent Assessments</b>
    <p class="display-5">All our certifications are independent of trainings. Hence it is not mandatory that you need to attend a training to take exam, although it is recommended if you want to enhance your knowledge levels in order to successfully complete the certification. Based on the level, each certification is conducted as a combination of online, practical, case study or project driven exams to validate both knowledge and skill on a chosen domain with specific tool, and benchmark industry equivalent experience of the professional.</p>
    <p class="display-5">Explore our wide range of certification exams and corresponding enablement by level in your respective stream to benchmark your skills and experience to accomplish any challenging role.</p>
    </div>
  </section>
  <app-offers></app-offers>