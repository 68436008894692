import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { NgxSpinnerService } from 'ngx-spinner';

import { ApiService } from '../../services/api.service';
import { ToasterService } from '../../services/toastr.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-searchaepcertification',
  templateUrl: './searchaepcertification.component.html',
  styleUrls: ['./searchaepcertification.component.css']
})
export class SearchaepcertificationComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  count = 100;
  certificateid: '';
  firstName: '';
  lastname: '';
  emailid: '';
  mailformat: '';
  hideTable = true;
  norecords = true;
  data;
  displayedColumns: string[] = ["certid", "name", "count", "certname", "certdate"]
  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService,
    public toast: ToasterService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.certificateid = "",
      this.firstName = "",
      this.emailid = "",
      this.lastname = ""
  }

  respArray = []
  date;
  searchResult() {
    this.spinner.show()
    if (this.certificateid == "" && this.firstName == "" && this.emailid == "" && this.lastname == "") {
      this.toast.showwarning(this.ValidationService.msg_Validate_empty)
      this.hideTable = true;
      this.norecords = true;
      this.data = []
      this.spinner.hide()
    } else {
      if (this.emailid != undefined && this.emailid != "") {
        if (this.funValidate()) {
          this.spinner.hide()
          return;
        }
      }
      var certiobj = {
        certificateid: this.certificateid,
        first_name: this.firstName,
        last_name: this.lastname,
        user_email: this.emailid
      }
      this.service.post(this.service.getCertificationdata, certiobj).subscribe(resp => {
        this.spinner.hide()
        if (resp != [] && resp.length != 0) {
          this.hideTable = false;
          this.norecords = true;
          this.respArray = []
          for(let i=0; i <= resp.length - 1; i++){
            if(resp[i].completed_date != null){
              var sdate = (resp[i].completed_date).split("T")[0];
              var spldate = sdate.split("-")
              this.date = spldate[2] + "-" + spldate[1] + "-" + spldate[0];
            }else{
              this.date = ""
            }
            this.respArray.push({
            username: resp[i].username,
            percountry: resp[i].percountry,
            certificationid: resp[i].certificationid,
            certification_exam_id_code: resp[i].certification_exam_id_code,
            completed_date: this.date,
            certification_name: resp[i].certification_name,
          })
          }
          this.data = new MatTableDataSource(this.respArray)
          this.data.paginator = this.paginator;
        } else {
          this.hideTable = false;
          this.norecords = false;
          resp = []
          this.data = new MatTableDataSource(resp)
          this.data.paginator = this.paginator;
        }
      })
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  funValidate() {
    if (this.ValidationService.funValidateEmailFormat(this.emailid,
      this.ValidationService.msg_Invalid_Email_Format, '#mailformat')) {
      return true;
    }
  }

  // FOR PAGINATOR
  funSyncPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }
}