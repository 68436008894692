import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';



@Component({
  selector: 'app-tipconversation',
  templateUrl: './tipconversation.component.html',
  styleUrls: ['./tipconversation.component.css']
})
export class TipConversationComponent implements OnInit {

  // public countrylist = ['india'];
  // public statelist = ['Telangana', 'Andhrapradesh'];
  // public districtlist = ['rangareddy', 'medak'];
  // public citylist = ['hyderabad', 'suryapet'];

  public selectCountry: string;
  public selectState: string;
  public selectDistrict: string;
  public selectCity: string;
  public viewtip: boolean = true;
  public showdetails: boolean = false;

  public country:any;
  public state:any;
  public org:any;
  public CCountries = [];
  States =[];
  public tipdetails = [];
  public indtipdetails = [];
  public orgdetails = [];
  statename;
  orgname;
  norecords = true;
  orgdis = false;


  constructor(private service: ApiService, private ValidationService :ValidationService, private toast : ToasterService, private router: Router,private spinner: NgxSpinnerService ) { }


  ngOnInit(): void {
    this.getcountries();
    
  }

  getcountries(){
    // COUNTRY
    var regionid = 5;
    this.service
      .get(this.service.getCountries)
      .subscribe((resp) => {
        if (resp != null) {
          // for (let i = 0; i < resp.length; i++) {
          //   // if (resp[i].country_id == '35') {
          //     this.CCountries.push({
          //       country_id: resp[i].country_id,
          //       country_name: resp[i].country_name
          //     })
          //   // }
          // }

          this.CCountries = resp.sort(function(a,b)
            {
              var st1 = a.country_name.toUpperCase();
              var st2 = b.country_name.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
        }
      })
  }

  getStates(id) {
    this.spinner.show()
  //   if(this.function1()){
               
  //     return;
  // }
    this.service.get(this.service.getStates + id).subscribe((resp) => {
      this.spinner.hide()
      this.States =resp.sort(function(a,b)
    {
      var st1 = a.statename.toUpperCase();
      var st2 = b.statename.toUpperCase();
      return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
    });
  })
    // console.log(this.statedata);  
}


 statefilter(id) {
  this.spinner.show()
   this.statename = id.statename;
  this.statefilter1();
  }

  statefilter1() {
  
    this.orgdetails = [];
    this.tipdetails = [];
      this.service.get(this.service.getorganisations).subscribe((res) => {
        // console.log(res);
        this.spinner.hide()
        if(res != null){
        for (let i = 0; i < res.length; i++) {
          if (this.statename == res[i].orgstate) {
            this.tipdetails.push({
              orgid: res[i].orgid,
              orgname: res[i].orgname,
              orgaddress: res[i].orgaddress,
              orgstate: res[i].orgstate,
              orgcountry: res[i].orgcountry,
              orggstnumber:res[i].orggstnumber,
              orgaepid: res[i].orgaepid,
              industrytype: res[i].industrytype,
              orgwebsite: res[i].orgwebsite,
              email: res[i].email,
              mobilenumber: res[i].mobilenumber,
              designation: res[i].designation,
              firstname: res[i].firstname,
              lastname: res[i].lastname,
              orgdistrict:res[i].orgdistrict,
              orgcity: res[i].orgcity, 
            })
          }
        }
        if(this.tipdetails.length!= 0) {
          this.norecords= true;
          this.orgdis= false;
          // this.org = '';
          // this.orgdetails=[];
          // this.orgfilter1();
          // this.tipdetails=[];
       }
       else{
         this.norecords= false;
         this.orgdis= true;
       }
       this.tipdetails = this.tipdetails.sort(function(a,b)
        {
          var st1 = a.orgname.toUpperCase();
          var st2 = b.orgname.toUpperCase();
          return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
        });
       }
      })
      // console.log(this.tipdetails);
    }

 orgfilter(data) {
      this.spinner.show()
      this.orgname = data;
     this.orgfilter1();
     }
 orgfilter1(){
    // this.tipdetails = [];
    this.orgdetails = [];
    this.service.get(this.service.getorganisations).subscribe((res) => {
      this.spinner.hide()
      // console.log(res);
      if(res != null){
        for (let i = 0; i < res.length; i++) {
          if (this.orgname == res[i].orgname) {
           this.orgdetails.push({
            orgid: res[i].orgid,
            orgname: res[i].orgname,
            orgaddress: res[i].orgaddress,
            orgstate: res[i].orgstate,
            orgcountry: res[i].orgcountry,
            orggstnumber:res[i].orggstnumber,
            orgaepid: res[i].orgaepid,
            industrytype: res[i].industrytype,
            orgwebsite: res[i].orgwebsite,
            email: res[i].email,
            mobilenumber: res[i].mobilenumber,
            designation: res[i].designation,
            firstname: res[i].firstname,
            lastname: res[i].lastname,
            orgdistrict:res[i].orgdistrict,
            orgcity: res[i].orgcity, 
          })
        }
      }
     }
    })

  }

  public viewdetails(data){
    this.spinner.show()
    this.viewtip = false;
    this.showdetails = true;

    var id1 = data.orgid;

    this.indtipdetails = [];
    this.service.get(this.service.getindorganisations + id1 ).subscribe((res) => {
      this.spinner.hide()
      // console.log(res);
      if(res != null){
      for (let i = 0; i < res.length; i++) {
        if (data.orgid == res[i].orgid) {
          this.indtipdetails.push({
            orgid: res[i].orgid,
            orgname: res[i].orgname,
            orgaddress: res[i].orgaddress,
            orgstate: res[i].orgstate,
            orgcountry: res[i].orgcountry,
            orggstnumber:res[i].orggstnumber,
            orgaepid: res[i].orgaepid,
            industrytype: res[i].industrytype,
            orgwebsite: res[i].orgwebsite,
            email: res[i].email,
            mobilenumber: res[i].mobilenumber,
            designation: res[i].designation,
            firstname: res[i].firstname,
            lastname: res[i].lastname,
            orgdistrict:res[i].orgdistrict,
            orgcity: res[i].orgcity, 
          })
        }
      }
     }
    })
  }

  public convert(data) {
    this.spinner.show()
    var id2 = data.orgid;
    var obj = {
      org_id      : id2,
      is_not_tip  : false
          }
    this.service.post(this.service.postconvtip, obj ).subscribe((res) => {
      this.spinner.hide()
        // console.log(res);
        if(res!=null){
          if(res==1){
            this.toast.showsuccess(this.ValidationService.msg_Validate_TipConversionSuccess);
             this.showTip();
            }
          else{
            this.toast.showwarning(this.ValidationService.msg_Validate_TipConversionFailure);
             this.showTip();
          }
        }
    
    })

  }

  public showTip(){
    // this.spinner.show()
    this.statefilter1();
    this.orgfilter1();
    this.viewtip = true;
    this.showdetails = false;
    
  }
}
