import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arp',
  templateUrl: './arp.component.html',
  styleUrls: ['./arp.component.css']
})
export class ArpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
