import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/services/toastr.service';

import { NgxSpinnerService } from 'ngx-spinner';

import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  
  inputAddress2:any;
  inputAddress3:any;
  

  constructor(private ValidationService: ValidationService,private spinner: NgxSpinnerService,private service :ApiService, public toast: ToasterService, private router: Router)
   { }

  ngOnInit(): void {
  }

  onPassordUpdate(){
    if(this.function1()){
      return;
    }
    var userid =Number(localStorage.getItem('userid'));
    var obj={
      user_id: userid,
      new_password:this.inputAddress2,
      roleid: 1,
    }
    this.service.post(this.service.updateArpPassword, obj).subscribe((resp) => {
      // console.log(resp)
      if(resp == 1){
        this.toast.showsuccess(this.ValidationService.msg_Validate_passwordUpdateSuccess);
        this.router.navigate(['/aep/signin']);
      }else{
          this.toast.showwarning(this.ValidationService.msg_Validate_passwordUpdateUnsuccessfull);
        }
    })
  }
  function1(){
    if (this.ValidationService.funValidateEmptyText(this.inputAddress2, this.ValidationService.msg_Validate_emptyConfirmPassword, '#inputAddress2')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.inputAddress3, this.ValidationService.msg_Validate_emptyConfirmPassword, '#inputAddress3')) {
      return true;
    }
    if (this.inputAddress2 != '' && this.inputAddress3 != '') {

      if (this.ValidationService.funvalidateConfirmpswd(this.inputAddress2, this.inputAddress3, this.ValidationService.msg_Validate_NewPasswordmatch)) {
        return true;
      }
    }
  }
}
