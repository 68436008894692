import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../../../services/api.service';
import { ToasterService } from '../../../services/toastr.service';
import { ValidationService } from '../../../services/validation.service';


// export class uploadDocModel{
//   id: number;
//   filepath: string='';
// }

@Component({
  selector: 'app-viewarp',
  templateUrl: './viewarp.component.html',
  styleUrls: ['./viewarp.component.css']
})
export class ViewarpComponent implements OnInit {
  public selectCountry: string;
  public selectState: string;
  public selectDistrict: string;
  public selectCity: string;
  public viewarp: boolean = true;
  public showdetails: boolean = false;
  public editcurrarp: boolean = false;
  public showtipdetails: boolean = false;
  public editselection: boolean = false;
  public editNewarp: boolean = false;
  public st_email: boolean = false;
  public pswd: boolean = false;
  public pswd2: boolean =true;
  public selectchck1:boolean = false;
  
  
  public country: any;
  public state: any;
  public Countries = [];
  public CCountries = [];
  Districts;
  Cities;
  DDistricts;
  CCities;
  States = [];
  SStates = [];
  arpdetails = [];
  iarpdetails = [];
  iarpdetails2 =[];
  tipdetails = [];
  companyname: any;
  ContactPersonName: any;
  Mobile: any;
  CountryCode: any;
  gstCode: any;
  panoruid: any;
  emailid: any;
  password: any;
  Doorno: any;
  LandMark: any;
  Street: any;
  Area: any;
  ucity: any;
  udistrict: any;
  ustate: any;
  ucountry: any;
  PinCode: any;
  AccountNumber: any;
  IFSCCode: any;
  cancelledcheques: any;
  companyname2: any;
  ContactPersonName2: any;
  Mobile2: any;
  CountryCode2: any;
  gstCode2: any;
  panoruid2: any;
  emailid2: any;
  password2: any;
  Doorno2: any;
  LandMark2: any;
  Street2: any;
  Area2: any;
  ucity2: any;
  udistrict2: any;
  ustate2: any;
  ucountry2: any;
  PinCode2: any;
  AccountNumber2: any;
  IFSCCode2: any;
  cancelledcheques2: any;
  selectedarp: any;
  echk_btn=1;
  arpkeyvalue:any;
  arpemailid: any;
  arpemailid2: any;
  cityid: any;
  districtid: any;
  stateid: any;
  countryid: any;
  arpkey: any;
  randomstring = '';
  arpid:any;
  Website:any;
  Website2:any;
  public fileToUpload: File = null;
  public fileToUpload2: File = null;
  public filepath: any;
  public filepath2: any;
  public genpswd:boolean=false; 
  count:any=0;
  public datasource = [];
  public tipdisplay:boolean=true;
  public object1 ={};
  public ifsc1:boolean=false;
  public ifsc2:boolean=false;

  
  obj = {
    arp_id: '',
    orgid: '',
    orgname: '',
    firstname: '',
    lastname: '',
    orgwebsite: '',
    orgaddress: '',
    industrytype: '',
    email: '',
    mobilenumber: '',
    orgcity: '',
    orgdistrict: '',
    orgstate: '',
    orgcountry: '',
    arp_tip_mapped_date: '',
  }
  obj2={
    company_name:'',
    contact_person_name: '',
    mobile_number: '',
    arp_email: '',
    arp_id:'',
    website:'',
    office_doorno: '',
    office_landmark: '',
    office_street: '',
    office_area: '',
    office_city: '',
    office_district: '',
    office_state: '',
    offfice_country: '',
    arp_tip_mapped_date: ''
      }

  

  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService, public toast: ToasterService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    localStorage.getItem('arpeditkey');
    this.getCountries();
    this.getcountries2();
    localStorage.setItem('arpeditkey','1');
    this.echk_btn=1;
    
  }
  generateArpPassword(){
    var letters = ['a','b','c','d','e','f','g','h','i','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    var numbers = [0,1,2,3,4,5,6,7,8,9];
    this.count+=1
    
       if(this.count==1){
        for(var i=0;i<5;i++){
            var rlet = Math.floor(Math.random()*letters.length);
            this.randomstring += letters[rlet];
        }
        for(var i=0;i<3;i++){
            var rnum = Math.floor(Math.random()*numbers.length);
            this.randomstring += numbers[rnum];
        }
     this.password2=this.randomstring;
    }else{
      this.genpswd=true;
      this.pswd2=true;

    }
    //  console.log(this.randomstring);
  }

  getCountries(){
    var regionid = 5;
    this.service
    .get(this.service.getCountries)
    .subscribe((res) => {
      if (res != null) {
        // this.Countries = res;
        // for (let i = 0; i < res.length; i++) {
        //   // if (res[i].country_id == '35') {
        //     this.Countries.push({
        //       country_id: res[i].country_id,
        //       country_name: res[i].country_name
        //     })
        //   // }
        // }

        this.Countries = res.sort(function(a,b)
            {
              var st1 = a.country_name.toUpperCase();
              var st2 = b.country_name.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
      }
    })
  }
  getcountries2() {
    var regionid = 5;
    this.service.get(this.service.getCountries).subscribe((resp) => {
      if (resp != null) {
        // for (let i = 0; i < resp.length; i++) {
        //   // if (resp[i].country_id == '35') {
        //     this.CCountries.push({
        //       country_id: resp[i].country_id,
        //       country_name: resp[i].country_name
        //     })
        //   // }
        // }

        this.CCountries = resp.sort(function(a,b)
            {
              var st1 = a.country_name.toUpperCase();
              var st2 = b.country_name.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
      }
    })
  }
  

  getStates(id) {
    if(id==2){
      this.ifsc1=true;
    }else{
      this.ifsc1=false;
    }
    this.countryid = id;
    
    // this.state = '';
    // this.stateid = '';
    if (this.countryid != "null") {
      this.service.get(this.service.getStates + this.countryid).subscribe((resp) => {
        this.States = resp.sort(function (a, b) {
          var st1 = a.statename.toUpperCase();
          var st2 = b.statename.toUpperCase();
          return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
        });
      })
    }
  }
  
  // getStates2(id) {
    
  //   var countryid2= id;
  //   if (this.ucountry2 != "null") {
  //     this.service.get(this.service.getStates + countryid2).subscribe((resp) => {
  //       this.SStates = resp.sort(function (a, b) {
  //         var st1 = a.statename.toUpperCase();
  //         var st2 = b.statename.toUpperCase();
  //         return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
  //       });
  //     })
  //   }
  // }
  getStates2(id){
    if(id==2){
      this.ifsc2=true;
    }else{
      this.ifsc2=false;
    }
    var countryid2 = id;
      if(countryid2 != "null"){
        this.service.get(this.service.getStates + countryid2).subscribe((resp) => {  
          if (resp != null) {
            this.SStates = resp.sort(function(a,b)
            {
              var st1 = a.statename.toUpperCase();
              var st2 = b.statename.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
          }
        })
      }
  }
  getDistricts(id) {
    var state_id = id;
    this.districtid = '';
    if (state_id != "null") {
      this.service.get(this.service.getDistricts + state_id).subscribe((resp) => {
        if (resp != null) {
          this.Districts = resp.sort(function (a, b) {
            var st1 = a.districtname.toUpperCase();
            var st2 = b.districtname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }
  getDistricts2(id) {
    var state_id = id;
    // this.districtid ='';
    if (state_id != "null") {
      this.service.get(this.service.getDistricts + state_id).subscribe((resp) => {
        if (resp != null) {
          this.DDistricts = resp.sort(function (a, b) {
            var st1 = a.districtname.toUpperCase();
            var st2 = b.districtname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }

  getCities(id) {
    var district_id = id;
    this.cityid = '';
    if (district_id != "null") {
      this.service
        .get(this.service.getCities + district_id)
        .subscribe((resp) => {
          if (resp != null) {
            this.Cities = resp.sort(function (a, b) {
              var st1 = a.cityname.toUpperCase();
              var st2 = b.cityname.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
          }
        })
    }
  }
  getCities2(id) {
    var district_id = id;
    // this.obj.cityid='';
    if (district_id != "null") {
      this.service
        .get(this.service.getCities + district_id)
        .subscribe((resp) => {
          if (resp != null) {
            this.CCities = resp.sort(function (a, b) {
              var st1 = a.cityname.toUpperCase();
              var st2 = b.cityname.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
          }
        })
    }
  }
  
  // get
  aStates=[];
  getarpstate() {
    var countryid = localStorage.getItem("arpcountry_id");
    if (countryid != "null") {
      this.service
        .get(this.service.getStates + countryid)
        .subscribe((resp) => {
          if (resp != null) {
            this.aStates = resp.sort(function (a, b) {
              var st1 = a.statename.toUpperCase();
              var st2 = b.statename.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
          }
        })
    }
  }
  
  getarpDistricts() {
    var state_id = localStorage.getItem('arpstateid');
    if (state_id != "null") {
      this.service
        .get(this.service.getDistricts + state_id)
        .subscribe((resp) => {
          if (resp != null) {
            this.Districts = resp.sort(function (a, b) {
              var st1 = a.districtname.toUpperCase();
              var st2 = b.districtname.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });

          }
        })
    }
  }

  getarpCities() {
    var district_id = localStorage.getItem('arpdistrictid');
    if (district_id != "null") {
      this.service
        .get(this.service.getCities + district_id)
        .subscribe((resp) => {
          if (resp != null) {
            this.Cities = resp.sort(function (a, b) {
              var st1 = a.cityname.toUpperCase();
              var st2 = b.cityname.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });

          }
        })
    }
  }

  public statename:any;
  statefilter(id){
    this.statename=id.value.statename;
    this.statefilter1();
  }
  statefilter1() {
    this.arpdetails = [];
    this.service.get(this.service.getArpList).subscribe((res) => {
    for (let i = 0; i <= res.length - 1; i++) {
      if (this.statename == res[i].office_state) {
        this.arpdetails.push({
          company_name: res[i].company_name,
          contact_person_name: res[i].contact_person_name,
          mobile_number: res[i].mobile_number,
          arp_email: res[i].arp_email,
          arp_id: res[i].arp_id,
          office_doorno: res[i].office_doorno,
          office_landmark: res[i].office_landmark,
          office_street: res[i].office_street,
          office_area: res[i].office_area,
          office_city: res[i].office_city,
          office_district: res[i].office_district,
          office_state: res[i].office_state,
          offfice_country: res[i].offfice_country,
        })
      }
    }
  })
}
    viewarpdetails(id2) {
    this.showdetails = true;
    this.viewarp = false;
    this.editcurrarp = false;
    var currentarpid = id2.arp_id;
    this.iarpdetails = [];
    this.tipdetails=[];
    this.service.get(this.service.getArpByArpid + currentarpid).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        this.iarpdetails.push({
          company_name: res[i].company_name,
          contact_person_name: res[i].contact_person_name,
          mobile_number: res[i].mobile_number,
          arp_email: res[i].arp_email,
          arp_id: res[i].arp_id,
          website:res[i].website,
          office_doorno: res[i].office_doorno,
          office_landmark: res[i].office_landmark,
          office_street: res[i].office_street,
          office_area: res[i].office_area,
          office_city: res[i].office_city,
          office_district: res[i].office_district,
          office_state: res[i].office_state,
          offfice_country: res[i].offfice_country,
          arp_tip_mapped_date: res[i].arp_tip_mapped_date
        })
      }
    })
    // TIP BASAED ON ARPID
    var arpid = currentarpid
    this.service.get(this.service.getTipByArpid + arpid).subscribe((res) => {
      // console.log(res);
      if (res != null) {
        for (let i = 0; i < res.length; i++) {
          this.tipdetails.push({
            arp_id: res[i].arp_id,
            orgid: res[i].orgid,
            orgname: res[i].orgname,
            firstname: res[i].firstname,
            orgwebsite: res[i].orgwebsite,
            orgaddress: res[i].orgaddress,
            industrytype: res[i].industrytype,
            email: res[i].email,
            mobilenumber: res[i].mobilenumber,
            orgcity: res[i].orgcity,
            orgdistrict: res[i].orgdistrict,
            orgstate: res[i].orgstate,
            orgcountry: res[i].orgcountry,
            arp_tip_mapped_date: res[i].arp_tip_mapped_date,
          })
        }
      }
    })
  }
  viewIndividualtip(id4) {
    this.showtipdetails = true;
    this.showdetails = false;
// this.obj=[];
    var orgid = id4.orgid;
    this.service.get(this.service.getindorgtips + orgid).subscribe((res) => {
      if (res != null) {
        // for(let i=0;i<res.length;i++){
          let val=res.filter(x=>x.orgid==id4.orgid)
          console.log(val)
        this.obj.arp_id = val[0].arp_id;
        this.obj.orgid = val.orgid;
        this.obj.orgname = val[0].orgname;
        this.obj.firstname = val[0].firstname;
        this.obj.lastname = val[0].lastname;
        this.obj.orgwebsite = val[0].orgwebsite;
        this.obj.orgaddress = val[0].orgaddress;
        this.obj.industrytype = val[0].industrytype;
        this.obj.email = val[0].email;
        this.obj.mobilenumber = val[0].mobilenumber;
        this.obj.orgcity = val[0].orgcity;
        this.obj.orgdistrict = val[0].orgdistrict;
        this.obj.orgstate = val[0].orgstate;
        this.obj.orgcountry = val[0].orgcountry;
        this.obj.arp_tip_mapped_date = val[0].arp_tip_mapped_date;
        // }
      }
    })
  }
  
 
  editArpdetails(id3) {
    this.genpswd=false;
    this.editcurrarp = true;
    this.editselection = true;
    this.showdetails = false;
    this.viewarp = false;
    this.editcurrarp = true;
    this.editNewarp = false;
    this.pswd = true;
    var arpid = id3.arp_id;
    localStorage.setItem('vc_arp',arpid);
    this.service.get(this.service.getArpByArpid + arpid).subscribe((res) => {
      if (res != null) {
        this.companyname = res[0].company_name;
        localStorage.setItem('arpcmpname',res[0].company_name);
        this.ContactPersonName = res[0].contact_person_name;
        this.Mobile = res[0].mobile_number;
        this.gstCode = res[0].gst_code;
        this.panoruid = res[0].pan_or_uid_number;
        this.emailid = res[0].arp_email;
        this.password = res[0].arp_password;
        this.Website= res[0].web_site;
        this.CountryCode = res[0].country_code;
        this.password = res[0].arp_password;
        this.Doorno = res[0].office_doorno;
        if(res[0].office_landmark!=null){
          this.LandMark = res[0].office_landmark;
        }else{
          this.LandMark ="";
        }
        if(res[0].office_street!=null){
          this.Street = res[0].office_street;
        }else{
          this.Street ="";
        }
        this.Area = res[0].office_area;
        this.PinCode = res[0].office_pincode;
        this.AccountNumber = res[0].bank_acc_number;
        this.IFSCCode = res[0].bank_ifsc;
        this.filepath = res[0].canncelled_cheque_filename;
        this.arpemailid = res[0].company_email;
        this.countryid = res[0].offfice_countryid;
        localStorage.setItem("arpcountry_id", res[0].offfice_countryid); //state
        this.getarpstate();
        this.stateid = res[0].office_stateid;
        localStorage.setItem('arpstateid', res[0].office_stateid);  //dis
        this.getarpDistricts();
        this.districtid = res[0].office_districtid;
        localStorage.setItem('arpdistrictid', res[0].office_districtid);  //city
        this.getarpCities();
        this.cityid = res[0].office_cityid;
        this.arpemailid = res[0].company_email;
      }
    })
    this.companyname2 = "";
    this.ContactPersonName2 ="";
    this.Mobile2 = "";
    this.CountryCode2 = "";
    this.gstCode2 = "";
    this.panoruid2 = "";
    this.arpemailid2="";
    this.password2 = "";
    this.Doorno2 = "";
    this.LandMark2 = "";
    this.Street2 = "";;
    this.Area2 = "";
    this.ucity2 = "";
    this.udistrict2 = "";
    this.ustate2 = "";
    this.ucountry2 = "";
    this.PinCode2 = "";
    this.AccountNumber2 = "";
    this.IFSCCode2 = "";
    this.filepath2 = "";
  }

  updateArpDetails() {
    if (this.function1()) {
      return;
    }
    var obj1 = {
      arp_email_id: this.emailid,
      arp_pass_word: this.password,
      arp_role: "arp",
      is_first_time_login: false,
      companyname: this.companyname,
      contactperson_name: this.ContactPersonName,
      mobilenumber: this.Mobile,
      gstcode: this.gstCode,
      pan_or_uidnumber: this.panoruid,
      web_site : this.Website,
      door_no: this.Doorno,
      landmark: this.LandMark,
      street: this.Street,
      area: this.Area,
      city_id: this.cityid,
      district_id: this.districtid,
      state_id: this.stateid,
      countryid: this.countryid,
      pincode: this.PinCode,
      bank_account_number: this.AccountNumber,
      ifsc: this.IFSCCode,
      cheque_name: this.filepath,
      password_reset: true,
      cmp_email_id: this.arpemailid,
      countrycode: this.CountryCode,
    }
    this.service.post(this.service.updateoldarp, obj1).subscribe((resp) => {
      if (resp == 1) {
        this.toast.showsuccess(this.ValidationService.msg_Validate_arpupdatesuccess);
      } else {
        this.toast.showwarning(this.ValidationService.msg_Validate_arpupdateFailure);
      }
    })
  }

  updateArpDetails2() {
    this.selectchck1=true;
    if (this.function2()) {
      return;
    }
    this.selectchck1=true;
    var obj2 = {
      arp_email_id: this.emailid,
      arp_pass_word: this.password2,
      arp_role: "arp",
      is_first_time_login: true,
      companyname: this.companyname2,
      contactperson_name: this.ContactPersonName2,
      mobilenumber: this.Mobile2,
      web_site:this.Website2,
      gstcode: this.gstCode2,
      pan_or_uidnumber: this.panoruid2,
      door_no: this.Doorno2,
      landmark: this.LandMark2,
      street: this.Street2,
      area: this.Area2,
      city_id: this.ucity2,
      district_id: this.udistrict2,
      state_id: this.ustate2,
      countryid: this.ucountry2,
      pincode: this.PinCode2,
      bank_account_number: this.AccountNumber2,
      ifsc: this.IFSCCode2,
      cheque_name: this.filepath2,
      password_reset: false,
      cmp_email_id: this.arpemailid2,
      countrycode: this.CountryCode2,
    }
    this.service.post(this.service.updatenewarp, obj2).subscribe((resp) => {
      // console.log(resp);
      if (resp == 1) {
        this.toast.showsuccess(this.ValidationService.msg_Validate_arpCreatedSuccess);
        this.selectchck1=true;
        this.getarp();
        this.editcurrarp=false;
        this.editNewarp=false;
        this.editselection=false;
        this.tipdisplay=false;
      } else {
        this.toast.showwarning(this.ValidationService.msg_Validate_arpCreationFailure);
        this.tipdisplay=true;
        this.editcurrarp=true;
        this.editNewarp=true;
        this.editselection=true;
      }
    })
  }
  showArps() {
    localStorage.removeItem('arpeditkey');
    this.statefilter1();
    this.selectchck1=false;
    this.selectchck1=false;
    this.showdetails = false;
    this.viewarp = true;
    this.editcurrarp = false;
    this.editNewarp = false;
    this.editselection = false;
  }
  selectcheck1(id) {
    //console.log(id);
    localStorage.setItem("arpeditkey",id);
    this.editcurrarp = true;
    this.editNewarp = false;
  }
  selectcheck2(id) {
    //console.log(id);
    localStorage.setItem("arpeditkey",id);
    this.editcurrarp = false;
    this.editNewarp = true;
  }
  
  
  getFiles(event) {
    if(event.target.files[0].name.split('.')[1] == 'pdf') {
      this.fileToUpload = event.target.files[0];
      this.filepath = event.target.files[0].name;
    }else{
    this.toast.showwarning(this.ValidationService.msg_validate_file);
    }
  }
  uploadDocument() {
    // console.log(this.filepath);
    this.arpid = Number(localStorage.getItem('vc_arp'));
    const formData: FormData = new FormData();
    formData.append("arpid", this.arpid);
    formData.append("imagefile", this.fileToUpload);
    this.service.post(this.service.uploadCancelledCheques, formData).subscribe((res) => { 
      if(res==1){
        this.toast.showsuccess(this.ValidationService.msg_validate_uploadSuccess);
      }
      else{
      this.toast.showwarning(this.ValidationService.msg_validate_uploadFailure);
      }
    })
  }

  getFiles2(event) {
    if(event.target.files[0].name.split('.')[1] == 'pdf') {
      this.fileToUpload2 = event.target.files[0];
      this.filepath2 = event.target.files[0].name;
    }else{
    this.toast.showwarning(this.ValidationService.msg_validate_file);
    }
  }
  uploadDocument2() {
    this.arpid = Number(localStorage.getItem('vc_arp'));
    const formData: FormData = new FormData();
    formData.append("arpid", this.arpid);
    formData.append("imagefile", this.fileToUpload2);
    this.service.post(this.service.uploadCancelledCheques, formData).subscribe((res) => { 
      if(res==1){
        this.toast.showsuccess(this.ValidationService.msg_validate_uploadSuccess);
      }
      else{
      this.toast.showwarning(this.ValidationService.msg_validate_uploadFailure);
      }
    })
  }
  showtipback(){
    this.showtipdetails=false;
    this.showdetails=true;
  }

  getarp(){
  
    var id1 = Number(localStorage.getItem('vc_arp'));
    
  this.service.get(this.service.gettips + id1).subscribe((res) => {
    this.spinner.hide();
    for (let i = 0; i < res.length; i++) {
        this.datasource.push({
          arp_id:res[i].arp_id,
          orgid: res[i].orgid,
          orgname: res[i].orgname,
          firstname: res[i].firstname,
          orgwebsite: res[i].orgwebsite,
          orgaddress: res[i].orgaddress,
          industrytype: res[i].industrytype,
          email: res[i].email,
          mobilenumber: res[i].mobilenumber,
          orgcity:res[i].orgcity,
          orgdistrict:res[i].orgdistrict,
          orgstate: res[i].orgstate,
          orgcountry: res[i].orgcountry
        })
    }
  })
}
currentarpid:any;
sendMailToTip(data2){
  this.currentarpid=data2.arp_id;
   var cmpname =localStorage.getItem('arpcmpname');
   this.iarpdetails2=[];
   this.service.get(this.service.getArpByArpid + this.currentarpid).subscribe((res) => {
    // for (let i = 0; i < res.length; i++) {
      // this.iarpdetails2.push({
        
        this.obj2.company_name = res[0].company_name;
        this.obj2.contact_person_name=res[0].contact_person_name;
        this.obj2.mobile_number = res[0].mobile_number;
        this.obj2.arp_email= res[0].arp_email;
        this.obj2.arp_id = res[0].arp_id;
        if(res[0].website != null){
          this.obj2.website = res[0].website;
        }else{
          this.obj2.website=""
        }

        this.obj2.office_doorno = res[0].office_doorno;
        if(res[0].office_landmark != null){
        this.obj2.office_landmark = res[0].office_landmark;
        }else{
          this.obj2.office_landmark=""
        }
        this.obj2.office_street = res[0].office_street;
        this.obj2.office_area = res[0].office_area;
        this.obj2.office_city = res[0].office_city;
        this.obj2.office_district = res[0].office_district;
        this.obj2.office_state = res[0].office_state;
        this.obj2.offfice_country = res[0].offfice_country;
        this.obj2.arp_tip_mapped_date = res[0].arp_tip_mapped_date;
      // })
      
      this.object1 = {
        tip_email:data2.email,
        tipcentername:data2.orgname,
        oldarp_name:cmpname,
        arp_email:this.obj2.arp_email,
        companyname:this.obj2.company_name,
        contactperson:this.obj2.contact_person_name,
        contactnumber:this.obj2.mobile_number,
        website: this.obj2.website ,
        city: this.obj2.office_city,
        district: this.obj2.office_district ,
        state:this.obj2.office_state ,
        country:this.obj2.offfice_country,
        office_doorno:this.obj2.office_doorno,
        office_landmark:this.obj2.office_landmark,
        office_street:this.obj2.office_street,
        office_area: this.obj2.office_area
      }
    // }
    this.service.post(this.service.arpadmintipmail, this.object1).subscribe((res) => { 
    if(res!=null){
      if(res==true){
        this.toast.showsuccess(this.ValidationService.msg_validate_ArpupdTipmailSuccess);
      }else{
        this.toast.showwarning(this.ValidationService.msg_validate_ArpupdTipmailFailure);
      }
    }
  })
    
  })
  
 
}

  


  function1() {
    if (this.ValidationService.funValidateEmptyText(this.companyname, this.ValidationService.msg_Validate_emptyCompanyName, '#companyname')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.ContactPersonName, this.ValidationService.msg_Validate_emptyContactPersonName, '#ContactPersonName')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.Mobile, this.ValidationService.msg_Validate_emptyMobileNumber, '#Mobile')) {
      return true;
    }
    if (this.ValidationService.funValidateMobileNumberFormat(this.Mobile, this.ValidationService.msg_Validate_mobile_number_format, '#Mobile')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.CountryCode, this.ValidationService.msg_Validate_emptyCountrycode, '#CountryCode')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.gstCode, this.ValidationService.msg_Validate_emptygstcode, '#gstcode')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.panoruid, this.ValidationService.msg_Validate_panuid, '#panoruid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.emailid, this.ValidationService.msg_Validate_Email, '#emailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.emailid, this.ValidationService.msg_Invalid_arpEmail_Format, '#emailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.password, this.ValidationService.msg_Validate_passwordgenerate, '#password')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.arpemailid, this.ValidationService.msg_Validate_arpEmail, '#arpemailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.arpemailid, this.ValidationService.msg_Invalid_Email_Format, '#arpemailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.Doorno, this.ValidationService.msg_Validate_Doorno, '#Doorno')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.Area, this.ValidationService.msg_Validate_Area, '#Area')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.countryid, this.ValidationService.msg_Validate_emptyCountryName, '#countryid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.stateid, this.ValidationService.msg_Validate_emptystateName, '#stateid')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.cityid, this.ValidationService.msg_Validate_emptyCityName, '#cityid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.districtid, this.ValidationService.msg_Validate_emptydistrict, '#districtid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.PinCode, this.ValidationService.msg_Validate_pincode, '#Pincode')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.AccountNumber, this.ValidationService.msg_Validate_emptyAccountnumber, '#AccountNumber')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.IFSCCode, this.ValidationService.msg_Validate_emptyIFSCCode, '#IFSCCode')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.filepath, this.ValidationService.msg_Validate_emptycancelledcheque, '#filepath')) {
      return true;
    }

  }

  function2() {
    if (this.ValidationService.funValidateEmptyText(this.companyname2, this.ValidationService.msg_Validate_emptyCompanyName, '#companyname')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.ContactPersonName2, this.ValidationService.msg_Validate_emptyContactPersonName, '#ContactPersonName')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.Mobile2, this.ValidationService.msg_Validate_emptyMobileNumber, '#Mobile')) {
      return true;
    }
    if (this.ValidationService.funValidateMobileNumberFormat(this.Mobile2, this.ValidationService.msg_Validate_mobile_number_format, '#Mobile')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.CountryCode2, this.ValidationService.msg_Validate_emptyCountrycode, '#CountryCode')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.gstCode2, this.ValidationService.msg_Validate_emptygstcode, '#gstcode')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.panoruid2, this.ValidationService.msg_Validate_panuid, '#panoruid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.emailid, this.ValidationService.msg_Validate_Email, '#emailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.emailid, this.ValidationService.msg_Invalid_arpEmail_Format, '#emailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.password2, this.ValidationService.msg_Validate_passwordgenerate, '#password')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.arpemailid2, this.ValidationService.msg_Validate_arpEmail, '#arpemailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.arpemailid2, this.ValidationService.msg_Invalid_Email_Format, '#arpemailid2')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.Doorno2, this.ValidationService.msg_Validate_Doorno, '#Doorno')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.Area2, this.ValidationService.msg_Validate_Area, '#Area')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.ucountry2, this.ValidationService.msg_Validate_emptyCountryName, '#ucountry2')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.ustate2, this.ValidationService.msg_Validate_emptystateName, '#ustate2')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.ucity2, this.ValidationService.msg_Validate_emptyCityName, '#ucity2')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.udistrict2, this.ValidationService.msg_Validate_emptydistrict, '#udistrict2')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.PinCode2, this.ValidationService.msg_Validate_pincode, '#Pincode')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.AccountNumber2, this.ValidationService.msg_Validate_emptyAccountnumber, '#AccountNumber')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.IFSCCode2, this.ValidationService.msg_Validate_emptyIFSCCode, '#IFSCCode')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.filepath2, this.ValidationService.msg_Validate_emptycancelledcheque, '#filepath2')) {
      return true;
    }
  }


}
