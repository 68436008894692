
import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ToasterService } from '../services/toastr.service';
import { ValidationService } from '../services/validation.service';

@Component({
  selector: 'app-connectpartners',
  templateUrl: './connectpartners.component.html',
  styleUrls: ['./connectpartners.component.css']
})
export class ConnectpartnersComponent implements OnInit {

  name: any;
  name2:any;
  contact:any;
  website:any;
  email:any;
  city:any;
  state:any;
  country:any;
  message:any;

  




  constructor( private ValidationService: ValidationService,private service :ApiService, public toast: ToasterService ) { }

  ngOnInit(): void {
  }
  onSubmitContact()
  {
    if(this.function1()){
      return;
   }

   var obj1= {
    companyname: String(this.name),
    contactperson:this.name2,
    contactnumber:this.contact,
    emailAddress:this.email,
    website:this.website,
    city:this.city,
    state:this.state,
    country:this.country,
    message:this.message,
    }
   this.service.post(this.service.connectToArpPartnerstoEmail, obj1).subscribe((resp) => {
    //  console.log(resp);
     if(resp==true){
      this.toast.showsuccess(this.ValidationService.msg_Validate_ContactusSuccess);
     }else{
      this.toast.showwarning(this.ValidationService.msg_Validate_warning);
     }

   })

  }

  





  function1(){
    if (this.ValidationService.funValidateEmptyText(this.name, this.ValidationService.msg_Validate_emptyCompanyName , '#name')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.name2, this.ValidationService.msg_Validate_emptyContactPersonName, '#name2')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.contact, this.ValidationService.msg_Validate_emptyMobileNumber, '#contact')) {
      return true;
    }
    if (this.ValidationService.funValidateMobileNumberFormat(this.contact, this.ValidationService.msg_Validate_mobile_number_format, '#contact')) {
      return true;
    }
    
    if (this.ValidationService.funValidateEmptyText(this.email, this.ValidationService.msg_Validate_Email, '#email')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.email, this.ValidationService.msg_Invalid_Email_Format, '#email')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.city, this.ValidationService.msg_Validate_emptyCityName, '#city')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.state, this.ValidationService.msg_Validate_emptystateName, '#state')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.country, this.ValidationService.msg_Validate_emptyCountryName, '#country')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.message, this.ValidationService.msg_Validate_emptyMessagefield, '#message')) {
      return true;
    }
  }

}
