import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';

import { NgxSpinnerService } from 'ngx-spinner';

import { ApiService } from '../../services/api.service';
import { ToasterService } from '../../services/toastr.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-arpparticipants',
  templateUrl: './arpparticipants.component.html',
  styleUrls: ['./arpparticipants.component.css'],
  providers: [DatePipe]
})
export class ArpparticipantsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  count = 100;
  certificateid: '';
  firstName: '';
  lastname: '';
  emailid: '';
  mailformat: '';
  hideTable = true;
  norecords = true;
  data;
  Status = '';
  fromDate;
  toDate = null;
  hideMain: boolean;
  detailedTab = true;
  participantDetails = []
  CertificationStatus = []
  orderStatus = "";
  isdisabledstatus: boolean;
  displayedColumns: string[] = ['certificationid', 'username', 'certification_name', 'certification_status_name', 'action']
  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService,
    public toast: ToasterService, private spinner: NgxSpinnerService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.clearFields()
    this.getCertificationStatus()
  }

  clearFields() {
    this.firstName = "",
      this.emailid = "",
      this.lastname = "",
      this.Status = "",
      this.fromDate = null,
      this.toDate = null,
      this.orderStatus = ""
      this.hideTable = true;
      var data = []
      this.data = new MatTableDataSource(data)
      this.data.paginator = this.paginator;
  }

  getCertificationStatus() {
    this.service.get(this.service.getCertificationStatus).subscribe(resp => {
      this.CertificationStatus = resp
    })
  }

  arpidArray = [];

  searchResult() {
    this.spinner.show()
    if (this.firstName == "" && this.emailid == "" && this.lastname == "" && this.Status == "" && this.fromDate == null &&
      this.toDate == null) {
      this.toast.showwarning(this.ValidationService.msg_Validate_empty)
      this.hideTable = true;
      this.norecords = true;
      this.data = []
      this.spinner.hide()
    } else {
      if (this.emailid != undefined && this.emailid != "") {
        if (this.funValidate()) {
          this.spinner.hide()
          return;
        }
      }
      var certiobj = {
        certificatestatus: this.Status,
        first_name: this.firstName,
        last_name: this.lastname,
        user_email: this.emailid,
        fromdate: (this.fromDate == "") ? null : (this.datePipe.transform(this.fromDate, "yyyy-MM-dd")),
        todate: (this.fromDate == "") ? null : (this.datePipe.transform(this.toDate, "yyyy-MM-dd")),
      }
      this.arpidArray=[];
      this.service.post(this.service.getarpCertificationdata, certiobj).subscribe(resp => {
        this.spinner.hide()
        // console.log(resp)
        if (resp != []) {
          var arp_id =  localStorage.getItem("arpid")
          for(let i=0; i<resp.length; i++){
          if(resp[i].arp_id == arp_id){
            this.arpidArray.push({
            userid: resp[i].userid,
            username: resp[i].username,
            mobilenumber: resp[i].mobilenumber,
            percountry: resp[i].percountry,
            certificationid: resp[i].certificationid,
            certification_exam_id_code: resp[i].certification_exam_id_code,
            submitted_date: resp[i].submitted_date,
            certification_name: resp[i].certification_name,
            certification_status_name: resp[i].certification_status_name,
            
            order_id: resp[i].order_id,
            arp_id: resp[i].arp_id
          })
          this.data = new MatTableDataSource(this.arpidArray)
          this.data.paginator = this.paginator;
          }
        }
        if( this.arpidArray.length != 0){
          this.hideTable = false;
          this.norecords = true;
        }
        else {
          this.hideTable = false;
          this.norecords = false;
          resp = []
          this.data = new MatTableDataSource(resp)
          this.data.paginator = this.paginator;
        }
       }
      })
    }
  }

  funview(id) {
    this.spinner.show()
    this.hideMain = true;
    this.detailedTab = false;
    this.isdisabledstatus = true;
    this.service.get(this.service.getdetailsbyOrderID + id).subscribe(res => {
      this.spinner.hide()
      if (res != []) {
        this.participantDetails = res;
        this.orderStatus = res[0].certification_status_id;
      }
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  funValidate() {
    if (this.ValidationService.funValidateEmailFormat(this.emailid,
      this.ValidationService.msg_Invalid_Email_Format, '#mailformat')) {
      return true;
    }
  }

  stepBack() {
    this.detailedTab = true;
    this.hideMain = false;
  }

  // FOR PAGINATOR
  funSyncPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }
}