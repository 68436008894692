
<header class="masthead my-3">
  <div class="container my-3">
    <div class="row">
      <div class="col-md-7">
        <div class="text-center">
          <img src="../../assets/img/enrolment.jpg" alt="" class="signin">
          <h4 class="text-uppercase">Update Password</h4>
         
        </div>
        <form #f="ngForm">
          <div class="row mb-3">
            <!-- <div class="col-6">
              <div class="form-group aep-form">
                
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/userIcon.jpg" alt="">
                  <mat-label>Email Id <span class="required-mark">*</span></mat-label>
                  <input matInput class="custom-form-control" placeholder="Enter Email Id" name="inputAddress"
                    [(ngModel)]="inputAddress" maxlength="50">
                </mat-form-field>
              </div>
            </div> -->
            <!-- <div class="col-6">
              <div class="form-group aep-form">
                
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/passwordIcon.jpg" alt="">
                  <mat-label>Old Password <span class="required-mark">*</span></mat-label>
                  <input matInput type="password" class="custom-form-control" placeholder="Enter old password" name="inputAddress1"
                    [(ngModel)]="inputAddress1" maxlength="50">
                </mat-form-field>

              </div>
              
            </div> -->
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group aep-form">
               
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/passwordIcon.jpg" alt="">
                  <mat-label>New Password <span class="required-mark">*</span></mat-label>
                  <input matInput type="password" class="custom-form-control" placeholder="Enter new password" name="inputAddress2"
                    [(ngModel)]="inputAddress2" maxlength="50">
                </mat-form-field>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group aep-form">
               
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/passwordIcon.jpg" alt="">
                  <mat-label>Confirm Password<span class="required-mark">*</span></mat-label>
                  <input matInput type="password" class="custom-form-control" placeholder="Enter confirm password" name="inputAddress3"
                    [(ngModel)]="inputAddress3" maxlength="50">
                </mat-form-field>
              </div>
            </div>
          </div>
         
      <div class="text-center mt-1">
        <button type="submit" class="btn custom-btn py-3" (click)="onPassordUpdate()">Submit</button>
      </div>
      </form>
    </div>
    <div class="offset-1 col-md-4 pt-5 mt-3">
      <img src="../../assets/img/signup.jpg" class="img-fluid">
    </div>
  </div>
  </div>
</header>

