import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { NgxSpinnerService } from 'ngx-spinner';

import { ApiService } from '../../services/api.service';
import { ToasterService } from '../../services/toastr.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  
  @ViewChild('countid') countid: MatSelect;
  @ViewChild('countstateid') countstateid: MatSelect;
  @ViewChild('countdistid') countdistid: MatSelect;
  @ViewChild('countcityid') countcityid: MatSelect;
  @ViewChild('percountid') percountid: MatSelect;
  @ViewChild('percountstateid') percountstateid: MatSelect;
  @ViewChild('percountdistid') percountdistid: MatSelect;
  @ViewChild('percountcityid') percountcityid: MatSelect;

  
  public isProfile: string = 'View and Edit';
  public currentMenuPath: string = '';

  public showTopMenu: boolean = true;
  st_email:boolean;
  Countries=[];
  
  ispermanent:boolean = false;
 
  CCountries=[];
  States;
  SStates
  Districts;
  DDistricts;
  Cities;
  CCities;
  Country = {
    country_name: ""
  };
  State = {
    statename: ""
  };
  District = {
    districtname: ""
  };
  City = {
    cityname: "",
  };
  Countrys = {
    country_names: ""
  };
  Stat = {
    statenames: ""
  };
  Dist = {
    districtnames: ""
  };
  Cityy = {
    citynames: "",
  };
  obj = {
    student_firstname: '',
    student_lastname: '',
    student_email: '',
    student_password: '',
    student_mobile: '',
    student_predoorno: '',
    student_prerelationship: '',
    student_preflat: '',
    student_prelandmark: '',
    student_prestreet: '',
    student_prearea: '',
    student_precity: '',
    student_predistrict: '',
    student_prestate: '',
    student_precountry: '',
    country_id:'',
    stateid: '',
    districtid:'',
    cityid:'',
    country_id2:'',
    stateid2: '',
    districtid2:'',
    cityid2:'',
    student_prepincode: '',
    student_perdoorno: '',
    student_perrelationship: '',
    student_perflat: '',
    student_perlandmark: '',
    student_perstreet: '',
    student_perarea: '',
    student_percity: '',
    student_perdistrict: '',
    student_perstate: '',
    student_percountry: '',
    student_perpincode: '',
    
  }
  issameaddress:any;
  // 
  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService,
    public toast: ToasterService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getCountries();
    this.getCountries2();
    this.st_email =true;
    this.getUserProfile();
    this.currentMenuPath = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    if (this.currentMenuPath === 'Addprofile') {
      this.isProfile = 'Add';
    }
  }


  getUserProfile() {
    this.spinner.show()
    var id = localStorage.getItem('userid');
    var userid = id;
     this.spinner.show()
    this.service.get(this.service.getUsers + userid).subscribe((resp) => {
      this.spinner.hide()
        if (resp != null) {
          for (let i = 0; i < resp.length; i++) {
            
            this.obj.student_firstname = resp[0].firstname;
            this.obj.student_lastname = resp[0].lastname;
            this.obj.student_email = resp[0].email;
            this.obj.student_password = resp[0].password;
            this.obj.student_mobile= resp[0].mobilenumber;
            if(resp[0].predoorno!=null){
            this.obj.student_predoorno= resp[0].predoorno;
            }else{
              this.obj.student_predoorno ="";
            }
            if(resp[0].prelandmark!=null){
              this.obj.student_prelandmark= resp[0].prelandmark;
            }else{
              this.obj.student_prelandmark="";
            }
            if(resp[0].preflat!=null){
              this.obj.student_preflat =resp[0].preflat;
            }else{
              this.obj.student_preflat="";
            }
            if(resp[0].prestreet!=null){
            this.obj.student_prestreet =resp[0].prestreet;
            }else{
              this.obj.student_prestreet="" ;
            }
            this.obj.student_prearea= resp[0].prearea;
            this.obj.country_id =resp[0].precountryid;
            localStorage.setItem("profcountry_id", resp[0].precountryid); //state
            this.getprestate();
            this.obj.stateid =resp[0].prestateid;
            localStorage.setItem('profstateid',resp[0].prestateid);  //dis
            this.getpreDistricts();
            this.obj.districtid =resp[0].predistrictid;
            localStorage.setItem('profdistid',resp[0].predistrictid); //city
            this.getpreCities();
            this.obj.cityid =resp[0].precityid;
            this.obj.country_id2 =resp[0].percountryid;
            localStorage.setItem("profcountry_id2", resp[0].percountryid);
            this.getperstate();
            this.obj.stateid2 =resp[0].perstateid;
            localStorage.setItem('profstateid2',resp[0].perstateid);
            this.getperDistricts();
            this.obj.districtid2=resp[0].perdistrictid;
            localStorage.setItem('profdistid2',resp[0].perdistrictid);
            this.getperCities();
            this.obj.cityid2 =resp[0].percityid;
            this.obj.student_prepincode= resp[0].prepincode;

            if(resp[0].perdoorno!=null){
            this.obj.student_perdoorno= resp[0].perdoorno;
            }else{
              this.obj.student_perdoorno="";
            }
            if(resp[0].perflat!=null){
            this.obj.student_perflat= resp[0].perflat;
            }else{
              this.obj.student_perflat=""; 
            }
            if(resp[0].perlandmark!=null){
            this.obj.student_perlandmark= resp[0].perlandmark;
            }else{
              this.obj.student_perlandmark="";
            }
            if(resp[0].perstreet!=null){
            this.obj.student_perstreet= resp[0].perstreet;
            }else{
              this.obj.student_perstreet="";
            }
            this.obj.student_perarea= resp[0].perarea;
            this.obj.student_perpincode= resp[0].perpincode;
            

            if(resp[0].sameaddress=="true"){
              this.issameaddress=JSON.parse(resp[0].sameaddress);
              this.ispermanent =true;
              
            }else{
              this.issameaddress=JSON.parse(resp[0].sameaddress);
              this.ispermanent=false;
            }

          }
          
        }
      })
      
  }

  getCountries(){
    var regionid = 5;
    this.service
    .get(this.service.getCountries)
    .subscribe((res) => {
      if (res != null) {
        // this.Countries = res;
        for (let i = 0; i < res.length; i++) {
          // if (res[i].country_id == '35') {
            this.Countries.push({
              country_id: res[i].country_id,
              country_name: res[i].country_name
            })
          // }
        }
      }
    })
  }
  getCountries2(){
    var regionid = 5;
    this.service
    .get(this.service.getCountries)
    .subscribe((res) => {
      if (res != null) {
        // this.CCountries = res;
        for (let i = 0; i < res.length; i++) {
          // if (res[i].country_id == '35') {
            this.CCountries.push({
              country_id: res[i].country_id,
              country_name: res[i].country_name
            })
          // }
        }
        
      }
    })
  }
  
  getprestate(){
    var countryid = localStorage.getItem("profcountry_id");
    if(countryid != "null"){
    this.service
      .get(this.service.getStates + countryid)
      .subscribe((resp) => {
        if (resp != null) {
          this.States = resp.sort(function(a,b)
          {
            var st1 = a.statename.toUpperCase();
            var st2 = b.statename.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }

  getperstate(){
    var countryid = localStorage.getItem("profcountry_id2");
    if(countryid != "null"){
    this.service
      .get(this.service.getStates + countryid)
      .subscribe((resp) => {
        if (resp != null) {
          
          this.SStates = resp.sort(function(a,b)
          {
            var st1 = a.statename.toUpperCase();
            var st2 = b.statename.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }
  
  
  getpreDistricts() {
    var state_id = localStorage.getItem('profstateid');
    if(state_id != "null"){
    this.service
      .get(this.service.getDistricts + state_id)
      .subscribe((resp) => {
        if (resp != null) {
          this.Districts = resp.sort(function(a,b)
          {
            var st1 = a.districtname.toUpperCase();
            var st2 = b.districtname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
         
        }
      })
    }
  }

  getperDistricts() {
    var state_id = localStorage.getItem('profstateid2');
    if(state_id != "null"){
    this.service
      .get(this.service.getDistricts + state_id)
      .subscribe((resp) => {
        if (resp != null) {
         
          this.DDistricts = resp.sort(function(a,b)
          {
            var st1 = a.districtname.toUpperCase();
            var st2 = b.districtname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }
  
  getpreCities() {
    var district_id =  localStorage.getItem('profdistid');
    if(district_id != "null"){
    this.service
      .get(this.service.getCities + district_id)
      .subscribe((resp) => {
        if (resp != null) {
          this.Cities = resp.sort(function(a,b)
          {
            var st1 = a.cityname.toUpperCase();
            var st2 = b.cityname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
          
        }
      })
    }
  }

  getperCities() {
    var district_id =  localStorage.getItem('profdistid2');
    if(district_id != "null"){
    this.service
      .get(this.service.getCities + district_id)
      .subscribe((resp) => {
        if (resp != null) {
          
          this.CCities = resp.sort(function(a,b)
          {
            var st1 = a.cityname.toUpperCase();
            var st2 = b.cityname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }
 

  getStatesm(id) {
    var countryid = id;
    // this.obj.='';
    if(countryid != "null"){
    this.service
      .get(this.service.getStates + countryid)
      .subscribe((resp) => {
        if (resp != null) {
          this.States = resp.sort(function(a,b)
          {
            var st1 = a.statename.toUpperCase();
            var st2 = b.statename.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
          
        }
      })
    }
  }
  getStates2(id) {
    var countryid = id;
    this.obj.stateid2='';
    if(countryid != "null"){
      this.service.get(this.service.getStates + countryid).subscribe((resp) => {
        if (resp != null) {
          this.SStates = resp.sort(function(a,b)
          {
            var st1 = a.statename.toUpperCase();
            var st2 = b.statename.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }


  getDistrictsm(id) {
    var state_id = id;
    this.obj.districtid ='';
    if(state_id != "null"){
      this.service.get(this.service.getDistricts + state_id).subscribe((resp) => {
        if (resp != null) {
          this.Districts = resp.sort(function(a,b)
          {
            var st1 = a.districtname.toUpperCase();
            var st2 = b.districtname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }
  
  getDistricts2(id) {
    var state_id = id;
    this.obj.districtid2='';
    if(state_id != "null"){
    this.service
      .get(this.service.getDistricts + state_id)
      .subscribe((resp) => {
        if (resp != null) {
          this.DDistricts = resp.sort(function(a,b)
          {
            var st1 = a.districtname.toUpperCase();
            var st2 = b.districtname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
      
        }
      })
    }
  } 
// PRE CITY
  getCitiesm(id) {
    var district_id = id;
    this.obj.cityid='';
    if(district_id != "null"){
    this.service
      .get(this.service.getCities + district_id)
      .subscribe((resp) => {
        if (resp != null) {
          this.Cities = resp.sort(function(a,b)
          {
            var st1 = a.cityname.toUpperCase();
            var st2 = b.cityname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        
        }
      })
    }
  }
//  
  getCities2(id) {
    var district_id = id;
    this.obj.cityid2 ='';
    if(district_id != "null"){
    this.service
      .get(this.service.getCities + district_id)
      .subscribe((resp) => {
        if (resp != null) {
          this.CCities = resp.sort(function(a,b)
          {
            var st1 = a.cityname.toUpperCase();
            var st2 = b.cityname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        
        }
      })
    }
  }

 
   c:any;
   issame(id) {
    // console.log(id);
    var x=id.checked;
    localStorage.setItem('chk', x);
    if(x==false)
    {
      this.issameaddress=false;
      this.ispermanent=false;
      this.obj.student_perdoorno='';
      this.obj.student_perflat='';
      this.obj.student_perlandmark='';
      this.obj.student_perstreet='';
      this.obj.student_perarea='';
      this.obj.cityid2='';
      this.obj.districtid2='';
      this.obj.stateid2='';
      this.obj.country_id2='';
      this.obj.student_perpincode='';
    }
   else{
      this.ispermanent=true;
      this.issameaddress=true;
    }
   }
  
  updProfile() {
    if (this.validcheck()) {
      return;
    }
    var id = localStorage.getItem('userid');
    this.c =localStorage.getItem('chk');

    if(this.c =='false'){
      
      this.ispermanent=false;
      var obj1 = {
      user_id: Number(id),
      student_firstname:        this.obj.student_firstname,
      student_lastname:         this.obj.student_lastname,
      student_email:            this.obj.student_email,
      student_mobilenumber:     this.obj.student_mobile,
      // student_password: this.obj.student_password,
      student_predoorno:        this.obj.student_predoorno,
      student_prerelationship:  "",
      student_preflat:          this.obj.student_preflat,
      student_prelandmark:      this.obj.student_prelandmark,
      student_prestreet:        this.obj.student_prestreet,
      student_prearea:          this.obj.student_prearea,
      student_precityid:        this.obj.cityid,
      student_predistrictid:    this.obj.districtid,
      student_prestateid:       this.obj.stateid,
      student_precountryid:     this.obj.country_id,
      student_prepincode:       this.obj.student_prepincode,
      student_perdoorno:        this.obj.student_perdoorno,
      student_perrelationship:  "",
      student_perflat:          this.obj.student_perflat,
      student_perlandmark:      this.obj.student_perlandmark,
      student_perstreet:        this.obj.student_perstreet,
      student_perarea:          this.obj.student_perarea,
      student_percityid:        this.obj.cityid2,
      student_perdistrictid:    this.obj.districtid2,
      student_perstateid:       this.obj.stateid2,
      student_percountryid:     this.obj.country_id2,
      student_perpincode:       this.obj.student_perpincode,
      is_active:                true,
      is_email_verified:        true,
      student_sameaddress:      "false",
      student_isnotaepcrt:      true
    }

    this.service
    .post(this.service.updProfileData, obj1)
    .subscribe((resp) => {
      // console.log(resp)
      if(resp == id){
      this.toast.showsuccess(this.ValidationService.msg_Validate_ProfileUpdateSuccess);
      if (this.currentMenuPath === 'Addprofile') {
        this.router.navigate(['/aep/participant/courseenrollment']);
      }
      }
      else{
        this.toast.showwarning(this.ValidationService.msg_Validate_ProfileUpdateFailure);
      }
     })
    }else{
    this.ispermanent=true;

    var obj2 = {
      user_id: Number(id),
      student_firstname:         this.obj.student_firstname,
      student_lastname:          this.obj.student_lastname,
      student_email:             this.obj.student_email,
      student_mobilenumber:      this.obj.student_mobile,
      // student_password: this.obj.student_password,
      student_predoorno:          this.obj.student_predoorno,
      student_prerelationship:    "",
      student_preflat:            this.obj.student_preflat,
      student_prelandmark:        this.obj.student_prelandmark,
      student_prestreet:          this.obj.student_prestreet,
      student_prearea:            this.obj.student_prearea,
      student_precityid:          this.obj.cityid,
      student_predistrictid:      this.obj.districtid,
      student_prestateid:         this.obj.stateid,
      student_precountryid:       this.obj.country_id,
      student_prepincode:         this.obj.student_prepincode,
      student_perdoorno:          this.obj.student_predoorno,
      student_perrelationship:    "",
      student_perflat:            this.obj.student_preflat,
      student_perlandmark:        this.obj.student_prelandmark,
      student_perstreet:          this.obj.student_prestreet,
      student_perarea:            this.obj.student_prearea,
      student_percityid:          this.obj.cityid,
      student_perdistrictid:      this.obj.districtid,
      student_perstateid:         this.obj.stateid,
      student_percountryid:       this.obj.country_id,
      student_perpincode:         this.obj.student_prepincode,
      is_active:                  true,
      is_email_verified:          true,
      student_sameaddress:        "true",
      student_isnotaepcrt:        true,
    }

    this.service
      .post(this.service.updProfileData, obj2)
      .subscribe((resp) => {
        console.log(resp)
        if(resp == id){
        this.toast.showsuccess(this.ValidationService.msg_Validate_ProfileUpdateSuccess);
        if (this.currentMenuPath === 'Addprofile') {
          this.router.navigate(['/aep/participant/courseenrollment']);
        }
        }
        else{
          this.toast.showwarning(this.ValidationService.msg_Validate_ProfileUpdateFailure);
        }
      })
  }
}


  validcheck() {
    if(this.ispermanent==true){

      if (this.ValidationService.funValidateEmptyText(this.obj.student_firstname, this.ValidationService.msg_Validate_emptyFirstname, '#obj.student_firstname')) {
        return true;
      }
      if (this.ValidationService.funValidateEmptyText(this.obj.student_lastname, this.ValidationService.msg_Validate_emptyLastname , '#obj.student_lastname')) {
        return true;
      }
      if (this.ValidationService.funValidateEmptyText(this.obj.student_mobile, this.ValidationService.msg_Validate_emptyMobileNumber , '#obj.student_mobile')) {
        return true;
      }
      if (this.ValidationService.funValidateMobileNumberFormat(this.obj.student_mobile, this.ValidationService.msg_Validate_mobile_number_format , '#obj.student_mobile')) {
        return true;
      }
      if (this.ValidationService.funValidateEmptyText( this.obj.student_prearea, this.ValidationService.msg_Validate_Area, '#obj.student_prearea')) {
        return true;
      }
      
      if (this.ValidationService.funValidateEmptyText( this.obj.student_prepincode, this.ValidationService.msg_Validate_pincode, '#obj.student_prepincode')) {
        return true;
      }
     



    if (this.ValidationService.funValidateEmailFormat(this.obj.student_email, 
      this.ValidationService.msg_Invalid_Email_Format, '#email')) {
      return true;
    }
    if (
        this.ValidationService.funValidateSelectElement(
        this.obj.country_id,
        this.ValidationService.msg_Validate_Country,
        this.countid
       )
      ) {
      return true;
    }
    if (
        this.ValidationService.funValidateSelectElement(
        this.obj.stateid,
        this.ValidationService.msg_Validate_State,
        this.countstateid
      )
    ) {
      return true;
    }
    if (
      this.ValidationService.funValidateSelectElement(
        this.obj.districtid,
        this.ValidationService.msg_Validate_District,
        this.countdistid
      )
    ) {
      return true;
    }
    if (
      this.ValidationService.funValidateSelectElement(
        this.obj.cityid,
        this.ValidationService.msg_Validate_City,
        this.countcityid
      )
    ) {
      return true;
    }

  }else{

    if (this.ValidationService.funValidateEmptyText(this.obj.student_firstname, this.ValidationService.msg_Validate_emptyFirstname, '#obj.student_firstname')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.obj.student_lastname, this.ValidationService.msg_Validate_emptyLastname , '#obj.student_lastname')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.obj.student_mobile, this.ValidationService.msg_Validate_emptyMobileNumber , '#obj.student_mobile')) {
      return true;
    }
    if (this.ValidationService.funValidateMobileNumberFormat(this.obj.student_mobile, this.ValidationService.msg_Validate_mobile_number_format, '#this.obj.student_mobile')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText( this.obj.student_prearea, this.ValidationService.msg_Validate_Area, '#obj.student_prearea')) {
      return true;
    }
    
    if (this.ValidationService.funValidateEmptyText( this.obj.student_prepincode, this.ValidationService.msg_Validate_pincode, '#obj.student_prepincode')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText( this.obj.student_perarea, this.ValidationService.msg_Validate_perArea, '#obj.student_perarea')) {
      return true;
    }
    
    if (this.ValidationService.funValidateEmptyText( this.obj.student_perpincode, this.ValidationService.msg_Validate_perpincode, '#obj.student_perpincode')) {
      return true;
    }

    if (this.ValidationService.funValidateEmailFormat(this.obj.student_email, 
      this.ValidationService.msg_Invalid_Email_Format, '#email')) {
      return true;
    }
    if (
        this.ValidationService.funValidateSelectElement(
        this.obj.country_id,
        this.ValidationService.msg_Validate_Country,
        this.countid
       )
    ) {
      return true;
    }
    if (
      this.ValidationService.funValidateSelectElement(
        this.obj.stateid,
        this.ValidationService.msg_Validate_State,
        this.countstateid
      )
    ) {
      return true;
    }
    if (
      this.ValidationService.funValidateSelectElement(
        this.obj.districtid,
        this.ValidationService.msg_Validate_District,
        this.countdistid
      )
    ) {
      return true;
    }
    if (
      this.ValidationService.funValidateSelectElement(
        this.obj.cityid,
        this.ValidationService.msg_Validate_City,
        this.countcityid
      )
    ) {
      return true;
    }


    if (
      this.ValidationService.funValidateSelectElement(
        this.obj.country_id2,
        this.ValidationService.msg_Validate_Country,
        this.percountid
      )
    ) {
      return true;
    }
    if (
      this.ValidationService.funValidateSelectElement(
        this.obj.stateid2,
        this.ValidationService.msg_Validate_State,
        this.percountstateid
      )
    ) {
      return true;
    }
    if (
      this.ValidationService.funValidateSelectElement(
        this.obj.districtid2,
        this.ValidationService.msg_Validate_District,
        this.percountdistid
      )
    ) {
      return true;
    }
    if (
      this.ValidationService.funValidateSelectElement(
        this.obj.cityid2,
        this.ValidationService.msg_Validate_City,
        this.percountcityid
      )
    ) {
      return true;
    }
  }
}
}