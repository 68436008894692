<div *ngIf="completedcourse">
    <h4 class="heading container mx-auto d-block">It may take upto 24 hours for your completed exams or certifications
        to display here. In case of SBT, PBP and EBT the Certification completion status will be shown based on the
        evaluations done by the designated Professional Practitioners.</h4>
    <mat-card class="completedcourse">
        <mat-card-header>
            <!-- <mat-card-title class="ml-2 p-0 headingstyling">
                <h4>My Completed Certifications</h4>
            </mat-card-title> -->
            <mat-card-title class="m-0 p-0 headerstyling">
                <h4>My Completed Certifications</h4>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="coursesdata.length > 0">
            <div class="row mt-3 px-5 mob-px5">
                <div class="col-md-6 pl-0 mb-3 mobpl0" *ngFor="let course of coursesdata">
                    <section class="CompletedCertificates">
                        <div class="row px-2">
                            <div class="col-md-5 bdr-l">
                                <div class="row px-2">
                                <h6>
                                    <img src="../../../assets/img/brand-logo.jpg" class="courseImg" alt="">
                                    {{course.certification_name}}
                                </h6>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <p><label>Code</label>{{course.certification_code}}</p>
                                <p><label>Completion Date  </label>{{course.completed_date}}</p>
                                <p><label>Result</label>Passed</p>
                                <button type="submit" class="btn custom-btn my-2" (click)="showCertificate(course)">View
                                    Certificate</button>
                                <button type="submit" class="btn custom-btn my-2"
                                    (click)="downloadCertificate(course)">Download Certificate</button>
                                <!-- <div class="text-right">
                                    <a href="#" (click)="showCourseDetails()" >Show Details</a>
                                </div> -->
                                <footer class="text-center mt-4">
                                    <a class="btn btn-outline-light btn-social mx-1"
                                        href="https://www.facebook.com"
                                        target="_blank"><i class="fab fa-fw fa-facebook-f"></i></a>
                                    <a class="btn btn-outline-light btn-social mx-1"
                                        href="https://twitter.com" target="_blank"><i
                                            class="fab fa-fw fa-twitter"></i></a>
                                    <a class="btn btn-outline-light btn-social mx-1"
                                        href="https://www.linkedin.com" target="_blank"><i
                                            class="fab fa-fw fa-linkedin-in"></i></a>
                                </footer>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </mat-card-content>
        <mat-card-content *ngIf="coursesdata.length == 0">
            <p class="no-data">You did not earn any certification yet. You can view your completed certifications in
                this page once you successfully pass in the enrolled exam(s). Please access the available certifications
                in Enroll Certifications page to enroll.</p>
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
                [fullScreen]="true">
                <p style="color: white">Loading... </p>
            </ngx-spinner>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="completedcoursedetails">
    <mat-card class="px-4 py-3 completedcourse large">
        <mat-card-header>
            <mat-card-title class="m-0 p-0">
                <span class="material-icons" (click)="stepBack()">keyboard_backspace</span>
                My Completed Certifications Course Details
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="col-md-12" *ngFor="let course1 of coursedata2">
                <div class="bordered">
                    <h5>{{course1.certification_name}}</h5>
                    <div class="row">
                        <div class="col-md-10">
                            <p><label>Code</label>{{course1.certification_code}}</p>
                            <p><label>Type</label>{{course1.exam_type}}</p>
                            <p><label>Level</label>{{course1.level_name}}</p>
                            <p><label>Exam Date</label>{{course1.course_examdate}}</p>
                            <p><label>Exam Duration</label>{{course1.exam_duration}}</p>
                            <p><label>Delivery Method</label>{{course1.delivery_method}}</p>
                            <p><label>Cost</label>{{course1.certification_cost }}</p>
                            <p><label>Status</label>
                                <span class="status">
                                    <div class="progress">
                                        <div class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                                            role="progressbar" style="width:100%">
                                            <small>{{course1.certification_status_name}}</small>
                                        </div>
                                    </div>
                                </span>
                            </p>
                        </div>
                        <!-- <div class="col-md-2">
                            <button mat-raised-button color="primary">Re Schedule</button>
                        </div> -->
                    </div>

                    <div class="my-4">
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="true" class="mb-3">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        ARP Details
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p><label>ARP Name</label>{{course1.company_name}}</p>
                                        <p><label>Contact Person Name</label>{{course1.contact_person_name}}</p>
                                        <p><label>Mobile No.</label>{{course1.mobile_number}}</p>
                                    </div>
                                    <div class="col-md-6">

                                        <p><label>Email</label>{{course1.arp_email}}</p>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Tip Details
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p><label>Tip Name</label>{{course1.orgname}}</p>
                                <label class="subheading">Tip Address</label>
                                <div class="row">
                                    <div class="col-md-6">
                                        <!-- <p><label>Door No.</label></p>
                                        <p><label>Landmark</label></p>
                                        <p><label>Street</label></p> -->
                                        <p><label>Area</label>{{course1.orgaddress}}</p>
                                        <p><label>City</label>{{course1.orgcity}}</p>
                                        <p><label>District</label>{{course1.orgdistrict}}</p>
                                    </div>
                                    <div class="col-md-6">
                                        <!-- <p><label>Pin Code</label></p> -->
                                        <p><label>Contact Name</label>{{course1.firstname}}</p>
                                        <p><label>Email</label>{{course1.email}}</p>
                                        <p><label>Mobile Number</label>{{course1.mobilenumber}}</p>
                                        <p><label>Tip Website</label>{{course1.orgwebsite}}</p>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>



<!-- Core Engineer Certificate popup for all levels -->
<div class="modal" *ngIf="showCoreCertificate">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-center">View Certificate</h5>
                <button type="button" class="close" (click)="closeCertificate()">&times;</button>
            </div>
            <div class="modal-body" id="CoreData">
                <section class="coreEngineer">
                    <div>
                        <img src="../../../assets/img/downloadIcon.png" alt="downloadicon" class="download"
                        (click)="downloadCertificate('download')" />
                    <p class="cert-text_highlight">This is to certify that</p>
                    <h3>{{obj1.firstname.toUpperCase()}}&nbsp;{{this.obj1.lastname.toUpperCase()}}</h3>
                    <p class="cert-text_highlight">has been formally validated by AEP Certification for their demonstrated</p>
                    <p class="cert-text_highlight">knowledge, skill and experience in achieving</p>
                    <h1 *ngIf = "oline">AEP CERTIFIED CORE ENGINEER-&nbsp;{{this.obj3.brachname}}&nbsp;ENGINEER IN MULTI DOMAIN PROGRAM &nbsp;&nbsp;-'{{this.obj3.level_name}}'&nbsp; LEVEL</h1>
                    <h1 *ngIf = "aline">AEP CERTIFIED CORE ENGINEER-&nbsp;{{this.obj3.brachname}}&nbsp;<span>IN</span>&nbsp;{{this.obj3.domain}}&nbsp;-'{{this.obj3.level_name}}'&nbsp; LEVEL</h1>
                    <p *ngIf ="oline" class="cert-text_highlight">and is hereby deemed to be considered as qualified Entry Level Engineer</p>
                    <p *ngIf ="aline" class="cert-text_highlight">and is hereby deemed to possess up to <span *ngIf ="aline" >{{this.obj2.yearsforlevel_a}}</span>&nbsp;industry equivalent experience in</p>
                    <p *ngIf ="aline" class="cert-text_highlight"><span *ngIf ="domainname">{{this.obj3.domain}}</span>&nbsp;domain/field.</p>
                    </div>
                    <!-- &nbsp;<span *ngIf ="softwaretool">with</span>&nbsp;
                    <span *ngIf ="softwaretool">{{this.obj3.software_tool}}</span></p> -->
                    <footer class="row">
                        <div class="col-md-5 footer-list">
                            <p><label class="ps-1">Certification ID :</label>{{this.obj3.certification_exam_id_code}}</p>
                            <p><label class="ps-2">Issue Date :</label>{{this.obj3.completed_date}}</p>
                            <!-- <p><label>Expiration Date :</label></p> -->
                        </div>
                        <div class="col-md-3">
                        <img src="../../../assets/img/Satya-Naggireddy-for-Exam-Director.png" alt="" class="directorsign" /> 
                            <p>Exam Director</p>
                            <span>(Satya Naggireddy)</span>
            
                        </div>
                        <div class="col-md-3 pl-0">
                            <img src="../../../assets/img/Siva-Karunakaram-for-CEO.png" alt="" class="executivesign" /> 
                            <p>Chief Executive Officer</p>
                            <span>(Siva Karunakaram)</span>
                            
                            
                        </div>
                    </footer>
                </section>
            </div>
        </div>
    </div>
</div>    

    <!-- Design Engineer Certificate popup  BASIC level-->
    <div class="modal" *ngIf="showDesignCertificate">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-center">View Certificate</h5>
                    <button type="button" class="close" (click)="closeCertificate()">&times;</button>
                </div>
                <div class="modal-body" id="designData">
                    <section class="designEngineer">
                        <div>
                            <img src="../../../assets/img/downloadIcon.png" alt="downloadicon" class="download"
                            (click)="downloadCertificate('download')" />
                        <p class="cert-text_highlight">This is to certify that</p>
                        <h3>{{obj1.firstname.toUpperCase()}}&nbsp;{{this.obj1.lastname.toUpperCase()}}</h3>
                        <p class="cert-text_highlight">has been formally validated by AEP Certification for their demonstrated</p>
                        <p class="cert-text_highlight">knowledge, skill and experience in achieving</p>
                        <h1 *ngIf = "basiclvl" >AEP CERTIFIED DESIGN ENGINEER &nbsp; - {{this.obj3.brachname}}&nbsp; with&nbsp;{{ this.obj3.software_tool}}&nbsp;-'{{this.obj3.level_name}}'&nbsp; LEVEL</h1>
                        <h1 *ngIf = "associatelvl" >AEP CERTIFIED DESIGN ENGINEER &nbsp; - {{this.obj3.brachname}}&nbsp; IN &nbsp;{{this.obj3.domain}}&nbsp;  with&nbsp;{{ this.obj3.software_tool}}&nbsp;-'{{this.obj3.level_name}}'&nbsp;LEVEL</h1>
                        <p *ngIf = "basiclvl" class="cert-text_highlight">and is hereby deemed to be considered as qualified Entry Level Engineer</p>
                        <p *ngIf = "associatelvl" class="cert-text_highlight">and is hereby deemed to possess up to&nbsp;<span *ngIf ="associatelvl" >{{this.obj2.yearsforlevel_associate}}</span> &nbsp; industry equivalent experience in  
                            <p *ngIf ="associatelvl" class="cert-text_highlight"><span *ngIf ="domainname">{{this.obj3.domain}} domain/field</span>
                                 <span *ngIf ="softwaretool" class="cert-text_highlight">with</span>&nbsp;
                                <span *ngIf ="softwaretool" class="cert-text_highlight">{{this.obj3.software_tool}}.</span></p>
                        </div>
                        <!-- <p><b>&lt;Domain Name&gt;</b> with <b>&lt;Tool Name&gt;</b></p> -->
                        <footer class="row">
                            <div class="col-md-5 footer-list">
                                <p><label class="ps-1">Certification ID :</label>{{this.obj3.certification_exam_id_code}}</p>
                                <p><label class="ps-2">Issue Date :</label>{{this.obj3.completed_date}}</p>
                                <!-- <p><label>Expiration Date :</label></p> -->
                            </div>
                            <div class="col-md-3">
                                <img src="../../../assets/img/Satya-Naggireddy-for-Exam-Director.png" alt="" class="directorsign" /> 
                                <p>Exam Director</p>
                                <span>(Satya Naggireddy)</span>
                                <!-- <span></span> -->
                                
                            </div>
                            <div class="col-md-3 pl-0">
                                <img src="../../../assets/img/Siva-Karunakaram-for-CEO.png" alt="" class="executivesign" /> 
                                <p>Chief Executive Officer</p>
                                <span>(Siva Karunakaram)</span>
                            </div>
                        </footer>
                    </section>
                </div>
            </div>
        </div>
    </div>
