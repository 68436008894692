<nav class="navbar navbar-expand-lg bg-white fixed-top" id="mainNav">
    <div class="container-fluid px-1">
        <a class="navbar-brand ">
            <img src="./assets/img/brand-logo.jpg" alt="brand-logo" class="brand-logo" (click)="showHomePage()">
        </a>
        <button #menuCollapseBtn class="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item  mx-0"><a class="nav-link py-3 px-0 px-lg-3" (click)="menuCollapse()" routerLink="/aep/home">Home</a></li>
                <li class="nav-item dropdown mx-0">
                    <a class="nav-link py-3 px-0 px-lg-3 rounded" id="navbardrop" data-toggle="dropdown"><span class="dropdown-toggle">About</span>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/about/whoweare">Who We Are?</a>
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/about/programoverview">AEP Certification Program Overview</a>
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/about/extendedservice">Extended Services</a>
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/about/careers">Careers</a>
                          </div>
                    </a>
                    
                </li>
                <li class="nav-item dropdown mx-0">
                    <a class="nav-link py-3 px-0 px-lg-3 rounded" id="navbardrop" data-toggle="dropdown"><span class="dropdown-toggle">Certifications</span>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/certificationofferings/certificate">Certification</a>
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/certificationofferings/searchaepcertification">Search AEP Certification Holders</a>
                          </div>
                    </a>
                </li>
                <li class="nav-item dropdown mx-0">
                    <a class="nav-link py-3 px-0 px-lg-3 rounded" id="navbardrop" data-toggle="dropdown"><span class="dropdown-toggle">Partners</span>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/partners/representative">Associate Representative Partner</a>
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/partners/traininginstitute">Training Institute Partners</a>
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/partners/authorizedindustry">Authorized Industry Trainers</a>
                            <a class="dropdown-item" (click)="menuCollapse()" routerLink="/aep/partners/course">Course Initiators</a>
                          </div>
                    </a>
                </li>
                <li class="nav-item mx-0"><a class="nav-link py-3 px-0 px-lg-3 rounded" (click)="menuCollapse()" routerLink="/aep/contact">Contact</a></li>
                <li class="nav-item mx-0 mt-3 ml-md-3">
                    <button class="btn custom-btn btn-sm mr-2" (click)="menuCollapse()" routerLink="/aep/signin" type="button">SIGN IN</button>
                    
                    <button class="btn custom-btn btn-sm" (click)="menuCollapse()" routerLink="/aep/signup" type="button">SIGN UP</button>

                    <!-- <button class="btn btn-outline-danger btn-sm" routerLink="/aep/profcomp" type="button">Edit Profile</button> -->
                </li>
            </ul>
        </div>
    </div>
</nav>