import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toastr.service';
import { ValidationService } from '../../services/validation.service';
import { CoreEngineerDetailsModel } from './offers.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public showTopScroll:boolean = false;

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
      let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
      this.showTopScroll = pos > 2836 ? true : false;
  }
  Levels = []
  Branches = []
  Lev;
  selectLev:any;
  Bran:any;
  // frstgrid = true;
  // thirdgrid = true;
  // fourthgrid = true;
  frstgrid1 = true;
  dataSource = [];
  dataSourcet;
  dataSourcef ;
  dataSourcef1 = [];
  frstid;
  classToggled: boolean = false;
  classToggleds: boolean = false;
  tab: any = 'tab1';
  tab1: any = 'tab2';
  public isCourse: boolean = false;
  public isCourse2:boolean =false;
  public currntUrlPath: string;
  Branches2=[]
  

  public showDetailsPopup: boolean = false;
  public isDesignEng: boolean = true;

  // public ELEMENT_DATA1ALL = [];
  // public ELEMENTDATA: CoreEngineerDetailsModel[] = [];
  public CoreEngineerDetailsData: CoreEngineerDetailsModel[] = [];

  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService,
    public toast: ToasterService,private spinner: NgxSpinnerService) { }
  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      
      this.spinner.hide();
    }, 500);
    this.img1();
    this.getBranches1('O');
    this.currntUrlPath = window.location.hash.substring(window.location.hash.lastIndexOf('/'));
  }

  approves(data){
   if (this.function4()) {
      return;
    }
    localStorage.setItem('certid',data.certification_id);
    localStorage.setItem('certname',data.certification_name);
    var certifications_id = localStorage.getItem('certid');
    var user_id = localStorage.getItem('userid');
    this.showDetailsPopup = false;
    this.CoreEngineerDetailsData = [];
    if(this.currntUrlPath === '/certificate'){
      this.router.navigateByUrl('/aep/signin');
    } 
    else if(this.currntUrlPath === '/courseenrollment'){

      this.service.get(this.service.getOrderedCourseDeatils   + user_id +  "/" + certifications_id).subscribe((res) => {
       if(res.length ==0){
          this.router.navigate(['/aep/participant/courseenrollment/:id']);
          return true;
        }
        else {
          if(res[0].trasaction_status=='success'){
           this.router.navigate(['/aep/participant/courseenrollment']);
           this.toast.showwarning(this.ValidationService.msg_Validate_courseEnrolled);
           return;
          }
          else {
            this.spinner.hide();
            this.router.navigate(['/aep/participant/courseenrollment/:id']);
            return;
        }
      }
     })
    }  
  }
  diff:any;
  img1() {
    this.classToggled = true;
    this.classToggleds = false;
    // this.frstgrid = true;
    // this.thirdgrid = true;
    // this.fourthgrid = true;
    this.frstgrid1 = true;
    this.isDesignEng = true;
    this.Lev = "";
    this.Bran = "";
    this.isCourse = true;
    this.isCourse2 =false;
    this.Levels = [];
    this.dataSource = [];
      this.dataSourcef =[];
      // this.spinner.show()
      this.service.get(this.service.getcourseandlevels).subscribe((res) => {
      // this.spinner.hide()
      
      for(let i=0; i<res.length; i++)
      {
        if( res[i].level_id == '1' || res[i].level_id == '2' )
        {
          this.Levels.push({
            course_level_id : res[i].course_level_id,
            level_name : res[i].level_name
           })
        }

      }
     
      
    })
  };

  img2() {
    this.classToggleds = true;
    this.classToggled = false;
    this.Lev = "";
    this.Bran = "";
    // this.frstgrid = true;
    // this.thirdgrid = true;
    this.isDesignEng = false;
    // this.fourthgrid = true;
    this.frstgrid1 = true;
    this.isCourse = false;
    this.isCourse2 =true;

    this.Branches = []
    this.Levels = [];
    this.dataSource = [];
      this.dataSourcef =[];

    this.service.get(this.service.getcourseandlevels).subscribe((res) => {
      
        for(let i=0; i<res.length; i++)
        {
          if( res[i].level_id == '5' || res[i].level_id == '6' || res[i].level_id == '7')
          {
            this.Levels.push({
              course_level_id : res[i].course_level_id,
              level_name : res[i].level_name
             })
          }
        }
      })
  }


  getBranches1(id) {
    // if(this.selectLev == ''){
    //   this.function1();
    // }
    
    localStorage.setItem('ofrlvlid',id.course_level_id);
    localStorage.setItem('levname', id.level_name);
    this.dataSource = [];
    this.dataSourcef =[];
    
    if(localStorage.getItem('ofrlvlid') !='undefined'){
    this.service.get(this.service.getcertificationcoursesbycourselevelid + id.course_level_id).subscribe((res) => {
      if(res != null){
        
        // this.frstgrid = false;
        // this.thirdgrid = true;
        // this.fourthgrid = true;
        // this.frstgrid1 = true;
        this.Bran = ""
        this.Branches = [];
        this.dataSource = [];
     for(let i=0; i<res.length; i++)
     {
       if( res[i].branch_id !== null  )
       {
        this.dataSource.push({
          certification_id   : res[i].certification_id,
          brachname          : res[i].brachname,
          certification_name : res[i].certification_name,
          certification_code : res[i].certification_code,
          exam_type          : res[i].exam_type,
          certification_cost : res[i].certification_cost,
          exam_duration      : res[i].exam_duration,
          delivery_method    : res[i].delivery_method,
          qualifying_percentage : res[i].qualifying_percentage,
          overview           : res[i].overview,
          who_can_apply      : res[i].who_can_apply,
          prerequisites      : res[i].prerequisites,
          level_name         : id.level_name

         })
      }
        
     }

      
      for(let i=0; i<this.dataSource.length; i++)
    {
      if( this.dataSource[i].branch_id !== null )
      {
        this.Branches.push({
          certification_id : this.dataSource[i].certification_id,
          brachname : this.dataSource[i].brachname,
          certification_name : this.dataSource[i].certification_name,
          certification_code : this.dataSource[i].certification_code,
          exam_type          : this.dataSource[i].exam_type,
          certification_cost : this.dataSource[i].certification_cost,
          exam_duration      : this.dataSource[i].exam_duration,
          delivery_method    : this.dataSource[i].delivery_method,
          qualifying_percentage : this.dataSource[i].qualifying_percentage,
          overview           : this.dataSource[i].overview,
          who_can_apply      : this.dataSource[i].who_can_apply,
          prerequisites      : this.dataSource[i].prerequisites
         })
      }
      // else {
      //     this.Branches=[];
      // }
    
      var outputArray = [];
      var count = 0;
      var start = false;

      for (let j = 0; j < this.Branches.length; j++) {
        for (let k = 0; k < outputArray.length; k++) {
          if (this.Branches[j]['brachname'] == outputArray[k]['brachname']) {
            start = true;
          }
        }
        count++;
        if (count == 1 && start == false) {
          outputArray.push(this.Branches[j]);
        }
        // console.log(outputArray)
        start = false;
        count = 0;
      }
      this.Branches = outputArray
 
   }
   this.Branches2 =this.Branches.sort(function(a,b)
   {
     var st1 = a.brachname.toUpperCase();
     var st2 = b.brachname.toUpperCase();
     return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
   });
  
  }
  })
}



}

getGrid(id) {
  // this.dataSource = [];
  if(this.dataSource.length!=0){
      // this.frstgrid = true;
        // this.thirdgrid = true;
        // this.fourthgrid = true;
        this.frstgrid1 = true;
        this.frstgrid2=false;
        this.dataSourcef1 = [];
        localStorage.setItem('branch',id);
  for( let i =0; i<this.dataSource.length; i++)
  {
      if(id==this.dataSource[i].brachname){
        this.dataSourcef1.push({
          certification_id  :this.dataSource[i].certification_id,
          certification_name: this.dataSource[i].certification_name,
          certification_code: this.dataSource[i].certification_code,
          exam_type : this.dataSource[i].exam_type,
          certification_cost: this.dataSource[i].certification_cost,
          exam_duration: this.dataSource[i].exam_duration,
          delivery_method: this.dataSource[i].delivery_method,
          qualifying_percentage : this.dataSource[i].qualifying_percentage,
          overview           : this.dataSource[i].overview,
          who_can_apply      : this.dataSource[i].who_can_apply,
          prerequisites      : this.dataSource[i].prerequisites,
          level_name         : localStorage.getItem('levname')
        })
      }
  }
  }
}

frstgrid2=true;
getGrid2(id) {
  // this.dataSource = [];
  if(this.dataSource.length!=0){
      // this.frstgrid = false;
      this.frstgrid2=true;
        // this.thirdgrid = true;
        // this.fourthgrid = true;
        this.frstgrid1 = false;
        this.dataSourcef1 = [];
        localStorage.setItem('branch',id);
  for( let i =0; i<this.dataSource.length; i++)
  {
      if(id==this.dataSource[i].brachname){
        this.dataSourcef1.push({
          certification_id  :this.dataSource[i].certification_id,
          certification_name: this.dataSource[i].certification_name,
          certification_code: this.dataSource[i].certification_code,
          exam_type : this.dataSource[i].exam_type,
          certification_cost: this.dataSource[i].certification_cost,
          exam_duration: this.dataSource[i].exam_duration,
          delivery_method: this.dataSource[i].delivery_method,
          qualifying_percentage : this.dataSource[i].qualifying_percentage,
          overview           : this.dataSource[i].overview,
          who_can_apply      : this.dataSource[i].who_can_apply,
          prerequisites      : this.dataSource[i].prerequisites,
          level_name         : localStorage.getItem('levname')
        })
      }
  }
  }
}
public closeDetailsPopup(){
  this.showDetailsPopup = false;
}

public showOptions(data){
  this.CoreEngineerDetailsData = [];
    this.showDetailsPopup = true;
    this.CoreEngineerDetailsData.push(data);
}

function4(){
  // if (this.ValidationService.funValidateEmptyText(this.selectLev, this.ValidationService.msg_Validate_Level, '#selectLev')) {
  //   return true;
  // }
  if (this.ValidationService.funValidateEmptyText(this.Bran, this.ValidationService.msg_Validate_Stream, '#Bran')) {
    return true;
  }
}
 
  public showScrollTop(): boolean{
    if(document.getElementById("myBtn").scrollHeight > 800){
      return true;
    }
    else return false;
  }


}
 