import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToasterService{
    constructor(public toastrService: ToastrService) {
      }
      showsuccess(message) {
        this.toastrService.success(message);
      }
    
      showerror(message) {
        this.toastrService.error(message);
      }
    
      showinfo(message) {
        this.toastrService.info(message);
      }
    
      showwarning(message) {
        this.toastrService.warning(message);
      }
    
      showHTMLMessage(message, title) {
        this.toastrService.success(message, title, {
          enableHtml: true
        });
      }
    }