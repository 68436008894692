<section class="page-section pb-4 mb-3">
  <div class="container text-center mt-5 mb-4">
    <h2 class="main-heading text-blue">AEP CERTIFICATION HOLDERS REGISTRY</h2>
    <!-- <p class="display-5">There are <b>{{count}}</b> engineering professionals who successfully completed AEP
      Certification globally. Search for certification holders by entering below details.</p> -->
    <p class="display-5">Search for certification holders by entering below details.</p>
    <div class="col-sm-8 d-block m-auto">
      <form>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-group">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/userIcon.jpg" alt="">
                  <mat-label>Certification ID<span></span></mat-label>
                  <input matInput class="custom-form-control" name="certificateid" [(ngModel)]="certificateid"
                    placeholder="Enter Your Certificate Id">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group aep-form">
              <mat-form-field class="example-full-width customForm">
                <img src="../../assets/img/emailIcon.jpg" alt="">
                <mat-label>Email<span></span></mat-label>
                <input matInput class="custom-form-control" id="mailformat" name="emailid" [(ngModel)]="emailid"
                  placeholder="Enter Your Last Name">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-group aep-form">
              <mat-form-field class="example-full-width customForm">
                <img src="../../assets/img/userIcon.jpg" alt="">
                <mat-label>First Name<span></span></mat-label>
                <input matInput class="custom-form-control" name="firstName" [(ngModel)]="firstName"
                  placeholder="Enter Your First Name">
              </mat-form-field>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group aep-form">
              <mat-form-field class="example-full-width customForm">
                <img src="../../assets/img/userIcon.jpg" alt="">
                <mat-label>Last Name<span></span></mat-label>
                <input matInput class="custom-form-control" name="lastname" [(ngModel)]="lastname"
                  placeholder="Enter Your Last Name">
              </mat-form-field>
            </div>
          </div>
        </div>

        <p class="mt-3">* At least one of Certification Code, First Name, Last Name or Email fields must be entered.
        </p>
        <div class="form-group mt-3 px-1">
          <div class="text-center mt-4">
            <button type="submit" (click)="searchResult()" class="btn custom-btn py-3">SEARCH</button>
          </div>
        </div>
      </form>
    </div>
  </div><br>
  <div class="col-sm-10 d-block m-auto">
    <section class="">
      <div [hidden]="hideTable">
        <div class="table-widtht">
          <table mat-table [dataSource]="data" class="mat-elevation-z8 filter-header remove-header-color tblwidth">
            <ng-container matColumnDef="certid">
              <th class="tablepadding" mat-header-cell *matHeaderCellDef> Certification Id
              </th>
              <td mat-cell *matCellDef="let element"> {{element.certification_exam_id_code}} </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th class="tablepadding" mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.username}} </td>
            </ng-container>
            <ng-container matColumnDef="count">
              <th class="tablepadding0" mat-header-cell *matHeaderCellDef> Country </th>
              <td mat-cell *matCellDef="let element"> {{element.percountry}} </td>
            </ng-container>
            <ng-container matColumnDef="certname">
              <th class="tablepadding1" mat-header-cell *matHeaderCellDef> Certification Name </th>
              <td mat-cell *matCellDef="let element"> {{element.certification_name}} </td>
            </ng-container>
            <ng-container matColumnDef="certdate">
              <th class="tablepadding2" mat-header-cell *matHeaderCellDef>
                Certificaion Date
              </th>
              <td mat-cell *matCellDef="let element"> {{element.completed_date}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <!-- <div [hidden]="norecords" class="center-align">
          <h3>No Records to Display</h3>
        </div> -->
          <!-- <mat-paginator (page)="funSyncPaginator($event)" [pageSize]="paginator.pageSize"
          [pageIndex]="paginator.pageIndex" [length]="paginator.length" [pageSizeOptions]="paginator.pageSizeOptions"
          showFirstLastButtons></mat-paginator> -->
          <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </section>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
</section>