import { Component, OnInit } from '@angular/core';
import { ValidationService } from '../services/validation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { ToasterService } from '../services/toastr.service';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';

@Component({
  selector: 'app-emailverified',
  templateUrl: './emailverified.component.html',
  styleUrls: ['./emailverified.component.css']
})
export class EmailverifiedComponent implements OnInit {

  

  user:{
    userid:any
  };

  constructor(private route:ActivatedRoute , private ValidationService: ValidationService,private spinner: NgxSpinnerService,private service :ApiService, public toast: ToasterService, private router: Router) { }

  ngOnInit(): void {
  
    this.user = {
      userid:this.route.snapshot.params['userid'],
    };

    var obj={
      user_id:Number(this.user.userid)
    }

    // var user_id=Number(obj);
    // var  a =Integer.valueOf((Number)obj);
    

    this.service.post(this.service.emailVerification, obj).subscribe((resp) => {
       if(resp ==1){
        // this.router.navigateByUrl('/aep/signin');
        // this.router.navigate(['/aep/signin']);
        this.toast.showsuccess(this.ValidationService.msg_Validate_emailVerifySuccess);
        
       }else{
        this.toast.showwarning(this.ValidationService.msg_Validate_emailVerifyFailure);
       }
    })
  }

  // submit(){
  //   var obj={
  //     userid: this.user.userid,
  //   }
  //   this.service.post(this.service.emailVerification, obj).subscribe((resp) => {
  //      if(resp ==1){
  //       this.router.navigateByUrl('/aep/signin');
  //      }else{
  //       this.toast.showwarning(this.ValidationService.msg_Validate_emailVerifyFailure);
  //      }
  //   })
  // }

  gotoLogin(){
    this.router.navigateByUrl('/aep/signin');
  }
}
