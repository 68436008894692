import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { ValidationService } from 'src/app/services/validation.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-certificationcourses',
  templateUrl: './certificationcourses.component.html',
  styleUrls: ['./certificationcourses.component.css']
})
export class CertificationcoursesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showtableView:any=true;
  showdetails:any=false;
  AddEditView:any=false;
  courseDisplayColumns: string[] = [
    'certification_id',
    'certification_name',
    'branch_name',
    'course_name',
    'action'
  ];
  datasource: any = [];
  datalen: any = [];
  courseslist:any=[]
  coursesData:any=[];
  viewdetaillist:any=[]
  Levels:any=[];
  courses:any=[];
  branches:any=[];
  action :any=true;
  actionflag:any='A'
  fun_data:any={
    levelid:0,
    courseid:0,
    branchid:0,
    certificationcode:'',
    overview:'',
    certificationname:'',
    whocanapply:'',
    prerequisites:'',
    deliverymethod:'',
    qualifyingpercentage:'',
    domaintype:'',
    softwaretool:'',
    certificationmode:'',
    examtype:'',
    pbp:'',
    courseduration:'',
    trainingcercost:'',
    certificationcost:'',
    years:'',
    category:'',
    coursename:'',
    examduration:'',
    certificationid:0
  }
  constructor(private service: ApiService, private ValidationService: ValidationService,
    private toast: ToasterService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getAllCertificationCourses();
    this.getLevelsAndCourses();
    this.getBranches();
  }
  getAllCertificationCourses(){
    this.showtableView=true;
    this.showdetails=false;
    this.courseslist=[];
    this.coursesData=[];
    this.service.get(this.service.GetCertificationcourses).subscribe((res) => {
      if (res.length != 0 && res.length != undefined) {
        this.courseslist = res;
        this.coursesData = new MatTableDataSource(this.courseslist);
        this.coursesData.paginator = this.paginator;
        this.coursesData.sort = this.sort;
        this.datalen = this.courseslist.length;
      }
    })
  }

  gotoMainPage(){
    this.showtableView=true;
    this.showdetails=false;
    this.AddEditView=false;
  }
  gotoView(data:any){
    this.showtableView=false;
    this.showdetails=true;
    this.AddEditView=false;
    this.viewdetaillist.push(data);
    console.log(this.viewdetaillist);
  }

  goToAddView(){
    this.action = true
    this.actionflag='A'
    this.showtableView=false;
    this.showdetails=false;
    this.AddEditView=true;
    this.getLevelsAndCourses();
    this.getBranches();
    this.fun_data.levelid              =  0
    this.fun_data.courseid             =  0
    this.fun_data.branchid             =  0
    this.fun_data.certificationcode    =  ""
    this.fun_data.overview             =  ""
    this.fun_data.certificationname    =  ""
    this.fun_data.whocanapply          =  ""
    this.fun_data.prerequisites        =  ""
    this.fun_data.deliverymethod       =  ""
    this.fun_data.qualifyingpercentage =  ""
    this.fun_data.domaintype           =  ""
    this.fun_data.softwaretool         =  ""
    this.fun_data.certificationmode    =  ""
    this.fun_data.examtype             =  ""
    this.fun_data.pbp                  =  ""
    this.fun_data.courseduration       =  ""
    this.fun_data.trainingcercost      =  ""
    this.fun_data.certificationcost    =  ""
    this.fun_data.years                =  ""
    this.fun_data.category             =  ""
    this.fun_data.coursename           =  ""
    this.fun_data.examduration         =  ""
    this.fun_data.certificationid =0

  }
  gotoEditView(e:any){
    this.action = false
    this.actionflag='E'
    this.showtableView=false;
    this.showdetails=false;
    this.AddEditView=true;
    localStorage.setItem('levelid',e.level_id);
    localStorage.setItem('courseid',e.course_id);
   // this.getLevelsAndCourses();
    // this.getBranches();
    this.fun_data.courseid=e.course_id;
    this.getlevelsBasedOnCourse2(this.fun_data.courseid);
    // this.getLevelsAndCourses();
    this.fun_data.branchid             =  e.branch_id
    this.fun_data.certificationcode    =  e.certification_code
    this.fun_data.overview             =  e.overview
    this.fun_data.certificationname    =  e.certification_name
    this.fun_data.whocanapply          =  e.who_can_apply
    this.fun_data.prerequisites        =  e.prerequisites
    this.fun_data.deliverymethod       =  e.delivery_method
    this.fun_data.qualifyingpercentage =  e.qualifying_percentage
    this.fun_data.domaintype           =  e.domain_type
    this.fun_data.softwaretool         =  e.software_tool
    this.fun_data.certificationmode    =  e.certification_mode
    this.fun_data.examtype             =  e.exam_type
    this.fun_data.pbp                  =  e.pbp
    this.fun_data.courseduration       =  e.course_duration
    this.fun_data.trainingcercost      =  e.training_and_cert_cost
    this.fun_data.certificationcost    =  e.certification_cost
     
    // // let levid=localStorage.getItem('levelid')
    // this.selectlevel(levid)
  
    this.fun_data.years                =  e.years
    this.fun_data.category             =  e.category
    this.fun_data.coursename           =  e.course_name
    this.fun_data.examduration         =  e.exam_duration
    this.fun_data.certificationid      =  e.certification_id

  
    
  }

  duplicatelist:any=[]
  getLevelsAndCourses()
  {
    this.courses=[]
    this.service.get(this.service.getcourseandlevels).subscribe((res) => {
      if(res.length!=0){
        let response=res;
        this.duplicatelist=res;
        let unique = []
        unique=[...new Map(response.map((m) => [m.course_id, m])).values()];
        console.log(unique)
        let newarry=[]
        newarry=unique
        newarry.forEach((ele:any)=>{
            this.courses.push({
              course_id:ele.course_id,
              course_name:ele.course_name
            });
        })
      
       

      }
    })
    // let courseid=localStorage.getItem('courseid')
    // this.getlevelsBasedOnCourse(Number(courseid));
    // this.callInedit();
    
  }
  callInedit(){
    let levid=localStorage.getItem('levelid')
    this.selectlevel(levid)
  }
 

getBranches()
{
  this.service.get(this.service.GetAllBranches).subscribe((res) => {
    if(res.length!=0){
      let response=res;
      response.forEach((ele:any)=>{
        this.branches.push(
          {
            branch_id:ele.branch_id,
            branch_name:ele.branch_name
          }
        )
      })
      console.log(this.branches)
      // this.callInedit()
    }
  })
}
  
selectlevel(e:any){
   this.Levels.forEach((ele:any)=>{
    if(ele.level_id==Number(e)){
      this.fun_data.levelid=Number(e)
    }
   })
}
getlevelsBasedOnCourse2(e:any){
  this.fun_data.courseid=e
  this.fun_data.levelid=0
  this.duplicatelist.forEach((ele:any)=>{
    if(ele.course_id==this.fun_data.courseid){
      this.Levels.push(
        {
          level_id:ele.level_id,
          level_name:ele.level_name
        }
      )
    }
  })
  if(this.action==false){
    let levid=localStorage.getItem('levelid')
    this.selectlevel(levid)
  }
}
getlevelsBasedOnCourse(e:any){
  this.fun_data.courseid=e
  this.fun_data.levelid=0
  this.duplicatelist.forEach((ele:any)=>{
    if(ele.course_id==this.fun_data.courseid){
      this.Levels.push(
        {
          level_id:ele.level_id,
          level_name:ele.level_name
        }
      )
    }
  })
  
}
selectBranches(e:any){
  this.fun_data.branchid=e
}
AddEditCertification(){
  this.spinner.show()
  if(this.courses.length!=0){
    this.courses.forEach((ele:any)=>{
      if(this.fun_data.course_id==ele.course_id){
        this.fun_data.coursename=ele.course_name
      }
    })
  }
  if(this.fun_data.levelid==0 ||  this.fun_data.levelid==undefined){
    this.toast.showwarning(this.ValidationService.enterlevelid);
    return;
  }
  if(this.fun_data.courseid==0 ||  this.fun_data.courseid==undefined){
    this.toast.showwarning(this.ValidationService.entercourseid);
    return;
  }
 
  if(this.fun_data.branchid==0 ||  this.fun_data.branchid==undefined){
    this.toast.showwarning(this.ValidationService.enterbranchid);
    return;
  }
  if(this.fun_data.whocanapply=='' ||  this.fun_data.whocanapply==undefined){
    this.toast.showwarning(this.ValidationService.enterwhocanapply);
    return;
  }
  if(this.fun_data.certificationname=='' ||  this.fun_data.certificationname==undefined){
    this.toast.showwarning(this.ValidationService.entercertificationname);
    return;
  }
  if(this.fun_data.overview=='' ||  this.fun_data.overview==undefined){
    this.toast.showwarning(this.ValidationService.enteroverview);
    return;
  }
  if(this.fun_data.certificationcode=='' ||  this.fun_data.certificationcode==undefined){
    this.toast.showwarning(this.ValidationService.entercertificationcode);
    return;
  }
  if(this.fun_data.prerequisites=='' ||  this.fun_data.prerequisites==undefined){
    this.toast.showwarning(this.ValidationService.enterprerequisites);
    return;
  }
  if(this.fun_data.deliverymethod=='' ||  this.fun_data.deliverymethod==undefined){
    this.toast.showwarning(this.ValidationService.enterdeliverymethod);
    return;
  }

  if(this.fun_data.qualifyingpercentage=='' ||  this.fun_data.qualifyingpercentage==undefined){
    this.toast.showwarning(this.ValidationService.enterqualifyingpercentage);
    return;
  }
  if(this.fun_data.domaintype=='' ||  this.fun_data.domaintype==undefined){
    this.toast.showwarning(this.ValidationService.enterdomaintype);
    return;
  }
  if(this.fun_data.examduration=='' ||  this.fun_data.examduration==undefined){
    this.toast.showwarning(this.ValidationService.enterexamduration);
    return;
  }
  if(this.fun_data.softwaretool=='' ||  this.fun_data.softwaretool==undefined){
    this.toast.showwarning(this.ValidationService.entersoftwaretool);
    return;
  }
  if(this.fun_data.certificationmode =='' ||  this.fun_data.certificationmode==undefined){
    this.toast.showwarning(this.ValidationService.entercertificationmode);
    return;
  }
  if(this.fun_data.examtype =='' ||  this.fun_data.examtype==undefined){
    this.toast.showwarning(this.ValidationService.enterexamtype);
    return;
  }
  if(this.fun_data.pbp =='' ||  this.fun_data.pbp==undefined){
    this.toast.showwarning(this.ValidationService.enterpbp);
    return;
  }
  if(this.fun_data.courseduration =='' ||  this.fun_data.courseduration==undefined){
    this.toast.showwarning(this.ValidationService.entercourseduration);
    return;
  }
  if(this.fun_data.trainingcercost =='' ||  this.fun_data.trainingcercost==undefined){
    this.toast.showwarning(this.ValidationService.entertrainingcercost);
    return;
  }
  if(this.fun_data.certificationcost =='' ||  this.fun_data.certificationcost==undefined){
    this.toast.showwarning(this.ValidationService.entercertificationcost);
    return;
  }
  if(this.fun_data.years =='' ||  this.fun_data.years==undefined){
    this.toast.showwarning(this.ValidationService.enteryears);
    return;
  }
  if(this.fun_data.category =='' ||  this.fun_data.category==undefined){
    this.toast.showwarning(this.ValidationService.entercategory);
    return;
  }
 
 
  var obj = {
    "courseid": this.fun_data.courseid,
    "levelid": this.fun_data.levelid,
    "v_branch_id": this.fun_data.branchid,
    "v_category": this.fun_data.category,
    "v_certification_code": this.fun_data.certificationcode,
    "v_certification_name": this.fun_data.certificationname,
    "v_overview": this.fun_data.overview,
    "v_who_can_apply": this.fun_data.whocanapply,
    "v_prerequisites": this.fun_data.prerequisites,
    "v_delivery_method": this.fun_data.deliverymethod,
    "v_qualifying_percentage":this.fun_data.qualifyingpercentage,
    "v_course_name": this.fun_data.coursename,
    "v_domain_type": this.fun_data.domaintype,
    "v_software_tool":this.fun_data.softwaretool,
    "v_certification_mode": this.fun_data.certificationmode,
    "v_exam_type": this.fun_data.examtype,
    "v_pbp": this.fun_data.pbp,
    "v_course_duration": this.fun_data.courseduration,
    "v_exam_duration": this.fun_data.examduration,
    "v_training_and_cert_cost": this.fun_data.trainingcercost,
    "v_certification_cost": this.fun_data.certificationcost,
    "v_years": this.fun_data.years,
    "v_flag": this.actionflag,
    "v_certification_id":Number(this.fun_data.certificationid)
  }
  this.service.post(this.service.insupcertificationcourses,obj).subscribe((res) => {
    if(res>0){
      this.toast.showsuccess(this.ValidationService.certificationaddsuccessfully)
      this.gotoMainPage();
      this.getAllCertificationCourses()
    }
    else if(res==-1){
      this.toast.showwarning(this.ValidationService.certificationaddunuccessfull)
    }else{
      this.toast.showwarning(this.ValidationService.certificationcodeIsDuplicate)
    }
  })
}
// allow only numbers
allowNumbersOnly(event: any) {
  const charCode = event.which ? event.which : event.target.valueCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}
// prevent the space in between
isNullEmptyBlank(event: any) {
  return event === null || event.match(/^ *$/) !== null;
}

}
