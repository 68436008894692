import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toastr.service';
import { ValidationService } from '../../services/validation.service';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-coursehistory',
  templateUrl: './coursehistory.component.html',
  styleUrls: ['./coursehistory.component.css']
})
export class CoursehistoryComponent implements OnInit {
  public completedcourse: boolean = true;
  public completedcoursedetails: boolean = false;
  public coursesdata = [];
  public coursedata2 =[];
  public showCoreCertificate: boolean = false;
  public showDesignCertificate: boolean = false;
  
  fileFormatIsWrong:boolean=false;
  orderid:any;

  obj1={
    firstname:'',
    lastname:'',
  }

  obj2={
    yearsforlevel_a :"3 years",
    yearsforlevel_associate :"3 years",
  }

  obj3={
    domain:'',
    certification_name:'',
    level_name:'',
    software_tool: '',
    brachname:'',
    certification_id:'',
    completed_date:'',
    certification_exam_id_code:'',
  }

  


  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService, private cdr: ChangeDetectorRef,
    public toast: ToasterService , private _location: Location,public dialog:MatDialog,private spinner: NgxSpinnerService) { 
    }
  
    ngOnInit(): void {
     this.showCourses();
   }
  
  // public showCourseDetails(){
    // this.spinner.show()
    // this.coursedata2=[];
    // var certifications_id = a;
    // var user_id = b ;
    // var order_id =c;
    // localStorage.setItem("orid",order_id);
    // this.completedcourse = false;
    // this.completedcoursedetails = true;
    
      // this.service.get(this.service.getOrderedCourseDeatils   + user_id +  "/" + certifications_id).subscribe((res) => {  
      //   this.spinner.hide()
      //   for (let i=0; i<res.length ;i++){
      //     this.coursedata2.push({
      //       certification_name : res[i].certification_name,
      //       exam_type: res[i].exam_type,
      //       certification_code : res[i].certification_code ,
      //       course_examdate :res[i].course_examdate,
      //       level_name  : res[i].level_name ,
      //       exam_duration  : res[i].exam_duration,
      //       delivery_method : res[i].delivery_method,
      //       certification_cost    : res[i].certification_cost,
      //       certification_status_name : res[i].certification_status_name,
      //       company_name : res[i].company_name,
      //       contact_person_name : res[i].contact_person_name,
      //       mobile_number : res[i].mobile_number,
      //       arp_email : res[i].arp_email,
      //       orgname : res[i].orgname,
      //       orgaddress : res[i].orgaddress,
      //       orgcity  : res[i].orgcity,
      //       orgdistrict  :  res[i].orgdistrict,
      //       firstname  : res[i].firstname,
      //       email  : res[i].email,
      //       mobilenumber  : res[i].mobilenumber,
      //       order_id :res[i].order_id,

      //     })
      //   }
      // })
   // }

    showCourses(){
    this.spinner.show()
    this.completedcoursedetails = false;
    this.completedcourse = true;
    var userid = localStorage.getItem('userid');
    this.coursesdata=[];
    this.service.get(this.service.getOrderSummaryByUser +userid).subscribe((res) => {
     this.spinner.hide()
     for(let i=0;i<res.length;i++){
      if(res[i].certification_status_name =='Completed' && res[i].trasaction_status=='success'){
        this.coursesdata.push({
        userid                    :userid ,
        order_id                  : res[i].order_id,
        certificationid           : res[i].certificationid,
        certification_code        : res[i].certification_code,
        course_examdate           : res[i].course_examdate,
        slot_range                : res[i].slot_range ,
        certification_name        : res[i].certification_name, 
        exam_type                 : res[i].exam_type,
        certification_cost        : res[i].certification_cost,
        exam_duration             : res[i].exam_duration,
        course_level_id           : res[i].course_level_id,
        certification_status_name : res[i].certification_status_name,
        level_name                : res[i].level_name,
        completed_date            : res[i].completed_date,
      })
     }
     }
   })

  }
  
  
  
//   getFiles(event) {
    
   
//     if(event.target.files[0].name.split('.')[1] == 'pdf') {
//         // const formData: FormData = new FormData();
//         this.fileToUpload = event.target.files[0];
//         this.filepath = event.target.files[0].name;
//       }else{
//       this.toast.showwarning(this.ValidationService.msg_validate_file);
//       }
    
    
// }

    
//   uploadDocument(){
   
//     this.orderid = Number(localStorage.getItem("orid"));
//     const formData: FormData = new FormData();
     
//     if(this.documentid == 0){
//     this.documentid = 0;
//     formData.append("imagefile",this.fileToUpload, this.filepath);
//     formData.append("documentid",this.documentid);
//     formData.append("orderid",this.orderid);
//     this.service
//      .post(this.service.uploadDocument, formData)
//      .subscribe((res) => {
//       console.log(res);
//       this.toast.showsuccess(this.ValidationService.msg_validate_uploadSuccess);

//     })
//   }
//   else{
//     var docid=this.documentid;
//     formData.append("imagefile",this.fileToUpload, this.filepath);
//     formData.append("documentid",docid);
//     formData.append("orderid",this.orderid);
//     this.service
//      .post(this.service.uploadDocumentUpdate, formData)
//      .subscribe((res) => {
//       console.log(res);
//       this.toast.showsuccess(this.ValidationService.msg_validate_uploadSuccess);

//     })


//   }
  
// }
datasource=[];
oline:boolean=false;
aline:boolean=false;
softwaretool:boolean=false;
domainname:boolean=false;
basiclvl:boolean=false;
associatelvl:boolean=false;

public stepBack(){
    this.completedcoursedetails = false;
    this.completedcourse = true;
  }

  public showCertificate(id){
    this.spinner.show()
    var leveltype =id.level_name;
    var u=id.userid;
    var o=id.order_id;
    var c=id.certificationid;
    var l=id.level_name;
    var clid=id.course_level_id;

    if(l=='O-MDP'){
     this.oline=true;
     this.aline=false;
    }else if( l=='A'){
      this.oline=false;
      this.aline=true;
    }
    if(l=='BASIC-MDP'){
      this.basiclvl=true;
      this.associatelvl=false;
     }else if( l=='ASSOCIATE'){
      this.basiclvl=false;
      this.associatelvl=true;
     }
    this.service.get(this.service.getOrderSummaryByUser +u).subscribe((res) => {
      if(res !=null){
        for(let i=0;i<res.length;i++){ 
          if(c==res[i].certificationid){
          this.obj3.level_name = res[i].level_name;
          this.obj3.completed_date = res[i].cbt_date;
          this.obj3.certification_exam_id_code = res[i].certification_exam_id_code;
          }
        }
      }
    })

    this.service.get(this.service.getUsers + u).subscribe((resp) => {
      // console.log(resp);
      for(let i=0;i<resp.length;i++){ 
        this.obj1.firstname = resp[i].firstname;
        this.obj1.lastname =resp[i].lastname;
      }
    })
    
    this.service.get(this.service.getcertificationcoursesbycourselevelid + clid).subscribe((res) => {
      this.spinner.hide()
      if(res!=null){
        for(let i=0;i<res.length;i++){
          if(c==res[i].certification_id){
            this.obj3.certification_id=res[i].certification_id,
            this.obj3.domain=res[i].domain_type;
            this.obj3.certification_name =res[i].certification_name;
            this.obj3.software_tool=res[i].software_tool;
            if(this.obj3.brachname !=null){
            this.obj3.brachname=res[i].brachname;
            }
          }
        }
      }
    })

    if(this.obj3.domain != null){
     this.domainname =true;
    }else{
      this.domainname =false; 
    }
    if(this.obj3.software_tool != null){
      this.softwaretool =true;
    }else{
       this.softwaretool =false; 
    }
      if(leveltype === 'O-MDP' || leveltype ==='A'){
        this.showCoreCertificate = true;
      }else if(leveltype === 'ASSOCIATE' || leveltype === 'BASIC-MDP' ){
        this.showDesignCertificate = true;
      }
    this.cdr.detectChanges();
  }
  
  public downloadCertificate(id){
    this.spinner.show()
    var leveltype =id.level_name;
  
localStorage.setItem('levelname',leveltype)
    var u=id.userid;
    var o=id.order_id;
    var c=id.certificationid;
    var l=id.level_name;
    var clid=id.course_level_id;

    if(l=='O-MDP'){
     this.oline=true;
     this.aline=false;
    }else if( l=='A'){
      this.oline=false;
      this.aline=true;
    }
    if(l=='BASIC-MDP'){
      this.basiclvl=true;
      this.associatelvl=false;
     }else if( l=='ASSOCIATE'){
      this.basiclvl=false;
      this.associatelvl=true;
      this.domainname=true;
     }
    this.service.get(this.service.getOrderSummaryByUser +u).subscribe((res) => {

      // this.service.get(this.service.getUsers + u).subscribe((resp) => {
      //   // console.log(resp);
      //   for(let i=0;i<resp.length;i++){ 
      //     this.obj1.firstname = resp[i].firstname;
      //     this.obj1.lastname =resp[i].lastname;
      //   }
      // })

      

      this.service.get(this.service.getcertificationcoursesbycourselevelid + clid).subscribe((res) => {
        
        this.spinner.hide()
        if(res!=null){
          for(let i=0;i<res.length;i++){
            if(c==res[i].certification_id){
              this.obj3.certification_id=res[i].certification_id,
              this.obj3.domain=res[i].domain_type;
              this.obj3.certification_name =res[i].certification_name;
              this.obj3.software_tool=res[i].software_tool;
              if(this.obj3.brachname !=null){
              this.obj3.brachname=res[i].brachname;
              }
            }
          }
        }
        this.service.get(this.service.getUsers + u).subscribe((resp) => {
          // console.log(resp);
          for(let i=0;i<resp.length;i++){ 
            this.obj1.firstname = resp[i].firstname;
            this.obj1.lastname =resp[i].lastname;
          }
          this.canvastohtml()
        })
        // this.canvastohtml()
      })


      if(res !=null){
        for(let i=0;i<res.length;i++){ 
          if(c==res[i].certificationid){
          this.obj3.level_name = res[i].level_name;
          this.obj3.completed_date = res[i].completed_date;
          this.obj3.certification_exam_id_code = res[i].certification_exam_id_code;
          }
        }
      }
   
    })

   

  }


  canvastohtml(){
   var leveltype= localStorage.getItem('levelname')
    if(leveltype === 'O-MDP' || leveltype ==='A'){
      this.showCoreCertificate = true;
    }else if(leveltype === 'ASSOCIATE' || leveltype === 'BASIC-MDP' ){
      this.showDesignCertificate = true;
    }
    this.cdr.detectChanges();
    var CoreData = document.getElementById('CoreData');
    var designData = document.getElementById('designData');
    var data = CoreData ? CoreData : designData;
    this.showCoreCertificate = false;
    this.showDesignCertificate = false;
    if(data){
      html2canvas(data).then(canvas => {
        var imgWidth = 630;
        var imgHeight = 445;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('l', 'px');
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
        pdf.save('Certificate.pdf');
      });
    }
  }
  public closeCertificate(){ 
    this.showCoreCertificate = false;
    this.showDesignCertificate = false;
}

}

