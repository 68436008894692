<h4 class="heading">Use this page to access and view TIP Centre in your region</h4>
<mat-card class="activeCourse py-4">
  <mat-card-header>
    <mat-card-title class="m-0 p-0">View My TIP Centres</mat-card-title>
</mat-card-header>
<mat-card-content>
  <div class="row mt-3">
    <div class="col-md-4 mb-5" *ngFor="let data2 of datasource; let i = index">
      <div class="tipCard">
        <div class="tip-header">
          <small>TIP Centre Name</small>
          <p>{{data2.orgname}}</p>
        </div>
        <img src="../../../assets/img/core-engineering.jpg" class="tipimage" alt="">
        <div class="arp-summary">
          <!-- <p class="d-flex"><small>Industry Type</small>{{data2.industrytype}}</p> -->
          <!-- <p class="d-flex"><small>Contact person firstname</small>{{data2.firstname}}</p> -->
          <p class="d-flex arp-summarymain"><small>Contact Number</small><span class="arp-summarytext">{{data2.mobilenumber}}</span></p>
          <p class="d-flex breakAll arp-summarymain"><small>Email</small><span class="arp-summarytext">{{data2.email}}</span></p>
          <p class="d-flex breakAll arp-summarymain"><small>Website</small><span class="arp-summarytext">{{data2.orgwebsite}}</span></p>
          <p class="d-flex f-height arp-summarymain"><small>Address</small><span class="arp-summarytext">{{data2.orgaddress}}</span></p>
          <p class="d-flex arp-summarymain"><small>City</small><span class="arp-summarytext">{{data2.orgcity}}</span></p>
          <p class="d-flex arp-summarymain"><small>District</small><span class="arp-summarytext">{{data2.orgdistrict}}</span></p>
          <p class="d-flex arp-summarymain"><small>State</small><span class="arp-summarytext">{{data2.orgstate}}</span></p>
          <p class="d-flex arp-summarymain"><small>Country</small><span class="arp-summarytext">{{data2.orgcountry}}</span></p>
        </div>
      </div>
    </div>
  </div>    
  </mat-card-content>
  <div [hidden]="notiprecords" class="center-align">
    <h3>No TIP Centre available in your Region.</h3>
  </div>
</mat-card>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>