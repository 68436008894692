import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../../../services/api.service';
import { ToasterService } from '../../../services/toastr.service';
import { ValidationService } from '../../../services/validation.service';

@Component({
  selector: 'app-arpenrollment',
  templateUrl: './arpenrollment.component.html',
  styleUrls: ['./arpenrollment.component.css']
})
export class ArpenrollmentComponent implements OnInit {

  ifsc:boolean=false;
  arpemailid:any;
  companyname:any;
  ContactPersonName:any;
  Mobile:any;
  CountryCode:any;
  gstCode:any;
  panoruid:any;
  emailid:any;
  Website:any;
  password:any;
  Doorno:any;
  LandMark:any;
  Street:any;
  Area:any;
  city:any;
  district:any;
  state:any;
  country:any;
  PinCode:any;
  AccountNumber:any;
  IFSCCode:any;
  cancelledcheques:any;
  Countries=[];
  States;
  Districts;
  Cities;
  randomstring = '';
  fileToUpload:File=null;
  filepath:any;
  arpid:any;
   upld:boolean=true;
  disablenxt:boolean=false;
  pswd1:boolean=false;
  pswd2:boolean=false;
  
  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService,public toast: ToasterService,private spinner: NgxSpinnerService)
  { }

  ngOnInit(): void {
    this.getCountries();
  }
  count:any=0;
  generateArpPassword(){
    var letters = ['a','b','c','d','e','f','g','h','i','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    var numbers = [0,1,2,3,4,5,6,7,8,9];
    this.count+=1
    
       if(this.count==1){
        for(var i=0;i<5;i++){
            var rlet = Math.floor(Math.random()*letters.length);
            this.randomstring += letters[rlet];
        }
        for(var i=0;i<3;i++){
            var rnum = Math.floor(Math.random()*numbers.length);
            this.randomstring += numbers[rnum];
        }
     this.password=this.randomstring;
    }else{
      this.pswd2=true;
      this.pswd1=true;

    }
    //  console.log(this.randomstring);
  }
  
  getCountries(){
    // var regionid = 5;
    this.service.get(this.service.getCountries).subscribe((res) => {
      if (res != null) {
        // for (let i = 0; i < res.length; i++) {
        //   // if (res[i].country_id == '35') {
        //     this.Countries.push({
        //       country_id: res[i].country_id,
        //       country_name: res[i].country_name
        //     })
        //   // }
        // }
        this.Countries = res.sort(function(a,b)
            {
              var st1 = a.country_name.toUpperCase();
              var st2 = b.country_name.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
      }
    })
  }
  
  
  getStates(id){

    var countryid = id;
    if(countryid==2){
      this.ifsc=true;
    }else{
      this.ifsc=false;
    }
      if(countryid != "null"){
        this.service.get(this.service.getStates + countryid).subscribe((resp) => {  
          if (resp != null) {
            this.States = resp.sort(function(a,b)
            {
              var st1 = a.statename.toUpperCase();
              var st2 = b.statename.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
          }
        })
      }
  }
  getDistricts(id) {
    var state_id = id;
    // this.districtid ='';
    if(state_id != "null"){
      this.service.get(this.service.getDistricts + state_id).subscribe((resp) => {
        if (resp != null) {
          this.Districts = resp.sort(function(a,b)
          {
            var st1 = a.districtname.toUpperCase();
            var st2 = b.districtname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        }
      })
    }
  }

  getCities(id) {
    var district_id = id;
    // this.obj.cityid='';
    if(district_id != "null"){
    this.service
      .get(this.service.getCities + district_id)
      .subscribe((resp) => {
        if (resp != null) {
          this.Cities = resp.sort(function(a,b)
          {
            var st1 = a.cityname.toUpperCase();
            var st2 = b.cityname.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
        
        }
      })
    }
  }
 
  getFiles(event) {
    if(event.target.files[0].name.split('.')[1] == 'pdf') {
      // const formData: FormData = new FormData();
      this.fileToUpload = event.target.files[0];
      this.filepath = event.target.files[0].name;
    }else{
    this.toast.showwarning(this.ValidationService.msg_validate_file);
    }
  }
 saveArpNewUser(){
    
    if (this.function1()) {
      return;
    }
    const formData: FormData = new FormData();
      formData.append("arpid", this.arpid);
      formData.append("arp_email_id", this.emailid),
      formData.append("arp_pass_word", this.password),
      formData.append("arp_role", "arp"),
      formData.append("companyname", this.companyname),
      formData.append("contactperson_name",this.ContactPersonName),
      formData.append("web_site",this.Website),
      formData.append("mobilenumber", this.Mobile),
      formData.append("gstcode", this.gstCode),
      formData.append("pan_or_uidnumber", this.panoruid),
      formData.append("door_no", this.Doorno),
      formData.append("landmark",this.LandMark),
      formData.append("street",this.Street),
      formData.append("area", this.Area),
      formData.append("city_id",this.city),
      formData.append("district_id",this.district),
      formData.append("state_id", this.state),
      formData.append("countryid",this.country),
      formData.append("pincode", this.PinCode),
      formData.append("bank_account_number",this.AccountNumber),
      formData.append("bank_ifsc", this.IFSCCode),
      formData.append("password_reset","false"),
      formData.append("cmp_email_id",this.arpemailid),
      formData.append("cheque_name",this.filepath),
      formData.append("imagefile", this.fileToUpload)
   
    this.service.post(this.service.arpEnrollment, formData).subscribe((resp) => {
      if(resp !=null){
        this.toast.showsuccess(this.ValidationService.msg_Validate_arpCreatedSuccess);
        
      }else if(resp==0){
        this.toast.showwarning(this.ValidationService.msg_Validate_arpCreationFailure1);
      }
      else
      {
        this.toast.showwarning(this.ValidationService.msg_Validate_arpCreationFailure);
      }
    })
  }

  function1(){
    if (this.ValidationService.funValidateEmptyText(this.companyname, this.ValidationService.msg_Validate_emptyCompanyName , '#companyname')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.ContactPersonName, this.ValidationService.msg_Validate_emptyContactPersonName, '#ContactPersonName')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.Mobile, this.ValidationService.msg_Validate_emptyMobileNumber, '#Mobile')) {
      return true;
    }
    if (this.ValidationService.funValidateMobileNumberFormat(this.Mobile, this.ValidationService.msg_Validate_mobile_number_format, '#Mobile')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.CountryCode, this.ValidationService.msg_Validate_emptyCountrycode, '#CountryCode')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.gstCode, this.ValidationService.msg_Validate_emptygstcode, '#gstcode')) {
      return true;
    }
  
    if (this.ValidationService.funValidateEmptyText(this.panoruid, this.ValidationService.msg_Validate_panuid, '#panoruid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.emailid, this.ValidationService.msg_Validate_Email, '#emailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.emailid, this.ValidationService.msg_Invalid_arpEmail_Format, '#emailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.password, this.ValidationService.msg_Validate_passwordgenerate, '#password')) {
      return true;
    }
   
    if (this.ValidationService.funValidateEmptyText(this.arpemailid, this.ValidationService.msg_Validate_arpEmail, '#arpemailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.emailid, this.ValidationService.msg_Invalid_Email_Format, '#emailid')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText( this.Doorno, this.ValidationService.msg_Validate_Doorno, '#Doorno')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText( this.Area, this.ValidationService.msg_Validate_Area, '#Area')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.country, this.ValidationService.msg_Validate_emptyCountryName, '#country')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.state, this.ValidationService.msg_Validate_emptystateName, '#state')) {
      return true;
    }
   
    if (this.ValidationService.funValidateEmptyText(this.city, this.ValidationService.msg_Validate_emptyCityName, '#city')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.district, this.ValidationService.msg_Validate_emptydistrict, '#district')) {
      return true;
    }
    
    
    
    if (this.ValidationService.funValidateEmptyText( this.PinCode, this.ValidationService.msg_Validate_pincode, '#Pincode')) {
      return true;
    }
   
    
    if (this.ValidationService.funValidateEmptyText(this.AccountNumber, this.ValidationService.msg_Validate_emptyAccountnumber, '#AccountNumber')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.IFSCCode, this.ValidationService.msg_Validate_emptyIFSCCode, '#IFSCCode')) {
      return true;
    }
    // if (this.ValidationService.funValidateEmptyText(this.cancelledcheques, this.ValidationService.msg_Validate_emptycancelledcheque, '#cancelledcheques')) {
    //   return true;
    // }

  }
}
