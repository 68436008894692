import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-programoverview',
  templateUrl: './programoverview.component.html',
  styleUrls: ['./programoverview.component.css']
})
export class ProgramoverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
