import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toastr.service';
import { ValidationService } from '../../services/validation.service';
import { DatePipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

export class uploadDocModel{
  id: number;
  filepath: string;
}

@Component({
  selector: 'app-activecourses',
  templateUrl: './activecourses.component.html',
  styleUrls: ['./activecourses.component.css'],
  providers: [DatePipe]
})
export class ActivecoursesComponent implements OnInit {

  public activecourse: boolean = true;
  public activecoursedetails: boolean = false;
  public coursesdata = [];
  public coursedata2 = [];
  public filename: any;
  public filepath: any;
  public fileFormatIsWrong: boolean = false;
  public isReschedulePopup: boolean = false;
  public orderid: any;
  public documentid: any;
  public fileToUpload: File = null;
  public file: any;
  public uploadeddata = [];
  public uploadDoc: uploadDocModel[] = [];
  public disableDates: string;
  timeslot:any;
  examtime:any;
  examdate:any;


  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService,
    public toast: ToasterService, private _location: Location, public dialog: MatDialog ,private spinner: NgxSpinnerService, public datepipe: DatePipe) {
      let date = new Date();
      this.disableDates = this.datepipe.transform(date, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.showActiveCourses();
    this.uploadDoc = [{id:1, filepath:''}];
  }

  public showCourseDetails(a, b, c) {
    this.spinner.show();
    this.coursedata2 = [];
    var certifications_id = a;
    var user_id = b;
    var order_id = c;
    localStorage.setItem("orid", order_id);
    this.activecourse = false;
    this.activecoursedetails = true;
    this.service.get(this.service.getOrderedCourseDeatils + user_id + "/" + certifications_id).subscribe((res) => {
      this.spinner.hide()
      for (let i = 0; i < res.length; i++) {
        if(res[i].trasaction_status =='success'){
        this.coursedata2.push({
          certificationid:res[i].certificationid,
          certification_name: res[i].certification_name,
          exam_type: res[i].exam_type,
          certification_code: res[i].certification_code,
          level_name: res[i].level_name,
          exam_duration: res[i].exam_duration,
          course_examdate: res[i].course_examdate,
          delivery_method: res[i].delivery_method,
          certification_cost: res[i].certification_cost,
          certification_status_name: res[i].certification_status_name,
          company_name: res[i].company_name,
          contact_person_name: res[i].contact_person_name,
          mobile_number: res[i].mobile_number,
          arp_email: res[i].arp_email,
          orgname: res[i].orgname,
          orgaddress: res[i].orgaddress,
          orgcity: res[i].orgcity,
          orgdistrict: res[i].orgdistrict,
          orgwebsite: res[i].orgwebsite,
          firstname: res[i].firstname,
          email: res[i].email,
          mobilenumber: res[i].mobilenumber,
          order_id: res[i].order_id,
        })
      }
      }

    })

    this.service.get(this.service.getUploadedDocument + order_id).subscribe((res) => {
      if (res[0] != null) {
        this.filepath = res[0].documentname;
        this.documentid = res[0].documentid;
      }
      if (res[0] == null) {
        this.filepath = '';
        this.documentid = 0;
      }
    })
    
  }

  showActiveCourses() {
    this.spinner.show();
    this.coursesdata = [];
    this.activecoursedetails = false;
    this.activecourse = true;
    var userid = localStorage.getItem('userid');
    this.service.get(this.service.getOrderSummaryByUser + userid).subscribe((res) => {
      this.spinner.hide()
      for (let i = 0; i < res.length; i++) {
        if(res[i].trasaction_status =='success'){
        if (res[i].certification_status_name == 'Enrolled' || res[i].certification_status_name == 'Submitted') {
          this.coursesdata.push({
            userid: userid,
            order_id: res[i].order_id,
            certificationid: res[i].certificationid,
            certification_code: res[i].certification_code,
            course_examdate: res[i].course_examdate,
            slot_range: res[i].slot_range,
            certification_name: res[i].certification_name,
            exam_type: res[i].exam_type,
            certification_cost: res[i].certification_cost,
            exam_duration: res[i].exam_duration,
            course_level_id: res[i].course_level_id,
            certification_status_name: res[i].certification_status_name,
            level_name: res[i].level_name,
          })
        }
      }
    }
    })
  }

  getFiles(event) {
    if (event.target.files[0].name.split('.')[1] == 'pdf') {
      // const formData: FormData = new FormData();
      this.fileToUpload = event.target.files[0];
      this.filepath = event.target.files[0].name;
    } else {
      this.toast.showwarning(this.ValidationService.msg_validate_file);
    }
  }


  uploadDocument() {
    this.orderid = Number(localStorage.getItem("orid"));
    const formData: FormData = new FormData();
    if (this.documentid == 0) {
      this.documentid = 0;
      formData.append("imagefile", this.fileToUpload, this.filepath);
      formData.append("documentid", this.documentid);
      formData.append("orderid", this.orderid);
      this.service
        .post(this.service.uploadDocument, formData)
        .subscribe((res) => {
          // console.log(res);
          this.toast.showsuccess(this.ValidationService.msg_validate_uploadSuccess);
        })
    }
    else {
      var docid = this.documentid;
      formData.append("imagefile", this.fileToUpload, this.filepath);
      formData.append("documentid", docid);
      formData.append("orderid", this.orderid);
      this.service
        .post(this.service.uploadDocumentUpdate, formData)
        .subscribe((res) => {
          // console.log(res);
          this.toast.showsuccess(this.ValidationService.msg_validate_uploadSuccess);
        })
    }
    // }else {
    //     formData.append("imagefile",this.fileToUpload, this.filepath);
    //     formData.append("documentid",this.documentid);
    //     formData.append("orderid",this.orderid);
    //     this.service
    //    .post(this.service.uploadDocumentUpdate, formData)
    //   .subscribe((res ) => {
    //     console.log(res);
    //     this.toast.showsuccess(this.ValidationService.msg_validate_uploadSuccess);
    //     })
    //   }
  }

  gettime(){
    this.service.get(this.service.getTimeSlot).subscribe((res) => {
    this.timeslot=res;
   })
  }
 

  public stepBack() {
    this.showActiveCourses();
    this.activecoursedetails = false;
    this.activecourse = true;
  }

  public removeUpload(index){
    let Index = this.uploadDoc.findIndex(x => x.id === index);
    if(Index !== -1){
      this.uploadDoc.splice(Index, 1);
    }
  }

  public addUpload(index){
    let newDoc = {id:index+1, filepath:''};
    this.uploadDoc.push(newDoc);
  }

  public showReschedulePopup(){
    this.isReschedulePopup = true;
    this.examdate = '';
    this.examtime = '';
  }
  closeDetailsPopup(){
    this.isReschedulePopup = false;
  }
  
  public time=[];
  public savedata(a,b){
    if (this.function1()) {
      return;
    }
    this.isReschedulePopup = false;
    var t=a;
    var exdate=b;
    var user = Number(localStorage.getItem('userid'));
    var cert_id =Number(this.coursedata2[0].certificationid);
    var obj1 = {
        "user_id": user,
        "courseexamdate": exdate,
        "certification_id": cert_id,
        "slotid": t,
      }
      this.service.post(this.service.rescheduleexamdate, obj1).subscribe((resp) => {

        if(resp != 0)
        {
          this.toast.showsuccess(this.ValidationService.msg_Validate_rescheduleSuccess);
        }else{
          this.toast.showsuccess(this.ValidationService.msg_Validate_reschedulefailure);
        }
         
    
    })
 }

 function1()
 {
  if (this.ValidationService.funValidateEmptyText(this.examdate, this.ValidationService.msg_Validate_dateSelect, '#examdate')) {
    return true;
  }
  if (this.ValidationService.funValidateEmptyText(this.examtime, this.ValidationService.msg_Validate_timeSelect,'#examtime')) {
    return true;
  }
}
}