<section class="page-section pt-0" id="showCards">
    <div class="container">
      <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
      <div class="headerstyling">
      <h4>Available AEP Certifications</h4>
      </div>
      <div class="col-md-12 bdr">
        <div class="course-catalogue">
          <div class="course-eng">
            <h6>Select the certification type</h6>
            <div class="row">
              <div class="col-md-3">
                <div class="img-position" [ngClass]="{'img-posit':  classToggled === true }" (click)="img1()">
                  <img src="./assets/img/core-engineering.jpg" />
                </div>
                <p class="text-center mt-2 text-brown"><b>Core Engineer</b></p>
              </div>
              <div class="col-md-3">
                <div class="img-position" [ngClass]="{'img-posit':  classToggleds === true }" (click)="img2()">
                  <img src="./assets/img/DESIGN-ENGG.jpg" />
                </div>
                <p class="text-center mt-2 text-green"><b>Design Engineer</b></p>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-4 inner-bdr">
                <!-- Core select starts -->
                <div *ngIf="isCourse">
                  <div class="form-group aep-form mt-3">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/level.jpg" alt="">
                      <mat-label>Level<span class="required-mark">*</span></mat-label>
                      <mat-select name="count" class="custom-form-control" [(ngModel)]="selectLev"
                        (selectionChange)="getBranches1($event.value)">
                        <mat-option *ngFor="let Level of Levels" [value]="Level">
                          {{Level.level_name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="form-group aep-form">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/branch.jpg" alt="">
                      <mat-label>Stream<span class="required-mark">*</span></mat-label>
                      <mat-select name="count" class="custom-form-control" [(ngModel)]="Bran"
                        (selectionChange)="getGrid($event.value)">
                        <mat-option *ngFor="let Branch of Branches " [value]="Branch.brachname">
                          {{Branch.brachname}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  
                </div>
                <!-- core select  ends -->

                <!-- design select starts here-->
                <div *ngIf="isCourse2">
                  <div class="form-group aep-form mt-3">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/level.jpg" alt="">
                      <mat-label>Level<span class="required-mark">*</span></mat-label>
                      <mat-select name="count" class="custom-form-control" [(ngModel)]="selectLev"
                        (selectionChange)="getBranches1($event.value)">
                        <mat-option *ngFor="let Level of Levels" [value]="Level">
                          {{Level.level_name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="form-group aep-form">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/branch.jpg" alt="">
                      <mat-label>Stream<span class="required-mark">*</span></mat-label>
                      <mat-select name="count" class="custom-form-control" [(ngModel)]="Bran"
                        (selectionChange)="getGrid2($event.value)">
                        <mat-option *ngFor="let Branch of Branches " [value]="Branch.brachname">
                          {{Branch.brachname}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                 
                </div>
                <!-- design select  ends -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div [hidden]="frstgrid">
          <div class="data-collection remove-padding">
              <div class="row mt-5">
                <div class="col-sm-4 mb-5" *ngFor="let data of dataSource">
                  <mat-card class="offerings">
                    <div style="height:110px">
                      <div class="heading">
                        <h6>{{data.certification_name}}</h6>
                      </div>
                    </div>
                    <div class="coursedetails">
                      <h6>Exam Details</h6>
                      <p><small>Code</small> {{data.certification_code}}</p>
                      <p><small>Type</small> {{data.exam_type}}</p>
                      <p><small>Level</small>{{data.level_name}}</p>
                      <p><small>Duration</small> {{data.exam_duration}} hrs</p>
                      <p><small>Delivery Method</small> {{data.delivery_method}}</p>
                      <p><small>Qualifying %</small> {{data.qualifying_percentage}}</p>
                    </div>
                    <div class="row mb-2">
                      <div class="col-7 pr-0">
                        <div class="choose-option">
                          <small class="d-block">Certification Cost :</small>
                          <small>INR</small> {{data.certification_cost}}/- <small> + GST  </small>
                        </div>
                      </div>
                      <div class="col-5">
                        <img src="../../assets/img/certified1.jpg" class="img-fluid" alt="certificed">
                      </div>
                    </div>
                   
                    <a class="link" (click)="showOptions(data)">View details</a>
                    <div class="mb-3">
                      <button type="submit" class="btn custom-btn2" (click)="approves(data)">Enroll</button>
                    </div>
                  </mat-card>
                    </div>
                </div>
            </div>
          </div> -->
          <!-- core -->
          <div [hidden]="frstgrid1">
          <div class="data-collection remove-padding">
              <div class="row mt-5">
                <div class="col-sm-4 mb-5 mob-card-width " *ngFor="let data of dataSourcef1">
                  <mat-card class="offerings">
                    <div style="height:110px">
                      <div class="heading2">
                        <h6>{{data.certification_name}}</h6>
                      </div>
                    </div>
                    <div class="coursedetails">
                      <h6>Exam Details</h6>
                      <p><small>Code</small> {{data.certification_code}}</p>
                      <p><small>Type</small> {{data.exam_type}}</p>
                      <p><small>Level</small>{{data.level_name}}</p>
                      <p><small>Duration</small> {{data.exam_duration}} hrs</p>
                      <p><small>Delivery Method</small> {{data.delivery_method}}</p>
                      <p><small>Qualifying %</small> {{data.qualifying_percentage}}</p>
                    </div>
                    <div class="row mb-2">
                      <div class="col-7 pr-0">
                        <div class="choose-option">
                          <small class="d-block">Certification Cost :</small>
                          <small>INR</small> {{data.certification_cost}}/- <small> + GST  </small>
                        </div>
                      </div>
                      <div class="col-5">
                        <img src="../../assets/img/certified1.jpg" class="img-fluid" alt="certificed">
                      </div>
                    </div>
                    <!-- <mat-checkbox class="form-check-label example-margin" [(ngModel)]="data.isOverView" (change)="showOptions($event, data)"> View details
                    </mat-checkbox> -->
                    <a class="link" (click)="showOptions(data)">View details</a>
                    <div class="mb-3">
                      <button type="submit" class="btn custom-btn" (click)="approves(data)">Enroll</button>
                    </div>
                  </mat-card>
                </div>
              </div>
          </div>
        </div>
        <!-- core grid ends here -->
        <!-- design grid starts here-->
        <div [hidden]="frstgrid2">
          <div class="data-collection remove-padding">
              <div class="row mt-5">
                <div class="col-sm-4 mb-5 mob-card-width" *ngFor="let data of dataSourcef1">
                  <mat-card class="offerings">
                    <div style="height:110px">
                      <div class="heading">
                        <h6>{{data.certification_name}}</h6>
                      </div>
                    </div>
                    <div class="coursedetails">
                      <h6>Exam Details</h6>
                      <p><small>Code</small> {{data.certification_code}}</p>
                      <p><small>Type</small> {{data.exam_type}}</p>
                      <p><small>Level</small>{{data.level_name}}</p>
                      <p><small>Duration</small> {{data.exam_duration}} hrs</p>
                      <p><small>Delivery Method</small> {{data.delivery_method}}</p>
                      <p><small>Qualifying %</small> {{data.qualifying_percentage}}</p>
                    </div>
                    <div class="row mb-2">
                      <div class="col-7 pr-0">
                        <div class="choose-option">
                          <small class="d-block">Certification Cost :</small>
                          <small>INR</small> {{data.certification_cost}}/- <small> + GST  </small>
                        </div>
                      </div>
                      <div class="col-5">
                        <img src="../../assets/img/certified1.jpg" class="img-fluid" alt="certificed">
                      </div>
                    </div>
                    <!-- <mat-checkbox class="form-check-label example-margin" [(ngModel)]="data.isOverView" (change)="showOptions($event, data)"> View details
                    </mat-checkbox> -->
                    <a class="link" (click)="showOptions(data)">View details</a>
                    <div class="mb-3">
                      <button type="submit" class="btn custom-btn2" (click)="approves(data)">Enroll</button>
                    </div>
                  </mat-card>
                </div>
              </div>
          </div>
        </div>
        <!-- dsesign grid ends here -->

        <!-- <div [hidden]="thirdgrid">
          <div class="data-collection remove-padding">
              <div class="row mt-5">
                <div class="col-sm-4 mb-4 " *ngFor="let data of dataSourcet">
                  <mat-card class="offerings">
                    <div style="height:110px">
                      <div class="heading2">
                        <h6>{{data.CertName}}</h6>
                      </div>
                    </div>
                    <div class="coursedetails">
                    <h6>Exam Details</h6>
                    <p><small>Code</small> {{data.CertCode}}</p>
                    <p><small>Type</small> {{data.ExamType}}</p>
                    <p><small>Level</small>{{data.Level}}</p>
                    <p><small>Duration</small> {{data.ExamDuration}} hrs</p>
                    <p><small>Delivery Method</small> {{data.DeliveryMethod}}</p>
                    <p><small>Qualifying %</small> {{data.Qualifying}}</p>
                    </div>
                    <div class="row mb-2">
                      <div class="col-7 pr-0">
                        <div class="choose-option">
                          <small class="d-block">Certification Cost :</small>
                          <small>INR</small> {{data.Certcost}} /-  <small> + GST  </small>
                        </div>
                      </div>
                      <div class="col-5">
                        <img src="../../assets/img/certified1.jpg" class="img-fluid" alt="certificed">
                      </div>
                    </div>
                    
                    <a class="link" (click)="showOptions(data)">View details</a>
                    <div class="mb-3">
                      <button type="submit" class="btn custom-btn" (click)="approves(data)">Enroll</button>
                    </div>
                  </mat-card>
                </div>
              </div>
          </div>
        </div> -->
        <!-- <div [hidden]="fourthgrid">
          <div class="data-collection remove-padding">
              <div class="row mt-5">
                <div class="col-sm-4 mb-4 " *ngFor="let data of dataSourcef">
                  <mat-card class="offerings">
                    <div style="height:110px">
                      <div class="heading2">
                        <h6>{{data.CertName}}</h6>
                      </div>
                    </div>
                    <div class="coursedetails">
                    <h6>Exam Details</h6>
                    <p><small>Code</small> {{data.CertCode}}</p>
                    <p><small>Type</small> {{data.ExamType}}</p>
                    <p><small>Level</small>{{data.Level}}</p>
                    <p><small>Duration</small> {{data.ExamDuration}} hrs</p>
                    <p><small>Delivery Method</small> {{data.DeliveryMethod}}</p>
                    <p><small>Qualifying %</small> {{data.Qualifying}}</p>
                    </div>
                    <div class="row mb-2">
                      <div class="col-7 pr-0">
                        <div class="choose-option">
                          <small class="d-block">Certification Cost :</small>
                          <small>INR</small> {{data.Certcost}} /-  <small> + GST  </small>
                        </div>
                      </div>
                      <div class="col-5">
                        <img src="../../assets/img/certified1.jpg" class="img-fluid" alt="certificed">
                      </div>
                    </div>
                    
                    <a class="link" (click)="showOptions(data)">View details</a>
                    <div class="mb-3">
                      <button type="submit" class="btn custom-btn" (click)="approves(data)">Enroll</button>
                    </div>
                  </mat-card>
                </div>
              </div>
          </div>
        </div> -->
      </div>
    </div>
    <a href="#showCards" *ngIf="showTopScroll" id="myBtn"><i class="fa fa-arrow-up"></i></a>
  </section>

  <div class="modal" *ngIf="showDetailsPopup">
    <div class="modal-dialog mob-model-dialog">
      <div class="modal-content" *ngFor="let data of CoreEngineerDetailsData">
        <div class="modal-header">
          <h5 class="modal-title text-center">{{data.certification_name}}</h5>
          <button type="button" class="close" (click)="closeDetailsPopup()">&times;</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-7">
              <div class="fullDetails bdr-right">
                <p><small>Overview</small> {{data.overview}}</p>
                <p><small>Who can apply?</small> {{data.who_can_apply}}</p>
                <p><small>Prerequisites</small> {{data.prerequisites}}</p>
                </div>
            </div>
            <div class="col-md-5 p-0">
              <div class="coursedetails new-p-0">
                <h6>Exam Details</h6>
                <p><small>Code</small> {{data.certification_code}}</p>
                <p><small>Type</small> {{data.exam_type}}</p>
                <p><small>Level</small>{{data.level_name}}</p>
                <p><small>Duration</small> {{data.exam_duration}} hrs</p>
                <p><small>Delivery Method</small> {{data.delivery_method}}</p>
                <p><small>Qualifying %</small> {{data.qualifying_percentage}}</p>
              <p><small>Certification Cost</small> <span>INR </span> <i> {{data.certification_cost}}</i> /- <span> + GST  </span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="submit" class="btn custom-btn py-2" (click)="approves(data)">Enroll</button>
        </div>
      </div>
    </div>
   </div>