import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { arpModel } from './courseenrollment.model';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSelect } from '@angular/material/select';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toastr.service';
import { ValidationService } from '../../services/validation.service';
import { getMaxListeners } from 'process';
import { DatePipe, Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-courseenrollment',
  templateUrl: './courseenrollment.component.html',
  styleUrls: ['./courseenrollment.component.css'],
  providers: [DatePipe]
})
export class CourseenrollmentComponent implements OnInit {
  // @ViewChild('payid',{static: true}) payid: ElementRef;

  // @ViewChild("payid") payid: ElementRef;
  sub: boolean = true;
  public payuform: any = {};
  public datasource5 = [];
  public isOffers: boolean = true;
  public isEnrollment: boolean = false;
  public isOrderSummary: boolean = false;
  // public arpdetails:boolean=false;
  arpdetails: any;
  public selectCountry: string;
  public selectState: string;
  public selectDistrict: string;
  public arpDataList: arpModel[] = [];
  public currntUrlPath: string;
  public isARPGrid: boolean = false;
  public selectedOrderSummary: any;
  public gstamount: any;
  public totalamount: any;
  public a: any;
  public b: any;
  public disableDates: string;
  // @ViewChild('countid') countid: MatSelect;
  terms: any;
  Countries: any;
  selecteddate: any;
  States = [];
  statedata = [];
  SStates: any;
  Districts: any;
  DDistricts: any;
  Cities: any;
  CCities: any;
  cityname: any;
  selectedstate: any;
  selectCity: any;
  arpdt: boolean = true;
  timeslot: any;
  examtime: any;
  examdate: any;
  public datasource = [];
  public datasource2 = [];
  public datasource3 = [];
  public datasource4 = [];
  public CCountries = [];
  public coursedetails = [];
  public tipdetails = [];
  public selectedarp: any;
  public selectedCity: string = '';
  public certname = localStorage.getItem('certname');


  objarp = {
    company_name: '',
    contact_person_name: '',
    mobile_number: '',
    arp_email: '',
    office_doorno: '',
    office_landmark: '',
    office_street: '',
    office_area: '',
    office_city: '',
    office_district: '',
    office_state: '',
    offfice_country: '',
  }

  objparti = {
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    predoorno: '',
    prerelationship: '',
    preflat: '',
    prelandmark: '',
    prestreet: '',
    prearea: '',
    precity: '',
    predistrict: '',
    prestate: '',
    precountry: '',
  }

  obj = {
    country: '',
    state: '',
  }

  data2 = {
    examtime: '',
    examdate: ''
  }

  productinfo = "AEP Certification";

  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService,
    public toast: ToasterService, private _location: Location, public datepipe: DatePipe, private spinner: NgxSpinnerService) {
      let date = new Date();
      this.disableDates = this.datepipe.transform(date, "yyyy-MM-dd");
      }

    ngOnInit(): void {
      
    localStorage.removeItem('arp_id')

    this.currntUrlPath = window.location.hash.substring(window.location.hash.indexOf('/'));
    // console.log(this.currntUrlPath);
    if (this.currntUrlPath === '/aep/participant/courseenrollment/:id') {
      this.isEnrollment = true;
      this.isOffers = false;
    }
    else {
      this.isEnrollment = false;
      this.isOffers = true;
    }
    // COUNTRY
    var regionid = 5;
    this.service
      .get(this.service.getCountries)
      .subscribe((resp) => {
        if (resp != null) {

          this.CCountries = resp.sort(function(a,b)
          {
            var st1 = a.country_name.toUpperCase();
            var st2 = b.country_name.toUpperCase();
            return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
          });
          
          // for (let i = 0; i < resp.length; i++) {
            // if (resp[i].country_id == '35') {
              // this.CCountries.push({
              //   country_id: resp[i].country_id,
              //   country_name: resp[i].country_name
              // })
            // }
          // }
        }
      })
  }
  getStates(id) {
    this.service.get(this.service.getStates + id).subscribe((resp) => {
      this.States = resp.sort(function (a, b) {
        var st1 = a.statename.toUpperCase();
        var st2 = b.statename.toUpperCase();
        return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
      });
    })
    // console.log(this.statedata);  
  }
  public isSearchbtn(): boolean {
    if (this.obj.country == '' || this.obj.state == '') {
      return true;
    }
    return false;
  }
  statefilter(id) {
    this.datasource = [];
    this.datasource3 = [];
    this.arpdt = true;
    this.isARPGrid = false;
    let statename = id.statename;
    this.selectedCity = '';
    this.selectedCity = statename;
    localStorage.setItem('state_name', statename);

  }

  public showARPGrid() {
    this.datasource = [];
    this.isARPGrid = true;
    var state = localStorage.getItem('state_name');
    if (this.isARPGrid = true) {

      this.service.get(this.service.getArpList).subscribe((res) => {
        for (let i = 0; i < res.length; i++) {
          if (state == res[i].office_state) {
            this.datasource.push({
              company_name: res[i].company_name,
              contact_person_name: res[i].contact_person_name,
              mobile_number: res[i].mobile_number,
              arp_email: res[i].arp_email,
              arp_id: res[i].arp_id,
              office_doorno: res[i].office_doorno,
              office_landmark: res[i].office_landmark,
              office_street: res[i].office_street,
              office_area: res[i].office_area,
              office_city: res[i].office_city,
              office_district: res[i].office_district,
              office_state: res[i].office_state,
              offfice_country: res[i].offfice_country,
            })
          }
        }
        // console.log(this.datasource);
      })
    }
  }


  getArpDetails(id1) {
     this.datasource3=[]
    this.arpdt = false;
    localStorage.setItem('arp_id', id1);
    for (let i = 0; i < this.datasource.length; i++) {
      if (id1 == this.datasource[i].arp_id) {
        this.objarp.company_name = this.datasource[i].company_name
        this.objarp.arp_email = this.datasource[i].arp_email,
          this.objarp.contact_person_name = this.datasource[i].contact_person_name,
          this.objarp.mobile_number = this.datasource[i].mobile_number,
          this.objarp.office_doorno = this.datasource[i].office_doorno,
          this.objarp.office_landmark = this.datasource[i].office_landmark,
          this.objarp.office_street = this.datasource[i].office_street,
          this.objarp.office_area = this.datasource[i].office_area,
          this.objarp.office_city = this.datasource[i].office_city,
          this.objarp.office_district = this.datasource[i].office_district,
          this.objarp.office_state = this.datasource[i].office_state,
          this.objarp.offfice_country = this.datasource[i].offfice_country
      }
    }
    this.service.get(this.service.gettips + id1).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        this.datasource3.push({
          arp_id: res[i].arp_id,
          orgid: res[i].orgid,
          orgname: res[i].orgname,
          firstname: res[i].firstname,
          orgwebsite: res[i].orgwebsite,
          orgaddress: res[i].orgaddress,
          industrytype: res[i].industrytype,
          email: res[i].email,
          mobilenumber: res[i].mobilenumber,
          orgcity: res[i].orgcity,
          orgdistrict: res[i].orgdistrict,
          orgstate: res[i].orgstate,
          orgcountry: res[i].orgcountry,
          examtime: '',
          examdate: '',
        })
      }
    })
    this.gettime()
  }

  gettime() {
    this.service.get(this.service.getTimeSlot).subscribe((res) => {
      this.timeslot = res;

    })
  }

  getAllTimeslot(data2, value) {
    var slotid = JSON.parse(value.slot_id);
    var slot_range = JSON.stringify(value.slot_range);
    localStorage.setItem('sltid', slotid);
    localStorage.setItem('sltrg', slot_range);
    for (let i = 0; i <= this.datasource3.length - 1; i++) {
      if (data2.orgid == this.datasource3[i].orgid) {
        this.datasource4.push({
          arp_id: this.datasource3[i].arp_id,
          orgid: this.datasource3[i].orgid,
          orgname: this.datasource3[i].orgname,
          firstname: this.datasource3[i].firstname,
          orgwebsite: this.datasource3[i].orgwebsite,
          orgaddress: this.datasource3[i].orgaddress,
          industrytype: this.datasource3[i].industrytype,
          email: this.datasource3[i].email,
          mobilenumber: this.datasource3[i].mobilenumber,
          orgcity: this.datasource3[i].orgcity,
          orgstate: this.datasource3[i].orgstate,
          orgcountry: this.datasource3[i].orgcountry,
          slot_id: slotid,
          examtime: slot_range,
          examdate: data2.examdate,
        })
        break;
      }
    }
  }
  public showOrderSummary(data2, i) {
    this.spinner.show();
    this.data2.examtime = localStorage.getItem('sltrg');

    if (data2.examdate == '') {

      if (this.ValidationService.funValidateEmptyText(data2.examdate, this.ValidationService.msg_Validate_dateSelect, '#data2.examdate')) {
        return true;
      }
    }
    if (data2.examtime == '') {
      if (this.ValidationService.funValidateEmptyText(data2.examtime, this.ValidationService.msg_Validate_timeSelect, '#data2.examtime')) {
        return true;
      }
    }

    this.isOrderSummary = true;
    this.sub = false;
    this.terms = false;
    this.coursedetails = [];
    this.isEnrollment = false;
    var id3 = data2.arp_id;
    var levelid = localStorage.getItem('ofrlvlid')
    var branchname1 = localStorage.getItem('branch')
    var cert_id = localStorage.getItem('certid');

    this.service.get(this.service.getcertificationcoursesbycourselevelid + levelid).subscribe((res) => {
      this.spinner.hide();
      // console.log(res);
      for (let i = 0; i < res.length; i++) {
        if (res[i].certification_cost != null) {
          if (branchname1 == res[i].brachname && cert_id == res[i].certification_id) {
            this.coursedetails.push({
              certification_id: res[i].certification_id,
              brachname: res[i].brachname,
              certification_name: res[i].certification_name,
              certification_code: res[i].certification_code,
              exam_type: res[i].exam_type,
              certification_cost: res[i].certification_cost,
              exam_duration: res[i].exam_duration,
              domain_type: res[i].domain_type,
              level_name: localStorage.getItem('levname'),
              time_slot: this.data2.examtime,
              examdate: data2.examdate,
              gstpercent: Number(res[i].gstpercent),

            })
          }
        }
      }

      // GST Calculation
      this.a = Number(this.coursedetails[0].certification_cost);
      this.b = this.coursedetails[0].gstpercent;
      this.gstamount = Math.round((this.a) * (this.b)) / 100;
      this.totalamount = (this.a) + this.gstamount;
      //  console.log(this.a)
      //  console.log(this.b)
      //  console.log(this.gstamount)
      //  console.log(this.totalamount)
    })

    //  TIPS
    this.tipdetails = [];
    this.service.get(this.service.gettips + id3).subscribe((res) => {
      if (res != null) {
        for (let i = 0; i < res.length; i++) {
          if (data2.orgid == res[i].orgid) {
            this.tipdetails.push({
              orgid: res[i].orgid,
              orgname: res[i].orgname,
              firstname: res[i].firstname,
              orgwebsite: res[i].orgwebsite,
              orgaddress: res[i].orgaddress,
              industrytype: res[i].industrytype,
              email: res[i].email,
              orgdistrict: res[i].orgdistrict,
              mobilenumber: res[i].mobilenumber,
              orgcity: res[i].orgcity,
              orgstate: res[i].orgstate,
              orgcountry: res[i].orgcountry,
            })
          }
        }
      }
    })

    // USER
    var id4 = localStorage.getItem('userid');
    var userid = id4;
    this.service
      .get(this.service.getUsers + userid).subscribe((resp) => {
        if (resp != null) {
          for (let i = 0; i < resp.length; i++) {
            this.objparti.firstname = resp[0].firstname;
            this.objparti.lastname = resp[0].lastname;
            this.objparti.email = resp[0].email;
            this.objparti.mobile = resp[0].mobilenumber;
            this.objparti.predoorno = resp[0].predoorno;
            this.objparti.preflat = resp[0].preflat;
            this.objparti.prelandmark = resp[0].prelandmark;
            this.objparti.prestreet = resp[0].prestreet;
            this.objparti.prearea = resp[0].prearea;
            this.objparti.precity = resp[0].precity;
            this.objparti.predistrict = resp[0].predistrict;
            this.objparti.prestate = resp[0].prestate;
            this.objparti.precountry = resp[0].precountry;
          }
        }
      })

  }


  function2() {
    if (this.ValidationService.funValidateEmptyText(this.terms, this.ValidationService.msg_Validate_emptyCheckbox2, '#terms')) {
      return true;
    }
  }

  // istick(id){
  //   var x=id.checked;
  //   if(x==false)
  //   {
  //     this.sub=true;
  //   }
  //   else {
  //     this.sub=false;
  //   }
  // }


  Checkout(event) {
    this.sub = false;
    if (event.checked === true) {
      var obj1 = {
        user_id: Number(localStorage.getItem('userid')),
        arpid: Number(localStorage.getItem('arp_id')),
        slotid: Number(localStorage.getItem('sltid')),
        org_id: this.tipdetails[0].orgid,
        courseexamdate: this.coursedetails[0].examdate,
        certificationstatusid: 1,
        submitteddate: null,
        completeddate: null,
        filelocation: "",
        transactionid: 0,
        trasactionstatus: "",
        transactionamount: String(this.totalamount),
        certificationid: this.coursedetails[0].certification_id,
        productname: this.productinfo,
        name: this.objparti.firstname + '' + this.objparti.lastname,
        email: this.objparti.email,
        phoneno: this.objparti.mobile,
        gstpercent: String(this.coursedetails[0].gstpercent),
        gstamount: String(this.gstamount),
      }
      console.log(obj1.gstamount);
      console.log(JSON.stringify(obj1));
      this.service.post(this.service.postOrderSummary, obj1).subscribe((resp) => {
        //  console.log(resp);
        this.payuform.txnid = resp.txnid;
        this.payuform.surl = resp.surl;
        this.payuform.furl = resp.furl;
        this.payuform.key = resp.key;
        this.payuform.hash = resp.hash;
        this.payuform.udf1 = resp.udf1;
        if (resp != 0) {
          this.toast.showwarning(this.ValidationService.msg_validate_Payment_Success);
          this.sub = true;
          //  this.router.navigate(['/aep/participant/activecourses']);
        }
        else {
          this.toast.showsuccess(this.ValidationService.msg_validate_Payment_Failed);
          //  this.router.navigate(['/aep/participant/courseenrollment']);
        }
      })
    }
  }

  public stepBack() {
    this._location.back();
  }

  public stepBacktoCourse() {
    this.isEnrollment = true;
    this.isOrderSummary = false;
  }

  function1() {
    if (this.ValidationService.funValidateEmptyText(this.obj.country, this.ValidationService.msg_Validate_Country, '#obj.country')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.obj.state, this.ValidationService.msg_Validate_State, '#obj.state')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.selectedarp, this.ValidationService.msg_Validate_Arpselect, '#selectedarp')) {
      return true;
    }
    // if (this.ValidationService.funValidateEmptyText(this.data2.examdate, this.ValidationService.msg_Validate_dateSelect, '#date')) {
    //   return true;
    // }
    // if (this.ValidationService.funValidateEmptyText(this.data2.examtime, this.ValidationService.msg_Validate_timeSelect,'#timeid')) {
    //       return true;
    //  }
  }

}
