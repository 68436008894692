import { Injectable } from '@angular/core';

import { ToasterService } from '../services/toastr.service';
@Injectable()
export class ValidationService{
// format
// varemailformat = /^[a-zA-Z0-9._]+@[a-zA-Z0-9._]+\.[a-zA-Z]{2,4}$/;
varemailformat  = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
varmobileNumberFormat = /^[0-9]{10}$/;
varWebsiteFormat =  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;


//CERTIFICATION
msg_Validate_empty = 'Please Enter Any of the Fields.';
msg_Validate_certSuccess = 'Certification Status Updated Successfully.';

//  LOGIN VALIDATION
msg_Validate_emptyFirstname = 'Please Enter your First Name.';
msg_Validate_emptyLastname  = 'Please Enter your Last Name.';
msg_Validate_emptyEmail = 'Please Enter your active Email Id.';
msg_Validate_Email = 'Please Enter Email.';
msg_Validate_Password= 'Please Enter Password.';
msg_Invalid_Email_Format = 'Invalid Email Format.';
msg_Validate_emptyPassword= 'Please Enter Password.';
msg_Validate_emptyConfirmPassword= 'Please Enter Confirm Password.';
msg_Validate_Passwordmatch= 'Password and Confirm Password Should Match.'
msg_Validate_emptyCheckbox = 'Please accept Privacy Policy and Terms Of Service.';
msg_Validate_LoginSuccess="Login success.";
msg_Validate_LoginFailure="Incorrect email/password.";
msg_Validate_emailNotVerified='Email not verified.'
msg_Validate_SignupSuccess = 'Registration completed successfully. Please check your email for instructions.';
// msg_Validate_SignupFailure = 'Failed to complete registration. Please try again ';
msg_Validate_SignupFailure = 'Your email is already registered.Please use a different email id.';


msg_Validate_siteUnderdevelopment='This Site Is Under Development.';

//CONTACT,CONNECT TO PARTNERS.
msg_Validate_emptyName = 'Please Enter your Name.';
msg_Validate_emptyCompanyName ='Please Enter Company Name.'
msg_Validate_emptyContactPersonName ='Please Enter Contact Person.'
msg_Validate_emptyMobileNumber ="Please Enter Your Contact Number.";
msg_Validate_mobile_number_format = "Please Enter Valid Mobile Number.";
msg_Validate_emptystateName ='Please Enter State Name.';
msg_Validate_emptyCityName ='Please Enter City Name.';
msg_Validate_emptyCountryName ='Please Enter Country Name.';
msg_Validate_emptyMessagefield = "Please Enter A Message.";
msg_Validate_emptyWebsite = "Please Enter Company Website.";
msg_Validate_website_format ="Please Enter Valid Website Url.";

// FORGOT PASSWORD ,PASSWORD UPDATE
msg_Validate_NewPasswordmatch = " New Password and Confirm Password Should Match.";
msg_Validate_passwordUpdateUnsuccessfull ="Please enter valid emailid/old password.";
msg_Validate_ForgotPasswordMailSuccess = "Please check your email and follow instructions to reset the password.";
msg_Validate_ForgotPasswordMailFail = "Email id not registered, please try using registered Email.";
msg_Validate_emptynewPassword="Please Enter New Password";
msg_Validate_emptyconfirmPassword="Please Enter Confirm Password";
msg_Validate_passwordUpdateSuccess = "Password  changed successfully,Use Updated password For Login.";
msg_Validate_passwordResetFailure="Email id not registered, Please try using registered Email to Update Password.";

// PAYMENT
msg_validate_Payment_Success = 'Please Click Proceed to Checkout.';
msg_validate_Payment_Failed = '';

//PROFILE 
msg_Validate_Area='Please Enter Your Present Area.';
msg_Validate_pincode= 'Please Enter Your Present Area Pincode.';
msg_Validate_perArea='Please Enter Your Permanent Area.';
msg_Validate_perpincode= 'Please Enter Your Permanent Area Pincode.';
msg_Validate_Country = "Please Select Country.";
msg_Validate_State = "Please Select State.";
msg_Validate_District = "Please Select District.";
msg_Validate_City = "Please Select City.";
msg_Validate_ProfileUpdateSuccess="Profile Updated Successfully.";
msg_Validate_ProfileUpdateFailure="Error in updating Profile,Please try again.";

// ERROR HANDLE
msg_Validate_TC_202Error = 'Imported file not in a correct format.';
msg_Validate_ERROR = 'There is some Technical Problem, please Contact Administrator.';
// FILE UPLOAD
msg_validate_file ='Invalid file format.';
msg_validate_uploadSuccess ='File uploaded successfully.';

// COURSE OFFERINGS
msg_Validate_courseEnrolled='You Have already Enrolled To This Course.';
// msg_Validate_Level ='Please Select Level ';
 msg_Validate_Stream ='Please Select Stream';

// COURSE ENROLLMENT SECTION
msg_Validate_Arpselect ='Please Select Arp.';
msg_Validate_Tipselect ='please select Institute.';
msg_Validate_dateSelect ='Please Select Exam Date.';
msg_Validate_timeSelect ='Please Select Exam Slot.';

// EMAIL VERIFICATION FOR RESGISTERED NEW USER
msg_Validate_emailVerifySuccess='Email Verification was Successfull.';
msg_Validate_emailVerifyFailure ='Email Verification failed Please Try After Sometime.';

// ORDER SUMMARY
msg_Validate_emptyCheckbox2='Please accept exam Terms and Conditions.';

// RESCHEDULE
msg_Validate_rescheduleSuccess="Exam Date And Time Rescheduled Successfully.";
msg_Validate_reschedulefailure="Exam Date And Time Reschedule is Unsuccessfull.";

// CONTACT US ,NOCCENTTO ARP
msg_Validate_ContactusSuccess = "Thank you for contacting us. Our team will contact you shortly.";
msg_Validate_warning ="There is some Technical Problem, please try after sometime.";

// TIP CPNVERSION 
msg_Validate_TipConversionSuccess = "Organisation Converted to TIP Successfully.";
msg_Validate_TipConversionFailure = "Failed to Convert Organiation to TIP.";

// ARP TIP MAPPING
msg_Validate_ArpTipMappingSuccess = "ARP Mapped to TIP Centre Successfully.";
msg_Validate_ArpTipMappingFailure = "Failed to Map ARP - TIP Centre.";

// ARP ENROLLMENT, VIEW ARP.
msg_Validate_arpCreatedSuccess="New ARP user created successfully.";
msg_Validate_arpCreationFailure="Failed to create new ARP user.";
msg_Validate_emptyCountrycode="Please enter country code.";
msg_Validate_emptygstcode="Please enter gst code.";
msg_Validate_panuid="Please enter your pan or uid.";
msg_Validate_passwordgenerate="Please generate password.";
msg_Invalid_arpEmail_Format="Please enter a valid ARP email format.";
msg_Validate_arpEmail="Please enter company email.";
msg_Validate_Doorno="Pleas enter door number.";
msg_Validate_landmark="Please enter landmark.";
msg_Validate_emptydistrict="Please enter district.";
msg_Validate_emptyAccountnumber="Please enter account number.";
msg_Validate_emptyIFSCCode="Please Enter IFSC code.";
msg_Validate_emptycancelledcheque="Please Upload Cancelled Cheques.";
msg_Validate_arpupdatesuccess="ARP details updated successfully.";
msg_Validate_arpupdateFailure='Failed to update ARP details.';
msg_validate_uploadFailure="Failed to upload file.";
msg_Validate_arpCreationFailure1="User already exists.";
msg_Validate_assignTips="Please assign tips to avaliable ARP.";

// ARP SCREEN
msg_Validate_ArpAdminRequestSuccess="Request sent to Admin successfully.";
msg_Validate_ArpAdminRequestFailure="Failed to send the Request, Try again after sometime.";
msg_validate_ArpupdTipmailSuccess="Mail sent to tip successfully";
msg_validate_ArpupdTipmailFailure="Failed to send mail to tip";
// ADMIN VIEW EXISTING  PARTICIPANT
msg_Validate_userDisabledSuccess ="Participant successfully disabled.";
msg_Validate_userDisabledFailure ="Failed to disable the participant.";
msg_Validate_userenabledSuccess="User enabled successfully.";
msg_Validate_userEnableFailure="Failed to enable the  participant.";
msg_Validate_cbtDateempty = "Please Enter CBT Date.";
msg_Validate_cbtDateUpdSuccess = "CBT Date Updated Successfully."

// searcharpcertification
msg_validate_certfail='Failed to update the completed status';

// bulk upload.
courseBulkEnrollmentSuccess = 'Bulk enrollment successfull';
courseBulkEnrollmentFailure ='Bulk enrollment unsucessfull';
emptyfile='Please upload excel file';
emptyexecelfile="Please upload execel file with data";

//certifications add
selectlevel='Please select level.';
certificationaddsuccessfully='Certification added successfully.';
certificationaddunuccessfull='Certification addition Failed.';
enterlevelid='Please enter level id.';
entercourseid='Please enter course id.';
enterbranchid='Please enter branch id.';
enterwhocanapply='Please enter who can apply.';
entercertificationname='Please enter certification name,';
enteroverview ='Please enter overview.';
entercertificationcode='Please enter certification code.';
enterprerequisites = 'Please enter prerequisites.';
enterdeliverymethod = 'Please enter delivery method.';
enterqualifyingpercentage = 'Please enter qualifying percentage.';
enterdomaintype ='Please enter domain percentage.';
enterexamduration ='Please enter exam duration.';
entersoftwaretool = 'Please enter software tool.';
entercertificationmode ='Please enter certification mode,';
enterexamtype ='Please enter examtype.';
enterpbp ='Please enter pbp.';
entercourseduration = 'Please course duration.';
entertrainingcercost ='Please enter training and certification cost.';
entercertificationcost = 'Please enter certification cost.';
enteryears ='Please enter years.';
entercategory ='Please enter category.';
certificationcodeIsDuplicate='Certification code already exists.'





funValidateEmptyText(value, message, id) {
  if (value == "" || value == null) {
    this.toast.showwarning(message);
    // this.renderer2.selectRootElement(id).focus();
    return true;
  } else {
    return false;
  }
}
// funValidateEmptyText2(value, message, id) {
 
//   if (value == "" || value == null) {
//     this.toast.showwarning(message);
//     // this.renderer2.selectRootElement(id).focus();
//     return true;
//   } else {
//     return false;
//   }
// }



funValidateEmailFormat(value, message, id) {
  if (!value.match(this.varemailformat)) {
    this.toast.showwarning(message);
    return true;
  } else {
    return false;
  }
}
funValidateMobileNumberFormat(value, message, id) {
  if (!value.match(this.varmobileNumberFormat)) {
    this.toast.showwarning(message);
    return true;
  } else {
    return false;
  }
}
funValidatevarWebsiteFormat(value, message, id) {
  if (!value.match(this.varWebsiteFormat)) {
    this.toast.showwarning(message);
    return true;
  } else {
    return false;
  }
}
funvalidateConfirmpswd(pswrd,confrm_pswd, message) {
  if ( pswrd!=  confrm_pswd   ) {
    this.toast.showwarning(message);
    return true;
  } else {
    return false;
  }
}

//DROP DOWN VALIDATIONf
funValidateSelectElement(value, message, element) {
  if (value == "" || value == null) {
    this.toast.showwarning(message);
    element.focus();
    return true;
  } else {
    return false;
  }
}


    constructor(public toast: ToasterService) {
       
      }
    
    }