import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-aepauthorizedtips',
  templateUrl: './aepauthorizedtips.component.html',
  styleUrls: ['./aepauthorizedtips.component.css']
})
export class AepauthorizedtipsComponent implements OnInit {

  public indtipdetails=[];
  notiprecords = true;
  arp_state:any;
  arp_email:any;
  company_name:any;

  constructor(private service: ApiService, private ValidationService :ValidationService, private toast : ToasterService, private router: Router,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show()
    this.getarpstate();
  }

        getarpstate(){
          var id1 = Number(localStorage.getItem('arpid'));
          this.service.get(this.service.getarpbyarpid + id1 ).subscribe((res) => {
          if(res != null){
                  this.arp_state = res[0].office_state;
                  this.arp_email = res[0].arp_email;
                  this.company_name = res[0].company_name;
                  this.getorgtips();

            }
          })
        }
        getorgtips(){
          this.indtipdetails=[];
         this.service.get(this.service.getorgtips).subscribe((res) => {
          this.spinner.hide()
          
          if(res != null){
          for (let i = 0; i < res.length; i++) {
            if (this.arp_state == res[i].orgstate && res[i].isarpmapped == false ) {
              this.indtipdetails.push({
                orgid: res[i].orgid,
                orgname: res[i].orgname,
                orgaddress: res[i].orgaddress,
                orgstate: res[i].orgstate,
                orgcountry: res[i].orgcountry,
                orggstnumber:res[i].orggstnumber,
                orgaepid: res[i].orgaepid,
                industrytype: res[i].industrytype,
                orgwebsite: res[i].orgwebsite,
                email: res[i].email,
                mobilenumber: res[i].mobilenumber,
                designation: res[i].designation,
                firstname: res[i].firstname,
                lastname: res[i].lastname,
                arp_tip_mapping_id: res[i].arp_tip_mapping_id,
                arp_tip_mapped_date: res[i].arp_tip_mapped_date,
                orgdistrict:res[i].orgdistrict,
                orgcity: res[i].orgcity, 
              })
            }
          }
          if(this.indtipdetails.length!= 0) {
            this.notiprecords=true;
         }
         else{
           this.notiprecords=false;
         }
         }
        })
    }

    request(data){

      var obj = {
        arpid             :   Number(localStorage.getItem('arpid')),
        arpname           :   this.company_name,
        arpstate          :   this.arp_state,
        tipcentername     :   data.orgname,
        contactname       :   data.lastname,
        tipemail          :   data.email,
        tipmobile         :   data.mobilenumber,
        tipaddress        :   data.orgaddress,
        tipcity           :   data.orgcity,
        tipdistrict       :   data.orgdistrict,
        tipstate          :   data.orgstate,
        tipcountry        :   data.orgcountry,
      }

      this.service.post(this.service.arpadmintipmapreq, obj ).subscribe((res) => {

        if(res!=null){
          if(res==true){
            this.toast.showsuccess(this.ValidationService.msg_Validate_ArpAdminRequestSuccess);
            }
            else{
              this.toast.showwarning(this.ValidationService.msg_Validate_ArpAdminRequestFailure);
            }
          
          }

      })

    }

}