
<section class="page-section mt-5 py-5">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="text-center mb-3">
          <img src="../../assets/img/message.jpg" alt="" class="signin">
          <h3>Connect with us.</h3>
        </div>
        <form>
          <div class="">
            <div class="">
              <div class="form-group">
                <div class="form-group aep-form">
                  <mat-form-field class="example-full-width customForm">
                    <img src="../../assets/img/userIcon.jpg" alt="">
                    <mat-label>Name<span class="required-mark">*</span></mat-label>
                    <input matInput class="custom-form-control" name="name" [(ngModel)]="name" placeholder="Enter Your First Name">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group aep-form">
                  <mat-form-field class="example-full-width customForm">
                    <img src="../../assets/img/emailIcon.jpg" alt="">
                    <mat-label>Email<span class="required-mark">*</span></mat-label>
                    <input matInput class="custom-form-control" name="email" [(ngModel)]="email" placeholder="Enter Your Emailid">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group aep-form">
                  <mat-form-field class="example-full-width customForm">
                    <img src="../../assets/img/contactph.jpg" alt="">
                    <mat-label>Contact Number<span class="required-mark">*</span></mat-label>
                    <input matInput class="custom-form-control" name="contact" [(ngModel)]="contact" placeholder="Enter Your Contact no">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mt-3 px-1">
            <label for="inputAddress">Message<span class="danger">*</span></label>
            <textarea name="message"  rows="4" maxlength="500" class="form-control" id="inputAddress1" [(ngModel)]="message" required>
            </textarea>
            <span class="alertmessage" *ngIf="message?.length >= 500">
                Only 500 characters are accepted
          </span>
            
            <div class="text-center mt-4">
              <button type="submit" class="btn custom-btn py-3" (click)="onSubmitContact()">Submit</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-6 mt-5">
        <img src="../../assets/img/contactus.jpg" class="img-fluid mt-5" alt="">
        <h5 class="text-center mt-4 wr-s">Write to us at info@aepcertification.com .</h5>
      </div>
    </div>
  </div>
</section> 