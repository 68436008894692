<!-- <header class="masthead">
    <img src="./assets/img/course-initiator.jpg" class="img-fluid">
</header>
<section class="page-section py-5">
    <div class="container">
        <div class="row">
            <h3 class="w-100 mb-4 text-center">Course Initiators</h3>
            <p>AEP Certification welcomes all interested organizations, expert professionals, working professionals, freelancers, faculties and retired professionals having passion in converting their experience into designing customized course curriculum and launch their own AEP approved courses.
            </p>
            <p>
                Register with aepcrt.org to become AEP authorized subject matter experts (SMEs).
            </p>
            <div class="mt-4 w-100 text-center">
                <button class="btn btn-outline-danger" (click)="gotoContactPartners()"><b class="h5">Click here to visit aepcrt.org
                </b></button>
            </div>
        </div>
    </div>
</section> -->

<!-- <img src="./assets/img/about.jpg" class="img-fluid"> -->
<section class="container-fluid">
    <!-- <div class="container"></div> -->
    <div class="page-section">
        <div class="row  web-page-content">
            <div class="col-md-6 col-sm-12 web-pl-5 mob-pl-5 mt-4">
                <h1 class="w-100">Course Initiators</h1>
                <p class="display-5">AEP Certification welcomes all interested organizations, expert professionals,
                    working professionals, freelancers, faculties and retired professionals having passion in converting
                    their experience into designing customized course curriculum and launch their own AEP approved
                    courses.<br />
                </p>
                <p class="display-5 mt-3">Register with aepcrt.org to become AEP authorized subject matter experts (SMEs).
                </p>
                <div class="mt-4 w-100">
                    <button class="btn custom-btn" (click)="gotoContactPartners()"><b class="h5">Click here to
                            visit aepcrt.org
                        </b></button>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 px-0 text-right">
                <img src="../../../assets/courseIniti.png" alt="" class="img-fluid">
            </div>
        </div>
        <div class="row mob-page-content">
            <div class="col-md-6 col-sm-12 px-0 text-right">
                <img src="../../../assets/courseIniti.png" alt="" class="img-fluid">
            </div>
            <div class="col-md-6 col-sm-12 web-pl-5 mob-pl-5">
                <h1 class="w-100 mob-fontsize">Course Initiators</h1>
                <p class="display-5">AEP Certification welcomes all interested organizations, expert professionals,
                    working professionals, freelancers, faculties and retired professionals having passion in converting
                    their experience into designing customized course curriculum and launch their own AEP approved
                    courses.<br />
                </p>
                <p class="display-5 mt-3">Register with aepcrt.org to become AEP authorized subject matter experts (SMEs).
                </p>
                <div class="mt-4 w-100">
                    <div class="py-4 w-100 text-center">
                        <button class="btn custom-btn" (click)="gotoContactPartners()"><b class="h5">Click here to visit aepcrt.org </b></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>