import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild{
constructor(private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean  {
    // return true;
    if( (localStorage.getItem('userid') != null) || (localStorage.getItem('arpid') != null) || (localStorage.getItem('adminid') != null) ){
      return true;
    }else{
      this.router.navigate(['/aep/signin']);
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if( (localStorage.getItem('userid') != null) || (localStorage.getItem('arpid') != null) || (localStorage.getItem('adminid') != null) ){
        return true;
      }else{
        this.router.navigate(['/aep/signin']);
      }
  }
}