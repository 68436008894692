    <!-- <img src="./assets/img/about.jpg" class="img-fluid"> -->
<section class="container-fluid">
    <!-- <div class="container"></div> -->
    <div class="page-section">
        <div class="row web-page-content">
            <div class="col-md-6 col-sm-12 web-pl-5 mob-pl-5">
                    <h1 class="w-100 mt-5">Extended Services</h1>
                    <p class="display-5">We are associated with <b class="h2">aepcrt.org</b> to build a knowledge management community of well-versed Engineers by networking all industries, industry professionals, faculties, engineering colleges, students and training centers to leverage various information such as Internships, Academic projects, Campus Recruitment, Jobs, Workshops, Guest lectures, Events and Seminars.<br />
                        </p>
                        <div class="mt-4 w-100">
                            <button class="btn custom-btn" (click)="onNavigate()">Visit <b class="h3">aepcrt.org</b></button>
                        </div>
            </div>
            <div class="col-md-6 col-sm-12 px-0 text-right">
                <img src="../../../assets/extendedPage.png" alt="" class="img-fluid width-img-fluid">
            </div>
        </div>
        <div class="row mob-page-content">
            <div class="col-md-6 col-sm-12 px-0 text-right">
                <img src="../../../assets/extendedPage.png" alt="" class="img-fluid">
            </div>
            <div class="col-md-6 col-sm-12 web-pl-5 mob-pl-5">
                    <h1 class="w-100 mob-fontsize">Extended Services</h1>
                    <p class="display-5">We are associated with <b class="h2 mob-h2">aepcrt.org</b> to build a knowledge management community of well-versed Engineers by networking all industries, industry professionals, faculties, engineering colleges, students and training centers to leverage various information such as Internships, Academic projects, Campus Recruitment, Jobs, Workshops, Guest lectures, Events and Seminars.<br />
                        </p>
                        <div class="mt-4 w-100 mob-btn-bg">
                            <button class="btn custom-btn" (click)="onNavigate()">Visit <b class="h3">aepcrt.org</b></button>
                        </div>
            </div>
            
        </div>
    </div>
</section>