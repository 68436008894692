<!-- Masthead-->
<!-- <header class="masthead">
    <img src="./assets/img/about.jpg" class="img-fluid">
</header>
<section class="page-section py-5">
    <div class="container">
        <div class="row text-center">
            <h3 class="w-100 mb-3">AEP Certification Program Overview</h3>
            <p class="display-5">We identify and fulfil the need and demand of specific resources to industry, under the guidance and  support of industry professionals
                on specific combination of <b>Domain, AODs (Area Of Discipline), Software tools, and  AOE’s (Area Of Expertise)</b>
                </p>
        </div>
        <div class="row mt-5">
            <div class="col-md-8">
                <h4 class="mb-3 text-center">A Four Phases Approach</h4>
                <p><b class="reg">Registration</b> - Forming of association of Institutional & Individuals in all streams</p>
                <p><b class="tra">Enablement</b> - On specific curriculum designed by industrial professionals by 6 Steps &   60 Hrs methodology through AEP authorized training partners</p>
                <p><b class="cer">AEP Certification</b> – Benchmarking the competencies and skills across O, A , B, C / Basic, Associate, Professional, Expert Levels through independent certifications</p>
                <p>
                    <b class="vali">Validate Experience</b> – through a case study driven practical assessment to benchmark industry equivalent experience
                </p>
            </div>
            <div class="col-md-4 text-center">
                <img src="./assets/img/programOverview.jpg" class="img-overview" alt="">
            </div>
        </div>
    </div>
</section> -->

<section class="aep_program container-fluid">
    <div class="row pt-5 mb-3">
        <div class="col-md-6 col-sm-6 col-xs-12 pt-5 px-0">
            <img src="../../../assets/left-circle.png" alt="" class="img-fluid">
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 text-right px-0">
            <img src="../../../assets/right-circle.png" alt="" class="img-fluid heading-bg">
            <div class="heading-text">
                <h3>AEP Certification</h3>
                <h4>Program Overview</h4>
            </div>
            <div class="main-content">
                <p class="display-5">We identify and fulfil the need and demand of specific resources to industry, under the guidance and
                    support of industry professionals on specific combination of Domain, AODs (Area Of Discipline), Software
                    tools, and AOE’s (Area Of Expertise)</p>
            </div>
        </div>
    </div>
    <div class="row pb-5">
        <div class="col-md-4 mt-5">
            <div class="regitration">
                <h4>REGISTRATION</h4>
                <p class="display-5">Forming of association of Institutional & Individuals in all streams</p>
            </div>
            <div class="enablement mt-5">
                <h4>ENABLEMENT</h4>
                <p class="display-5">On specific curriculum designed by industrial professionals by 6 Steps & 60 Hrs methodology through
                    AEP authorized training partners</p>
            </div>
        </div>
        <div class="col-md-4 text-center">
            <img src="../../../assets/registration.png" alt="" class="img-fluid">
        </div>
        <div class="col-md-4 mt-5">
            <div class="certification">
                <h4>AEP CERTIFICATION</h4>
                <p class="display-5">Benchmarking the competencies and skills across O, A , B, C / Basic, Associate, Professional, Expert
                    Levels through independent certifications</p>
            </div>
            <div class="experience mt-5">
                <h4>VALIDATE EXPERIENCE</h4>
                <p class="display-5">through a case study driven practical assessment to benchmark industry equivalent experience</p>
            </div>
        </div>
    </div>
</section>