<!-- <header class="masthead">
    <img src="./assets/img/tip.jpg" class="img-fluid">
</header>
<section class="page-section py-5">
    <div class="container">
        <div class="row">
            <h3 class="w-100 mb-4 text-center">Training Institute Partners (TIP)</h3>
            <p>Training Institute Partner (TIP) is the AEP authorized preparatory center which is identified and empaneled by the ARP in its territory or region to successfully execute AEP Certifications and their corresponding trainings programs designed by respective industry professionals.</p>
            <p>TIPs will be supported by respective ARP with necessary inputs, guidelines, marketing aids, industry trainers, and guidance on required operational, logistical and infrastructural needs in order to execute various certifications and corresponding training programs offered by AEP Certification.</p>
            <b class="w-100">TIPs are classified into:</b>
                <ul>
                    <li>Commercial Training Institute (CTI) </li>
                    <li>Professional Practitioner’s Institute (PPI) </li>
                    <li>Research Expert’s Institute (REI) </li>
                </ul>
            <p>Commercial Training Institute (CTI) - CTI is a training organization or institution which is a training partner empaneled under ARP, responsible to execute and deliver training and AEP certification on:
            </p>
            <ul>
                <li>Core Engineer MDP “O and A” Level programs</li>
                <li>Design Engineer “Basic and Associate” level programs</li>
            </ul>
            <p>CTIs are primarily expected to conduct Theoretical Contact Training (TCT), Practical Simulation Training, Practical Onsite Training, and other related activities and assistance for the participants on the above-mentioned programs.</p>
            <b class="w-100">Following institutions/Institutes are eligible for becoming CTIs:</b>
            <ul>
                <li>Academic Training Institutions (Engineering colleges)</li>
                <li>Universities - Engineering Level</li>
                <li>Software/Hardware Training Institutes</li>
                <li>Corporate Training Institutes</li>
            </ul>
            <p>Professional Practitioner’s Institute (PPI) - PPI is a training organization or institution which is a training partner empaneled under ARP, responsible to execute and deliver training and AEP certification on:
            </p>
            <ul>
                <li>
                    Core Engineer ‘B’ Level programs
                </li>
                <li>Design Engineer “Professional” level programs</li>
            </ul>
            <p>PPI are primarily expected to conduct Industrial Domain Training (IDT), Practical Simulation Training, Practical Onsite Training, Practical Based Project guidance and other related activities and assistance for the participants on the above-mentioned programs. 
            </p>
            <b class="w-100">Following institutions/Institutes are eligible for becoming PPI:</b>
            <ul>
                <li>Training institutes run by industry professionals, Engineering Consultants, and Small, Medium self-employed companies
                </li>
            </ul>
            <p>Research Expert’s Institute (REI) - REI is a training organization or institution which is a training partner empaneled under ARP, responsible to execute and deliver training and AEP certification on:
            </p>
            <ul>
                <li>Core Engineer ‘C’ Level programs</li>
                <li>Design Engineer ‘Expert’ level programs</li>
            </ul>
            <p>REI are primarily expected to conduct Practical Expert Training, Practical Simulation/Software Training, Practical Onsite Training, Practical Based Project guidance and other related activities and assistance for the participants on the above-mentioned programs. 
            </p>
            <b class="w-100">Following institutions/Institutes/organizations are eligible for becoming REI:
            </b>
            <ul>
                <li>
                    Consulting firms run by R&D experts, DRDO Scientists, Design & Analysis analysts, FEA/ SME across industries   
                </li>
            </ul>
            <b class="w-100">Register with aepcrt.org to become AEP authorized TIP
            </b>
            <div class="mt-4 w-100 text-center">
                <button class="btn btn-outline-danger" (click)="gotoContactPartners()"><b class="h5">Click here to visit aepcrt.org
                </b></button>
            </div>
        </div>
    </div>
</section> -->
<section>
    <div class="main-section">
        <h2>Training Institute Partners (TIP)</h2>
        <img src="../../../assets/trainingInistrute.png" alt="" class="img-fluid">
    </div>
    <div class="container text-center mb-4">
        <p class="display-5">
            Training Institute Partner (TIP) is the AEP authorized preparatory center which is identified and empaneled
            by the ARP in its territory or region to successfully execute AEP Certifications and their corresponding
            trainings programs designed by respective industry professionals.
            TIPs will be supported by respective ARP with necessary inputs, guidelines, marketing aids, industry
            trainers, and guidance on required operational, logistical and infrastructural needs in order to execute
            various certifications and corresponding training programs offered by AEP Certification.
        </p>
        <small class="display-5">TIPs are classified into:</small>
    <div id="accordion">
        <div class="card">
            <div class="card-header">
                <a class="card-link" data-toggle="collapse" href="#collapseOne">
                    Commercial Training Institute (CTI)
                </a>
            </div>
            <div id="collapseOne" class="collapse" data-parent="#accordion">
                <div class="card-body">
                    <p class="display-5">Commercial Training Institute (CTI) - CTI is a training organization or
                        institution which is a training partner empaneled under ARP, responsible to execute and deliver
                        training and AEP certification on:</p>
                    <ul>
                        <li>Core Engineer MDP “O and A” Level programs</li>
                        <li>Design Engineer “Basic and Associate” level programs</li>
                    </ul>
                    <p class="display-5">CTIs are primarily expected to conduct Theoretical Contact Training (TCT),
                        Practical Simulation Training, Practical Onsite Training, and other related activities and
                        assistance for the participants on the above-mentioned programs.</p>
                    <small>Following institutions/Institutes are eligible for becoming CTIs:</small>
                    <ul>
                        <li>Academic Training Institutions (Engineering colleges)</li>
                        <li>Universities - Engineering Level</li>
                        <li>Software/Hardware Training Institutes</li>
                        <li>Corporate Training Institutes</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                    Professional Practitioner’s Institute (PPI)
                </a>
            </div>
            <div id="collapseTwo" class="collapse" data-parent="#accordion">
                <div class="card-body">
                    <p>Professional Practitioner’s Institute (PPI) - PPI is a training organization or institution which
                        is a training partner empaneled under ARP, responsible to execute and deliver training and AEP
                        certification on:</p>
                    <ul>
                        <li>Core Engineer ‘B’ Level programs</li>
                        <li>Design Engineer “Professional” level programs</li>
                    </ul>
                    <p>PPI are primarily expected to conduct Industrial Domain Training (IDT), Practical Simulation
                        Training, Practical Onsite Training, Practical Based Project guidance and other related
                        activities and assistance for the participants on the above-mentioned programs.</p>
                    <p>Following institutions/Institutes are eligible for becoming PPI:</p>
                    <p>Training institutes run by industry professionals, Engineering Consultants, and Small, Medium
                        self-employed companies</p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                    Research Expert’s Institute (REI)
                </a>
            </div>
            <div id="collapseThree" class="collapse" data-parent="#accordion">
                <div class="card-body">
                    <p>Research Expert’s Institute (REI) - REI is a training organization or institution which is a
                        training partner empaneled under ARP, responsible to execute and deliver training and AEP
                        certification on:</p>
                    <ul>
                        <li>Core Engineer ‘C’ Level programs</li>
                        <li>Design Engineer ‘Expert’ level programs</li>
                    </ul>
                    <p>REI are primarily expected to conduct Practical Expert Training, Practical Simulation/Software
                        Training, Practical Onsite Training, Practical Based Project guidance and other related
                        activities and assistance for the participants on the above-mentioned programs.</p>
                    <p>Following institutions/Institutes/organizations are eligible for becoming REI:
                    </p>
                    <p>Consulting firms run by R&D experts, DRDO Scientists, Design & Analysis analysts, FEA/ SME across
                        industries
                    </p>
                </div>
            </div>
        </div>
    </div>
        <button class="btn custom-btn" (click)="gotoContactPartners()"><b class="h5">Click here to visit aepcrt.org
            </b></button>
    </div>

</section>