import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';

import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { ValidationService } from 'src/app/services/validation.service';
@Component({
  selector: 'app-trainers',
  templateUrl: './trainers.component.html',
  styleUrls: ['./trainers.component.css']
})
export class TrainersComponent implements OnInit {
  trainerdetails = []
  districts = []
  trainers = []
  State;
  Stateid;
  trainer1;
  district1;
  public country:any;
  public state:any;
  public CCountries = [];
  States =[];

  trainertypeData= [
    {id: 1, name: 'IDT ( Industrial Domain Trainers )'},
    {id: 2, name: 'PST ( Practical Simulation Trainers )'},
    {id: 3, name: 'POT ( Practical Onsite Trainers )'},
    {id: 4, name: 'PET ( Practical Expert Trainers )'},
    {id: 5, name: 'HR ( Industry HR )'}		]

    public showdetails: boolean = false;
    public showdetails1: boolean = false;
    trainerfulldetails = []
    norecords = true;
    norecords1 = true;
    norecords2 = true;

  constructor(private service: ApiService, private ValidationService: ValidationService, private toast: ToasterService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getcountries();
    // this.gettrainers();
    this.showdetails = true;
    this.showdetails1 = false;
  }

  showTip(){
    this.showdetails = true;
    this.showdetails1 = false;
  }

  

  // getarpstate() {
  //   var id1 = Number(localStorage.getItem('arpid'));
  //   this.service.get(this.service.getarpbyarpid + id1).subscribe((res) => {
  //     if (res != null) {
  //       this.State = res[0].office_state;
  //       this.Stateid = res[0].office_stateid
  //       this.getdistricts();
  //       this.gettrainers();
  //     }
  //   })
  // }

  getcountries(){
    // COUNTRY
    var regionid = 5;
    this.service
      .get(this.service.getCountries)
      .subscribe((resp) => {
        if (resp != null) {
         this.CCountries = resp.sort(function(a,b)
           {
             var st1 = a.country_name.toUpperCase();
             var st2 = b.country_name.toUpperCase();
             return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
           });
        }
      })

 }
 getStates(id) {
   this.spinner.show()
   this.service.get(this.service.getStates + id).subscribe((resp) => {
     this.spinner.hide()
   this.States =resp.sort(function(a,b)
   {
     var st1 = a.statename.toUpperCase();
     var st2 = b.statename.toUpperCase();
     return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
   });
  //  this.Stateid= resp.office_stateid;
  //  this.getdistricts();
    // this.gettrainers();
 })
   // console.log(this.statedata);  
}

  getdistricts(id){
    this.Stateid = id.stateid;
    this.service.get(this.service.getDistricts + this.Stateid).subscribe(resp=>{
      if (resp != null) {
        this.districts = resp.sort(function (a, b) {
          var st1 = a.districtname.toUpperCase();
          var st2 = b.districtname.toUpperCase();
          return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
        });
        // this.districts = resp
      }
    })
  }

  empArr = []
  temparr = []
  temparr2 = []
  gettrainers(id) {
    this.spinner.show()
    this.empArr = []
    this.trainerdetails =[] 
    // this.trainertypeData = []
    var user_id = 0
    this.service.get(this.service.getTrainersList + "/" + user_id).subscribe((res) => {
      this.spinner.hide()
      if (res != null) {
        this.empArr = res
        for (let i = 0; i < res.length; i++) {
          if (id == res[i].homedistrict) {
            this.trainerdetails.push({
              firstname: res[i].firstname,
              lastname: res[i].lastname,
              branch: res[i].branch,
              trainertype: res[i].typeoftrainer,
              highesteducation: res[i].highesteducation,
              industryexperience: res[i].industryexperience,
              homedistrict: res[i].homedistrict,
              typeoftrainer: res[i].typeoftrainer,
              id: res[i].userid
            })
            this.temparr = this.trainerdetails
            this.temparr2 = this.trainerdetails
          }
        }
        if(this.trainerdetails.length!=0){
          this.norecords=true;
          this.norecords1=true;
          this.norecords2=true;
        }
        else{
          this.norecords=false;
          this.norecords1=true;
          this.norecords2=true;
        }
      }
    })
  }

  view(id) {
    this.trainerfulldetails = [];
    this.showdetails = false;
    this.showdetails1 = true;
    for (let i = 0; i < this.empArr.length - 1; i++) {
    if(id == this.empArr[i].userid){
      console.log(this.empArr[i]);
      console.log('hai');
    this.trainerfulldetails.push({
      type: this.empArr[i].typeoftrainer,
      firstname:this.empArr[i].firstname,
      lastname:this.empArr[i].lastname,
      email:this.empArr[i].email,
      mobilenumber:this.empArr[i].mobilenumber,
      branch:this.empArr[i].branch,
      highesteducation:this.empArr[i].highesteducation,
      homeaddress:this.empArr[i].homeaddress,
      homeaddressline1:this.empArr[i].homeaddressline1,
      homeaddressline2:this.empArr[i].homeaddressline2,
      homedistrict:this.empArr[i].homedistrict,
      homestate:this.empArr[i].homestate,
      officeaddress:this.empArr[i].officeaddress,
      officeaddressline1:this.empArr[i].officeaddressline1,
      officeaddressline2:this.empArr[i].officeaddressline2,
      officedistrict:this.empArr[i].officedistrict,
      officestate:this.empArr[i].officestate,
      workmode:this.empArr[i].workmode,
      domainshecanteach:this.empArr[i].domainshecanteach,
      softwareshecanteach:this.empArr[i].softwareshecanteach,
      aoehecanteach:this.empArr[i].aoehecanteach,
      yearsofexperience:this.empArr[i].yearsofexperience,
      facultyexperience:this.empArr[i].facultyexperience,
      industryexperience:this.empArr[i].industryexperience,
      domain:this.empArr[i].domain,
      interesttocreatecoursematerial:this.empArr[i].interesttocreatecoursematerial,
      interestinpreparingexampattern:this.empArr[i].interestinpreparingexampattern,
      interesttoconductonlinetraining:this.empArr[i].interesttoconductonlinetraining
    })
    }
  }
}

// getfilteredgridbydistrict(id){
// this.trainer1 = "";
// this.trainerdetails =  this.temparr.filter(x => x.homedistrict == id);
// if(this.trainerdetails.length!=0){
//   this.norecords2=true;
//   this.norecords=true;
//   this.norecords1=true;
// }
// else{
//   this.norecords2=false;
//   this.norecords1=true;
//   this.norecords=true;
// }
// this.temparr2 = this.trainerdetails

// }

getdatabytype(id){
  if(this.temparr2.length != 0){
  this.trainerdetails = []
  this.temparr2.forEach((val, indx)=>{
    if(val['typeoftrainer'] != null){
    let filteredval = val['typeoftrainer'].filter(x => x == id);
    if( filteredval != 0){
      this.trainerdetails.push(this.temparr2[indx])
    }
    if(this.trainerdetails.length!=0){
      this.norecords1=true;
      this.norecords=true;
      this.norecords2=true;
    }
    else{
      this.norecords1=false;
      this.norecords2=true;
      this.norecords=true;
    }
    }
  })
}
}
}
