<div *ngIf="activecourse">
    <h4 class="heading">Following are your enrolled certifications. Click on the View Details to view and edit the exam
        schedule.</h4>
    <mat-card class="activeCourse py-4 px-4">
        <mat-card-header>
            <mat-card-title class="m-0 p-0 headerstyling">
            <h4>My Enrolled Certifications</h4>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row">
                <ng-container *ngIf="coursesdata.length > 0">
                    <div class="col-md-4" *ngFor="let course  of coursesdata">
                        <div class="bordered pointer">
                            <div class="courseSection" style="height: 113px;">
                                <h6>{{course.certification_name}}</h6>
                            </div>
                            <div class="coursedetails">
                                <p><label>Code</label>{{course.certification_code}}</p>
                                <p><label>Type</label>{{course.exam_type}}</p>
                                <p><label>Level</label>{{course.level_name}}</p>
                                <p><label>Exam Date</label>{{course.course_examdate }}</p>
                                <p><label>Exam Slot</label>{{course.slot_range}}</p>
                                <p><label>Status</label>
                                    <span class="status">
                                        <div class="progress">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                [ngClass]="course.certification_status_name === 'submitted' ? 'bg-primary' : 'bg-success'"
                                                role="progressbar" style="width:100%">
                                                <span>{{course.certification_status_name}}</span>
                                            </div>
                                        </div>
                                    </span></p>
                            </div>
                            <div class="mb-3">
                                <button type="submit" class="btn custom-btn btn-s" (click)="showCourseDetails(course.certificationid,course.userid,course.order_id)" >View Details</button>
                              </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="coursesdata.length == 0">
                  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
                    <p class="no-data">
                        You did not enroll for any certification yet. You can view the scheduled certifications in this
                        page once you successfully enroll for the certification. Please access the available
                        certifications in Enroll Certifications page.
                    </p>
                 
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="activecoursedetails">
    <mat-card class="px-4 py-3 activeCourse large">
        <mat-card-header>
            <!-- <mat-card-title class="m-0 p-0" style="margin-left: -32px;">
                <span class="material-icons" (click)="stepBack()">keyboard_backspace</span>
                Enrolled Certification Details
            </mat-card-title> -->
            <mat-card-title class="m-0 p-0 headerstyling2">
                <!-- 
                <span class="material-icons" (click)="stepBack()">keyboard_backspace</span> -->
                <div>
                    <button mat-stroked-button color="primary" (click)="stepBack()">Back</button>
                </div>
                <h4>Enrolled Certification Details</h4>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="col-md-12" *ngFor="let course1  of coursedata2">
                <div class="bordered">
                    <h5 class="activeCourse-heading">{{course1.certification_name}}</h5>
                    <div class="row px-3">
                        <div class="col-md-10">
                            <p><label>Code</label>{{course1.certification_code}}</p>
                            <p><label>Type</label>{{course1.exam_type}}</p>
                            <p><label>Level</label>{{course1.level_name}}</p>
                            <p><label>Duration</label>{{course1.exam_duration}} hrs</p>
                            <p><label>Delivery Method</label>{{course1.delivery_method}}</p>
                            <!-- <p><label>Cost</label>{{course1.certification_cost }}</p> -->
                            <p><label>Status</label>
                                <span class="status">
                                    <div class="progress">
                                        <div class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                                            role="progressbar" style="width:100%">
                                            <small>{{course1.certification_status_name}}</small>
                                        </div>
                                    </div>
                                </span>
                            </p>
                        </div>
                        <div class="col-md-2 mt-2">
                            <button mat-raised-button color="primary" (click)="showReschedulePopup()">Reschedule</button>
                        </div>
                    </div>

                    <div class="ActiveCourseDetails">
                        <h6>ARP Details</h6>
                        <div class="row px-3">
                            <div class="col-md-6">
                                <p><label>ARP Name</label>{{course1.company_name}}</p>
                                <p><label>Contact Person Name</label>{{course1.contact_person_name}}</p>
                            </div>
                            <div class="col-md-6">
                                <p><label>Contact Number</label>{{course1.mobile_number}}</p>
                                <p><label>Email</label>{{course1.arp_email}}</p>
                            </div>
                        </div>
                        <h6>TIP Centre Details</h6>
                        <p class="px-3"><label>TIP Centre Name</label>{{course1.orgname}}</p>
                        <label class="px-3 subheading">TIP Centre Address</label>
                        <div class="row px-3">
                            <div class="col-md-6">
                                <p><label>Area</label>{{course1.orgaddress}}</p>
                                <p><label>City</label>{{course1.orgcity}}</p>
                                <p><label>District</label>{{course1.orgdistrict}}</p>
                            </div>
                            <div class="col-md-6">
                                <p><label>Contact Person Name</label>{{course1.firstname}}</p>
                                <p><label>Email</label>{{course1.email}}</p>
                                <p><label>Contact Number</label>{{course1.mobilenumber}}</p>
                                <p><label>Website</label>{{course1.orgwebsite}}</p>
                            </div>
                        </div>

                        <h6>Upload SBT/PBP answersheets:</h6>
                        <div class="mb-2">
                            <div class="row px-3" *ngFor="let upload of uploadDoc; last as isLast">
                                <div class="input-group col-md-10 py-1">
                                    <input id="docs" name="filepath" readonly="true"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="filepath"
                                        placeholder="upload pdf only" type="text" class="form-control square">
                                    <label class="input-group-btn upload-col">
                                        <span class="btn inform-btn btn-raised btn-primary square">Browse
                                            <input type="file" (change)="getFiles($event)" style="display: none;"
                                                value="">
                                        </span>
                                    </label>
                                </div>
                                <!-- <div class="col-1">
                                    <span class="example-button-container mr-3" *ngIf="uploadDoc.length > 1">
                                        <button mat-mini-fab color="accent" (click)="removeUpload(upload.id)">
                                            <span class="material-icons">remove</span>
                                        </button>
                                    </span>
                                    <span class="example-button-container" *ngIf="isLast">
                                        <button mat-mini-fab color="primary" (click)="addUpload(upload.id)">
                                            <span class="material-icons">add</span>
                                        </button>
                                    </span>
                                </div> -->
                                <div class="col-0">
                                    <button type="submit" class="btn custom-btn btn1-s px-3"
                                        (click)="uploadDocument()"><small>Upload</small></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>


<!-- reschedule popup -->
<div class="modal" *ngIf="isReschedulePopup">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="reschedule-styling">
                <h5 class="modal-title text-center">Reschedule</h5>
                <button type="button" class="close" (click)="closeDetailsPopup()">&times;</button>
            </div>
            <div class="modal-body">
                <div class="row px-5">
                    <label for="examdate">Select Exam Date</label>
                    <input type="date" class="form-control mb-2" [min]="disableDates" #date [(ngModel)]="examdate">
                    <mat-form-field class="example-full-width">
                        <mat-label>Select Exam Slot</mat-label>
                        <mat-select #timeid name="examtime" (click)="gettime()" [(ngModel)]="examtime">
                            <mat-option *ngFor="let data1 of timeslot" [value]="data1.slot_id">{{data1.slot_range}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field class="example-full-width px-3">
                        <mat-label>Country<span class="required-mark">*</span></mat-label>
                        <mat-select #countid name="precount" [(ngModel)]="obj.country_id"
                        (selectionChange) = "getStates($event.value)">
                          <mat-option *ngFor="let Country of Countries" [value]="Country.country_id">
                            {{Country.country_name}}</mat-option>
                        </mat-select>
                      </mat-form-field> -->
                </div>
                <div class="modal-footer justify-content-center">
                    <button type="submit" class="btn btn-primary py-2" (click)="savedata(examtime,examdate)">Save</button>
                    <button type="reset" class="btn btn-primary py-2" (click)="closeDetailsPopup()">Cancel</button>
                </div>
            </div>
        </div>
       
    </div>