<section *ngIf="isOffers">
  <h4 class="heading">Use this page to explore and enroll for various AEP certifications designed across Streams, Domains and Software Tools.</h4>
  <mat-card class="py-5">
    <app-offers></app-offers>
  </mat-card>
</section>
<section class="arp" *ngIf="isEnrollment">
  <h4 class="heading">Please contact the ARP / TIP Centre if you would like to Enroll for both Training and Certification for the selected Certification.</h4>
  <mat-card class="py-4 mob-pp">
    <mat-card-header>
      <mat-card-title class="arp-e">
        <div>
          <button mat-stroked-button color="primary" (click)="stepBack()">Back</button>
        </div>
       <h4>Enroll for  {{certname}}</h4>
      </mat-card-title>
    </mat-card-header>
    <div class="row">
      <div class="col-md-6 pl-0">
        <div class="bdr pt-3 flexItem">
          <div class="slct-s"> 
          <label for="arp">Select Location</label>
          </div>
          <div class="row">
            <div class="col-sm-5 p-0">
              <mat-form-field class="example-full-width px-3">
                <mat-label>Country:</mat-label>
                <mat-select #countcountryid [(ngModel)]="obj.country" name="country"
                  (selectionChange)="getStates($event.value)">
                  <mat-option *ngFor="let Country of CCountries" [value]="Country.country_id">
                    {{Country.country_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-7 p-0">
              <mat-form-field class="example-full-width px-3">
                <mat-label>State</mat-label>
                <mat-select #countstateid name="state" [(ngModel)]="obj.state"
                  (selectionChange)="statefilter($event.value)">
                  <mat-option *ngFor="let State of States" [value]="State">
                    {{State.statename}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
         <div class="m-auto my-2 text-center">
            <button mat-raised-button class="btn-color" (click)="showARPGrid()" [disabled]="isSearchbtn()">Proceed</button>
          </div>
        </div>
      </div>
      <div class="col-md-6 pr-0 mob-pr" *ngIf="isARPGrid">
        <div class="bdr flexItem">
          <label for="arp">Select ARP | ARP Region <span class="arp-s">({{this.selectedCity}})</span> </label>
          <div class="row">
            <div class="col-sm-4 p-0">
              <mat-form-field class="example-full-width px-3">
                <mat-label>Select ARP</mat-label>
                <mat-select #countarpid name="arpdetails1" [(ngModel)]="selectedarp" (selectionChange)="getArpDetails($event.value)">
                  <mat-option *ngFor="let data of datasource" [value]="data.arp_id">
                    {{data.company_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-8 p-0 arp-details" [hidden]="arpdt">
              <h5>{{this.objarp.company_name}}</h5>
              <p class="d-flex wrd-brk"><small>Email</small>{{this.objarp.arp_email}}</p>
              <!-- <p class="d-flex"><small>Contact Person</small>{{this.objarp.contact_person_name}}</p> -->
              <p class="d-flex"><small>Contact Number</small>{{this.objarp.mobile_number}}</p>
              <p class="d-flex"><small>City</small>{{this.objarp.office_city}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="mt-4">
        <h6 class="pl-0 st-1" *ngIf="datasource3.length>0"><b>Select the Training Institute Partner Centre to schedule the exam</b></h6>
        <div class="bdr-styling row mt-1" *ngFor="let data2 of datasource3; let i = index">
          <div class="col-md-1 pl-0 pt-3">
            <img src="../../../assets/img/core-engineering.jpg" class="img-fluid" alt="">
          </div>
          <div class="col-md-8 py-2 pr-4 arp-summary bdr-r mob-arp-summary">
            <p class="d-flex"><small>TIP Centre Name</small>{{data2.orgname}}</p>
            <!-- <p class="d-flex"><small>Industry Type</small>{{data2.industrytype}}</p> -->
            <!-- <p class="d-flex"><small>Contact person firstname</small>{{data2.firstname}}</p> -->
            <p class="d-flex"><small>Contact Number</small>{{data2.mobilenumber}}</p>
            <p class="d-flex wrd-brk"><small>Email</small>{{data2.email}}</p>
            <p class="d-flex"><small>Website</small>{{data2.orgwebsite}}</p>
            <p class="d-flex"><small>Address</small>{{data2.orgaddress}}</p>
            <p class="d-flex"><small>City</small>{{data2.orgcity}}</p>
            <p class="d-flex"><small>District</small>{{data2.orgdistrict}}</p>
            <p class="d-flex"><small>state</small>{{data2.orgstate}}</p>
            <p class="d-flex"><small>Country</small>{{data2.orgcountry}}</p>
          </div>
          <div class="col-md-3 mob-pl4">
            <label for="examdate">Select Exam Date</label>
            <input type="date" class="form-control mb-2" [min]="disableDates" #date [(ngModel)]="data2.examdate">
            <mat-form-field class="example-full-width">
              <mat-label>Select Exam Slot</mat-label>
              <mat-select #timeid name="timeslot" (selectionChange)="getAllTimeslot(data2, $event.value)" [(ngModel)]="data2.examtime">
                <mat-option *ngFor="let data3 of timeslot, let idx = index" [value]="data3">
                  {{data3.slot_range}}</mat-option>
              </mat-select>
          </mat-form-field>
            <!-- <div class="m-auto my-2 text-center">
              <button mat-raised-button  (click)="showOrderSummary(data2, i)">Proceed to Order Summary</button>
            </div> -->
            
            <div class="m-auto my-2 text-center">
              <button mat-raised-button class="btn-color"(click)="showOrderSummary(data2, i)">Proceed to Order Summary</button>
            </div>
           </div>
        </div>
      </div>
    </div>
  </mat-card>
</section>
<section *ngIf="isOrderSummary">
  <mat-card class="ordersummary">
    
      <!-- <span class="material-icons" >keyboard_backspace</span> -->
    <div>
        <button mat-stroked-button color="primary" (click)="stepBacktoCourse()">Back</button>
    </div>
    <mat-card-title class="headerstyling3">
    <h4>Order Summary</h4>
  </mat-card-title>
    <div class="col-sm-12 px-0">
      <div class="bordered mb-3">
        <h6>Participant Details</h6>
        <p><label>Name</label>{{this.objparti.firstname}} {{this.objparti.lastname}}</p>
        <p><label>Contact Number</label>{{this.objparti.mobile}}</p>
        <p class="wrd-brk"><label>Email</label>{{this.objparti.email}}</p>
        <p><label>Door No.</label>{{this.objparti.predoorno}}
        <p><label>Flat/Floor/Building</label>{{this.objparti.preflat}}
        <p><label>Street/Colony </label>{{this.objparti.prestreet}}
        <p><label>Area</label>{{this.objparti.prearea }}
        <p><label>Landmark</label>{{this.objparti.prelandmark }}
        <p><label>City/Town/Area</label>{{this.objparti.precity}}
        <p><label>District</label>{{this.objparti.predistrict}}
        <p><label>State</label>{{this.objparti.prestate}}
        <p><label>Country</label>{{this.objparti.precountry}}</p>
        <div class="row py-4">
          <div class="col-sm-6 bdr-r">
              <h6>ARP Details</h6>
              <p><label>ARP Name </label>{{this.objarp.company_name}}</p>
              <p><label>Contact Number</label>{{this.objarp.mobile_number}}</p>
              <p><label>Email</label> {{this.objarp.arp_email}}</p>
              <p><label>Flat/Floor/Building</label>{{this.objarp.office_doorno}}</p>
              <p><label>Street</label>{{this.objarp.office_street}}</p>
              <p><label>Area</label>{{this.objarp.office_area}}</p>
              <p><label>Landmark</label>{{this.objarp.office_landmark}}</p>
              <p><label>City</label>{{this.objarp.office_city}}</p>
              <p><label>District</label>{{this.objarp.office_district}}</p>
              <p><label>State</label>{{this.objarp.office_state}}</p>
              <p><label>Country</label>{{this.objarp.offfice_country}}</p>
          </div>
          <div class="col-sm-6" *ngFor="let tip of tipdetails">
              <h6>TIP Centre Details</h6>
              <p><label>TIP Centre Name</label>{{tip.orgname}}</p>
              <p><label>Contact Number</label>{{tip.mobilenumber}}</p>
              <p><label>Email</label>{{tip.email}}</p>
              <p><label>Address</label>{{tip.orgaddress}}</p>
              <p><label>City</label>{{tip.orgcity}}</p>
              <p><label>District</label>{{tip.orgdistrict}}</p>
              <p><label>State</label>{{tip.orgstate}}</p>
              <p><label>Country</label>{{tip.orgcountry}}</p>
          </div>
        </div>
        <div class="col-sm-12 px-0 styling" *ngFor="let course of coursedetails">
          <h6>Certification Details</h6>
          <p><label>Certification Name</label>{{course.certification_name}}</p>
          <p><label>Level</label>{{course.level_name}}</p>
          <p><label>Duration</label>{{course.exam_duration}} hrs</p>
          <div *ngIf="course.domain_type != null">
            <p><label>Domain Type</label>{{course.domain_type}}</p>
          </div>
          <div *ngIf="course.domain_type == null">
            <p><label>Domain Type</label>NA</p>
           </div>
          <p><label>Exam Type</label>{{course.exam_type}}</p>
          <p><label>Exam Date</label>{{course.examdate}}</p>
          <p><label>Exam Slot</label>{{course.time_slot}}</p>
          <p><label>Certification Amount</label><b>{{course.certification_cost}} /-</b></p>
          <p><label>G.S.T Amount (18%) </label><b>{{gstamount}} /-</b></p>
          <p><label>Total Amount</label><b>{{totalamount}} /-</b></p>
      </div>
     </div>
     <mat-checkbox (change)="Checkout($event)" [(ngModel)]="terms" class="displaywat">I have read and accept exam
      <a href="../../assets/pdf/Participant's NDA for AEP Certification Training and Exam Enrolment.pdf" target="_blank">
        Terms & Condtions
      </a> 
      </mat-checkbox>
     <div class="m-auto mt-2 text-center">
  <!-- <form ngNoForm action="https://sandboxsecure.payu.in/_payment" name="payuform" method="POST"> -->
  <form ngNoForm action="https://secure.payu.in/_payment" name="payuform" method="POST"> 
  <input name="surl" id="surl" ng-model="surl"   hidden [(ngModel)]="payuform.surl"/>
      <input name="furl" id="furl" ng-model="furl"   hidden [(ngModel)]="payuform.furl"/>
      <input name="key" id="key" ng-model="key"  hidden [(ngModel)]="payuform.key"/>
      <input name="hash" id="hash" ng-model="hash"   hidden [(ngModel)]="payuform.hash"/>
      <input name="txnid" id="txnid" ng-model="txnid"   hidden [(ngModel)]="payuform.txnid"/>
      <input name="service_provider" value="payu_paisa"   id="service_provider"  hidden/>
      <input type="text"  id="productInfo" name="productinfo" hidden [(ngModel)]="productinfo">
      <input type="text" id="firstname" name="firstname" hidden [(ngModel)]="objparti.firstname + '' + objparti.lastname">
      <input type="email" id="email" name="email" hidden [(ngModel)]="objparti.email">
      <input type="number" id="phone" name="phone" hidden [(ngModel)]="objparti.mobile">
      <input type="number" id="udf1" name="udf1" hidden [(ngModel)]="payuform.udf1">
      <input type="number" id="amount" name="amount" hidden [(ngModel)]="totalamount">
      <button class="btn custom-btn pay mt-2" type="submit" #payid [disabled]="!sub">Proceed to Checkout</button>
    </form>

     </div>
    </div>
</mat-card>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
</section>