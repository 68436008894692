import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('menuCollapseBtn', {static: false}) menuCollapseBtn : ElementRef

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public showHomePage(){
    this.router.navigateByUrl('/aep/home')
  }

  public menuCollapse(){
    this.menuCollapseBtn.nativeElement.click();
  }

}
