<mat-card [hidden]="hideMain" class="mob-pding">
  <mat-card-header>
    <mat-card-title class="m-0 p-0">View Certifications</mat-card-title>
  </mat-card-header>
  <section class="page-section pb-4 mb-3">
    <div class="container text-center mb-4">
      <!-- <p class="display-5">There are <b>{{count}}</b> engineering professionals who successfully completed AEP
                          Search for certification holders by entering below details.</p> -->
      <p class="display-5">Search for certification holders by entering below details.</p>
      <div class="col-sm-12">
        <form>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/userIcon.jpg" alt="">
                  <mat-label>First Name<span></span></mat-label>
                  <input matInput class="custom-form-control" name="firstName" [(ngModel)]="firstName"
                    placeholder="Enter Your First Name">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/userIcon.jpg" alt="">
                  <mat-label>Last Name<span></span></mat-label>
                  <input matInput class="custom-form-control" name="lastname" [(ngModel)]="lastname"
                    placeholder="Enter Your Last Name">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/emailIcon.jpg" alt="">
                  <mat-label>Email<span></span></mat-label>
                  <input matInput class="custom-form-control" id="mailformat" name="emailid" [(ngModel)]="emailid"
                    placeholder="Enter Your Last Name">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group clsdrop">
                <div class="">
                  <mat-form-field class="example-full-width px-3">
                    <mat-label>Status</mat-label>
                    <mat-select name="lastname" [(ngModel)]="Status">
                      <mat-option *ngFor="let Status of CertificationStatus" [value]="Status.certification_status_name">
                        {{Status.certification_status_name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group clsdrop">
                <mat-form-field class="example-full-width px-3">
                  <mat-label>From Date</mat-label>
                  <input matInput [matDatepicker]="picker" name="fromDate" [(ngModel)]="fromDate"
                    data-date-format='YYYY-MM-DD' value="fromDate" disabled>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group clsdrop">
                <mat-form-field class="example-full-width px-3">
                  <mat-label>To After</mat-label>
                  <input matInput [matDatepicker]="picker1" name="toDate" [(ngModel)]="toDate" value="toDate" disabled>
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>

          <p class="mt-3">* At least one of First Name, Last Name, Email, Status, From Date or To Date fields must be
            entered.
          </p>
          <div class="form-group mt-3 px-1">
            <div class="text-center mt-4">
              <button type="submit" (click)="searchResult()" class="btn custom-btn py-3">SEARCH</button>
            </div>
          </div>
        </form>
      </div>
    </div><br>
    <div class="col-md-12 d-block m-auto">
      <section class="">
        <div [hidden]="hideTable">
          <mat-paginator class="page-mg"
      #paginator
      [pageSizeOptions]="[10, 20, 30]"
      showFirstLastButtons
    ></mat-paginator>
          <div class="table-widtht arppt">
            <table mat-table [dataSource]="data" class="mat-elevation-z8 filter-header remove-header-color tblwidth">
              <ng-container matColumnDef="certificationid">
                <th class="header1" mat-header-cell *matHeaderCellDef> Certification Id </th>
                <td mat-cell *matCellDef="let element"> {{element.certification_exam_id_code}} </td>
              </ng-container>
              <ng-container matColumnDef="username">
                <th class="header1" mat-header-cell *matHeaderCellDef>Name
                </th>
                <td mat-cell *matCellDef="let element"> {{element.username}} </td>
              </ng-container>
              <ng-container matColumnDef="certification_status_name">
                <th class="header1" mat-header-cell *matHeaderCellDef>
                  Status
                </th>
                <td mat-cell *matCellDef="let element">
                  <span class="status">
                    <div class="progress">
                      <div class="progress-bar progress-bar-striped progress-bar-animated"
                        [ngClass]="element.certification_status_name === 'Completed' ? 'bg-primary' : 'bg-success'"
                        role="progressbar" style="width:100%">
                        <span>{{element.certification_status_name}}</span>
                      </div>
                    </div>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="certification_name">
                <th class="" mat-header-cell *matHeaderCellDef>
                  Certification Name
                </th>
                <td mat-cell *matCellDef="let element"> {{element.certification_name}} </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th class="header123" mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <button mat-icon-button id="schemeToggle" [style.overflow]="'visible'" matTooltip="View" class=""
                    (click)="funview(element)">
                    <mat-icon>pageview</mat-icon>
                  </button>
                  <button *ngIf="element.certification_status_name != 'Completed'" mat-icon-button id="schemeToggle"
                    [style.overflow]="'visible'" matTooltip="Edit" class="" (click)="funedit(element.order_id)">
                    <mat-icon>create</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div [hidden]="norecords" class="center-align">
              <h3>No Records to Display</h3>
            </div>
            <mat-paginator (page)="funSyncPaginator($event)" [pageSize]="paginator.pageSize"
              [pageIndex]="paginator.pageIndex" [length]="paginator.length" #paginator [pageSizeOptions]="[10, 20, 30]"
              showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </section>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
      <p style="color: white"> Loading... </p>
    </ngx-spinner>
  </section>
</mat-card>
<div [hidden]="detailedTab">
  <mat-card class="px-4 py-3 activeCourse large">
    <mat-card-header>
      <mat-card-title class="m-0 p-0" style="margin-left: -32px;">
        <!-- <span class="material-icons" (click)="stepBack()">keyboard_backspace</span> -->
        <button mat-stroked-button color="primary" (click)="stepBack()">Back</button>
        Participant Certification Details
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="col-md-12" *ngFor="let pdetails  of participantDetails">
        <div class="bordered">
          <h5 class="activeCourse-heading">{{pdetails.certification_name}}</h5>
          <div class="px-3">
            <div class="mb-4">
              <p><label>FirstName</label>{{pdetails.firstname}}</p>
              <p><label>LastName</label>{{pdetails.lastname}}</p>
              <p><label>Email</label>{{pdetails.email}}</p>
              <p><label>MobileNumber</label>{{pdetails.mobilenumber}}</p>
              <div *ngIf="pdetails.enrolled_date != null">
                <p><label>Enrolled Date</label>{{pdetails.enrolled_date}}</p>
              </div>
              <div *ngIf="pdetails.enrolled_date == null">
                <p><label>Enrolled Date</label>NA</p>
              </div>
              <div *ngIf="pdetails.course_examdate != null">
                <p><label>Planned Exam Date</label>{{pdetails.course_examdate}}</p>
              </div>
              <div *ngIf="pdetails.course_examdate == null">
                <p><label>Planned Exam Date</label>NA</p>
              </div>
              <div class="row">
                <div class="col-md-10">
                  <p><label>CBT Date</label></p>
                  <div class="col-sm-4">
                    <div class="form-group dropdowncls">
                      <div class="">
                        <mat-form-field class="example-full-width px-3">
                          <!-- <mat-label>CBT Date</mat-label> -->
                          <input matInput [matDatepicker]="picker" name="cbtDate" [(ngModel)]="cbtDate"
                            data-date-format='DD-MM-YYYY' [value]="cbtDate" disabled>
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker [disabled]="datedis"></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 mt-2" [hidden]="hidecbtUpdate">
                  <button mat-raised-button color="primary" (click)="updatecbtstatus()">Update</button>
                </div>
              </div>

              <div *ngIf="pdetails.submitted_date != null">
                <p><label>SBT/PBP Submission Date</label>{{pdetails.submitted_date}}</p>
              </div>
              <div *ngIf="pdetails.submitted_date == null">
                <p><label>SBT/PBP Submission Date</label>NA</p>
              </div>
              <div *ngIf="pdetails.completed_date!= null">
                <p><label>Certification Completion Date</label>{{pdetails.completed_date}}</p>
              </div>
              <div *ngIf="pdetails.completed_date == null">
                <p><label>Certification Completion Date</label>NA</p>
              </div>
              <div *ngIf="pdetails.file_location!= null ">
                <p><label>Uploaded Document</label> <a href="{{pdetails.file_location}}" target="_blank">Click here to
                    View</a></p>
              </div>
              <div *ngIf="pdetails.file_location == null ">
                <p><label>Uploaded Document</label>NA</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10">
                <p><label>Certification Status</label></p>
                <div class="col-sm-4">
                  <div class="form-group dropdowncls">
                    <div class="">
                      <mat-form-field class="example-full-width px-3">
                        <mat-label>Status</mat-label>
                        <mat-select name="orderStatus" [(ngModel)]="orderStatus" [disabled]="isdisabledstatus">
                          <mat-option *ngFor="let orderStatus of CertificationStatus1"
                            [value]="orderStatus.certification_status_id">
                            {{orderStatus.certification_status_name}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>
                  </div>
                </div>
                <div *ngIf="pdetails.certification_status_id == 2">
                  <button type="submit" class="btn custom-btn my-2" (click)="showCertificate()">View
                    Certificate</button>
                </div>
              </div>
              <div class="col-md-2 mt-2" [hidden]="hideUpdate">
                <button mat-raised-button color="primary" (click)="updateStatus()">Update</button>
              </div>
            </div>
            <div class="ActiveCourseDetails">
              <h6>ARP Details</h6>
              <div class="row px-3">
                <div class="col-md-6">
                  <p><label>ARP Name</label>{{pdetails.arp_name}}</p>
                </div>
                <div class="col-md-8">
                  <p><label>Contact Number</label>{{pdetails.arp_number}}</p>
                  <p><label>Email</label>{{pdetails.arp_email}}</p>
                </div>
              </div>
              <h6>TIP Centre Details</h6>
              <div class="row px-3">
                <div class="col-md-6">
                  <p><label>TIP Centre Name</label>{{pdetails.tipname}}</p>
                </div>
                <div class="col-md-8">
                  <p><label>First Name</label>{{pdetails.tipcontactname}}</p>
                  <p><label>Contact Number</label>{{pdetails.tip_mobile}}</p>
                  <p><label>Email</label>{{pdetails.tip_email}}</p>
                  <p><label>Website</label>{{pdetails.orgwebsite}}</p>
                  <p><label>Area</label>{{pdetails.orgaddress}}</p>
                  <p><label>City</label>{{pdetails.orgcity}}</p>
                  <p><label>District</label>{{pdetails.orgdistrict}}</p>
                </div>
              </div>
              <!-- <h6>view upload pdf</h6>
                            <div class="viewPDF">
                              <div>
                                <span class="editPDF">
                                  <a [hidden]="hideUpdate">
                                    <mat-icon>create</mat-icon>
                                  </a>
                                  <a>
                                    <mat-icon>pageview</mat-icon>
                                  </a>
                                </span>
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <a [href]= "pdetails.file_location" target="_blank" ></a>
                                <caption>samplepdffile.pdf</caption>
                              </div>
                              <div>
                                <span class="editPDF">
                                  <a [hidden]="hideUpdate">
                                    <mat-icon>create</mat-icon>
                                  </a>
                                  <a>
                                    <mat-icon>pageview</mat-icon>
                                  </a>
                                </span>
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <caption>samplepdffile55555555555555555555555555.pdf</caption>
                              </div>
                              <div>
                                <span class="editPDF">
                                  <a [hidden]="hideUpdate">
                                    <mat-icon>create</mat-icon>
                                  </a>
                                  <a>
                                    <mat-icon>pageview</mat-icon>
                                  </a>
                                </span>
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <caption>samplepdffile.pdf</caption>
                              </div>
                              <div>
                                <span class="editPDF">
                                  <a [hidden]="hideUpdate">
                                    <mat-icon>create</mat-icon>
                                  </a>
                                  <a>
                                    <mat-icon>pageview</mat-icon>
                                  </a>
                                </span>
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <caption>samplepdffile.pdf</caption>
                              </div>
                              <div>
                                <span class="editPDF">
                                  <a [hidden]="hideUpdate">
                                    <mat-icon>create</mat-icon>
                                  </a>
                                  <a>
                                    <mat-icon>pageview</mat-icon>
                                  </a>
                                </span>
                                <img src="../../../assets/img/pdfIcon.png" alt="pdf icon" />
                                <caption>samplepdffile.pdf</caption>
                              </div>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<!--  Certificate Code -->
<!-- Core Engineer Certificate popup for all levels -->
<div class="modal" *ngIf="showCoreCertificate">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center">View Certificate</h5>
        <button type="button" class="close" (click)="closeCertificate()">&times;</button>
      </div>
      <div class="modal-body" id="CoreData">
        <section class="coreEngineer">
          <div>
            <!-- <img src="../../../assets/img/downloadIcon.png" alt="downloadicon" class="download"
                                                  (click)="downloadCertificate('download')" /> -->
            <p>This is to certify that</p>
            <h3>{{obj1.firstname.toUpperCase()}}&nbsp;{{this.obj1.lastname.toUpperCase()}}</h3>
            <p>has been formally validated by AEP Certification for their demonstrated</p>
            <p>knowledge, skill and experience in achieving</p>
            <h1 *ngIf="oline">AEP CERTIFIED CORE ENGINEER-&nbsp;{{this.obj3.brachname}}&nbsp;ENGINEER IN MULTI DOMAIN
              PROGRAM &nbsp;&nbsp;-'{{this.obj3.level_name}}'&nbsp; LEVEL</h1>
            <h1 *ngIf="aline">AEP CERTIFIED CORE
              ENGINEER-&nbsp;{{this.obj3.brachname}}&nbsp;<span>IN</span>&nbsp;{{this.obj3.domain}}&nbsp;-'{{this.obj3.level_name}}'&nbsp;
              LEVEL</h1>
            <p *ngIf="oline">and is hereby deemed to be considered as qualified Entry Level Engineer</p>
            <p *ngIf="aline">and is hereby deemed to possess up to <span
                *ngIf="aline">{{this.obj2.yearsforlevel_a}}</span>&nbsp;industry equivalent experience in</p>
            <p *ngIf="aline"><span *ngIf="domainname">{{this.obj3.domain}}</span>&nbsp;domain/field.</p>
          </div>
          <!-- &nbsp;<span *ngIf ="softwaretool">with</span>&nbsp;
                                              <span *ngIf ="softwaretool">{{this.obj3.software_tool}}</span></p> -->
                                              <footer class="row">
                                                  <div class="col-md-5 footer-list">
                                                      <p><label class="ps-1">Certification ID :</label>{{this.obj3.certification_exam_id_code}}</p>
                                                      <p><label class="ps-2">Issue Date :</label>{{this.obj3.completed_date}}</p>
                                                      <!-- <p><label>Expiration Date :</label></p> -->
                                                  </div>
                                                  <div class="col-md-3">
                                                  <img src="../../../assets/img/Satya-Naggireddy-for-Exam-Director.png" alt="" class="directorsign" /> 
                                                      <p>Exam Director</p>
                                                      <span>(Satya Naggireddy)</span>
                                      
                                                  </div>
                                                  <div class="col-md-3 pl-0">
                                                      <img src="../../../assets/img/Siva-Karunakaram-for-CEO.png" alt="" class="executivesign" /> 
                                                      <p>Chief Executive Officer</p>
                                                      <span>(Siva Karunakaram)</span>
                                                      
                                                      
                                                  </div>
                                              </footer>
                                          </section>
                                      </div>
                                  </div>
                              </div>
                            </div>    
                            
                              <!-- Design Engineer Certificate popup  BASIC level-->
                              <div class="modal" *ngIf="showDesignCertificate">
                                  <div class="modal-dialog modal-lg">
                                      <div class="modal-content">
                                          <div class="modal-header">
                                              <h5 class="modal-title text-center">View Certificate</h5>
                                              <button type="button" class="close" (click)="closeCertificate()">&times;</button>
                                          </div>
                                          <div class="modal-body" id="designData">
                                              <section class="designEngineer">
                                                  <div>
                                                      <!-- <img src="../../../assets/img/downloadIcon.png" alt="downloadicon" class="download"
                                                      (click)="downloadCertificate('download')" /> -->
                                                  <p>This is to certify that</p>
                                                  <h3>{{obj1.firstname.toUpperCase()}}&nbsp;{{this.obj1.lastname.toUpperCase()}}</h3>
                                                  <p>has been formally validated by AEP Certification for their demonstrated</p>
                                                  <p>knowledge, skill and experience in achieving</p>
                                                  <h1 *ngIf = "basiclvl">AEP CERTIFIED DESIGN ENGINEER &nbsp;-{{this.obj3.brachname}}&nbsp; with&nbsp;{{ this.obj3.software_tool}}&nbsp;-'{{this.obj3.level_name}}'&nbsp; LEVEL</h1>
                                                  <h1 *ngIf = "associatelvl">AEP CERTIFIED DESIGN ENGINEER &nbsp;-{{this.obj3.brachname}}&nbsp; IN &nbsp;{{this.obj3.domain}}&nbsp;  with&nbsp;{{ this.obj3.software_tool}}&nbsp;-'{{this.obj3.level_name}}'&nbsp;LEVEL</h1>
                                                  <p *ngIf = "basiclvl">and is hereby deemed to be considered as qualified Entry Level Engineer</p>
                                                  <p *ngIf = "associatelvl">and is hereby deemed to possess up to&nbsp;<span *ngIf ="associatelvl" >{{this.obj2.yearsforlevel_associate}}</span> &nbsp; industry equivalent experience in  
                                                      <p *ngIf ="associatelvl"><span *ngIf ="domainname">{{this.obj3.domain}} domain/field</span>
                                                          &nbsp; <span *ngIf ="softwaretool">with</span>&nbsp;
                                                          <span *ngIf ="softwaretool">{{this.obj3.software_tool}}.</span></p>
                                                  </div>
                                                  <!-- <p><b>&lt;Domain Name&gt;</b> with <b>&lt;Tool Name&gt;</b></p> -->
                                                  <footer class="row">
                                                      <div class="col-md-5 footer-list">
                                                          <p style="margin-left: -6px;"><label class="ps-1">Certification ID :</label>{{this.obj3.certification_exam_id_code}}</p>
                                                          <p><label class="ps-2">Issue Date :</label>{{this.obj3.completed_date}}</p>
                                                          <!-- <p><label>Expiration Date :</label></p> -->
                                                      </div>
                                                      <div class="col-md-3">
                                                          <img src="../../../assets/img/Satya-Naggireddy-for-Exam-Director.png" alt="" class="directorsign" /> 
                                                          <p>Exam Director</p>
                                                          <span>Satya Naggireddy</span>
                                                          <!-- <span></span> -->
                                                          
                                                      </div>
                                                      <div class="col-md-3 pl -0">
                                                          <img src="../../../assets/img/Siva-Karunakaram-for-CEO.png" alt="" class="executivesign" /> 
                                                          <p>Chief Executive Officer</p>
                                                          <span>Siva Karunakaram</span>
                                                      </div>
                                                  </footer>
                                              </section>
                                          </div>
                                      </div>
                                  </div>
                              </div>                    <!-- Certificate Code Ends Here -->
                                  
