<header class="masthead my-3">
  <div class="container my-3">
    <div class="row">
      <div class="col-md-5" *ngIf="isSignin">
        <div class="text-center">
          <img src="../../assets/img/signInicon.jpg" alt="" class="signin">
          <h4 class="text-uppercase mb-3">SIGN IN</h4>
          <mat-radio-group aria-label="Select an option" [(ngModel)]="users">
            <mat-radio-button value="1" checked name="users" (change)="selectcheck1($event.value)">Participant
            </mat-radio-button>
            <mat-radio-button value="2" name="users" class="ml-4"  (change)="selectcheck2($event.value)">ARP
            </mat-radio-button>
            <mat-radio-button value="3" name="users" class="ml-4" [disabled]="ad" (change)="selectcheck3($event.value)">AEP Admin
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="users=='1'">
          <p class="text mb-3">Are you member of aepcrt.org? You can use same Email ID and Password to logn in.</p>
        </div>

        <form #f="ngForm">
          <div class="form-group aep-form mt-3">
            <mat-form-field class="example-full-width customForm">
              <img src="../../assets/img/userIcon.jpg" alt="">
              <mat-label>Email Id <span class="required-mark">*</span></mat-label>
              <input matInput class="custom-form-control" placeholder="Enter Email Id" name="inputAddress"
                [(ngModel)]="inputAddress" maxlength="50">
            </mat-form-field>
          </div>

          <div class="form-group aep-form">
            <mat-form-field class="example-full-width customForm">
              <img src="../../assets/img/passwordIcon.jpg" alt="">
              <mat-label>Password<span class="required-mark">*</span></mat-label>
              <input matInput type="password" class="custom-form-control" name="inputAddress2"
                placeholder="Enter Password" [(ngModel)]="inputAddress2">
            </mat-form-field>
          </div>

          <div class="form-group mt-2">
            <div class="form-check">
              <mat-checkbox class="form-check-label example-margin">Remember me</mat-checkbox>
              <p class="forgot" (click)="showForgotpage()">Forgot Password?</p>
            </div>
          </div>
          <div class="text-center mt-3">
            <button type="submit" class="btn custom-btn py-3"  (click)="onSubmitSignIn()">Sign In</button>
          </div>
          <div class="mt-3">
            <h6>NOT REGISTERED?</h6>
            If you don't have an account, you can sign up <a (click)="gotoSignin()">Here</a>
          </div>
        </form>
      </div>
      <div class="col-md-5" *ngIf="isForgot">
        <div class="text-center">
          <img src="../../assets/img/forgot.jpg" alt="" class="signin">
          <h4 class="text-uppercase mb-3">Forgot Password</h4>
        </div>
        <div>
          <p class="text mb-3">If your email address is linked to an account, we will send you an email to reset your
            password.</p>
        </div>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="users">
          <mat-radio-button value="1" checked name="users" (change)="selectcheck1($event.value)">Participant
          </mat-radio-button>
          <mat-radio-button value="2" name="users" class="ml-4" [disabled]="ar" (change)="selectcheck2($event.value)">ARP
          </mat-radio-button>
          <mat-radio-button value="3" name="users" class="ml-4" [disabled]="ad" (change)="selectcheck3($event.value)">AEP Admin
          </mat-radio-button>
        </mat-radio-group>
        <form #f="ngForm">
          <div class="form-group aep-form mt-4">
            <mat-form-field class="example-full-width customForm">
              <img src="../../assets/img/userIcon.jpg" alt="">
              <mat-label>Email Id <span class="required-mark">*</span></mat-label>
              <input matInput class="custom-form-control" placeholder="Enter Email Id" name="inputAddress4"
                [(ngModel)]="inputAddress4" maxlength="50">
            </mat-form-field>
          </div>
          <div class="text-center mt-5">
            <button type="submit" class="btn custom-btn py-3" (click)="onSubmitForgotPassword()">Submit</button>
          </div>
          <div class="mt-3">
            <span class="d-block text-center" (click)="showSigninpage()">Return to <a class="link">SIGN IN</a></span>
          </div>
        </form>
      </div>
      <div class="offset-2 col-md-4 pt-5 mt-3 mob-image-no">
        <img src="../../assets/img/signin.jpg" class="img-fluid">
      </div>
    </div>
  </div>
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
</header>
<!-- <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner> -->