<mat-card [hidden]="hideMain">
  <mat-card-header>
    <mat-card-title class="m-0 p-0">View Participants</mat-card-title>
</mat-card-header>
  <section class="page-section pb-4 mb-3" >
    <div class="container text-center mb-4">
      <!-- <p class="display-5">There are <b>{{count}}</b> engineering professionals who successfully completed AEP
                          Search for certification holders by entering below details.</p> -->
    <p class="display-5">Search for Participants by entering below details.</p>
      <div class="col-sm-12">
        <form>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/userIcon.jpg" alt="">
                  <mat-label>First Name<span></span></mat-label>
                  <input matInput class="custom-form-control" name="firstName" [(ngModel)]="firstName"
                    placeholder="Enter Your First Name">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/userIcon.jpg" alt="">
                  <mat-label>Last Name<span></span></mat-label>
                  <input matInput class="custom-form-control" name="lastname" [(ngModel)]="lastname"
                    placeholder="Enter Your Last Name">
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/emailIcon.jpg" alt="">
                  <mat-label>Email<span></span></mat-label>
                  <input matInput class="custom-form-control" id="mailformat" name="emailid" [(ngModel)]="emailid"
                    placeholder="Enter Your Last Name">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            
            <div class="col-sm-4">
              <div class="form-group clsdrop">
              <mat-form-field class="example-full-width px-3">
                <mat-label>Country</mat-label>
                <mat-select #countid name="country" [(ngModel)]="country_id"
                (selectionChange) = "getStates($event.value)">
                  <mat-option *ngFor="let Country of Countries" [value]="Country.country_id">
                    {{Country.country_name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group clsdrop">
            <mat-form-field class="example-full-width px-3">
                <mat-label>State</mat-label>
                <mat-select #countstateid name="prestat" [(ngModel)]="stateid"
                (selectionChange) = "getDistricts($event.value)">
                  <mat-option *ngFor="let State of States" [value]="State.stateid">
                    {{State.statename}}</mat-option>
                  </mat-select>
              </mat-form-field>
            </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group clsdrop">
              <mat-form-field class="example-full-width px-3">
                <mat-label>District</mat-label>
                <mat-select #countdistid name="predist" [(ngModel)]="districtid"
                (selectionChange) = "getCities($event.value)">
                  <mat-option *ngFor="let District of Districts" [value]="District.districtid">
                    {{District.districtname}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group clsdrop">
              <mat-form-field class="example-full-width px-3">
                <mat-label>City</mat-label>
                <mat-select #countcityid name="precity" [(ngModel)]="cityid">
                  <mat-option *ngFor="let City of Cities" [value]="City.cityid">
                    {{City.cityname}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          
          </div>
          
          

          <p class="mt-3">* At least one of First Name, Last Name, Email, Country,State,District or City must be
            entered.
          </p>
          <div class="form-group mt-3 px-1">
            <div class="text-center mt-4">
              <button type="submit" (click)="searchResult()" class="btn custom-btn py-3">SEARCH</button>
            </div>
          </div>
        </form>
      </div>
    </div><br>
    <div class="col-md-12 d-block m-auto">
      <section class="">
        <div [hidden]="hideTable">
          <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
          <div class="table-widtht">
            <table mat-table [dataSource]="data"  class="mat-elevation-z8 filter-header remove-header-color tblwidth">
              <ng-container matColumnDef="username">
                <th class="header1" mat-header-cell *matHeaderCellDef>Name </th>
                <td mat-cell *matCellDef="let element">{{element.username}}</td>
              </ng-container>
              <ng-container matColumnDef="Email">
                <th class="header1" mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
              </ng-container>
              <ng-container matColumnDef="contact number">
                <th class="header1" mat-header-cell *matHeaderCellDef>contact number</th>
                <td mat-cell *matCellDef="let element"> {{element.mobilenumber}} </td>
              </ng-container>
              <ng-container matColumnDef="certification user">
                <th class="header1" mat-header-cell *matHeaderCellDef>certification user</th>
                <td mat-cell *matCellDef="let element"> {{element.isnotaepcrt}} </td>
              </ng-container>
              
              
              <ng-container matColumnDef="action">
                <th class="header123"  mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <button mat-icon-button id="schemeToggle" [style.overflow]="'visible'" matTooltip="View" class=""
                    (click)="viewParticipantDetails(element.userid)">
                    <mat-icon>pageview</mat-icon>
                  </button>
                   <!-- <button *ngIf="element.certification_status_name != 'Completed'"
                  mat-icon-button id="schemeToggle" [style.overflow]="'visible'" matTooltip="Edit" class=""
                    (click)="funedit(element.order_id)">
                    <mat-icon>create</mat-icon>
                  </button> -->
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div [hidden]="norecords" class="center-align">
              <h3>No Records to Display</h3>
            </div>
            <mat-paginator (page)="funSyncPaginator($event)" [pageSize]="paginator.pageSize"
              [pageIndex]="paginator.pageIndex" [length]="paginator.length"
              #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
          </div>
        </div>
      </section>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
      <p style="color: white"> Loading... </p>
    </ngx-spinner>
  </section>
</mat-card>
<div [hidden]="detailedTab">
  <mat-card class="px-4 py-3 activeCourse large">
      <mat-card-header>
          <mat-card-title class="m-0 p-0" style="margin-left: -32px;">
              <!-- <span class="material-icons" (click)="stepBack()">keyboard_backspace</span> -->
              <button mat-stroked-button color="primary" (click)="stepBack()">Back</button>
              Participant Details
          </mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div class="col-md-12">
              <div class="bordered">
                  <h5 class="activeCourse-heading"></h5>
                  <div class="row px-3">
                      <div class="col-md-10 mb-4">
                          <p><label>FirstName :</label>{{this.objparti.firstname}}</p>
                          <p><label>LastName :</label>{{this.objparti.lastname}}</p>
                          <p><label>Email :</label>{{this.objparti.email}}</p>
                          <p><label>MobileNumber :</label>{{this.objparti.mobile}}</p>
                          <p><label>Address:</label>{{this.objparti.predoorno}}</p>
                          <p><label>Flat/Building :</label>{{this.objparti.preflat}}</p>
                          <p><label>Landmark :</label>{{this.objparti.prelandmark}}</p>
                          <p><label>Street :</label>{{this.objparti.prestreet}}</p>
                          <p><label>Area :</label>{{this.objparti.prearea}}</p>
                          <p><label>City :</label>{{this.objparti.precity}}</p>
                          <p><label>District :</label>{{this.objparti.predistrict}}</p>
                          <p><label>State :</label>{{this.objparti.prestate}}</p>
                          <p><label>Country :</label>{{this.objparti.precountry}}</p>
                          <p><label>Status :</label>{{this.objparti.uservalue}}</p>
                          <div class="row my-2 d-block text-center">
                             <button mat-flat-button color="primary" [disabled]="enable"  (click) = "userEnable()">Activate</button>
                          </div>
                          <div class="row my-2 d-block text-center">
                            <button mat-flat-button color="primary" [disabled]="disable" (click) = "userDisable()">Deactivate</button>
                         </div>

                        </div>
                      </div>
                  <div class="row px-3" [hidden]="coursetab">
                    <mat-card class="px-4 py-3 activeCourse large">
                      <mat-card-content>
                        <div class="col-md-12" *ngFor="let pdetails  of incoursedetails">
                            <div class="bordered">
                                <h5 class="activeCourse-heading">{{pdetails.certification_name}}</h5>
                                <div class="row px-3">
                                    <div class="col-md-10 mb-4">
                                        <p><label>Certification Id: </label>{{pdetails.certificationid}}</p>
                                        <p><label>certification code: </label>{{pdetails.certification_code}}</p>
                                        <p><label>certification status: </label>{{pdetails.certification_status_name}}</p>
                                       </div>
                                 </div>
                            </div>
                        </div>
                    </mat-card-content>
                    </mat-card>
                  </div>
              </div>
          </div>
      </mat-card-content>
  </mat-card>
</div>