<h4 class="heading container mx-auto d-block">Please refer the exam guide across levels for the certifications which you have already enrolled or you would like to enroll. Click on the certification level to view and download the exam guide.</h4>
<mat-card>
    <div class="headerstyling">
        <h4>Core Engineer</h4>
    </div>
    <div class="row py-3 mb-3 mpbpl3">
        <div class="col-md-3 mgb15">
            <a href="../../assets/pdf/Exam Guides/Core Engineer/O Level/AEP Certifified Core Engineer MDP O Level Exam Guide.pdf" target="_blank" >
            <div id="content" class="core">
                <div>
                    <h5>O Level</h5>
                    <br><br>
                    <!-- <p>MDP O Level Updated in G Drive (Exam Guides  Core Engineer  MDP O Level)</p> -->
                    <!-- <a target="_self" href="../../assets/pdf/Participant's NDA for AEP Certification Training and Exam Enrolment.pdf" download="Participant's NDA for AEP Certification Training and Exam Enrolment.pdf"></a> -->
                    
                    <mat-icon class="downloadIcon">save_alt</mat-icon>
                </div>
            </div>
        </a>
        </div>
        <div class="col-md-3 mgb15">
            <div id="content" class="core">
                <div>
                    <h5>A Level </h5>
                    <p>Will be updated soon</p>
                    <mat-icon class="downloadIcon">save_alt</mat-icon>
                </div>
            </div>
        </div>
        <div class="col-md-3 mgb15">
            <div id="content" class="core">
                <div>
                    <h5>B Level</h5>
                    <p>Will be updated soon</p>
                    <mat-icon class="downloadIcon">save_alt</mat-icon>
                </div>
            </div>
        </div>
        <div class="col-md-3 mgb15">
            <div id="content" class="core">
                <div>
                    <h5>C Level</h5>
                    <p>Will be updated soon</p>
                    <mat-icon class="downloadIcon">save_alt</mat-icon>
                </div>
            </div>
        </div>
    </div>
    <!-- <h4 class="ml-3 headerstyling">Design Engineer</h4> -->
    <div class="headerstyling2">
        <h4>Design Engineer</h4>
    </div>
    <div class="row py-3 mb-3 mpbpl3">
        <div class="col-md-3 mgb15">
            <a href="../../assets/pdf/Exam Guides/Design Engineer/Basic Level/AEP Certifified Design Engineer Mechanical - Basic Level Exam Guide.pdf" target="_blank" >
            <div id="content" class="design">
                <div>
                    <h5>Basic Level</h5>
                    <br><br>
                    <!-- <p>Mechanical (Exam Guides Design Engineer Basic Level Mechanical</p> -->
                    <mat-icon class="downloadIcon">save_alt</mat-icon>
                </div>
            </div>
        </a>
        </div>
        <div class="col-md-3 mgb15">
            <div id="content" class="design">
                <div>
                    <h5>Associate Level</h5>
                    <p>Will be updated soon</p>
                    <mat-icon class="downloadIcon">save_alt</mat-icon>
                </div>
            </div>
        </div>
        <div class="col-md-3 mgb15">
            <div id="content" class="design">
                <div>
                    <h5>Professional Level</h5>
                    <p>Will be updated soon</p>
                    <mat-icon class="downloadIcon">save_alt</mat-icon>
                </div>
            </div>
        </div>
        <div class="col-md-3 mgb15">
            <div id="content" class="design">
                <div>
                    <h5>Expert Level</h5>
                    <p>Will be updated soon</p>
                    <mat-icon class="downloadIcon">save_alt</mat-icon>
                </div>
            </div>
        </div>
    </div>
</mat-card>