// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiEndpoint: "http://183.82.98.147:5002/api/",
  // apiEndpoint: "http://api.aepcertification.com:8880/api/",
  // direct
   //  apiEndpoint: "http://localhost:49969/api/",

  apiEndpoint: "https://api.aepcertification.com/api/",
  
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
documentextensions: [
  "txt",
  "doc",
  "docx",
  "xlsx",
  "jpg",
  "jpeg",
  "png",
]