
import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ToasterService } from '../services/toastr.service';
import { ValidationService } from '../services/validation.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  name:any;
  email:any;
  contact:any;
  message:any;
  

  constructor(private ValidationService: ValidationService,private service :ApiService, public toast: ToasterService) { }


  ngOnInit(): void {
  }
  onSubmitContact()
  {
    if(this.function1()){
      return;
   }

   var obj1= {
    name: this.name,
    email:this.email,
    contactnumber:this.contact,
    message:this.message,
    }

    this.service.post(this.service.connectWithusEmail, obj1).subscribe((resp) => {
      // console.log(resp);

      if(resp==true){
        this.toast.showsuccess(this.ValidationService.msg_Validate_ContactusSuccess);
       }else{
        this.toast.showwarning(this.ValidationService.msg_Validate_warning);
       }
 
    })

  }
  function1(){
    if (this.ValidationService.funValidateEmptyText(this.name, this.ValidationService.msg_Validate_emptyName, '#name')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.email, this.ValidationService.msg_Validate_Email, '#email')) {
      return true;
    }
    if (this.ValidationService.funValidateEmailFormat(this.email, this.ValidationService.msg_Invalid_Email_Format, '#email')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.contact, this.ValidationService.msg_Validate_emptyMobileNumber, '#contact')) {
      return true;
    }
    if (this.ValidationService.funValidateMobileNumberFormat(this.contact, this.ValidationService.msg_Validate_mobile_number_format, '#contact')) {
      return true;
    }
    if (this.ValidationService.funValidateEmptyText(this.message, this.ValidationService.msg_Validate_emptyMessagefield, '#message')) {
      return true;
    }
  }
}

