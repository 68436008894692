<section class="page-section mt-5 py-5">
    <div class="container-fluid">
      <div class="row px-5">
        <div class="col-sm-7">
          <div class="text-center mb-5">
            <img src="../../assets/img/message.jpg" alt="" class="signin">
            <h3>Contact to partner with us.</h3>
          </div>
          <form>
            <div class="col-sm-12">
              <div class="row">
                  <div class="form-group aep-form col-sm-4">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/company.jpg" alt="">
                      <mat-label>Company Name<span class="required-mark">*</span></mat-label>
                      <input matInput class="custom-form-control" name="name" [(ngModel)]="name" placeholder="Company Name">
                    </mat-form-field>
                  </div>
                  <div class="form-group aep-form col-sm-4">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/userIcon.jpg" alt="">
                      <mat-label>Contact Person<span class="required-mark">*</span></mat-label>
                      <input matInput class="custom-form-control" name="name2" [(ngModel)]="name2" placeholder="Contact Person">
                    </mat-form-field>
                  </div>
                  <div class="form-group aep-form col-sm-4">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/contactph.jpg" alt="">
                      <mat-label>Contact Number<span class="required-mark">*</span></mat-label>
                      <input matInput class="custom-form-control" name="contact" [(ngModel)]="contact" placeholder="Contact Number">
                    </mat-form-field>
                  </div>
              </div>
              <div class="row">
                  <div class="form-group aep-form col-sm-6">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/website.jpg" alt="">
                      <mat-label>Company Website URL</mat-label>
                      <input matInput class="custom-form-control" name="website" [(ngModel)]="website" placeholder="Enter Company Website">
                    </mat-form-field>
                  </div>

                  <div class="form-group aep-form col-sm-6">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/emailIcon.jpg" alt="">
                      <mat-label>Email Id<span class="required-mark">*</span></mat-label>
                      <input matInput class="custom-form-control" name="email" [(ngModel)]="email" placeholder="Enter Email id">
                    </mat-form-field>
                  </div>
              </div>
              <div class="row">
                    <div class="form-group aep-form col-sm-4">
                      <mat-form-field class="example-full-width customForm">
                        <img src="../../assets/img/location.jpg" alt="">
                        <mat-label>City<span class="required-mark">*</span></mat-label>
                        <input matInput class="custom-form-control" name="city" [(ngModel)]="city" placeholder="City Name">
                      </mat-form-field>
                    </div>
                  <div class="form-group aep-form col-sm-4">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/location.jpg" alt="">
                      <mat-label>State<span class="required-mark">*</span></mat-label>
                      <input matInput class="custom-form-control" name="state" [(ngModel)]="state" placeholder="State Name">
                    </mat-form-field>
                  </div>
                  <div class="form-group aep-form col-sm-4">
                    <mat-form-field class="example-full-width customForm">
                      <img src="../../assets/img/location.jpg" alt="">
                      <mat-label>Country<span class="required-mark">*</span></mat-label>
                      <input matInput class="custom-form-control" name="country" [(ngModel)]="country" placeholder="Country Name">
                    </mat-form-field>
                  </div>
              </div>
              <div class="form-group mt-3 px-1">
                <label for="inputAddress">Message<span class="danger">*</span></label>
                <textarea name="message"  rows="4" maxlength="500" class="form-control" [(ngModel)]="message" required>
                </textarea>
                <span class="alertmessage" *ngIf="message?.length >= 500">
                    Only 500 characters are accepted
              </span>
                
                <div class="text-center mt-4">
                  <button type="submit" class="btn custom-btn py-3" (click)="onSubmitContact()">Submit</button>
                </div>
              </div>
            </div>
                
          </form>
        </div>
        <div class="col-sm-5 mt-3 text-right">
          <img src="../../assets/img/contactPartners.jpg" class="img-fluid mt-5" alt="">
        </div>
      </div>
    </div>
  </section>