<header class="masthead">
    <img src="./assets/img/who-we-are.png" class="img-fluid w-vw">
</header>
<section class="page-section py-5 text-center">
    <div class="container">
        <div class="row">
            <h3 class="w-100">Who We Are?</h3>
            <p class="display-5">AEP Certification is an organization formed by a group of industry professionals, offers a patent competency and experience benchmarking
                model for engineering graduates and working professionals
                across streams through a customized curriculum designed by industry professionals.
                We collaborate with potential stakeholders with authorized industrial training background as Associate Representative Partners (ARPs)
                to impart and execute AEP Certification and corresponding training  on the programs installed by industries and professionals.
                </p>
        </div>
    </div>
</section>
<section class="page-section py-5 mb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="vision">
                    <img src="./assets/img/vision1.jpg" alt="">
                    <h4 class="ml-4">Vision</h4>
                    Create an ecosystem through building Role-Skill-Experience baselined engineering professionals across industries.
                </div>
            </div>
            <div class="col-md-4">
                <div class="vision">
                    <img src="./assets/img/vision2.jpg" alt="">
                    <h4 class="ml-4">Mission</h4>
                    Strive to bridge the gap between Industry expectation and Engineering professionals. Make AEP Certification deemed for Industry Entry Level Engineers.
                </div>
            </div>
            <div class="col-md-4">
                <div class="vision">
                    <img src="./assets/img/vision3.jpg" alt="">
                    <h4 class="ml-4">Strategies</h4>
                    Authorized and predefined unique curriculum, training and certification programs conducted by industry professionals and research experts.
                </div>
            </div>
        </div>
    </div>
</section>