

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';

import { NgxSpinnerService } from 'ngx-spinner';

import { ApiService } from '../../../services/api.service';
import { ToasterService } from '../../../services/toastr.service';
import { ValidationService } from '../../../services/validation.service';
import { strict } from 'assert';

interface Stat {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-searcharpcertification',
  templateUrl: './searcharpcertification.component.html',
  styleUrls: ['./searcharpcertification.component.css'],
  providers: [DatePipe]
})
export class SearcharpcertificationComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  count = 100;
  certificateid: '';
  firstName: '';
  lastname: '';
  emailid: '';
  mailformat: '';
  hideTable = true;
  norecords = true;
  data;
  Status = '';
  fromDate;
  cbtDate;
  toDate = null;
  hideMain: boolean;
  hideUpdate: boolean;
  hidecbtUpdate: boolean;
  datedis: boolean;
  detailedTab = true;
  participantDetails = []
  CertificationStatus = []
  CertificationStatus1 = []
  orderStatus = "";
  isdisabledstatus: boolean;
  uobj={
    firstname:'',
    lastname:'',    
    certification_name:'',
    exam_type:'',
    level_name:'',
    delivery_method:'',
    certification_code:'',
    course_duration:'',
    software_tool:'',
    email:''
  }
  displayedColumns: string[] = ['certificationid', 'username', 'certification_name', 'certification_status_name', 'action']

    // CERTIFICATE

    public completedcourse: boolean = true;
  public completedcoursedetails: boolean = false;
  public coursesdata = [];
  public coursedata2 =[];
  public showCoreCertificate: boolean = false;
  public showDesignCertificate: boolean = false;
  
  fileFormatIsWrong:boolean=false;
  orderid:any;
  userid:any;
  order_id:any;

  obj1={
    firstname:'',
    lastname:'',
  }

  obj2={
    yearsforlevel_a :"3 years",
    yearsforlevel_associate :"3 years",
  }

  obj3={
    domain:'',
    certification_name:'',
    level_name:'',
    software_tool: '',
    brachname:'',
    certification_id:'',
    completed_date:'',
    certification_exam_id_code:'',
  }

  
oline:boolean=false;
aline:boolean=false;
softwaretool:boolean=false;
domainname:boolean=false;
basiclvl:boolean=false;
associatelvl:boolean=false;


  constructor(private router: Router, private service: ApiService, private ValidationService: ValidationService,
    public toast: ToasterService, private spinner: NgxSpinnerService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.clearFields()
    this.getCertificationStatus()
  }

  clearFields() {
    this.firstName = "",
      this.emailid = "",
      this.lastname = "",
      this.Status = "",
      this.fromDate = null,
      this.cbtDate = null,
      this.toDate = null,
      this.orderStatus = ""
      this.hideTable = true;
      var data = []
      this.data = new MatTableDataSource(data)
      this.data.paginator = this.paginator;
  }

  getCertificationStatus() {
    this.service.get(this.service.getCertificationStatus).subscribe(resp => {
      
      this.CertificationStatus = resp
      // this.CertificationStatus1 = resp.filter((x)=>x.certification_status_id==2)
    })
  }

  searchResult() {
    this.spinner.show()
    if (this.firstName == "" && this.emailid == "" && this.lastname == "" && this.Status == "" && this.fromDate == null &&
      this.toDate == null) {
      this.toast.showwarning(this.ValidationService.msg_Validate_empty)
      this.hideTable = true;
      this.norecords = true;
      this.data = []
      this.spinner.hide()
    } else {
      if (this.emailid != undefined && this.emailid != "") {
        if (this.funValidate()) {
          this.spinner.hide()
          return;
        }
      }
      var certiobj = {
        certificatestatus: this.Status,
        first_name: this.firstName,
        last_name: this.lastname,
        user_email: this.emailid,
        fromdate: (this.fromDate == "") ? null : (this.datePipe.transform(this.fromDate, "yyyy-MM-dd")),
        todate: (this.fromDate == "") ? null : (this.datePipe.transform(this.toDate, "yyyy-MM-dd")),
      }
      this.service.post(this.service.getarpCertificationdata, certiobj).subscribe(resp => {
        this.spinner.hide()
        // console.log(resp)
        if (resp != [] && resp.length != 0) {
          this.hideTable = false;
          this.norecords = true;
          this.data = new MatTableDataSource(resp)
          this.data.paginator = this.paginator;
        } else {
          this.hideTable = false;
          this.norecords = false;
          resp = []
          this.data = new MatTableDataSource(resp)
          this.data.paginator = this.paginator;
        }
      })
    }
  }

  funedit(id) {
    this.getStatus1();
    this.spinner.show()
    localStorage.setItem("order_id", id)
    this.hideMain = true;
    this.detailedTab = false;
    this.hideUpdate = false;
    this.hidecbtUpdate = false;
    this.datedis=false;
    this.isdisabledstatus = false;
    this.service.get(this.service.getdetailsbyOrderID + id).subscribe(res => {
      this.spinner.hide()
      if (res != []) {
        this.participantDetails = res;
        this.orderStatus = JSON.parse(res[0].certification_status_id);
      }
    })
  }
  getStatus1() {
    this.service.get(this.service.getCertificationStatus).subscribe(resp => {
      
      // this.CertificationStatus1 = resp
       this.CertificationStatus1 = resp.filter((x)=>x.certification_status_id==2)
    })
  }

  

  funview(data) {
    this.order_id = data.order_id;
    this.userid= data.userid;
    this.getStatus();
    this.showCourses();
    this.spinner.show()
    this.hideMain = true;
    this.detailedTab = false;
    this.hideUpdate = true;
    this.hidecbtUpdate = true;
    this.datedis=true;
    this.isdisabledstatus = true;
    this.service.get(this.service.getdetailsbyOrderID + this.order_id).subscribe(res => {
      this.spinner.hide()
      if (res != []) {
        this.participantDetails = res;
        this.orderStatus = res[0].certification_status_id;
        this.cbtDate = res[0].cbt_date;
      }
     
    })
  }
  getStatus() {
    this.service.get(this.service.getCertificationStatus).subscribe(resp => {
      
      this.CertificationStatus1 = resp
      // this.CertificationStatus1 = resp.filter((x)=>x.certification_status_id==2)
    })
  }

  // getdetails(){
  //   var id=Number(localStorage.getItem("order_id"))
  //   this.service.get(this.service.getdetailsbyOrderID + id).subscribe(res => {
  //     this.uobj.firstname=res[0].firstname;
  //     this.uobj.lastname=res[0].lastname;  
  //     this.uobj.certification_name=res[0].certification_name;
  //     this.uobj.exam_type=res[0].exam_type;
  //     this.uobj.level_name=res[0].level_name;
  //     this.uobj.delivery_method=res[0].delivery_method;
  //     this.uobj.certification_code=res[0].certification_code;
  //     this.uobj.course_duration=res[0].course_duration;
  //     // this.uobj.software_tool=res[0].software_tool; 
  //     if(this.uobj.level_name=='O'||this.uobj.level_name=='A'){
  //       this.uobj.software_tool='Software tool is not applicable'
  //     }else{
  //       this.uobj.software_tool=res[0].software_tool;  
  //     }
  //     this.uobj.email=res[0].email;     
                      
  //   })
  // }
tool:any;
domain:any;
odrid:any;
a:any;
subdate:any;
cbdate:any;

  updateStatus() {
    var a=this.participantDetails[0].certification_name.replace(/"/g,' ');
    Number(localStorage.getItem('order_id'))
    this.spinner.show()
    if(this.participantDetails[0].level_name=='O-MDP'||this.participantDetails[0].level_name=='A' ){
      this.tool='Software tool is not applicable'
    }else{
          this.tool=this.participantDetails[0].software_tool;  
    }

    if(this.participantDetails[0].level_name=='BASIC-MDP'||this.participantDetails[0].level_name=='ASSOCIATE' ){
      this.domain='Domain is not applicable'
    }else{
          this.domain=this.participantDetails[0].domain_type;  
    }

    if(this.participantDetails[0].submitted_date != null){
      this.subdate = (this.participantDetails[0].submitted_date).substr(8,2) + "-" +(this.participantDetails[0].submitted_date).substr(5,2) +"-" +(this.participantDetails[0].submitted_date).substr(0,4) ;
    }
    else{
      this.subdate = 'NA'
      }

      if(this.participantDetails[0].cbt_date != null){
        this.cbdate  = (this.participantDetails[0].cbt_date).substr(8,2) + "-" +(this.participantDetails[0].cbt_date).substr(5,2) +"-" +(this.participantDetails[0].cbt_date).substr(0,4) ;
      }
      else{
        this.cbdate = 'NA'
        }

  
      var obj1 = {
        orderid: Number(localStorage.getItem('order_id')),
        certificatestatusid: this.orderStatus,
        certification_name:a,
        certification_exam_id_code:this.participantDetails[0].certification_exam_id_code,
        submitted_date:this.subdate,
        cbt_date:this.cbdate,
        domain_type: this.domain,
        participant_mail:this.participantDetails[0].email,
        lname:this.participantDetails[0].lastname,
        fname:this.participantDetails[0].firstname,
        softwaretool:this.tool,
        code:this.participantDetails[0].certification_code ,
        type: this.participantDetails[0].exam_type,
        level:this.participantDetails[0].level_name ,
        duration:this.participantDetails[0].exam_duration,
        delivery:this.participantDetails[0].delivery_method,
        // completed_date: "2020-10-27T11:26:20.680Z"
      }
      this.service.post(this.service.updateCertificateStatus, obj1).subscribe(resp => {
        this.spinner.hide()
        // if (resp!=0 || resp!=null) {
          if (resp!=null) {
            if(resp = true){
          this.toast.showsuccess(this.ValidationService.msg_Validate_certSuccess)
          this.clearFields()
          this.detailedTab = true;
          this.hideMain = false;
         }
        else{
          this.toast.showwarning(this.ValidationService.msg_validate_certfail);
        }
      }
      })           
    
  }


  updatecbtstatus(){
    if (this.cbtDate == null) {
      this.toast.showwarning(this.ValidationService.msg_Validate_cbtDateempty)
      return;
      }
      this.spinner.show()
    var obj = {
      orderid: Number(localStorage.getItem("order_id")),
      cbtdate: (this.cbtDate == "") ? null : (this.datePipe.transform(this.cbtDate, "yyyy-MM-dd")),
    }
    this.service.post(this.service.postCBTdate, obj).subscribe(resp => {
      this.spinner.hide()
      if (resp == 1) {
        this.toast.showsuccess(this.ValidationService.msg_Validate_cbtDateUpdSuccess)
        this.detailedTab = true;
        this.hideMain = false;
      }
    })
  }

// CERTIFICATE CODE

showCourses(){
  this.spinner.show()
  this.completedcoursedetails = false;
  this.completedcourse = true;
  this.coursesdata=[];
  this.service.get(this.service.getOrderSummaryByUser +this.userid).subscribe((res) => {
   this.spinner.hide()
   for(let i=0;i<res.length;i++){
    if(res[i].certification_status_name =='Completed' && res[i].trasaction_status=='success' && res[i].order_id==this.order_id){
      this.coursesdata.push({
      userid                    :this.userid ,
      order_id                  : res[i].order_id,
      certificationid           : res[i].certificationid,
      certification_code        : res[i].certification_code,
      course_examdate           : res[i].course_examdate,
      slot_range                : res[i].slot_range ,
      certification_name        : res[i].certification_name, 
      exam_type                 : res[i].exam_type,
      certification_cost        : res[i].certification_cost,
      exam_duration             : res[i].exam_duration,
      course_level_id           : res[i].course_level_id,
      certification_status_name : res[i].certification_status_name,
      level_name                : res[i].level_name,
      completed_date            : res[i].completed_date,
    })
   }
   }
 })

}

public showCertificate(){
  this.spinner.show()
  var leveltype =this.coursesdata[0].level_name;
  var u=this.coursesdata[0].userid;
  var o=this.coursesdata[0].order_id;
  var c=this.coursesdata[0].certificationid;
  var l=this.coursesdata[0].level_name;
  var clid=this.coursesdata[0].course_level_id;

  if(l=='O-MDP'){
   this.oline=true;
   this.aline=false;
  }else if( l=='A'){
    this.oline=false;
    this.aline=true;
  }
  if(l=='BASIC-MDP'){
    this.basiclvl=true;
    this.associatelvl=false;
   }else if( l=='ASSOCIATE'){
    this.basiclvl=false;
    this.associatelvl=true;
   }
  this.service.get(this.service.getOrderSummaryByUser +u).subscribe((res) => {
    if(res !=null){
      for(let i=0;i<res.length;i++){ 
        if(c==res[i].certificationid){
        this.obj3.level_name = res[i].level_name;
        this.obj3.completed_date = res[i].completed_date;
        this.obj3.certification_exam_id_code = res[i].certification_exam_id_code;
        }
      }
    }
  })

  this.service.get(this.service.getUsers + u).subscribe((resp) => {
    // console.log(resp);
    for(let i=0;i<resp.length;i++){ 
      this.obj1.firstname = resp[i].firstname;
      this.obj1.lastname =resp[i].lastname;
    }
  })
  
  this.service.get(this.service.getcertificationcoursesbycourselevelid + clid).subscribe((res) => {
    this.spinner.hide()
    if(res!=null){
      for(let i=0;i<res.length;i++){
        if(c==res[i].certification_id){
          this.obj3.certification_id=res[i].certification_id,
          this.obj3.domain=res[i].domain_type;
          this.obj3.certification_name =res[i].certification_name;
          this.obj3.software_tool=res[i].software_tool;
          if(this.obj3.brachname !=null){
          this.obj3.brachname=res[i].brachname;
          }
        }
      }
    }
  })

  if(this.obj3.domain != null){
   this.domainname =true;
  }else{
    this.domainname =false; 
  }
  if(this.obj3.software_tool != null){
    this.softwaretool =true;
  }else{
     this.softwaretool =false; 
  }
    if(leveltype === 'O-MDP' || leveltype ==='A'){
      this.showCoreCertificate = true;
    }else if(leveltype === 'ASSOCIATE' || leveltype === 'BASIC-MDP' ){
      this.showDesignCertificate = true;
    }
  // this.cdr.detectChanges();
}

public closeCertificate(){ 
  this.showCoreCertificate = false;
  this.showDesignCertificate = false;
}


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  funValidate() {
    if (this.ValidationService.funValidateEmailFormat(this.emailid,
      this.ValidationService.msg_Invalid_Email_Format, '#mailformat')) {
      return true;
    }
  }

  stepBack() {
    this.detailedTab = true;
    this.hideMain = false;
    this.cbtDate = null;
  }

  // FOR PAGINATOR
  funSyncPaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }
}