<mat-card class="p-4">
    <h5>ARP Empanelment</h5>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Company name<span class="required-mark">*</span></mat-label>
                <input matInput  placeholder="Enter your Company Name" name="companyname" [(ngModel)]="companyname">
            </mat-form-field>
        </div>
        <div class="col-sm-4">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Contact person name<span class="required-mark">*</span></mat-label>
                <input matInput  name="ContactPersonName" [(ngModel)]="ContactPersonName" placeholder="Enter Contact Person Name">
            </mat-form-field>
        </div>
        <div class="col-sm-4 pr-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Contact number<span class="required-mark">*</span></mat-label>
                <input matInput  name="Mobile" [(ngModel)]="Mobile" placeholder="Enter your Mobile">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Country Code<span class="required-mark">*</span></mat-label>
                <input matInput  name="CountryCode" [(ngModel)]="CountryCode" placeholder="Enter your Country Code">
            </mat-form-field>
        </div>
        <div class="col-sm-4">
            <mat-form-field class="example-full-width px-3">
                <mat-label>GST Code<span class="required-mark">*</span></mat-label>
                <input matInput name="gstCode" [(ngModel)]="gstCode" placeholder="Enter GST Code">
            </mat-form-field>
        </div>
        <div class="col-sm-4 pr-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>PAN number/ any applicable UID<span class="required-mark">*</span></mat-label>
                <input matInput name="panoruid" [(ngModel)]="panoruid" placeholder="Enter Number pan or uid">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Company Email id<span class="required-mark">*</span></mat-label>
                <input matInput name="emailid" [(ngModel)]="emailid"  placeholder="Enter company Email id">
            </mat-form-field>
        </div>
        <div class="col-sm-4">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Password<span class="required-mark">*</span></mat-label>
                <input matInput name="password" [(ngModel)]="password" [disabled]="pswd1" placeholder="Enter your password">
                
            </mat-form-field>
            <button mat-raised-button color="primary" [disabled]="pswd2"  (click)="generateArpPassword()">Generate Password</button>
        </div>
        <div class="col-sm-4 pr-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>ARP Email id<span class="required-mark">*</span></mat-label>
                <input matInput name="arpemailid" [(ngModel)]="arpemailid"  placeholder="Enter ARP Email id">
            </mat-form-field>
        </div>
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Website</mat-label>
                <input matInput name="Website" [(ngModel)]="Website"  placeholder="Enter Website">
            </mat-form-field>
        </div>
        
       
    </div>
    <h6>Office Address</h6>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Door No<span class="required-mark">*</span></mat-label>
                <input matInput name="Doorno" [(ngModel)]="Doorno" placeholder="Enter your door number">
            </mat-form-field>
        </div>
        <div class="col-sm-4">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Landmark</mat-label>
                <input matInput name="LandMark" [(ngModel)]="LandMark" placeholder="Enter your Landmark">
            </mat-form-field>
        </div>
        <div class="col-sm-4 pr-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Street</mat-label>
                <input matInput name="Street" [(ngModel)]="Street" placeholder="Enter your street">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Area<span class="required-mark">*</span></mat-label>
                <input matInput name="Area" [(ngModel)]="Area" placeholder="Enter Area">
            </mat-form-field>
        </div>
        <div class="col-sm-4">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Country<span class="required-mark">*</span></mat-label>
                <mat-select [(ngModel)]="country" name="country" (selectionChange) = "getStates($event.value)">
                  <mat-option *ngFor="let sCountry of Countries" [value]="sCountry .country_id">
                    {{sCountry .country_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-sm-4 pr-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>State<span class="required-mark">*</span></mat-label>
                <mat-select [(ngModel)]="state" name="state" (selectionChange) = "getDistricts($event.value)">
                    <mat-option *ngFor="let State of States" [value]="State.stateid">
                        {{State.statename}}
                    </mat-option>
                  </mat-select>
            </mat-form-field>
        </div>
        
    </div>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Select district<span class="required-mark">*</span></mat-label>
                <mat-select [(ngModel)]="district" name="district"  (selectionChange) = "getCities($event.value)">
                  <mat-option *ngFor="let District of Districts" [value]="District.districtid">
                    {{District.districtname}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-sm-4">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Select city<span class="required-mark">*</span></mat-label>
                <mat-select [(ngModel)]="city" name="city">
                  <mat-option *ngFor="let City of Cities" [value]="City.cityid">
                    {{City.cityname}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-sm-4 pr-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Pincode<span class="required-mark">*</span></mat-label>
                <input matInput name="PinCode" [(ngModel)]="PinCode" placeholder="Enter your pin code">
            </mat-form-field>
        </div>
    </div>
    <h6>Bank Account Details</h6>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Account number<span class="required-mark">*</span></mat-label>
                <input matInput name="AccountNumber" [(ngModel)]="AccountNumber" placeholder="Enter your Account Number">
            </mat-form-field>
        </div>
        <div class="col-sm-4" [hidden]='ifsc'>
            <mat-form-field class="example-full-width px-3">
                <mat-label>IFSC Code<span class="required-mark">*</span></mat-label>
                <input matInput name="IFSCCode" [(ngModel)]="IFSCCode" placeholder="Enter IFSC Code">
            </mat-form-field>
        </div>
        <!-- <div class="col-sm-4" *ngIf="country=='India'">
            <mat-form-field class="example-full-width px-3">
                <mat-label>IFSC Code<span class="required-mark">*</span></mat-label>
                <input matInput name="IFSCCode" [(ngModel)]="IFSCCode" placeholder="Enter IFSC Code">
            </mat-form-field>
        </div> -->
        <!-- <div class="col-sm-4 pr-0 pl-3">
                <label>Upload Cancelled Cheques<span class="required-mark">*</span></label>
                <input [(ngModel)]="cancelledcheques" type="file">
        </div> -->
        
        <!-- <div class="row">
        <h6 class="h6-s">Upload cancelled cheque</h6>
        <div class="col-sm-4 pr-0 pl-3">
            <div class="input-group col-md-12">
                <input id="docs" name="filepath" readonly="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="filepath"
                placeholder="upload a pdf file only" type="text" class="form-control square">
                <label class="input-group-btn upload-col">
                    <span class="btn inform-btn btn-raised btn-primary square"> Browse
                        <input type="file" (change)="getFiles($event)" style="display: none;" value="">
                    </span>
                </label>
               <div class="text-center mt-5 btn-style">
                        <button type="submit" class="btn custom-btn up-s py-3"  (click)="uploadDocument()">upload</button>
               </div>
            </div> 
        </div>
    </div> -->
    </div>
    <!-- <div class="m-auto my-2 text-center">
        <button type ="button" class="btn-color"  (click)="saveArpNewUser()">Next</button>
    </div> -->
    <div class="row">
        
        <h6 class="h6-s">Upload GST Certificate PAN/UID, Cancelled Cheque</h6>
    <div class="col-sm-8 pr-0 pl-3">
        <div class="input-group col-md-8">
        <input id="docs" name="filepath" class="txt-st" readonly="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="filepath"
        placeholder="upload a pdf file only" type="text" class="form-control square file-s">
        <label class="input-group-btn upload-col">
            <span class="btn inform-btn btn-raised btn-primary square">Browse
                <input type="file" (change)="getFiles($event)" style="display: none;" value="">
            </span>
        </label>
    </div> 
    <span class="required-note2"  >Note:Please Upload GST Certificate PAN/UID, Cancelled Cheque.</span>
</div>
</div>
<div class="row btn-d">
    <div class="m-auto my-2 text-center">
        <!-- <button type ="button" class="btn-color"  (click)="saveArpNewUser()">Enpanell</button>
         -->
         <button mat-raised-button color="primary"   (click)="saveArpNewUser()">Empanel</button>
    </div>
</div>
   

</mat-card>
