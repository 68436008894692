<header class="masthead my-3">
  <div class="container my-3">
    <div class="row">
      <div class="col-md-7">
        <div class="text-center">
          <img src="../../assets/img/enrolment.jpg" alt="" class="signin">
          <h4 class="text-uppercase">SIGN UP</h4>
          Already have an account? Sign in <a (click)="gotoSignin()">Here</a>
          <p class="text my-3">Please register for AEP Certification in order to enroll for certifications,
            trainings, and leverage extended services and more.</p>
        </div>
        <form #f="ngForm">
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/userIcon.jpg" alt="">
                  <mat-label>First Name<span class="required-mark">*</span></mat-label>
                  <input matInput class="custom-form-control" name="firstname" placeholder="Enter Your First Name" [(ngModel)]="firstname">
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/emailIcon.jpg" alt="">
                  <mat-label>Email Id<span class="required-mark">*</span></mat-label>
                  <input matInput class="custom-form-control" name="emailid" placeholder="Enter your Active Email Id" [(ngModel)]="emailid">
                </mat-form-field>
              </div>
              <span class="required-note">All notifications from AEP Certification will be sent to this Email Id.</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/passwordIcon.jpg" alt="">
                  <mat-label>Password<span class="required-mark">*</span></mat-label>
                  <input matInput type="password" class="custom-form-control" placeholder="Enter Password" name="password" [(ngModel)]="password">
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group aep-form">
                <mat-form-field class="example-full-width customForm">
                  <img src="../../assets/img/passwordIcon.jpg" alt="">
                  <mat-label>Confirm Password<span class="required-mark">*</span></mat-label>
                  <input matInput type="password" class="custom-form-control" placeholder="Enter Confirm Password" name="confirmpassword" [(ngModel)]="confirmpassword">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form-group mob-form-group">
            <mat-checkbox class="form-check-label example-margin" [(ngModel)]="checkbox" name="checkbox">I have read and accept the
              <a href="../../assets/pdf/AEP Certification Privacy Policy.pdf" target="_blank">
                Privacy Policy
              </a>
              and
              <a href="../../assets/pdf/AEP Certification Terms of Service.pdf" target="_blank">
                Terms Of Service
              </a>
            </mat-checkbox>
          </div>
      <div class="text-center mt-1">
        <button type="submit" class="btn custom-btn py-3" (click)="submit()">Submit</button>
      </div>
      </form>
    </div>
    <div class="offset-1 col-md-4 pt-5 mt-3 mob-image-no">
      <img src="../../assets/img/signup.jpg" class="img-fluid">
    </div>
  </div>
  </div>
</header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

