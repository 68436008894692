import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { CertificationofferingsComponent } from './certificationofferings/certificationofferings.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { PartnersComponent } from './partners/partners.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { CareersComponent } from './about/careers/careers.component';
import { ExtendedservicesComponent } from './about/extendedservices/extendedservices.component';
import { ProgramoverviewComponent } from './about/programoverview/programoverview.component';
import { WhoweareComponent } from './about/whoweare/whoweare.component';
import { AuthorizedindustryComponent } from './partners/authorizedindustry/authorizedindustry.component';
import { CourseComponent } from './partners/course/course.component';
import { RepresentativeComponent } from './partners/representative/representative.component';
import { TraininginstituteComponent } from './partners/traininginstitute/traininginstitute.component';
import { ConnectpartnersComponent } from './connectpartners/connectpartners.component';
import { UpdatearppasswordComponent } from './updatearppassword/updatearppassword.component';
import { AuthorizationComponent } from './authorization/authorization.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { EmailverifiedComponent } from './emailverified/emailverified.component';
import { AuthGuard } from './auth.guard';
import { CertificateComponent } from './certificationofferings/certificate/certificate.component';
import { SearchaepcertificationComponent } from './certificationofferings/searchaepcertification/searchaepcertification.component';

const routes: Routes = [
  { path: 'aep',
    component : LayoutComponent,
    children: [
      
      { path: 'home', component: HomeComponent },
      { path: 'about', component: AboutComponent },
      { path: 'about/whoweare', component: WhoweareComponent },
      { path: 'about/programoverview', component: ProgramoverviewComponent },
      { path: 'about/extendedservice', component: ExtendedservicesComponent },
      { path: 'about/careers', component: CareersComponent },
      { path: 'certificationofferings', component: CertificationofferingsComponent },
      { path: 'certificationofferings/certificate', component: CertificateComponent },
      { path: 'certificationofferings/searchaepcertification', component: SearchaepcertificationComponent },
      { path: 'partners', component: PartnersComponent },
      { path: 'partners/representative', component: RepresentativeComponent },
      { path: 'partners/traininginstitute', component: TraininginstituteComponent },
      { path: 'partners/authorizedindustry', component: AuthorizedindustryComponent },
      { path: 'partners/course', component: CourseComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'connectpartners', component: ConnectpartnersComponent },
      { path: 'signin', component: SigninComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'updatearppassword', component: UpdatearppasswordComponent },
      { path: 'authorization', component: AuthorizationComponent },
      { path: 'emailverified/:userid', component: EmailverifiedComponent },
      { path: 'resetpassword/:userid/:id', component: ResetpasswordComponent},
      
    ]
  },
  { path: 'aep/participant', canActivate: [AuthGuard],canActivateChild:[AuthGuard], loadChildren: () => import('./adminlayout/adminlayout.module').then(m => m.AdminLayoutModule) },
  { path: 'aep/admin', canActivate: [AuthGuard],canActivateChild:[AuthGuard], loadChildren: () => import('./adminsidelayout/adminsidelayout.module').then(m => m.AdminSideLayoutModule) },
  { path: 'aep/arp', canActivate: [AuthGuard],canActivateChild:[AuthGuard], loadChildren: () => import('./arplayout/arplayout.module').then(m => m.ArplayoutModule) },
  
  { path: '***', component : PageNotFoundComponent } ,
  { path: '', redirectTo: 'aep/home', pathMatch:'full' },
 
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: "reload",
    useHash:true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
