<!-- bulk upload -->
<mat-card>
    <mat-card-header>
        <mat-card-title class="m-0 p-0 trainer">Certification Bulk enrollment</mat-card-title>
    </mat-card-header>
    <div>
        <div class="filter-section position-relative">
            <div class="text-right mb-3">
                <div class="export-list dropdown">
                    <button class="btn btn-export dropdown-toggle" id="ExportDropdown" type="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-floppy-o mr-2"
                            aria-hidden="true"></i>Export to</button>
                    <ul class="dropdown-menu" aria-labelledby="ExportDropdown">
                        <li><a class="cursor-pointer" (click)="exportAsXLSX()"><i class="fa fa-file-word-o mr-2"
                                    aria-hidden="true"></i>Excel</a></li>
                        <li><a class="cursor-pointer" (click)="generatepdf()"><i class="fa fa-file-excel-o mr-2"
                                    aria-hidden="true"></i>Pdf</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 px-0" style="margin-left: 15px;">
            <a href="../../../../../assets/excel/toenrollsheet.xlsx" alt="">Download Sheet to upload</a>
        </div>
        <!-- <span><a href=""></a></span> -->
        <div class="mb-2">
            <div class="row px-3">
                <div class="input-group col-md-6 py-1">
                    <input id="docs" name="filepath" readonly="true" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="filepath" placeholder="upload excel only" type="text" class="form-control square">
                    <label class="input-group-btn upload-col">
                        <span class="btn inform-btn btn-raised btn-primary square">Browse
                            <input type="file" (change)="getFiles($event)" style="display: none;" value="">
                        </span>
                    </label>
                </div>

                <div class="col-0">
                    <button type="submit" class="btn custom-btn btn1-s px-3"
                        (click)="insStudentBulkCourseEnrollment($event)"><small>Upload</small></button>
                </div>
            </div>
        </div>
        <div [hidden]="showBulk">
            <div class="row" id="getdata">
                <table mat-table [dataSource]="studentBulkData" matSort class="table-striped w-100">
                    <ng-container matColumnDef="emailid" mat-column-EMAIL>
                        <th class="mat-cell-concession checkboxpadding" mat-header-cell *matHeaderCellDef
                            mat-sort-header>
                            Email
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.emailid }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="arpid">
                        <th class="mat-cell-concession checkboxpadding" mat-header-cell *matHeaderCellDef
                            mat-sort-header>
                            Arp id
                        </th>
                        <td mat-cell *matCellDef="let element" matTooltip="{{ element.arpid }}">
                            {{ element.arpid }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="slotid">
                        <th class="mat-cell-concession  checkboxpadding" mat-header-cell *matHeaderCellDef
                            mat-sort-header>
                            Slot id
                        </th>
                        <td mat-cell *matCellDef="let element" matTooltip="{{ element.slotid }}">
                            {{ element.slotid }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="certificationid">
                        <th class="checkboxpadding" mat-header-cell *matHeaderCellDef mat-sort-header>
                            Certification id
                        </th>
                        <td mat-cell *matCellDef="let element" matTooltip="{{ element.certificationid }}">
                            {{ element.certificationid }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th class="mat-cell-concession checkboxpadding " mat-header-cell *matHeaderCellDef
                            mat-sort-header>
                            status
                        </th>
                        <td class="td-position" mat-cell *matCellDef="let element" matTooltip="{{ element.status }}">
                            <span *ngIf="element.status=='true'">
                                Enrolled
                            </span>
                            <span *ngIf="element.status=='false'">
                                Failed
                            </span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="BulkDisplayColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: BulkDisplayColumns">
                    </tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell text-center" colspan="4" *ngIf="datalen == 0">No Data Available</td>
                    </tr>

                </table>
                <!-- <mat-paginator #bulkPaginator aria-label="Select page" 
            
            [pageSizeOptions]="[10, 20, 30]" 
             showFirstLastButtons
            *ngIf="datalen!=0"></mat-paginator> -->


                <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</mat-card>