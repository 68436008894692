<!-- Masthead-->
<header class="masthead">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-sm-7 col-xs-12">
                <h1 class="heading font-heading">Authorized
                    Industry Trainers</h1>
            </div>
            <div class="col-md-6 col-sm-5 col-xs-12 text-right pr-0">
                <img src="../../../assets/industry.png" alt="" class="img-fluid" />
            </div>
        </div>
    </div>
</header>
<section class="container">
    <div class="dec col-md-6 col-sm-7 col-xs-12">
Authorized Industry Trainers are AEP authorized industry experts and trainers who can customize and conduct various
    trainings required for AEP certifications across levels on Industrial Domain Trainings, Practical Simulation
    Trainings, Training on specific Software and Hardware, Practical Expert Training pertaining to below AEP
    Certifications.
    </div>
</section>
<section class="page-section py-5">
    <div class="container">
        <div class="row px-5">
            <div class="col-md-6 col-sm-12 mb-4">
                <div class="core">
                    <span>Core Engineer</span> ‘O’ ‘A’ ‘B’ and ‘C’ <br> Level programs
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="design">
                    <span>Design Engineer</span> ‘Basic’, ‘Associate’, ‘Professional’, and ‘Expert’ Level programs
                </div>
            </div>
            <b class="w-100 mt-5">Following are the trainers who can become AEP authorized industry trainers.</b>
            <ul>
                <li>Experienced industrial professionals</li>
                <li>Experts running engineering services/ consultancy</li>
                <li>Professionals with Industrial experience in specific domains and on designing software tools</li>
                <li>Skilled industrial experts and research scholars across industries
                </li>
                <li>Industry HRs</li>
            </ul>
            <b class="w-100 mt-2">Register with aepcrt.org to become AEP authorized industry trainers
            </b>
            <div class="mt-4 w-100 text-center">
                <button class="btn custom-btn" (click)="gotoContactPartners()"><b class="h5">Click here to visit
                        aepcrt.org
                    </b></button>
            </div>
        </div>
    </div>
</section>