<!-- <header class="masthead">
    <img src="./assets/img/about.jpg" class="img-fluid">
</header>
<section class="page-section py-5">
    <div class="container">
        <div class="row text-center">
            <h3 class="w-100">Careers</h3>
            <p class="display-5">Join us to become part of the journey to create an eco-system of building skilled engineering professionals.<br />
            </p>
        </div>
        <div class="row text-center mt-5">
            <h4 class="w-100">Open Positions</h4>
            <img src="../../../assets/img/none.jpg" class="img-fluid mb-2" alt="">
            <img src="../../../assets/img/none.jpg" class="img-fluid" alt="">
        </div>
    </div>
</section> -->

<section class="container-fluid">
    <!-- <div class="container"></div> -->
    <div class="page-section careers">
        <div class="row">
            <div class="col-md-6 col-sm-12 px-0">
                <img src="../../../assets/careers-small.png" alt="" class="xs-show">
            </div>
            <div class="col-md-6 col-sm-12 pl-5 customdiv mt-5">
                <h1 class="w-100">Careers</h1>
            </div>
        </div>
    </div>
    <section class="page-section py-5">
        <div class="container">
            <div class="row text-center">
                <p class="display-5">Join us to become part of the journey to create an eco-system of building skilled engineering professionals.<br />
                </p>
            </div>
            <div class="row text-center mt-5">
                <h4 class="w-100">Open Positions</h4>
                <img src="../../../assets/img/none.jpg" class="img-fluid mb-2" alt="">
                <img src="../../../assets/img/none.jpg" class="img-fluid" alt="">
            </div>
        </div>
    </section>
</section>