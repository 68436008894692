import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-tipview',
  templateUrl: './tipview.component.html',
  styleUrls: ['./tipview.component.css']
})
export class TipviewComponent implements OnInit {
  
  public selectCountry: string;
  public selectState: string;
  public selectDistrict: string;
  public selectCity: string;
  public viewtip: boolean = true;
  public showdetails: boolean = false;

  public country:any;
  public state:any;
  public CCountries = [];
  States =[];
  public tipdetails = [];
  public indtipdetails = [];
  public arpdetails = [];
  norecords = true;
  noarprecords =true;


  constructor(private service: ApiService, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {

    this.getcountries();
   
  }


  getcountries(){
     // COUNTRY
     var regionid = 5;
     this.service
       .get(this.service.getCountries)
       .subscribe((resp) => {
         if (resp != null) {
          //  for (let i = 0; i < resp.length; i++) {
          //   //  if (resp[i].country_id == '35') {
          //      this.CCountries.push({
          //        country_id: resp[i].country_id,
          //        country_name: resp[i].country_name
          //      })
          //   //  }
          //  }

          this.CCountries = resp.sort(function(a,b)
            {
              var st1 = a.country_name.toUpperCase();
              var st2 = b.country_name.toUpperCase();
              return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
            });
         }
       })
 
  }

  getStates(id) {
    this.spinner.show()
    this.service.get(this.service.getStates + id).subscribe((resp) => {
      this.spinner.hide()
    this.States =resp.sort(function(a,b)
    {
      var st1 = a.statename.toUpperCase();
      var st2 = b.statename.toUpperCase();
      return (st1 < st2) ? -1 : (st1 > st2) ? 1 : 0
    });
  })
    // console.log(this.statedata);  
}

statefilter(id) {
  this.spinner.show()
  let statename = id.statename;
  // localStorage.setItem('admin_state_name', statename);


  this.tipdetails = [];
    this.service.get(this.service.getorgtips).subscribe((res) => {
      this.spinner.hide()
      if(res != null){
      for (let i = 0; i < res.length; i++) {
        if (statename == res[i].orgstate) {
          this.tipdetails.push({
            orgid: res[i].orgid,
            orgname: res[i].orgname,
            orgaddress: res[i].orgaddress,
            orgstate: res[i].orgstate,
            orgcountry: res[i].orgcountry,
            orggstnumber:res[i].orggstnumber,
            orgaepid: res[i].orgaepid,
            industrytype: res[i].industrytype,
            orgwebsite: res[i].orgwebsite,
            email: res[i].email,
            mobilenumber: res[i].mobilenumber,
            designation: res[i].designation,
            firstname: res[i].firstname,
            lastname: res[i].lastname,
            arp_tip_mapping_id: res[i].arp_tip_mapping_id,
            arp_tip_mapped_date: res[i].arp_tip_mapped_date,
            orgdistrict:res[i].orgdistrict,
            orgcity: res[i].orgcity,
            isarpmapped: res[i].isarpmapped,
            arp_id: res[i].arp_id,
          })
        }
      }
      if(this.tipdetails.length!= 0) {
        this.norecords=true;
     }
     else{
       this.norecords=false;
     }
     }
    })
  
}


  public viewdetails(data){
    this.spinner.show()
    this.viewtip = false;
    this.showdetails = true;
    var id1 = data.orgid;

    this.indtipdetails = [];
    this.service.get(this.service.getindorgtips + id1 ).subscribe((res) => {
      // console.log(res);
      if(res != null){
      for (let i = 0; i < res.length; i++) {
        if (data.orgid == res[i].orgid) {
          this.indtipdetails.push({
            orgid: res[i].orgid,
            orgname: res[i].orgname,
            orgaddress: res[i].orgaddress,
            orgstate: res[i].orgstate,
            orgcountry: res[i].orgcountry,
            orggstnumber:res[i].orggstnumber,
            orgaepid: res[i].orgaepid,
            industrytype: res[i].industrytype,
            orgwebsite: res[i].orgwebsite,
            email: res[i].email,
            mobilenumber: res[i].mobilenumber,
            designation: res[i].designation,
            firstname: res[i].firstname,
            lastname: res[i].lastname,
            arp_tip_mapping_id: res[i].arp_tip_mapping_id,
            arp_tip_mapped_date: res[i].arp_tip_mapped_date,
            // orgdistrict:res[i].orgdistrict,
            // orgcity: res[i].orgcity, 
          })
        }
      }
     }
    })


    this.arpdetails = [];
    this.service.get(this.service.getarpbyorgid + id1 ).subscribe((res) => {
      // console.log(res);
      this.spinner.hide()
      if(res != null){
      for (let i = 0; i < res.length; i++) {
          this.arpdetails.push({
            arp_email: res[i].arp_email,
            rolename: res[i].rolename,
            arp_aepid: res[i].arp_aepid,
            company_name: res[i].company_name,
            contact_person_name: res[i].contact_person_name,
            gst_code:res[i].gst_code,
            pan_or_uid_number: res[i].pan_or_uid_number,
            office_doorno: res[i].office_doorno,
            office_landmark: res[i].office_landmark,
            office_street: res[i].office_street,
            office_area: res[i].office_area,
            office_city: res[i].office_city,
            office_district: res[i].office_district,
            office_state: res[i].office_state,
            offfice_country: res[i].offfice_country,
            office_pincode: res[i].office_pincode,
            bank_acc_number: res[i].bank_acc_number,
            bank_ifsc: res[i].bank_ifsc,
            canncelled_cheque_path: res[i].canncelled_cheque_path,
            isactive: res[i].isactive,
            mobile_number: res[i].mobile_number,
            arp_tip_mapping_id: res[i].arp_tip_mapping_id,
            arp_tip_mapped_date: res[i].arp_tip_mapped_date,
            arp_id: res[i].arp_id,
          })
      }

      if(this.arpdetails.length!= 0) {
        this.noarprecords=true;
     }
     else{
       this.noarprecords=false;
     }

     }
    })
 }

  public showTip(){
    this.spinner.show()
    this.viewtip = true;
    this.spinner.hide()
    this.showdetails = false;
  }

}
