import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule} from '@angular/material/tree';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule} from '@angular/material/select';
import { MatCardModule} from '@angular/material/card';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { MatChipsModule} from '@angular/material/chips';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatTabsModule} from '@angular/material/tabs';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatRadioModule} from '@angular/material/radio';
import { MatSliderModule} from '@angular/material/slider';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatTableModule} from '@angular/material/table';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatSortModule} from '@angular/material/sort';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatStepperModule} from '@angular/material/stepper';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ToastrModule } from 'ngx-toastr';

import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';

import { AuthorizationComponent } from './authorization/authorization.component';
import { CertificationofferingsComponent } from './certificationofferings/certificationofferings.component';
import { ConnectpartnersComponent } from './connectpartners/connectpartners.component';
import { ContactComponent } from './contact/contact.component';
import { EmailverifiedComponent } from './emailverified/emailverified.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { PartnersComponent } from './partners/partners.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ToasterService } from './services/toastr.service';
import { ValidationService } from './services/validation.service';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { UpdatearppasswordComponent } from './updatearppassword/updatearppassword.component';
import { CareersComponent } from './about/careers/careers.component';
import { ExtendedservicesComponent } from './about/extendedservices/extendedservices.component';
import { ProgramoverviewComponent } from './about/programoverview/programoverview.component';
import { WhoweareComponent } from './about/whoweare/whoweare.component';
import { ActivecoursesComponent } from './admin/activecourses/activecourses.component';
import { CourseenrollmentComponent } from './admin/courseenrollment/courseenrollment.component';
import { CoursehistoryComponent } from './admin/coursehistory/coursehistory.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { SupportComponent } from './admin/support/support.component';
import { DataService } from './admin/tables/data.service';
import { ViewexamguideComponent } from './admin/viewexamguide/viewexamguide.component';
import { ArpparticipantsComponent } from './arp/arpparticipants/arpparticipants.component';
import { TipsComponent } from './arp/tips/tips.component';
import { ArpComponent } from './arpadmin/arp/arp.component';
import { ParticipantsComponent } from './arpadmin/participants/participants.component';
import { CertificateComponent } from './certificationofferings/certificate/certificate.component';
import { OffersComponent } from './certificationofferings/offers/offers.component';
import { OffersModelPopupComponent } from './certificationofferings/offers/offersmodelpopup';
import { SearchaepcertificationComponent } from './certificationofferings/searchaepcertification/searchaepcertification.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { ArpenrollmentComponent } from './arpadmin/arp/arpenrollment/arpenrollment.component';
import { SearcharpcertificationComponent } from './arpadmin/arp/searcharpcertification/searcharpcertification.component';
import { ViewarpComponent } from './arpadmin/arp/viewarp/viewarp.component';
import { ViewProfileComponent } from './arpadmin/participants/viewprofile/viewprofile.component';
import { TipConversationComponent } from './arpadmin/tip/tipconversation/tipconversation.component';
import { TipMappingComponent } from './arpadmin/tip/tipmapping/tipmapping.component';
import { TipviewComponent } from './arpadmin/tip/tipview/tipview.component';
import { AepauthorizedtipsComponent } from './arp/aepauthorizedtips/aepauthorizedtips.component';
import { ChangepasswordComponent } from './admin/changepassword/changepassword.component';
import { ChangearppasswordComponent } from './arp/changearppassword/changearppassword.component';
import { ArptrainersComponent } from './arp/arptrainers/arptrainers.component';
import { TrainersComponent } from './arpadmin/arp/trainers/trainers.component';
import { CertificationbulkenrollmentComponent } from './arpadmin/bulkenrollent/certificatiobbulkenrollment/certificationbulkenrollment/certificationbulkenrollment.component';
import { ExcelserviceService } from './services/excelservice.service';
import { CertificationcoursesComponent } from './arpadmin/certificationcourses/certificationcourses/certificationcourses/certificationcourses.component';



// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    LayoutComponent,
    SignupComponent,
    SigninComponent,
    HomeComponent,
    ContactComponent,
    CareersComponent,
    ExtendedservicesComponent,
    ProgramoverviewComponent,
    WhoweareComponent,
    CertificationofferingsComponent,
    PageNotFoundComponent,
    PartnersComponent,
    ProfileComponent,
    ParticipantsComponent,
    CourseenrollmentComponent,
    ActivecoursesComponent,
    CoursehistoryComponent,
    ConnectpartnersComponent,
    UpdatearppasswordComponent,
    OffersComponent,
    ViewarpComponent,
    ArpenrollmentComponent,
    ViewProfileComponent,
    TipviewComponent,
    TipConversationComponent,
    TipMappingComponent,
    ArpComponent,
    OffersModelPopupComponent,
    AuthorizationComponent,
    ResetpasswordComponent,
    EmailverifiedComponent,
    ViewexamguideComponent,
    SupportComponent,
    CertificateComponent,
    SearchaepcertificationComponent,
    SearcharpcertificationComponent,
    TipsComponent,
    ArpparticipantsComponent,
    AepauthorizedtipsComponent,
    ChangepasswordComponent,
    ChangearppasswordComponent,
    ArptrainersComponent,
    TrainersComponent,
    CertificationbulkenrollmentComponent,
    CertificationcoursesComponent,
  
   
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatListModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatProgressBarModule,
    MatChipsModule,
    MatGridListModule,
    MatTabsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDatepickerModule,
    MatTreeModule,
    MatDialogModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
     MatNativeDateModule,
     
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    AppRoutingModule,ToastrModule.forRoot({
      timeOut:3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates:true,
     } ),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  
  providers: [
    AuthGuard,
    ToasterService,
    ValidationService,
    DataService,
    ExcelserviceService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
