import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { ExcelserviceService } from 'src/app/services/excelservice.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { ValidationService } from 'src/app/services/validation.service';


@Component({
  selector: 'app-certificationbulkenrollment',
  templateUrl: './certificationbulkenrollment.component.html',
  styleUrls: ['./certificationbulkenrollment.component.css']
})
export class CertificationbulkenrollmentComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  public fileToUpload: File = null;
  public file: any;
  public uploadeddata = [];
  // public uploadDoc: uploadDocModel[] = [];
  public filename: any;
  public filepath: any;
  studentslist: any = []
  studentBulkData: any;
  showBulk: boolean = true;
  BulkDisplayColumns: string[] = [
    'emailid',
    'arpid',
    'slotid',
    'certificationid',
    'status',
  ];
  datasource: any = [];
  datalen: any = [];

  constructor(private excelservice: ExcelserviceService,
    private service: ApiService, private ValidationService: ValidationService,
    private toast: ToasterService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }
  // bulk upload 
  getFiles(event:any) {
    this.studentBulkData=[]
    this.fileToUpload = null
    if (event.target.files[0].name.split('.')[1] == 'xlsx') {
      // const formData: FormData = new FormData();
      this.fileToUpload = event.target.files[0];
      this.filepath = event.target.files[0].name;
      event.target.value=''
    } else {
      this.toast.showwarning(this.ValidationService.msg_validate_file);
      event.target.value=''
    }
  }
  insStudentBulkCourseEnrollment(e:any) {
    if (this.filepath != undefined || this.fileToUpload != null) {
      const formData: FormData = new FormData();
      formData.append("file", this.fileToUpload, this.filepath);
      this.service.post(this.service.InsStudentBulkCourseEnrollment, formData).subscribe((res) => {
        if (res.length != 0 && res.length != undefined) {

          this.studentslist = res;
          this.studentBulkData = new MatTableDataSource(this.studentslist);
          this.studentBulkData.paginator = this.paginator;
          this.studentBulkData.sort = this.sort;
          this.datalen = this.studentslist.length;
          this.toast.showsuccess(this.ValidationService.courseBulkEnrollmentSuccess);
          this.showBulk = false;
          this.filepath = "";
          this.fileToUpload = null
          e.target.value=''
        }
        else if (res == 0) {
          this.toast.showwarning(this.ValidationService.emptyexecelfile);
          this.filepath = "";
          this.fileToUpload = null;
          e.target.value=''
        }
        else {
          this.toast.showwarning(this.ValidationService.courseBulkEnrollmentFailure)
          this.filepath = "";
          this.fileToUpload = null;
          e.target.value=''
        }

      })
    } else {
      this.toast.showwarning(this.ValidationService.emptyfile)
      this.filepath = "";
      this.fileToUpload = null;
    }

  }

  // Exporting data to excel and pdf 
  exportAsXLSX(): void {
    this.excelservice.exportAsExcelFile(this.studentslist, 'Enrolleddata');
  }
  // pdf exporting 
  public generatepdf() {
    const htmlToPrint =
      '' +
      '<style type="text/css">' +
      '.pageFooter {' +
      '    display: table-footer-group;' +
      '    counter-increment: page;' +
      '}' +
      '.pageFooter:after {' +
      '   content: "Page " counter(page)' +
      '}' +
      '</style>';
    var printContents = document.getElementById('getdata')?.innerHTML;
    console.log(printContents);
    var popupWin: any = window.open(
      'Angular Large Table to pdf',

      'width=768,height=auto'
    );

    popupWin.document.write(
      '<html><head>' +
      '<link rel="stylesheet" href="' +
      'https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"/>' +
      '<style type="text/css">' +
      '.pageFooter {' +
      '    display: table-footer-group;' +
      '    counter-increment: page;' +
      '}' +
      '.pageFooter:after {' +
      '   content: "Page " counter(page)' +
      '}' +

      '.clreven{' +
      'background-color: #fffae8}' +

      ' .clrodd{background-color: #f0f7ff}' +
      '.address-box {' +

      'box-shadow: 4px 9px 15px #0000001D' +
      ' padding: 15px' +
      'height: 100%' +
      'word-break: break-all}' +
      '</style>' +
      '</head><body onload="window.print();window.close()">' +
      printContents +
      '</body></html>'
    );
    popupWin.document.close();
  }
}


