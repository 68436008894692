<mat-card>
    <mat-card-header>
        <mat-card-title class="m-0 p-0 trainer" *ngIf="action">Add Certification </mat-card-title>
        <mat-card-title class="m-0 p-0 trainer" *ngIf="!action">Edit Certification </mat-card-title>
    </mat-card-header>
    <div *ngIf="showtableView">
        <button mat-stroked-button color="primary" (click)="goToAddView()">Add Certification</button>
        <div class="row" id="getdata">
            <table mat-table [dataSource]="coursesData" matSort class="table-striped w-100">
                <ng-container matColumnDef="certification_id" mat-column-EMAIL>
                    <th class="mat-cell-concession checkboxpadding" mat-header-cell *matHeaderCellDef
                        mat-sort-header>
                        Certification_id
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.certification_id }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="certification_name">
                    <th class="mat-cell-concession checkboxpadding" mat-header-cell *matHeaderCellDef
                        mat-sort-header>
                        Certification 
                    </th>
                    <td mat-cell *matCellDef="let element" matTooltip="{{ element.certification_name }}">
                        {{ element.certification_name }}
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="level_name">
                    <th class="mat-cell-concession  checkboxpadding" mat-header-cell *matHeaderCellDef
                        mat-sort-header>
                        Level 
                    </th>
                    <td mat-cell *matCellDef="let element" matTooltip="{{ element.level_name }}">
                        {{ element.level_name }}
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="branch_name">
                    <th class="checkboxpadding" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Branch
                    </th>
                    <td mat-cell *matCellDef="let element" matTooltip="{{ element.branch_name }}">
                        {{ element.branch_name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="course_name">
                    <th class="checkboxpadding" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Course
                    </th>
                    <td mat-cell *matCellDef="let element" matTooltip="{{ element.course_name }}">
                        {{ element.course_name }}
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="action">
                    <th class="header123" mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <button mat-icon-button id="schemeToggle matTooltip="View" class=""
                        >
                        <mat-icon>view</mat-icon>
                      </button>
                      <button mat-icon-button id="schemeToggle"
                        matTooltip="Edit" class="">
                        <mat-icon>create</mat-icon>
                      </button>
                    </td>
                  </ng-container> -->
                <!-- <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="action">
                    <th class="mat-cell-concession  checkboxpadding" mat-header-cell *matHeaderCellDef
                        mat-sort-header>
                        Action 
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button id="schemeToggle"
                        matTooltip="Edit" class="" (click)="gotoEditView(element)">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button id="schemeToggle"
                      matTooltip="View" class="" (click)="gotoView(element)">
                      <mat-icon>pageview</mat-icon>
                    </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="courseDisplayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: courseDisplayColumns">
                </tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-center" colspan="4" *ngIf="datalen == 0">No Data Available</td>
                </tr>

            </table>
            <!-- <mat-paginator #bulkPaginator aria-label="Select page" 
        
        [pageSizeOptions]="[10, 20, 30]" 
         showFirstLastButtons
        *ngIf="datalen!=0"></mat-paginator> -->


            <mat-paginator #paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</mat-card>

<!-- view starts-->
<mat-card *ngIf="showdetails" class="details">
    <button mat-stroked-button color="primary" (click)="gotoMainPage()">Back</button>
    <div class="row mt-3">
        <div class="col-12" >
          <mat-card  *ngFor="let data2 of viewdetaillist, let i = index">
            <h6>Certification Course Details:</h6>
            <p><label>Certification Id :</label>{{data2.certification_id}}</p>
            <p><label>Certification Code :</label>{{data2.certification_code}}</p> 
            <p><label>Certification Name :</label>{{data2.certification_name}}</p>
            <p><label>Overview :</label>{{data2.overview}}</p>
            <p><label>Level Name :</label>{{data2.level_name}}</p>
            <p><label>Branch Name :</label>{{data2.branch_name}}</p>
            <p><label>Domain Type :</label>{{data2.domain_type}}</p>
            <p><label>Certification Cost :</label>{{data2.certification_cost}}</p>
            <p><label>Exam Duration :</label>{{data2.exam_duration}}</p>
            <p><label>Course Duration :</label>{{data2.course_duration}}</p>
            <p><label>Exam Type :</label>{{data2.exam_type}}</p>
            <p><label>Certification Mode :</label>{{data2.certification_mode}}</p> 
            <p><label>Software Tool :</label>{{data2.software_tool}}</p>
            <p><label>Qualifying Percentage :</label>{{data2.qualifying_percentage}}</p>
            <p><label>Delivery Method :</label>{{data2.delivery_method}}</p>
            <p><label>Prerequisites :</label>{{data2.prerequisites}}</p>
            <p><label>who Can Apply :</label>{{data2.who_can_apply}}</p>
            <p><label>pbp :</label>{{data2.pbp}}</p>
          </mat-card>
        </div>
    </div>
  </mat-card>
<!-- view ends -->

<!-- Add certification starts -->
<mat-card *ngIf="AddEditView">
    <!-- <h5 *ngIf="action">Add certification</h5>
    <h5 *ngIf="!action">Edit certification</h5> -->
    <button mat-stroked-button color="primary" (click)="gotoMainPage()">Back</button>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Select Course<span class="required-mark">*</span></mat-label>
                <mat-select matInput name="course" [(ngModel)]="fun_data.courseid"
                  (selectionChange)="getlevelsBasedOnCourse($event.value)">
                  <mat-option *ngFor="let data of courses" [value]="data.course_id">
                    {{data.course_name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Select Level<span class="required-mark">*</span></mat-label>
                <mat-select matInput   name="Level" [(ngModel)]="fun_data.levelid"
                (selectionChange)="selectlevel($event.value)">
                <mat-option *ngFor="let Level of Levels" [value]="Level.level_id">
                    {{Level.level_name}}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Branches</mat-label>
                <mat-select matInput name="course"  [(ngModel)]="fun_data.branchid"
                  (selectionChange)="selectBranches($event.value)">
                  <mat-option *ngFor="let data of branches" [value]="data.branch_id">
                    {{data.branch_name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>who can apply<span class="required-mark">*</span></mat-label>
                <textarea matInput   class="form-control" [(ngModel)]="fun_data.whocanapply" >
                </textarea>
            </mat-form-field>
        </div>
        <!-- <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>certification code<span class="required-mark">*</span></mat-label>
                <input matInput  name="certificationcode" [(ngModel)]="fun_data.certificationcode" placeholder="Enter your certification code">
            </mat-form-field>
        </div> -->
        <div class="col-sm-4 pr-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Certification Name<span class="required-mark">*</span></mat-label>
                <textarea matInput class="form-control" name="certificationname" [(ngModel)]="fun_data.certificationname" placeholder="Enter certification name">
                </textarea>
                </mat-form-field>
        </div>
        <div class="col-sm-4">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Overview<span class="required-mark">*</span></mat-label>
                <textarea matInput  name="message" class="form-control" [(ngModel)]="fun_data.overview" required>
                </textarea>
            </mat-form-field>
        </div>
       
    </div>
    <div class="row">
        <!-- <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>who can apply<span class="required-mark">*</span></mat-label>
                <textarea matInput   class="form-control" [(ngModel)]="fun_data.whocanapply" >
                </textarea>
            </mat-form-field>
        </div> -->
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>certification code<span class="required-mark">*</span></mat-label>
                <input matInput  name="certificationcode" [(ngModel)]="fun_data.certificationcode"[disabled]="!action" placeholder="Enter your certification code">
            </mat-form-field>
        </div>
        <div class="col-sm-4">
            <mat-form-field class="example-full-width px-3">
                <mat-label>prerequisites<span class="required-mark">*</span></mat-label>
                <input matInput  [(ngModel)]="fun_data.prerequisites">
            </mat-form-field>
        </div>
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>delivery_method<span class="required-mark">*</span></mat-label>
                <input matInput name="deliverymethod" [(ngModel)]="fun_data.deliverymethod"  placeholder="Please enter delivery method">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Qualifying percentage</mat-label>
                <input matInput name="qualifying_percentage" [(ngModel)]="fun_data.qualifyingpercentage"  placeholder="Enter qualifying percentage">
            </mat-form-field>
        </div>  
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>domain_type<span class="required-mark">*</span></mat-label>
                <input matInput name="domain_type" [(ngModel)]="fun_data.domaintype"  placeholder="Please enter domain type">
            </mat-form-field>
        </div>
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Exam Duration</mat-label>
                <input matInput name="examduration" [(ngModel)]="fun_data.examduration" (keypress)="allowNumbersOnly($event)"   placeholder="Enter exam duration">
            </mat-form-field>
        </div> 
        <!-- <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>delivery_method<span class="required-mark">*</span></mat-label>
                <input matInput name="deliverymethod" [(ngModel)]="fun_data.deliverymethod"  placeholder="Please enter delivery method">
            </mat-form-field>
        </div> -->
    </div>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Software tool</mat-label>
                <input matInput name="softwaretool" [(ngModel)]="fun_data.softwaretool"  placeholder="Enter software tool">
            </mat-form-field>
        </div>  
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>certification_mode<span class="required-mark">*</span></mat-label>
                <input matInput name="certification_mode" [(ngModel)]="fun_data.certificationmode"  placeholder="Please enter certification mode">
            </mat-form-field>
        </div>
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Exam type<span class="required-mark">*</span></mat-label>
                <input matInput name="examtype" [(ngModel)]="fun_data.examtype"  placeholder="Please enter exam type">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Pbp</mat-label>
                <input matInput name="pbp" [(ngModel)]="fun_data.pbp"  placeholder="Enter pbp">
            </mat-form-field>
        </div>  
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>course_duration<span class="required-mark">*</span></mat-label>
                <input matInput name="courseduration" [(ngModel)]="fun_data.courseduration"  (keypress)="allowNumbersOnly($event)"   placeholder="Please enter course duration">
            </mat-form-field>
        </div>
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Training And Certification Cost<span class="required-mark">*</span></mat-label>
                <input matInput name="examtype" [(ngModel)]="fun_data.trainingcercost" (keypress)="allowNumbersOnly($event)"    placeholder="Please enter training and certification cost">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Certification cost</mat-label>
                <input matInput name="certification_cost" (keypress)="allowNumbersOnly($event)"   [(ngModel)]="fun_data.certificationcost"  placeholder="Enter certificationcost">
            </mat-form-field>
        </div>  
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Years<span class="required-mark">*</span></mat-label>
                <input matInput name="years" [(ngModel)]="fun_data.years" (keypress)="allowNumbersOnly($event)"   placeholder="Please enter years">
            </mat-form-field>
        </div>
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Category<span class="required-mark">*</span></mat-label>
                <input matInput name="Category" [(ngModel)]="fun_data.category"  placeholder="Please enter category">
            </mat-form-field>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-sm-4 pl-0">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Exam Duration</mat-label>
                <input matInput name="examduration" [(ngModel)]="fun_data.examduration"  placeholder="Enter exam duration">
            </mat-form-field>
        </div>  
    </div> -->
    <div class="m-auto my-2 text-center"  *ngIf="action">
        <button  type ="button" class="btn-color"  (click)="AddEditCertification()">Save</button>
    </div>
    <div class="m-auto my-2 text-center"  *ngIf="!action">
        <button  type ="button" class="btn-color"  (click)="AddEditCertification()">Update</button>
    </div>
  </mat-card>
<!-- Add certification ends -->
