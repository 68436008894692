import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authorizedindustry',
  templateUrl: './authorizedindustry.component.html',
  styleUrls: ['./authorizedindustry.component.css']
})
export class AuthorizedindustryComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public gotoContactPartners(){
    // this.router.navigateByUrl('/aep/connectpartners')
    window.open('https://aepcrt.org/', '_blank');
  }

}
