<mat-card *ngIf="showdetails" class="py-4 px-4">
    <!-- <div class="row">
        <div class="col-7">
            <h5 class="trainer"></h5>
        </div>
     </div> -->
     <mat-card-header>
      <mat-card-title class="m-0 p-0 trainer">View Trainers</mat-card-title>
  </mat-card-header>
  <p class="mt-3"><b class="note">*</b> Please Select Country, State, District to View the Trainers.</p>
    <div class="row">
        <div class="col-md-3">
            <mat-form-field class="example-full-width px-3">
              <mat-label>Country:</mat-label>
              <mat-select #countcountryid [(ngModel)]="country" name="country"
                (selectionChange)="getStates($event.value)">
                <mat-option *ngFor="let Country of CCountries" [value]="Country.country_id">
                  {{Country.country_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="example-full-width px-3">
          <mat-label>State</mat-label>
          <mat-select #countstateid name="state" [(ngModel)]="state"
            (selectionChange)="getdistricts($event.value)">
            <mat-option *ngFor="let State of States" [value]="State">
              {{State.statename}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
        <div class="col-md-3">
            <mat-form-field class="example-full-width px-3">
                <mat-label>Select District</mat-label>
                <mat-select #district [(ngModel)]="district1" name="district"
                 (selectionChange)="gettrainers($event.value)" >
                  <mat-option *ngFor="let district of districts" [value]="district.districtname">
                    {{district.districtname}}
                  </mat-option>
                </mat-select> 
              </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="example-full-width px-3">
            <mat-label>Select Trainer Type</mat-label>
            <mat-select #trainer name="trainer" [(ngModel)]="trainer1"
            (selectionChange)="getdatabytype($event.value)">
              <mat-option *ngFor="let trainer of trainertypeData" [value]="trainer.name">
                {{trainer.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead class="table-dark">
              <tr>
                <th scope="col">#</th>
                <!-- <th scope="col">AEP Id</th> -->
                <th scope="col" width="221px">Name</th>
                <th scope="col" width="200px">Stream</th>
                <th scope="col">Type</th>
                <th scope="col" width="215px">Qualification</th>
                <th scope="col">Industry Experience (yrs) </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of trainerdetails, let i = index">
                    <td>{{i+1}}</td>
                    <!-- <td>{{data.aepid}}</td> -->
                    <td>{{data.firstname}} {{data.lastname}}</td>
                    <td>{{data.branch}}</td>
                    <td>{{data.trainertype}}</td>
                    <td>{{data.highesteducation}}</td>
                    <td>{{data.industryexperience}}</td>
                    <td>
                        <!-- <button mat-stroked-button color="primary" >View Profile</button>&nbsp; -->
                        <!-- <button mat-stroked-button color="primary" (click)="editArpdetails(data)">Edit</button>&nbsp; -->

                        <button mat-icon-button [style.overflow]="'visible'" matTooltip="View"
                        (click)="view(data.id)">
                          <mat-icon>pageview</mat-icon>
                        </button>
                   </td>
                </tr>
            </tbody>
          </table>
        </div>
          <div [hidden]="norecords" class="center-align">
            <h3>No Trainers available in your region</h3>
          </div>
          <div [hidden]="norecords1" class="center-align">
            <h3>Trainer Type not available</h3>
          </div>
          <div [hidden]="norecords2" class="center-align">
            <h3>No Trainers available in selected region</h3>
          </div>
  </mat-card>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  
<mat-card *ngIf="showdetails1" class="details">
    <button mat-stroked-button color="primary" (click)="showTip()">Back</button>
    <div class="row mt-3">
        <div class="col-12" >
            <mat-card class="arptrainer" *ngFor="let data of trainerfulldetails, let i = index">
              <!-- <h5 class="activeCourse-heading">{{data.type}}</h5> -->
                <h6>Trainer Details:</h6>
                <p><label>Name</label>{{data.firstname}} {{data.lastname}}</p>
                <p class="break-word"><label>Email</label>{{data.email}}</p>
                <p><label>Mobile No</label>{{data.mobilenumber}}</p>
                <p><label>Home Address</label>{{data.homeaddress}}</p>
                <p><label>Home Address Line1</label>{{data.homeaddressline1}}</p>
                <p><label>Home Address Line2</label>{{data.homeaddressline2}}</p>
                <p><label>Home District</label>{{data.homedistrict}}</p>
                <p><label>Home State</label>{{data.homestate}}</p>
                <p><label>Office Address</label>{{data.officeaddress}}</p>
                <p><label>Office Address Line 1</label>{{data.officeaddressline1}}</p>
                <p><label>Office Address Line 2</label>{{data.officeaddressline2}}</p>
                <p><label>Office District</label>{{data.officedistrict}}</p>
                <p><label>Office State</label>{{data.officestate}}</p>
                <p><label>Trainer Type</label>{{data.type}}</p>
                <p><label>Work Mode</label>{{data.workmode}}</p>
                <p><label>Domain He Can Teach</label>{{data.domainshecanteach}}</p>
                <p><label>Software He Can Teach</label>{{data.softwareshecanteach}}</p>
                <p><label>Area of Expertise</label>{{data.aoehecanteach}}</p>
                <p><label>Total Experience in Years</label>{{data.yearsofexperience}}</p>
                <p><label>Faculty Experience in Years</label>{{data.facultyexperience}}</p>
                <p><label>Domain</label>{{data.domain}}</p>
                <div *ngIf="data.interesttocreatecoursematerial == 'true'">
                  <p><label>Intrested to Create Course Material</label>Yes</p>
                </div>
                <div *ngIf="data.interesttocreatecoursematerial == 'false'">
                  <p><label>Intrested to Create Course Material</label>No</p>
                </div>
                <div *ngIf="data.interestinpreparingexampattern == 'true'">
                  <p><label>Intrested to Prepare Exam Pattern</label>Yes</p>
                </div>
                <div *ngIf="data.interestinpreparingexampattern == 'false'">
                  <p><label>Intrested to Prepare Exam Pattern</label>No</p>
                </div>
                <div *ngIf="data.interesttoconductonlinetraining == 'true'">
                  <p><label>Intrested to Conduct Online Training</label>Yes</p>
                </div>
                <div *ngIf="data.interesttoconductonlinetraining == 'false'">
                  <p><label>Intrested to Conduct Online Training</label>No</p>
                </div>
            </mat-card>
        </div>
    </div>
</mat-card>
