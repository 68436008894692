import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toastr.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.css']
})
export class TipsComponent implements OnInit {

  public datasource = [];
  notiprecords = true;

  constructor(private service: ApiService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();

    this.getarp();

    
  }


    getarp(){
  
    var id1 = Number(localStorage.getItem('arpid'));
    
  this.service.get(this.service.gettips + id1).subscribe((res) => {
    this.spinner.hide();
    for (let i = 0; i < res.length; i++) {
        this.datasource.push({
          arp_id:res[i].arp_id,
          orgid: res[i].orgid,
          orgname: res[i].orgname,
          firstname: res[i].firstname,
          orgwebsite: res[i].orgwebsite,
          orgaddress: res[i].orgaddress,
          industrytype: res[i].industrytype,
          email: res[i].email,
          mobilenumber: res[i].mobilenumber,
          orgcity:res[i].orgcity,
          orgdistrict:res[i].orgdistrict,
          orgstate: res[i].orgstate,
          orgcountry: res[i].orgcountry
        })
    }
    if(this.datasource.length!= 0) {
      this.notiprecords=true;
   }
   else{
     this.notiprecords=false;
   }
  })
}

}
