<div class="mt-5">&nbsp;</div>
<div class="container mt-5">
    <mat-card class="p-5 my-5">
        <div class="row m-5">
            <h1>AUTHORIZATION</h1>
        <p>The program or concept of imparting relevant knowledge and guidance to
            shape and enlighten the young minds to dream for their cognitive career
            has been patented instilling confidence to our stake holders, partners and
            participants for a restricted access to the program.</p>
        <p>Any unauthorized usage or exploitation of the content, assets/image,
            methodology and inappropriate usage of the program would hold you
            liable strictly as per the local governance law. The punishment or subdue
            consequences would be as per the law under no reliance or leverage.</p>
        <p>
            The concept or program has been patented under Patents Act 1970,
            Subsection 39 of 1970; Patent Rules 2003; Trademarks Act 1999;
            Copyright Act 1957; Designs Act 2001. Any violation or infringement of
            the said patent concept will fall you under infringement of Intellectual
            property rights and will hold you liable at Civil court of a local jurisdiction.
        </p>
        </div>
    </mat-card>
</div>