import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificationofferings',
  templateUrl: './certificationofferings.component.html',
  styleUrls: ['./certificationofferings.component.css']
})
export class CertificationofferingsComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  
  }

  
}
