<header class="masthead">
    <div id="homeBanner" class="carousel slide" data-ride="carousel">
        <!-- Indicators -->
        <ul class="carousel-indicators">
          <li data-target="#homeBanner" data-slide-to="0" class="active"></li>
          <li data-target="#homeBanner" data-slide-to="1"></li>
          <li data-target="#homeBanner" data-slide-to="2"></li>
        </ul>
        <!-- The slideshow -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="./assets/img/slider1.jpg">
          </div>
          <div class="carousel-item">
            <img src="./assets/img/slider3.jpg">
          </div>
          <div class="carousel-item">
            <img src="./assets/img/slider2.jpg">
          </div>
        </div>
        <!-- Left and right controls -->
        <a class="carousel-control-prev" href="#homeBanner" data-slide="prev">
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" href="#homeBanner" data-slide="next">
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>
</header>
<section class="lookup">
    <div class="container">
        <div class="row">
            <div class="col-md-2 p-0 mob-imgwidth">
                <img src="./assets/img/look.png" alt="" class="looking">
            </div>
            <div class="col-md-10 mob-textwidth">
                <h3 class="required-job"> validating your Job & Role required Skills and Experience?</h3>
            </div>
        </div>
    </div>
</section>
<section class="page-section aep-content">
    <div class="container">
        <div class="row text-center">
            <h3 class="sub-heading">AEP Certification Background</h3>
            <p class="display-5">A survey was conducted by a group of professionals on the challenges faced by the industry, in recruiting and utilizing the required engineering professionals with right skills to successfully perform the job aligned to the roles.
                The team being understood the gaps identified between the Industry expectations and Academia, have designed a unique curriculum approved by the industry experts and recruiters, which can cater to the industry requirements to deploy skilled resources into the specialized roles. 
                <b class="text-bold">AEP Certification (Authorized Engineering Practitioner’s Certification)</b> an Industry aligned, learner centric, customized framework created for engineering professionals to benchmark their skills and experience, and enable them to handle any challenging role with a strong engineering skillset irrespective of background or experience.
                </p>
        </div>
    </div>
</section>
<section class="page-section mb-0">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="why-aep">
                    <h4>Why AEP Certification?</h4>
                    <div class="row">
                        <div class="col-md-8">
                            <p class="px-3 p-st">AEP Certification a patent concept, is targeted for the aspired engineering graduates and working professionals who aim to jump start in career and transform from Job Ready to Role Ready to Growth Ready. Through an unique competency and skill baselining framework which covers wide range of independent certification programs, the engineering professionals will be enabled to be day one deployable resources aligning with any industry specific role.</p>
                        </div>
                        <div class="col-md-4">
                            <img src="./assets/img/aep.png" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="next-step h-100">
                    <h4>Next Step..!</h4>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="px-3 p-st">Become AEP Certified Engineering Professional to sharpen your career and validate your Skills and Experience.</p>
                            <button type="button" class="btn custom-btn btn-sm text-uppercase m-3 mb-4" (click)="gotoSignup()">Register Now!</button>
                        </div>
                        <div class="col-md-6">
                            <img src="./assets/img/next-level.png" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="page-section clientsection p-0">
    <div class="container">
        <div class="row">
            <div class="col-md-3 client">
                <img src="./assets/img/client1.png" class="img-fluid">
            </div>
        </div>
    </div>
</section> -->