import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { LocalStorageService } from 'ngx-webstorage';

import { NgxSpinnerService } from 'ngx-spinner';

import { ToasterService } from '../services/toastr.service';
import { ValidationService } from '../services/validation.service';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: "root",
})
export class ApiService {
    private apiUrl = environment.apiEndpoint;
    

    constructor(
        public http: HttpClient,
        public toast: ToasterService,
        private spinner: NgxSpinnerService,
        private ValidationService: ValidationService
    ) { }
    // PROFILE
    getUsers = `${this.apiUrl}accounts/getuserprofile/`;
    getProfileData = `${this.apiUrl}accounts/getcustomeraccounts/`;
    getCountries = `${this.apiUrl}accounts/getallcountries`;
    getStates = `${this.apiUrl}accounts/getstatesbycountryid/`;
    getDistricts = `${this.apiUrl}accounts/getdistrictsbystateid/`;
    getCities = `${this.apiUrl}accounts/getcitiesbydistrictid/`;
    updProfileData = `${this.apiUrl}accounts/updateuserprofile`;
    getStudentLogin = `${this.apiUrl}accounts/getstudentlogin`;
    getArpLogin = `${this.apiUrl}accounts/getarplogin`;
    getAdminLogin = `${this.apiUrl}accounts/getadminlogin`;
    postStudentSignup = `${this.apiUrl}accounts/adduserenrollment`;
    getcourseandlevels = `${this.apiUrl}accounts/getcourseandlevels`;
    getcertificationcoursesbycourselevelid = `${this.apiUrl}accounts/getcertificationcoursesbycourselevelid/`;
    updateArpPassword =`${this.apiUrl}accounts/updpasswordforuserarpadmin`;

    
    // COURSE ENROLLMENT
    getArpList =`${this.apiUrl}accounts/getallarps`;
    gettips = `${this.apiUrl}accounts/getorganisationtipbyarpid/`;
    getTimeSlot = `${this.apiUrl}accounts/getallslots/`;

    // ACTIVE COURSES
    getOrderSummaryByUser = `${this.apiUrl}accounts/getordersummarybyuserid/`;
    getOrderedCourseDeatils = `${this.apiUrl}accounts/getordersummarybyuseridandcertificateid/`;
    uploadDocument =  `${this.apiUrl}accounts/insupddocumentuploads/`;
    uploadDocumentUpdate =  `${this.apiUrl}accounts/insupddocumentuploads/`;
    getUploadedDocument =  `${this.apiUrl}accounts/getdocumentuploads/`;
    rescheduleexamdate =  `${this.apiUrl}accounts/courseslotreschedule`;

   //  EMAIL NOTIFICATION FOR FORGOT PASSWORD.
    forgotPasswordEmailNotification =`${this.apiUrl}emailNotification/updatepasswordemail/notificaton`;
    resetPassword =`${this.apiUrl}accounts/updpasswordforuserarpadmin`;
    
    // EMAIL VERIFICATION FOR RESGISTERED NEW USER
    emailVerification =  `${this.apiUrl}accounts/updateuseremailverification`;

    // Email connect to partners.
    connectToArpPartnerstoEmail =`${this.apiUrl}emailNotification/connecttoarppartnersemail/notificaton`;

    // PAYMENT
    postOrderSummary = `${this.apiUrl}accounts/inscertificateordersummary`;

    // CERTIFICATION
    getCertificationdata = `${this.apiUrl}accounts/getusersearch`;
    getarpCertificationdata = `${this.apiUrl}accounts/getusersearchdetails`;
    getdetailsbyOrderID = `${this.apiUrl}accounts/getusersearchviewdetails/`;
    getCertificationStatus = `${this.apiUrl}accounts/getcertificationstatus`;
    updateCertificateStatus = `${this.apiUrl}accounts/updusercertifictestatus`;

    //CONTACTUS
    connectWithusEmail = `${this.apiUrl}emailNotification/contactwithusemailnotification`;

    // ARP ENROLLMENT.
    arpEnrollment = `${this.apiUrl}accounts/addarpenrollment`;
    generatePassword =`${this.apiUrl}accounts/getrandompassword`;
    // ORGANISATION TIPS 
    getorgtips = `${this.apiUrl}accounts/getallorganisationstips`;
    getindorgtips = `${this.apiUrl}accounts/getorganisationstips/`;

    // ARP BY Organisation
    getarpbyorgid = `${this.apiUrl}accounts/getarpbyid/`;

    //VIEW ,EDIT ARP IN ADMIN
    getAllArps =`${this.apiUrl}accounts/getallarps`;
    getArpByArpid = `${this.apiUrl}accounts/getarpbyarpid/`;
    getTipByArpid = `${this.apiUrl}accounts/getorganisationtipbyarpid/`;
    updateoldarp =`${this.apiUrl}accounts/updarpenrollment`;
    updatenewarp=`${this.apiUrl}accounts/updarpfornewenrollment`;
    // disablearp =`${this.apiUrl}accounts/updarpdisable`;
    getUploadedcheque=`${this.apiUrl}accounts/getcancelledchequeuploads/`;
    uploadCancelledCheques=`${this.apiUrl}accounts/insupdarpchequesuploads`;
  // Get ORGANISATIONS
  getorganisations = `${this.apiUrl}accounts/getallorganisationusers`;
  getindorganisations = `${this.apiUrl}accounts/getorganisations/`;

  // TIP CONVERSION
  postconvtip = `${this.apiUrl}accounts/gettipsconversion`;

   // ARP BY ARPID
   getarpbyarpid = `${this.apiUrl}accounts/getarpbyarpid/`;

   // Get ORGANISATIO BY ARP ID
   gettipsbyarpid = `${this.apiUrl}accounts/getorganisationtipbyarpid/`;

   // ARP TIP MAPPING
   postarptipmap = `${this.apiUrl}accounts/insupdarptipmapping`;

   // ARP,TIP ADMIN REQUEST MAIL
   arpadmintipmapreq = `${this.apiUrl}emailNotification/arpadminrequestnotification`;
   arpadmintipmail = `${this.apiUrl}emailNotification/arpupdatetipnotification`;

   //  ADMIN VIEW EXISTING PARTICIPANT
   getAdminParticipantdata = `${this.apiUrl}accounts/getparticipantsearchdetails`;
  //  ADMIN VIEW EXISTING PARTICIPANT 
  userEnableDisable = `${this.apiUrl}accounts/upduserenable`;
  courseDetailsbyParticipant = `${this.apiUrl}accounts/getordersummarybyuserid/`;

  // ADMIN CBT DATE UPDATE

  postCBTdate =  `${this.apiUrl}accounts/upddateincertificationordersummary`;     
  
   //GET TRAINERS LIST
   getTrainersList = `${this.apiUrl}accounts/gettrainerdetails`;
   msg_Validate_certSuccess="Successfully updated completed status.";
   msg_validate_certfail="Failed to update the completed status.";

  // STUDENTS BULK UPLOAD FOR COURSES 
  InsStudentBulkCourseEnrollment =  `${this.apiUrl}accounts/InsBulkEnrollemnt/`;
  // certification courses
  GetCertificationcourses =  `${this.apiUrl}accounts/GetCertificationcourses/`;
  GetAllBranches =  `${this.apiUrl}accounts/GetAllBranches/`;
  insupcertificationcourses=`${this.apiUrl}accounts/insupcertificationcourses`;
  




    getWithoutLoader(url): Observable<any> {
      //console.log(url);
      return this.http.get(url);
    }
    postLogin(url, data): Observable<any> {
     // console.log(url);
     // console.log(data);
      return this.http
        .post(url, data)
        .map((res) => {
          return res;
        })
        .catch((res) => Observable.throw(this.handleErr(res)));
    }
    post(url, data): Observable<any> {
     // console.log(url);
     // console.log(data);
      let token = 'Bearer ' + localStorage.getItem("token");
    //  console.log('token '+ token);
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
      // return this.http.post(url, data, httpOptions);
      return this.http
        .post(url, data, httpOptions)
        .map((res) => {
          return res;
        })
        .catch((res) => Observable.throw(this.handleErr(res)));
    }
    
    postForUpload(url, data): Observable<any> {
     // console.log(url);
     // console.log(data);
      let token = localStorage.getItem("token");
      //console.log('token '+ token);
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
      return this.http
        .post(url, data, { responseType: "text" })
        .map((res) => {
          return res;
        })
        .catch((res) => Observable.throw(this.handleErr(res)));
    }
    
    get(url): Observable<any> {
     // console.log(url);
      let token = 'Bearer ' + localStorage.getItem("token");
    //  console.log('token '+ token);
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
      };
      return this.http
        .get(url, httpOptions)
        .map((res) => {
          return res;
        })
        .catch((res) => Observable.throw(this.handleErr(res)));
    }
    handleErr(err: any) {
      this.spinner.hide();
    //  console.log(err);
      if (err.status == 202) {
        this.toast.showwarning(this.ValidationService.msg_Validate_TC_202Error);
      } else {
        this.toast.showerror(
          "There is some Technical Problem, please Contact Administrator"
        );
      }
      return err;
    }
    get_previewdata(previewUrl): Observable<any> {
      return this.http
        .get(previewUrl)
        .map((res) => {
          return res;
        })
        .catch((res) => Observable.throw(this.handleErr(res)));
    }
}