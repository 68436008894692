import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-page-not-found',
    template: `<h1>Page Not Found!</h1>`
})
export class PageNotFoundComponent implements OnInit {

constructor() { }

ngOnInit(): void {
}

}