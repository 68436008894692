
<header class="masthead my-3" style="background: white !important;">
    <div class="container my-3">
      <div class="row">
        <div class="col-md-7">
          <div class="text-center">
            <img src="../../assets/img/enrolment.jpg" alt="" class="signin">
            <h4 class="text-uppercase">Update Password</h4>
           
          </div>
          <form #f="ngForm">
            <div class="row mb-3">
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group aep-form">
                 
                  <mat-form-field class="example-full-width customForm">
                    <img src="../../assets/img/passwordIcon.jpg" alt="">
                    <mat-label>New Password <span class="required-mark">*</span></mat-label>
                    <input matInput type="password" class="custom-form-control" placeholder="Enter new password" name="inputAddress2"
                      [(ngModel)]="inputAddress2" maxlength="50">
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group aep-form">
                 
                  <mat-form-field class="example-full-width customForm">
                    <img src="../../assets/img/passwordIcon.jpg" alt="">
                    <mat-label>Confirm Password<span class="required-mark">*</span></mat-label>
                    <input matInput type="password" class="custom-form-control" placeholder="Enter confirm password" name="inputAddress3"
                      [(ngModel)]="inputAddress3" maxlength="50">
                  </mat-form-field>
                </div>
              </div>
            </div>
           
        <div class="text-center mt-1">
          <button type="submit" class="btn custom-btn py-3" style="margin-top: 30px;margin-bottom: 10px;" (click)="onPassordUpdate()">Submit</button>
        </div>
        </form>
      </div>
      <div class="offset-1 col-md-4 pt-5 mt-3 mob-image-no">
        <img src="../../assets/img/signup.jpg" class="img-fluid">
      </div>
    </div>
    </div>
  </header>
  
  