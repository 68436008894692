<!-- Masthead-->
<!-- <header class="masthead">
    <img src="./assets/img/arp.jpg" class="img-fluid">
</header>
<section class="page-section py-5">
    <div class="container">
        <div class="row">
            <h3 class="w-100 mb-4 text-center">Associate Representative Partner (ARP)</h3>
            <p>Associate Representative Partner(ARP) is the franchisee and an associate representative of AEP Certification for a designated territory, responsible to cater various tailor-made certifications and services offered by AEP Certification in the respective region.</p>
            <p>AEP Certification as an organization collaborates with potential and interested stakeholders with authorized industrial and corporate training background, and strong network with industry and academia. ARPs can further partner with AEP authorized training institutes or preparatory centers which are called  Training Institute Partners (TIPs) to impart and execute AEP Certification programs across levels and the corresponding training programs installed by various industry experts.</p>
            <p>AEP Certification invites all interested organizations from the states across India who want to partner with AEP Certification in the journey of bridging Industry and Academia expectations.</p>
            <p>AEP Certification as an organization and owner of the concept, shall design, direct and manage end-end process of Concept Proliferation, Industry-Academia-Local body connect, Quality and Legal aspects to ensure smooth and successful conduct of AEP Certification offerings globally.
                As part of its vision and mission, AEP certification will work closely with the industries across sectors to acknowledge and absorb AEP certified professionals and make AEP Certification deemed for engineering professionals.
                </p>
                <b class="w-100">AEP Certification supports its ARPs in the following areas:
                </b>
                <ul>
                    <li>Connecting and interfacing with Industries and Academia in the respective territory
                        <ul>
                            <li>Includes signing MOU and MOA</li>
                            <li>Liaison with industries and local bodies to support in placements and expand the business </li>
                        </ul>
                    </li>
                    <li>Marketing guidelines and aides, and other materials in e-format to promote within the territory</li>
                    <li>Guidance in identifying potential TIPs (CTI, PPI, REI) and facilitate in TIP franchisee agreement and empanelling TIPs in the region</li>
                    <li>Access to the database of Industries, Industry professionals, AEP authorized Training Institute Partners and Trainers to conduct the AEP Certification training programs and exams</li>
                    <li>Identifying trainers across streams and connect with TIP to conduct training programs 
                        <ul>
                            <li>Industry Domain Trainers</li>
                            <li>Professional Practitioners- Software</li>
                            <li>Professional Practitioners- Onsite/Hardware</li>
                            <li>Professional Expert Trainers and Research Scholars </li>
                        </ul>
                    </li>
                    <li>Guidelines and Checklists to conduct AEP Certification programs across levels</li>
                    <li>Soft copy material for all curriculums </li>
                    <li>End-end execution of online exams- CBTs</li>
                    <li>TIP and Trainer Rules and Regulation policies</li>
                    <li>Quality checklist and periodic audits for TIPs and Trainers</li>
                    <li>Guidance in any other logistical requirements</li>
                </ul>
                <div class="mt-4 w-100 text-center">
                    <button class="btn btn-outline-danger" (click)="gotoContactPartners()"><b class="h5">Contact to partner with us</b></button>
                </div>
        </div>
    </div>
</section> -->
<section class="arp_section container-fluid">
    <p>Associate Representative Partner(ARP) is the franchisee and an associate representative of AEP Certification for
        a designated territory, responsible to cater various tailor-made certifications and services offered by AEP
        Certification in the respective region.</p>
    <img src="../../../assets/arp.png" alt="arp_page" class="arp_image img-fluid" />
    <div class="row">
        <div class="col-md-6 arp_leftSection">
            <p>AEP Certification as an organization collaborates with potential and interested stakeholders with
                authorized industrial and corporate training background, and strong network with industry and academia.
                ARPs can further partner with AEP authorized training institutes or preparatory centers which are called
                Training Institute Partners (TIPs) to impart and execute AEP Certification programs across levels and
                the corresponding training programs installed by various industry experts.</p>
        </div>
        <div class="col-md-6 arp_rightSection">
            <p>AEP Certification invites all interested organizations from the states across India who want to partner
                with AEP Certification in the journey of bridging Industry and Academia expectations.</p>
            <p>AEP Certification as an organization and owner of the concept, shall design, direct and manage end-end
                process of Concept Proliferation, Industry-Academia-Local body connect, Quality and Legal aspects to
                ensure smooth and successful conduct of AEP Certification offerings globally.
                As part of its vision and mission, AEP certification will work closely with the industries across
                sectors to acknowledge and absorb AEP certified professionals and make AEP Certification deemed for
                engineering professionals.
        </div>
    </div>
    <div class="supports">
        <h3>AEP Certification supports its ARPs in the following areas</h3>
        <div class="row">
            <div class="col-md-6 bdr-rignt">
                <ul>
                    <li>Connecting and interfacing with Industries and Academia in the respective territory</li>
                    <ul>
                        <li>Includes signing MOU and MOA</li>
                        <li>Liaison with industries and local bodies to support in placements and expand the business
                        </li>
                    </ul>
                    <li>Marketing guidelines and aides, and other materials in e-format to promote within the territory
                    </li>
                    <li>Guidance in identifying potential TIPs (CTI, PPI, REI) and facilitate in TIP franchisee
                        agreement and empanelling TIPs in the region</li>
                    <li>Access to the database of Industries, Industry professionals, AEP authorized Training Institute
                        Partners and Trainers to conduct the AEP Certification training programs and exams</li>
                </ul>
            </div>
            <div class="col-md-6">
                <ul>
                    <li>Identifying trainers across streams and connect with TIP to conduct training programs</li>
                    <ul>
                        <li>Industry Domain Trainers</li>
                        <li>Professional Practitioners- Software</li>
                        <li>Professional Practitioners- Onsite/Hardware</li>
                        <li>Professional Expert Trainers and Research Scholars</li>
                    </ul>
                    <li>Guidelines and Checklists to conduct AEP Certification programs across levels</li>
                    <li>Soft copy material for all curriculums</li>
                    <li>End-end execution of online exams- CBTs</li>
                    <li>TIP and Trainer Rules and Regulation policies</li>
                    <li>Quality checklist and periodic audits for TIPs and Trainers</li>
                    <li>Guidance in any other logistical requirements</li>
                </ul>
            </div>
        </div>
    </div>
            <div class="py-4 w-100 text-center">
                <button class="btn custom-btn" (click)="gotoContactPartners()"><b class="h5">Contact to partner with us</b></button>
            </div>
</section>