import { Component, OnInit } from '@angular/core';
import { ValidationService } from '../services/validation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { ToasterService } from '../services/toastr.service';
// import { Router } from '@angular/router';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  
  
  newpassword: '';
  confirmpassword: '';

  user:{userid:any,id:any;};

  constructor(private route:ActivatedRoute , private ValidationService: ValidationService,private spinner: NgxSpinnerService,private service :ApiService, public toast: ToasterService, private router: Router) { }

  ngOnInit(): void {

    this.user = {
      userid:this.route.snapshot.params['userid'],
      id: this.route.snapshot.params['id']

    };
    //  Dynamic observable 
    // this.route.params.subscribe((params:Params)=>{
    //   this.user.userid=params['userid'];
    //   this.user.id=params['id'];
    // }
    // );

    }
  submit()
  {
          if(this.function1()){
               
              return;
          }
          var obj={
            user_id: Number(this.user.userid),
            new_password: String(this.newpassword),
            roleid: Number(this.user.id)
          }
          this.service.post(this.service.resetPassword, obj).subscribe((resp) => {
          //  console.log(resp);
           if ( resp != null){

            if(resp == 1){
              this.toast.showsuccess(this.ValidationService.msg_Validate_passwordUpdateSuccess);
              this.router.navigate(['/aep/signin']);
            }
            
            else{
              this.toast.showwarning(this.ValidationService.msg_Validate_passwordResetFailure);
            }

          }

          })
        }

        





        function1() {
        
          if (this.ValidationService.funValidateEmptyText(this.newpassword, this.ValidationService.msg_Validate_emptynewPassword, '#newpassword')) {
            return true;
          }
      
          if (this.ValidationService.funValidateEmptyText(this.confirmpassword, this.ValidationService.msg_Validate_emptyconfirmPassword, '#confirmpassword')) {
            return true;
          }
      
          if (this.newpassword != '' && this.confirmpassword != '') {
      
            if (this.ValidationService.funvalidateConfirmpswd(this.newpassword, this.confirmpassword, this.ValidationService.msg_Validate_Passwordmatch)) {
              return true;
            }
          }
        
        }
  

}
