import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ValidationService } from '../services/validation.service';
import { Component, OnInit } from '@angular/core';
import { ToasterService } from '../services/toastr.service';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  inputAddress = '';
  inputAddress2 = '';
  inputAddress3 = '';
  inputAddress4 = '';
  // firstname:any;
  // lastname:any;
  // emailid:any;
  // password:any;
  // confirmpassword:any;
  // checkbox:any;
  firstname: '';
  lastname: '';
  emailid: '';
  password: '';
  confirmpassword: '';
  checkbox: false;


  constructor(private ValidationService: ValidationService, private service: ApiService, public toast: ToasterService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }
  submit()
  {
     if(this.function1()){
               
          return;
        }

      this.spinner.show()
      var obj= {
        student_firstname: String(this.firstname),
        student_email: String(this.emailid).toLowerCase(),
        student_password: String(this.password),
        is_not_aepcrt: true,
        is_email_verified: false
          }
          
          this.service.post(this.service.postStudentSignup, obj).subscribe((res) => {
            // console.log(res)
            this.spinner.hide()
            if(res!= null){
              if(res !=0){
              this.toast.showsuccess(this.ValidationService.msg_Validate_SignupSuccess);
              this.router.navigate(['/aep/signin']);
              }
            }
            if( res == 0){
              this.toast.showwarning(this.ValidationService.msg_Validate_SignupFailure);
            }
        })
    }

    
  function1() {

    if (this.ValidationService.funValidateEmptyText(this.firstname, this.ValidationService.msg_Validate_emptyFirstname, '#inputAddress')) {
      return true;
    }


    if (this.ValidationService.funValidateEmptyText(this.emailid, this.ValidationService.msg_Validate_emptyEmail, '#inputAddress2')) {
      return true;
    }

    if (this.ValidationService.funValidateEmailFormat(this.emailid, this.ValidationService.msg_Invalid_Email_Format, '#inputAddress2')) {
      return true;
    }


    if (this.ValidationService.funValidateEmptyText(this.password, this.ValidationService.msg_Validate_emptyPassword, '#inputAddress3')) {
      return true;
    }

    if (this.ValidationService.funValidateEmptyText(this.confirmpassword, this.ValidationService.msg_Validate_emptyConfirmPassword, '#inputAddress4')) {
      return true;
    }

    if (this.password != '' && this.confirmpassword != '') {

      if (this.ValidationService.funvalidateConfirmpswd(this.password, this.confirmpassword, this.ValidationService.msg_Validate_Passwordmatch)) {
        return true;
      }
    }

    if (this.ValidationService.funValidateEmptyText(this.checkbox, this.ValidationService.msg_Validate_emptyCheckbox, '#gridCheck')) {
      return true;
    }

  }

  public gotoSignin() {
    this.router.navigateByUrl('/aep/signin');
  }

}

