
  <h4 class="heading">Use this page to access and view AEP Authorized TIP Centre in your region</h4>
<mat-card class="activeCourse py-4">
  <mat-card-header>
    <mat-card-title class="m-0 p-0">View AEP Authorized TIP Centres</mat-card-title>
</mat-card-header>
<mat-card-content>
  <div class="row px-3 mt-3">
    <div class="col-md-4 px-3 mb-5" *ngFor="let data of indtipdetails; let i = index">
      <div class="tipCard">
        <div class="tip-header">
          <small>TIP Centre Name</small>
          <p>{{data.orgname}}</p>
        </div>
        <img src="../../../assets/img/core-engineering.jpg" class="tipimage" alt="">
        <div class="arp-summary">
          <!-- <p class="d-flex"><small>Industry Type</small>{{data2.industrytype}}</p> -->
          <!-- <p class="d-flex"><small>Contact person firstname</small>{{data2.firstname}}</p> -->
          <p class="d-flex"><small>Contact Number</small>{{data.mobilenumber}}</p>
          <p class="d-flex"><small>Email</small>{{data.email}}</p>
          <p class="d-flex"><small>Website</small>{{data.orgwebsite}}</p>
          <p class="d-flex"><small>Address</small>{{data.orgaddress}}</p>
          <p class="d-flex"><small>City</small>{{data.orgcity}}</p>
          <p class="d-flex"><small>District</small>{{data.orgdistrict}}</p>
          <p class="d-flex"><small>State</small>{{data.orgstate}}</p>
          <p class="d-flex"><small>Country</small>{{data.orgcountry}}</p>
          <div class="btn">
            <button mat-raised-button color="primary" (click)="request(data)" >Request to Empanel</button>  
          </div>
        </div>
      </div>
    </div>
  </div>    
  </mat-card-content>
  <div [hidden]="notiprecords" class="center-align">
    <h3>No AEP Authorized TIP Centre available in your Region.</h3>
  </div>
</mat-card>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>