import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-traininginstitute',
  templateUrl: './traininginstitute.component.html',
  styleUrls: ['./traininginstitute.component.css']
})
export class TraininginstituteComponent implements OnInit {

  constructor( private router: Router) { }

  ngOnInit(): void {
  }

  public gotoContactPartners(){
    // this.router.navigateByUrl('/aep/connectpartners')
    window.open('https://aepcrt.org/', '_blank');
  }

}
