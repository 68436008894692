import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extendedservices',
  templateUrl: './extendedservices.component.html',
  styleUrls: ['./extendedservices.component.css']
})
export class ExtendedservicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public onNavigate(){
    window.open("http://aepcrt.org/", "_blank");
  }

}
